import styled, {css} from "styled-components";
import theme from "../../../assets/css/theme";
import * as React from "react";
import {below} from "../../../assets/css/media";

interface ILeagueFooterWrapper {
	color?: string;
	width?: string;
}

const LeagueFooterWrapper = styled.div<ILeagueFooterWrapper>`
	font-size: 12px;
	font-family: ${theme.base_font};
	line-height: 14px;
	height: 40px;
	color: ${theme.titles};
	display: flex;
	padding: 10px;
	align-items: center;
	box-sizing: border-box;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border: 1px solid #fafafa;
	background-color: #fafafa;
	${({color}) =>
		color &&
		css`
			border: 1px solid ${color};
			background-color: ${color};
		`}
	width: ${({width}) => (width ? width : "100%")};
`;

const LeaguePlayers = styled.div`
	font-family: ${theme.base_bold_font};
	margin-right: 20px;
`;

const LeagueSchedule = styled.div`
	margin-right: 20px;

	${below.desktop`
		margin-right:0;
	`};
`;

interface IProps {
	players: number;
	start_round: number;
	color?: string;
	width?: string;
}

export const LeagueFooter = ({players, start_round, color, width}: IProps) => (
	<LeagueFooterWrapper width={width} color={color}>
		<LeaguePlayers>
			{players} {window.getTranslations("pickem_title_players")}
		</LeaguePlayers>
		<LeagueSchedule>
			{window.getTranslations("mycomps_list_text_starting_gameweek")}{" "}
			{start_round}
		</LeagueSchedule>
	</LeagueFooterWrapper>
);
