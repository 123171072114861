export const tooltipsText = {
	tip_bubble: {
		bubble_name: "tip_bubble",
		textKey: "pickem_tooltip_selection",
		mobText: "pickem_tooltip_popup_one",
		tipIndex: 0,
	},
	margin_bubble: {
		bubble_name: "margin_bubble",
		textKey: "pickem_tooltip_tiebreaker",
		mobText: "pickem_tooltip_popup_two",
		tipIndex: 1,
	},
	form_guide_bubble: {
		bubble_name: "form_guide_bubble	",
		textKey: "pickem_tooltip_formguide",
		mobText: "pickem_tooltip_popup_three",
		tipIndex: 2,
	},
	total_tips_bubble: {
		bubble_name: "total_tips_bubble",
		textKey: "pickem_tooltip_selection_percentage",
		mobText: "pickem_tooltip_popup_four",
		tipIndex: 3,
	},
	leagues_bubble: {
		bubble_name: "leagues_bubble",
		textKey: "pickem_tooltip_leagues",
		mobText: "pickem_tooltip_popup_five",
		tipIndex: 4,
	},
};
