import * as React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {IconArrowSlim} from "../../../components";

import ExternalIcon from "../../../assets/img/icons/external_dark_grey.png";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";
const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";

const Wrapper = styled.div`
	display: block;
`;

const NavLinkStyled = styled(NavLink)`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;

	&.active {
		background-color: #ffffff;
		border-radius: 2px;

		svg {
			color: #1b48e0;
		}
	}

	svg {
		margin: 0 15px;
	}
`;

const GoToExternalPrivacyPolicy = styled.a`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;
	background: url(${ExternalIcon}) no-repeat 13px center;
	padding-left: 50px;

	&.active {
		background-color: #ffffff;
		border-radius: 2px;

		svg {
			color: #1b48e0;
		}
	}

	svg {
		margin: 0 15px;
	}
`;

class HelpWidget extends React.Component {
	/**
	 * @ignore
	 */
	public render() {
		const WIDTH_ARROW = 14;

		return (
			<Wrapper>
				<NavLinkStyled to="/help/game-guidelines">
					<IconArrowSlim
						color="#555555"
						to="right"
						width={WIDTH_ARROW}
					/>
					{window.getTranslations(
						"pickem_footer_hyperlink_howtoplay"
					)}
				</NavLinkStyled>

				<NavLinkStyled to="/help/rules">
					<IconArrowSlim
						color="#555555"
						to="right"
						width={WIDTH_ARROW}
					/>
					{window.getTranslations("help_list_label_rules")}
				</NavLinkStyled>

				{GAME_NAME !== "Mexico" && (
					<NavLinkStyled to="/help/prizes">
						<IconArrowSlim
							color="#555555"
							to="right"
							width={WIDTH_ARROW}
						/>
						{window.getTranslations("pickem_nav_button_prizes")}
					</NavLinkStyled>
				)}

				<NavLinkStyled to="/help/faqs">
					<IconArrowSlim
						color="#555555"
						to="right"
						width={WIDTH_ARROW}
					/>
					FAQ
				</NavLinkStyled>
				<NavLinkStyled to="/help/contact-us">
					<IconArrowSlim
						color="#555555"
						to="right"
						width={WIDTH_ARROW}
					/>
					{window.getTranslations("help_list_label_contact")}
				</NavLinkStyled>

				{GAME_LANGUAGE === "AU" ? (
					<NavLinkStyled to="/help/privacy-policy">
						<IconArrowSlim
							color="#555555"
							to="right"
							width={WIDTH_ARROW}
						/>
						{window.getTranslations(
							"pickem_footer_hyperlink_privacypolicy"
						)}
					</NavLinkStyled>
				) : (
					<GoToExternalPrivacyPolicy
						href={this.privacy_policy_url}
						rel="noopener"
						target="_blank"
					>
						{window.getTranslations(
							"pickem_footer_hyperlink_privacypolicy"
						)}
					</GoToExternalPrivacyPolicy>
				)}

				{GAME_NAME === "Tabcorp" && (
					<NavLinkStyled to="/help/collection-statement">
						<IconArrowSlim
							color="#555555"
							to="right"
							width={WIDTH_ARROW}
						/>
						Collection Statement
					</NavLinkStyled>
				)}
			</Wrapper>
		);
	}

	private get privacy_policy_url() {
		switch (GAME_LANGUAGE) {
			case "DE":
				return "https://www.nfl.com/legal/privacy/de";
			case "MX":
				return "https://www.nfl.com/legal/privacy/es";
			default:
				return "https://www.nfl.com/legal/privacy/";
		}
	}
}

export default HelpWidget;
