import * as React from "react";
import styled from "styled-components";
import ChecedIcon from "../../assets/img/icons/checked.svg";
import CloseIcon from "../../assets/img/icons/close_Icon.svg";

const RadioStyled = styled.input`
	display: none;
`;

interface IRadioButtonWrapper {
	readonly hidden: boolean;
}

const RadioButtonWrapper = styled.div`
	position: relative;
	display: inline-flex;
	visibility: ${({hidden}: IRadioButtonWrapper) =>
		hidden ? "hidden" : "auto"};
`;

const RadioButtonStyled = styled.label`
	height: 30px;
	width: 30px;
	border: 1px solid #151515;
	border-radius: 50%;
	cursor: pointer;

	&.pulse-element {
		border-color: #104d6f;
		box-shadow: 0 0 0 rgba(204, 169, 44, 0);
		animation: pulse 1s infinite;

		@-webkit-keyframes pulse {
			0% {
				-webkit-box-shadow: 0 0 0 0 #a0aacc;
			}
			70% {
				-webkit-box-shadow: 0 0 0 10px #a0aacc;
			}
			100% {
				-webkit-box-shadow: 0 0 0 0 #a0aacc;
			}
		}
		@keyframes pulse {
			0% {
				-moz-box-shadow: 0 0 0 0 #a0aacc;
				box-shadow: 0 0 0 0 #a0aacc;
			}
			70% {
				-moz-box-shadow: 0 0 0 5px #a0aacc;
				box-shadow: 0 0 0 5px #a0aacc;
			}
			100% {
				-moz-box-shadow: 0 0 0 0 #a0aacc;
				box-shadow: 0 0 0 0 #a0aacc;
			}
		}
	}

	${RadioStyled}:checked + & {
		border-color: #1b48e0;
		background: url(${ChecedIcon}) no-repeat #1b48e0 center;
		background-size: 16px;
		box-shadow: 0 0 7px #6587f9;
	}

	&:hover {
		border-color: #eeeeee;
		background: url(${ChecedIcon}) no-repeat #eeeeee center;
		background-size: 16px;
	}
`;

const LoserButton = styled(RadioButtonStyled)`
	border-color: #f50003;

	${RadioStyled}:checked + & {
		border-color: #f50003;
		background: url(${CloseIcon}) no-repeat #f50003 center;
		background-size: 15px;
		box-shadow: none;
	}
`;

const WinnerButton = styled(RadioButtonStyled)`
	border-color: #3cb200;

	${RadioStyled}:checked + & {
		border-color: #3cb200;
		background: url(${ChecedIcon}) no-repeat #3cb200 center;
		background-size: 15px;
		box-shadow: none;
	}
`;

interface IProps {
	readonly name: number;
	readonly value: number;
	readonly is_checked?: boolean;
	readonly hidden: boolean;
	readonly if_wone: boolean;
	readonly status: string;
	readonly is_pulse?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export class RadioButton extends React.Component<IProps> {
	/**
	 * @ignore
	 */
	public render() {
		const {name, value, is_checked, onChange, hidden, status} = this.props;

		return (
			<RadioButtonWrapper hidden={hidden}>
				<RadioStyled
					type="radio"
					id={`${name}_${value}`}
					name={name.toString()}
					value={value}
					checked={is_checked}
					disabled={status !== "scheduled"}
					onChange={onChange}
				/>

				{status !== "complete"
					? this.active_radio_button
					: this.complete_radio_button}
			</RadioButtonWrapper>
		);
	}

	private get active_radio_button() {
		const {name, value, is_pulse} = this.props;

		return (
			<RadioButtonStyled
				htmlFor={`${name}_${value}`}
				className={is_pulse ? "pulse-element" : ""}
			/>
		);
	}

	private get complete_radio_button() {
		const {if_wone} = this.props;

		return if_wone ? <WinnerButton /> : <LoserButton />;
	}
}

export default RadioButton;
