import styled from "styled-components";

export const MobileSectionHeader = styled.div`
	height: 60px;
	padding-left: 20px;
	display: flex;
	align-items: center;
	text-decoration: none;
	font-size: 16px;

	background-color: #fff;
	&.active {
		background-color: #111111;
	}
`;
