import styled from "styled-components";
import theme from "../../../assets/css/theme";

export const HelpSubTitle = styled.h3`
	color: #333;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.1px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 20px;
`;
