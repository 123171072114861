import styled from "styled-components";
import {RegularButton} from "../../../components/Buttons";
import {below} from "../../../assets/css/media";
import theme from "../../../assets/css/theme";

const SPONSOR = process.env.REACT_APP_SPONSOR || "";

interface ILeagueNavButtons {
	justify_end?: boolean;
}

export const LeagueNavButtons = styled.div<ILeagueNavButtons>`
	display: flex;
	align-items: center;
	justify-content: ${({justify_end}) =>
		justify_end ? "flex-end" : "space-between"};
	min-width: 350px;

	${RegularButton} {
		width: 170px;
		background-color: ${theme.sponsor_league_secondary_color};
		color: ${SPONSOR === "pokerstars" ? "#000" : "#FFF"};

		&:hover {
			background: ${theme.sponsor_league_secondary_hover_color};
		}

		${below.desktop`
			width: 160px;
			margin-top: 15px;
		`};
	}

	${below.desktop`
		width: 100%;
		flex-wrap: wrap;
		min-width: 100%;
		padding: 0 20px;
	`};
`;
