import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import * as actions from "../../actions";
import Api from "../../utils/Api";

export const fetchDivisionalStandingsJSONSaga = function* (): SagaIterator {
	try {
		const result = yield call(Api.JSON.divisional_standings);

		if (result) {
			yield put(actions.fetchStandingsJSONSuccess(result));
		}
	} catch (err) {
		yield put(actions.errorsGlobalError(err));
	}
};

export default fetchDivisionalStandingsJSONSaga;
