import * as React from "react";
import {withHeader} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {IRules, IStoreState} from "../../modules/types";
import {getOmnitureTracking} from "../../modules/selectors/";
import {getRules} from "../../modules/selectors/jsons";
import {connect} from "react-redux";
import {fetchRules} from "../../modules/actions";
import {map, get} from "lodash";
import {HelpContent, HelpTitle} from "./help";
import {LoadingSpinner} from "../../components";

interface IProps {
	rules: IRules[];
	fetchRules: typeof fetchRules;
	header_hidden?: boolean;
	readonly omniture_tracking: any;
}

class GameRulesComponent extends React.Component<IProps> {
	public componentDidMount(): void {
		const {fetchRules: _fetchRules, omniture_tracking} = this.props;
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("pick em", "helpModal", "GameRules")
			);
		}
		_fetchRules();
	}

	public render() {
		const {header_hidden} = this.props;

		return header_hidden ? (
			this.rules_content
		) : (
			<HelpPagesWrapper
				title={window.getTranslations("help_list_label_rules") || ""}
			>
				{this.rules_content}
			</HelpPagesWrapper>
		);
	}

	private get rules_content() {
		const {rules} = this.props;

		return rules.length ? (
			<React.Fragment>
				<HelpTitle>
					{window.getTranslations("help_list_label_rules")}
				</HelpTitle>

				{map(
					rules,
					(item, key) =>
						!item.tags && (
							<HelpContent
								key={key}
								dangerouslySetInnerHTML={{
									__html: get(item, "content", ""),
								}}
							/>
						)
				)}
			</React.Fragment>
		) : (
			<LoadingSpinner />
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	rules: getRules(state),
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	fetchRules,
};

export const GameRules = connect(
	mapStateToProps,
	mapDispatchToProps
)(GameRulesComponent);
export default withHeader(GameRules);
