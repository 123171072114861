import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface IModal {
	player_id: number | null;
	show_login: boolean;
}

const initialState: IModal = {
	player_id: null,
	show_login: false,
};

export const modal = createReducer<IModal>({}, initialState)
	.on(actions.showPlayerModal, (state, player_id) => ({...state, player_id}))
	.on(actions.closePlayerModal, (state) => ({...state, player_id: null}))
	.on(actions.showLoginModal, (state) => ({...state, show_login: true}))
	.on(actions.closeLoginModal, (state) => ({...state, show_login: false}));
