import * as _ from "lodash";
export * from "./Api";
export * from "./social_share";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";
const matchMedia = window.matchMedia;

export const isDesktop = () =>
	typeof matchMedia === "undefined" ||
	matchMedia("(min-width: 1200px)").matches;

export const isMobile = () =>
	typeof matchMedia === "undefined" ||
	matchMedia("(max-width: 840px)").matches;

export const delay = (ms: number): Promise<NodeJS.Timeout> =>
	new Promise((res: any) => setTimeout(res, ms));

export const convertDecimalToAmerican = (odds: number) => {
	const americanOdds = odds > 2 ? 100 * (odds - 1) : -100 / (odds - 1);
	const americanOddsFixed = americanOdds.toFixed(0);
	return `${americanOddsFixed}`.charAt(0) === "-"
		? americanOddsFixed
		: `+${americanOddsFixed}`;
};

export const formatOdds = (odds: string | number) => {
	const oddsAsNumber = Number(odds);
	if (GAME_NAME === "Mexico") {
		return convertDecimalToAmerican(oddsAsNumber);
	} else {
		const convertedOdds = oddsAsNumber;
		const hasDecimal = convertedOdds % 1;
		const isEuro = GAME_NAME === "prosieben";
		const seperator = isEuro ? "," : ".";
		if (hasDecimal) {
			const oddsWithDecimal = convertedOdds.toFixed(2);
			return isEuro ? oddsWithDecimal.replace(".", ",") : oddsWithDecimal;
		} else {
			return `${odds}${seperator}00`;
		}
	}
};

export const germanOddsStr = (odds: string) => `Quote: ${odds} Jetzt Wetten`;
export const getCurrencyLabel = () => {
	return (
		{
			prosieben: "€",
			Mexico: "",
			UK: "£",
		}[GAME_NAME] || ""
	);
};

export const SIZE_OF_PREDICTIONS = JSON.parse(
	process.env.REACT_APP_SIZE_OF_PREDICTIONS || "15"
);
export const TOTAL_PICKS_AT_FIRST_ROUND = 32;

const IS_PRODUCTION = _.eq(process.env.REACT_APP_ENVIRONMENT, "production");
const REACT_APP_DK_LOGIN_ID = process.env.REACT_APP_DK_LOGIN_ID;
const REACT_APP_SSO_TOKEN_PAGE = process.env.REACT_APP_SSO_TOKEN_PAGE;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const PROD_LOGIN_URL = `https://www.draftkings.com/gateway?s=${REACT_APP_DK_LOGIN_ID}`;
const UAT_LOGIN_URL = `${REACT_APP_SSO_TOKEN_PAGE}&signup=false&returnUrl=${REACT_APP_BASE_URL}login`;

export const LOGIN_LINK = IS_PRODUCTION ? PROD_LOGIN_URL : UAT_LOGIN_URL;

export * from "./constants";
