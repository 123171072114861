import * as React from "react";
import {RouteComponentProps, withRouter, Redirect} from "react-router";
import {connect} from "react-redux";
import {IStoreState} from "../../modules/types";
import * as selectors from "../../modules/selectors";

interface IProps extends RouteComponentProps {
	is_logged_in: boolean;
}

const SECRET_KEY_ENABLED = JSON.parse(
	process.env.REACT_APP_SECRET_KEY || "false"
);

const SecretKeyComponent = (props: IProps) => {
	const SECRET_COUNT = 10;
	const {
		location: {pathname},
		is_logged_in,
	} = props;
	const secret_count = localStorage.getItem("secret_count") || 0;
	const is_secret_enabled =
		!!SECRET_KEY_ENABLED && secret_count < SECRET_COUNT;

	if (is_secret_enabled && !is_logged_in && pathname !== "/") {
		return <Redirect to="/" />;
	}

	return null;
};

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.isLoggedIn(state),
});

export const SecretKey = withRouter(
	connect(mapStateToProps)(SecretKeyComponent)
);
export default SecretKey;
