import {IJoinLeague, ILeague, IMyLeague, IStoreState} from "../types";
import {get, isEmpty} from "lodash";
import {createSelector} from "reselect";
import {getGameWeeks} from "./game_weeks";

export const getMyLeagues = ({my_leagues}: IStoreState): ILeague[] =>
	get(my_leagues, "items", []);

export const isLeaguesDataRequested = ({my_leagues}: IStoreState): boolean =>
	get(my_leagues, "is_data_requested", false);
export const getJoinLeagues = ({join_leagues}: IStoreState): IJoinLeague[] =>
	get(join_leagues, "items", []);

export const isJoinLeaguesDataRequested = ({
	join_leagues,
}: IStoreState): boolean => get(join_leagues, "is_data_requested", false);

export const hasNextLoadMore = ({join_leagues}: IStoreState): boolean =>
	get(join_leagues, "next", false);

export const getJoinedCodes = ({join_leagues}: IStoreState) =>
	get(join_leagues, "joined_codes", {});
export const getMyLeague = ({league_manage}: IStoreState) =>
	get(league_manage, "league", {});
export const isLeagueDataRequested = ({league_manage}: IStoreState): boolean =>
	get(league_manage, "is_data_requested", false);

export const getLeagueLadder = ({league}: IStoreState) => get(league, "ladder");
export const getSponsoredLadder = ({league}: IStoreState) =>
	get(league, "sponsored_rank");

export const getLadderRounds = createSelector(
	getMyLeague,
	getGameWeeks,
	(league, game_weeks) => {
		if (isEmpty(league) || isEmpty(game_weeks)) {
			return [];
		}

		const {start_round} = league as IMyLeague;

		return (
			game_weeks.filter(({id, status}) => {
				return id >= start_round && status !== "scheduled";
			}) || game_weeks.filter(({id}) => id === start_round)
		);
	}
);

export const getLeagueJoinedUsers = ({league}: IStoreState) =>
	get(league, "joined_users");
