import * as React from "react";
import {IUserTips} from "../../modules/types";
import {IMatch} from "./../../modules/reducers/gameweeks";
import {
	map,
	bindAll,
	reduce,
	isNaN,
	debounce,
	filter,
	size,
	toInteger,
	isEmpty,
} from "lodash";
import {SquadAvatar} from "../";
import logoSrc from "../../assets/img/sponsor/888-logo-white.svg";
import {formatOdds, getCurrencyLabel, isMobile, ODDS_PARTNER_KEY} from "../../modules/utils";
import {
	BigLabel,
	DescriptionDiv,
	FlexibleInputsDiv,
	FlexibleDiv,
	LogosDiv,
	LinkOut,
	OddsGeneratorheaderDiv,
	OddsGeneratorWrapperDiv,
	OddsValueDiv,
	OddsWrapperDiv,
	MultiWrapperDiv,
	PlaceBetDisabledButton,
	SmallLabel,
	SeparatorDiv,
	TeamInfoDiv,
	TipItemDiv,
	TipItemWrapperDiv,
	TipsWrapperDiv,
	TitleH5,
} from "./MultiGeneratorStyles";

const FIXED_NUM = 2;

interface IProps {
	readonly tips: IUserTips[] | [];
}

interface IState {
	stake: number;
	is_mobile: boolean;
}

const getLink = (oddsPropIds: string) => {
	return oddsPropIds
		? `https://www.888sport.com/deeplink/?selections=[${oddsPropIds}]`
		: "";
};

export class MultiOddsGeneratorComponent extends React.PureComponent<
	IProps,
	IState
> {
	public state = {
		stake: 10,
		is_mobile: isMobile(),
	};
	private onResizeDebounced?: () => void;

	constructor(props: IProps, state: IState) {
		super(props, state);
		bindAll(this, ["setStake", "onResize"]);
	}
	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const debounce_time = 100;
		this.onResizeDebounced = debounce(this.onResize, debounce_time);
		window.addEventListener("resize", this.onResizeDebounced);
	}
	/**
	 * @ignore
	 */
	public render() {
		if (!this.is_odds) {
			return null;
		}
		const {is_mobile} = this.state;
		const AVATAR_WIDTH = 20;
		const odds_prop_ids = this.odds_prop_ids;

		const formattedOddsValue = this.odds_value.toFixed(2);

		return (
			<MultiWrapperDiv>
				<OddsGeneratorheaderDiv>
					<LogosDiv>
						<img src={logoSrc} alt="888" />
					</LogosDiv>
					<TitleH5>
						{window.getTranslations("pickem_betting_calculator")}
					</TitleH5>
					<DescriptionDiv>
						{window.getTranslations("pickem_betting_text_multi")}
					</DescriptionDiv>
				</OddsGeneratorheaderDiv>
				<OddsGeneratorWrapperDiv>
					<TipsWrapperDiv>
						{map(this.odds_tips, (tip) => (
							<TipItemWrapperDiv key={tip.id}>
								<TipItemDiv
									is_active={
										tip.predict_winner_squad_id ===
										tip.away_squad_id
									}
								>
									<OddsValueDiv
										is_active={
											tip.predict_winner_squad_id ===
											tip.away_squad_id
										}
									>
										{getCurrencyLabel()}
										{formatOdds(
											tip.away_squad_odds_data!.decimal
										)}
									</OddsValueDiv>

									<TeamInfoDiv>
										{is_mobile
											? tip.away_squad_short_name
											: tip.away_squad_name}
										<SquadAvatar
											alt={tip.away_squad_name}
											squad_id={tip.away_squad_id}
											margin="0 20px"
											width={AVATAR_WIDTH}
										/>
									</TeamInfoDiv>
								</TipItemDiv>
								<SeparatorDiv>
									{window.getTranslations(
										"pickem_betting_at"
									)}
								</SeparatorDiv>
								<TipItemDiv
									is_active={
										tip.predict_winner_squad_id ===
										tip.home_squad_id
									}
								>
									<TeamInfoDiv>
										<SquadAvatar
											alt={tip.home_squad_name}
											squad_id={tip.home_squad_id}
											margin="0 20px"
											width={AVATAR_WIDTH}
										/>
										{is_mobile
											? tip.home_squad_short_name
											: tip.home_squad_name}
									</TeamInfoDiv>

									<OddsValueDiv
										is_active={
											tip.predict_winner_squad_id ===
											tip.home_squad_id
										}
									>
										{getCurrencyLabel()}
										{formatOdds(
											tip.home_squad_odds_data!.decimal
										)}
									</OddsValueDiv>
								</TipItemDiv>
							</TipItemWrapperDiv>
						))}

						<OddsWrapperDiv>
							<BigLabel>
								{window.getTranslations(
									"pickem_betting_text_stake"
								)}
							</BigLabel>

							<FlexibleInputsDiv>
								<input
									type="text"
									value={`${getCurrencyLabel()}${
										this.state.stake
									}`}
									onChange={this.setStake}
									name="stake"
								/>
								{odds_prop_ids ? (
									<LinkOut
										href={getLink(odds_prop_ids)}
										target="_blank"
										rel="noopener noreferrer"
										is_active={!!odds_prop_ids}
									>
										{window.getTranslations(
											"pickem_betting_text_place"
										)}
									</LinkOut>
								) : (
									<PlaceBetDisabledButton disabled={true}>
										{window.getTranslations(
											"pickem_betting_text_place"
										)}
									</PlaceBetDisabledButton>
								)}
							</FlexibleInputsDiv>
							<FlexibleDiv>
								<div>
									<SmallLabel>
										{window.getTranslations(
											ODDS_PARTNER_KEY
										)}
										:
									</SmallLabel>
									<p>
										{this.odds_value
											? `${getCurrencyLabel()}${formattedOddsValue}`
											: "NA"}
									</p>
								</div>
								<div>
									<SmallLabel>
										{window.getTranslations(
											"pickem_betting_text_estimated"
										)}
										:
									</SmallLabel>
									<p>{this.estimated_return}</p>
								</div>
								<div>
									<SmallLabel>
										{window.getTranslations(
											"pickem_betting_text_gamble_responsibly"
										)}
									</SmallLabel>
								</div>
							</FlexibleDiv>
						</OddsWrapperDiv>
					</TipsWrapperDiv>
				</OddsGeneratorWrapperDiv>
			</MultiWrapperDiv>
		);
	}

	private get odds_tips() {
		const {tips} = this.props;
		const tipsWithOdds = tips.filter(
			(tip: IMatch) =>
				tip.status === "scheduled" &&
				!isEmpty(tip.home_squad_odds_data) &&
				!isEmpty(tip.away_squad_odds_data)
		);
		const LIMIT = 5;
		return tipsWithOdds.slice(0, LIMIT);
	}

	private get is_odds() {
		if (this.odds_tips[0] && this.odds_tips[0].status !== "scheduled") {
			return false;
		}
		return (
			size(
				filter(
					this.odds_tips,
					(tip) =>
						!isEmpty(tip.home_squad_odds_data) &&
						!isEmpty(tip.away_squad_odds_data)
				)
			) > 0
		);
	}

	private get odds_value() {
		const acc_def = 1;
		const odds_value = reduce(
			this.odds_tips,
			(acc, item) => {
				const {
					predict_winner_squad_id,
					home_squad_id,
					away_squad_id,
					home_squad_odds_data,
					away_squad_odds_data,
				} = item;

				if (
					predict_winner_squad_id === home_squad_id &&
					home_squad_odds_data?.decimal
				) {
					return home_squad_odds_data!.decimal * acc;
				}

				if (
					predict_winner_squad_id === away_squad_id &&
					away_squad_odds_data?.decimal
				) {
					return away_squad_odds_data!.decimal * acc;
				}

				return acc;
			},
			1
		);

		return odds_value === acc_def ? 0 : odds_value;
	}

	private get estimated_return() {
		const estimated_return = this.odds_value * this.state.stake;
		return isNaN(estimated_return) || estimated_return === 0
			? "NA"
			: `${getCurrencyLabel()}${estimated_return.toFixed(FIXED_NUM)}`;
	}

	private get odds_prop_ids() {
		return this.odds_tips
			.map((item) => {
				const {
					predict_winner_squad_id,
					home_squad_id,
					away_squad_id,
					home_squad_odds_data,
					away_squad_odds_data,
				} = item;

				if (predict_winner_squad_id === home_squad_id) {
					return home_squad_odds_data?.id;
				}

				if (predict_winner_squad_id === away_squad_id) {
					return away_squad_odds_data?.id;
				}

				return "";
			})
			.filter((item) => item !== "")
			.join(",");
	}

	private setStake(e: React.SyntheticEvent<HTMLInputElement>) {
		e.preventDefault();
		const {value} = e.currentTarget;
		const stake = toInteger(value.substring(1));
		if (!stake) {
			this.setState({
				stake: 0,
			});
			return;
		}

		this.setState({
			stake,
		});
	}

	private onResize() {
		const is_mobile = isMobile();

		this.setState({
			is_mobile,
		});
	}
}

export const MultiOddsGeneratorUK = MultiOddsGeneratorComponent;

export default MultiOddsGeneratorUK;
