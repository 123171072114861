// @flow
import * as React from "react";

import ErrorField from "./ErrorField";
import styled from "styled-components";

interface IInput {
	readonly error?: boolean;
	readonly is_disabled?: boolean;
}

const Label = styled.label`
	color: ${({error}: IInput) => (error ? "red" : "#757575")};
	display: block;
	font-size: 12px;
	padding-bottom: 5px;
	line-height: 20px;
`;

const Input = styled.input`
	height: 40px;
	width: 100%;
	border: 1px solid ${({error}: IInput) => (error ? "red" : "#CCCCCC")};
	color: ${({error}: IInput) => (error ? "red" : "#757575")};
	opacity: ${({error}: IInput) => (error ? ".6" : "1")};
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 10px;
	margin-top: 5px;

	&:focus {
		border-color: #1b48e0;
		color: #333333;
	}
`;

interface IMeta {
	touched: any;
	error: string;
	warning?: string;
}

interface IProps {
	label: string;
	name: string;
	input: any;
	type: string;
	placeholder: string;
	className?: string;
	meta: IMeta;
	is_disabled: boolean;
	max_length: number;
}

const is_error = (meta: IMeta) => {
	return !!(meta.touched && meta.error);
};

export const FormField = ({
	label,
	name,
	type,
	placeholder,
	input,
	is_disabled,
	meta: {touched, error, warning},
	className,
	max_length,
}: IProps) => (
	<div className={className}>
		{name}
		<Label htmlFor={name} error={is_error({touched, error})}>
			{label}
		</Label>
		{touched &&
			((error && <ErrorField>{error}</ErrorField>) ||
				(warning && <ErrorField>{warning}</ErrorField>))}
		<Input
			name={input.name}
			onChange={input.onChange}
			defaultValue={input.defaultValue}
			onDragStart={input.onDragStart}
			onDrop={input.onDrop}
			onFocus={input.onFocus}
			type={type}
			placeholder={placeholder}
			is_disabled={is_disabled}
			error={is_error({touched, error})}
			maxLength={max_length}
		/>
	</div>
);

export default FormField;
