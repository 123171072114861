import {isMobile} from "../utils";

export const getOmnitureTracking = (
	siteSection: string,
	siteSubSection: string,
	pageDetail: string,
	user: any,
	leagueID: any,
	teamID: any
) => {
	if (window?.dataLayer) {
		window.dataLayer.customVars = {
			page: {
				pageName: `nfl fantasy intl game:${siteSection}:${siteSubSection}:${pageDetail}`,
				siteSection: `${siteSection}`,
				siteSubsection: `${siteSubSection}`,
				siteName: "nfl fantasy intl game",
				pageDetail: `${pageDetail}`,
				optimizedFor: isMobile() ? "phone app" : "desktop",
			},
			user: {
				userID: user ? user.id : "",
				leagueID: leagueID ? leagueID : "",
				teamID: teamID ? teamID : "",
			},
		};
	}
};
