import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconStar = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 20 23`}
		{...rest}
	>
		<title>Star</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-24.000000, -18.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<path d="M42.9655486,24.8255514 C42.8770673,24.5538846 42.6221987,24.3706119 42.3342159,24.3706119 L36.0593927,24.3817574 L34.1292998,18.4541694 C34.0404918,18.1831106 33.7894613,18 33.5016419,18 C33.2136591,18 32.9553198,18.1831917 32.8666752,18.4541694 L30.9400937,24.3817574 L24.6652705,24.3706929 C24.3772877,24.3706929 24.1225824,24.5539251 24.0341419,24.8256324 C23.9418633,25.0966102 24.0415324,25.3966068 24.2740663,25.5652891 L29.3601695,29.2144914 L27.4075786,35.1351894 C27.3190973,35.4062077 27.4151323,35.7068123 27.6474621,35.8714011 C27.8837117,36.0400833 28.1975407,36.0400833 28.4300746,35.8714011 L33.5015602,32.2007993 L38.5692077,35.8714011 C38.6837801,35.9557422 38.8241171,36 38.960616,36 C39.0971148,36 39.2336137,35.9557422 39.3481453,35.8714011 C39.5841499,35.7067717 39.6803483,35.4061672 39.5919079,35.1351894 L37.6391944,29.2144509 L42.7256242,25.565208 C42.9581174,25.3965258 43.0539891,25.0965291 42.9655486,24.8255514 Z" />
			</g>
		</g>
	</svg>
);

IconStar.defaultProps = {
	width: 20,
	height: 23,
	color: "#1976D2",
};

export default IconStar;
