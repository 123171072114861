import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface ISquad {
	readonly id: number;
	readonly title: string;
	readonly first_name: string;
	readonly full_name: string;
	readonly last_name: string;
	readonly name: string;
	readonly short_name: string;
	readonly pick_order: number[];
	readonly color: string;
}

const initialState: ISquad[] = [];

export const squads = createReducer<ISquad[]>({}, initialState)
	.on(
		actions.fetchSquadsJSONSuccess,
		(state: ISquad[], payload: ISquad[]) => payload
	)
	.on(actions.fetchSquadsJSONFailed, () => initialState);
