import * as React from "react";
import styled from "styled-components";

const mapDirectionToDegrees = {
	top: 0,
	right: 90,
	bottom: 180,
	left: 270,
};

type Direction = "top" | "bottom" | "left" | "right";

interface IPropSide {
	to: Direction;
}

interface IProps {
	width: number;
	height: number;
	color: string;
	to: Direction;
}

const SvgContainer = styled.svg`
	transform: rotate(${({to}: IPropSide) => mapDirectionToDegrees[to]}deg);
`;

export const IconArrowSlide = ({width, height, to, ...rest}: IProps) => (
	<SvgContainer
		to={to}
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 10 7`}
		{...rest}
	>
		<title>Arrow slide</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-9.000000, -16.000000)" fill="currentColor">
				<path
					d="M14.625,16.3079912 L18.7411165,21.2048957 C19.0862945,21.6155507 19.0862945,
					22.2813539 18.7411165,22.6920088 C18.5753562,22.8892123 18.350537,23 18.1161165,
					23 L9.88388348,23 C9.39572811,23 9,22.529206 9,21.9484523 C9,21.6695645 9.09312317,
					21.4020992 9.25888348,21.2048957 L13.375,16.3079912 C13.720178,15.8973363 14.279822,
					15.8973363 14.625,16.3079912 Z"
				/>
			</g>
		</g>
	</SvgContainer>
);

IconArrowSlide.defaultProps = {
	width: 10,
	height: 7,
	color: "#9E9E9E",
	to: "top",
};

export default IconArrowSlide;
