import * as React from "react";
import {Bling as GPT} from "react-gpt";
import styled from "styled-components";
import {bindAll, debounce} from "lodash";
import {isMobile} from "../../modules/utils";
GPT.syncCorrelator();
GPT.enableSingleRequest();
const REACT_APP_AD_KEY = process.env.REACT_APP_AD_KEY || "";
const AdWrapper = styled.div`
	justify-content: center;
	display: flex;
	//background: #333333;
	//padding: 15px;
	//box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
	//width: 100%;
	//max-width: 723px;
	//overflow: hidden;
	//margin: 25px auto;
	//min-height: 90px;
	//box-sizing: border-box;
	//
	//@media screen and (max-width: 1000px) {
	//	max-width: 758px;
	//}
	//
	//@media screen and (max-width: 758px) {
	//	max-width: 350px;
	//	min-height: 80px;
	//}
	//
	//@media screen and (max-width: 350px) {
	//	padding: 0;
	//}
`;

interface IState {
	is_mobile: boolean;
}

export class Ad extends React.Component<{}, IState> {
	public state = {
		is_mobile: isMobile(),
	};

	private onResizeDebounced?: () => void;

	constructor(props: {}, state: IState) {
		super(props, state);
		bindAll(this, ["onResize"]);
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const debounce_time = 100;
		this.onResizeDebounced = debounce(this.onResize, debounce_time);
		window.addEventListener("resize", this.onResizeDebounced);
	}
	/**
	 * @ignore
	 */
	public render() {
		const {is_mobile} = this.state;
		const size = is_mobile
			? [Number("320"), Number("50")]
			: [Number("300"), Number("250")];
		const slot = is_mobile ? "bottom" : "rightrail";
		return (
			<AdWrapper>
				<GPT
					id={`medrec`}
					adUnitPath={`/4595/nfl.fantasy.international/pickem/${REACT_APP_AD_KEY}`}
					targeting={{
						s1: "fantasy",
						s2: "international",
						s3: "pickem",
						slot,
						geo: REACT_APP_AD_KEY,
					}}
					slotSize={[size]}
				/>
				{/*<div id="div-id-for-top-leaderboard" />*/}
			</AdWrapper>
		);
	}

	private onResize() {
		const is_mobile = isMobile();

		this.setState({
			is_mobile,
		});
	}
}

export default Ad;
