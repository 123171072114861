import * as React from "react";
import styled, {css} from "styled-components";

// import ClearIcon from '../../assets/img/icons/close_Icon.svg';
import ClearIcon from "../../assets/img/icons/clear-grey.svg";
import passwordIcon from "../../assets/img/icons/password_icon.png";
import lock_icon from "../../assets/img/icons/Lock.png";

interface IInput {
	readonly is_error?: boolean;
	readonly is_disabled?: boolean;
}

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`;

const InputStyle = styled.input`
	height: 40px;
	width: 100%;
	border: 1px solid ${({is_error}: IInput) => (is_error ? "red" : "#CCCCCC")};
	color: ${({is_error}: IInput) => (is_error ? "red" : "#757575")};
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 10px;
	margin-top: 5px;

	&:focus {
		border-color: #1b48e0;
		color: #333333;
	}

	&:not(:valid) {
		border-color: red;
		color: red;
	}

	${({is_disabled}) =>
		is_disabled &&
		css`
			background: url(${lock_icon}) no-repeat right 10px center;
			pointer-events: none;
		`};
`;

const ClearElement = styled.div`
	position: absolute;
	//right: 0;
	//top: 19px;
	top: 14px;
	right: 10px;
	border: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	background: url(${ClearIcon}) no-repeat;
`;

const PasswordVisibleDiv = styled.div`
	position: absolute;
	//right: 0;
	//top: 19px;
	top: 14px;
	right: 10px;
	border: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	background: url(${passwordIcon}) no-repeat right 10px center;
`;

const defaultOnChange = () => null;

interface IProps {
	name: string;
	type: string;
	value?: string;
	placeholder?: string;
	className?: string;
	is_disabled?: boolean;
	maxLength?: number;
	minLength?: number;
	is_error?: boolean;
	clearField?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index?: number
	) => void | undefined;
	togglePassword?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index?: number
	) => void | undefined;
	onChange?: any;
	required?: boolean;
	pattern?: string;
	defaultValue?: string;
}

export const Input = ({
	name,
	type,
	value,
	placeholder,
	is_disabled,
	className,
	maxLength,
	minLength,
	is_error,
	clearField,
	togglePassword,
	onChange,
	required,
	pattern,
	defaultValue,
}: IProps) => (
	<InputWrapper className={className}>
		<InputStyle
			name={name}
			id={name}
			type={type}
			value={value}
			placeholder={placeholder}
			is_disabled={is_disabled}
			is_error={is_error}
			maxLength={maxLength}
			minLength={minLength}
			onChange={onChange || defaultOnChange}
			required={required}
			pattern={pattern}
			defaultValue={defaultValue}
		/>
		{value && value !== "" && type === "text" && !is_disabled ? (
			<ClearElement data-element_name={name} onClick={clearField} />
		) : null}
		{["password", "confirm_password"].includes(name) ? (
			<PasswordVisibleDiv
				data-element_name={name}
				onClick={togglePassword}
			/>
		) : null}
	</InputWrapper>
);

export default Input;
