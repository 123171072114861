import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconSearch = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 16 16`}
		{...rest}
	>
		<title>Search</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-20.000000, -18.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<g transform="translate(20.000000, 15.000000)">
					<g transform="translate(0.000000, 3.000000)">
						<path
							d="M6.73684211,13.8947368 C3.01618695,13.8947368 0,10.8785499 0,7.15789474 C0,
							3.43723958 3.01618695,0.421052632 6.73684211,0.421052632 C10.4574973,
							0.421052632 13.4736842,3.43723958 13.4736842,7.15789474 C13.4736842,
							10.8785499 10.4574973,13.8947368 6.73684211,13.8947368 Z M6.73684211,
							12.7320331 C9.81535373,12.7320331 12.3109805,10.2364064 12.3109805,
							7.15789474 C12.3109805,4.07938311 9.81535373,1.58375635 6.73684211,
							1.58375635 C3.65833048,1.58375635 1.16270371,4.07938311 1.16270371,
							7.15789474 C1.16270371,10.2364064 3.65833048,12.7320331 6.73684211,12.7320331 Z"
						/>
						<path
							d="M11.1249083,11.561094 C10.8881885,11.3243742 10.8881885,10.9405755 11.1249083,
							10.7038557 C11.3616281,10.4671358 11.7454268,10.4671358 11.9821467,10.7038557 L15.8224601,
							14.5441691 C16.05918,14.780889 16.05918,15.1646877 15.8224601,15.4014075 C15.5857403,
							15.6381273 15.2019416,15.6381273 14.9652218,15.4014075 L11.1249083,11.561094 Z"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

IconSearch.defaultProps = {
	width: 16,
	height: 16,
	color: "#FFF",
};

export default IconSearch;
