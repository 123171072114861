import * as React from "react";
import {connect} from "react-redux";
import {IGigyaOnLogin, IGigyaUserData, IStoreState} from "../../modules/types";
import {
	fetchUser,
	gigyaIsReady,
	setSSOUser,
	userLogin,
	userLogout,
} from "../../modules/actions";
import {IUserReducerState} from "../../modules/reducers/user";
import LoadingSpinner from "../AppLoading";
import {isLoggedIn} from "../../modules/selectors";
import styled from "styled-components";
import GigyaWrapper from "gigya-api-wrapper";
import {first, get} from "lodash";
import * as selectors from "../../modules/selectors";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IS_LOCAL, OFF_SEASON_ENABLED} from "modules/utils";
import {SECRET_COUNT} from "../Navigation/HeaderNavigation";

const PreloaderContainer = styled.div`
	width: 100%;
	height: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface IProps extends RouteComponentProps {
	children: React.ReactNode;
	user: IUserReducerState;
	is_logged_in: boolean;
	additional_information_required: boolean;
	fetchUser: typeof fetchUser;
	gigyaWrapperInstance: typeof GigyaWrapper;
	gigyaIsReady: typeof gigyaIsReady;
	gigya_is_ready: boolean;
	userLogin: typeof userLogin;
	userLogout: typeof userLogout;
	setSSOUser: typeof setSSOUser;
}

interface IState {
	show_children: boolean;
}

const SECRET_KEY_ENABLED = JSON.parse(
	process.env.REACT_APP_SECRET_KEY || "false"
);

class FetchUserComponent extends React.Component<IProps, IState> {
	public readonly state = {
		show_children: false,
	};

	public componentDidMount(): void {
		const {
			is_logged_in,
			fetchUser: refreshUser,
			gigyaIsReady: checkGigya,
		} = this.props;

		this.props.gigyaWrapperInstance
			.onLibraryReady()
			.then(() => {
				checkGigya();
				this.props.gigyaWrapperInstance.registerEventListeners({
					onLogout: this.props.userLogout,
				});
				return this.getSSOUser();
			})
			.catch(() => this.setState({show_children: true}));

		if (is_logged_in) {
			refreshUser();
		}
	}

	public get secret_count(): number {
		return Number(localStorage.getItem("secret_count"));
	}

	private get secret_key_enabled() {
		const {is_logged_in} = this.props;

		if (!SECRET_KEY_ENABLED) {
			return true;
		}

		return (
			!!SECRET_KEY_ENABLED &&
			!is_logged_in &&
			this.secret_count < SECRET_COUNT
		);
	}

	/**
	 * Fetch user object from BE
	 */
	public componentDidUpdate(prevProps: IProps): void {
		const {user, additional_information_required} = this.props;
		const is_user_requested =
			prevProps.user.is_data_requested !== user.is_data_requested;
		const is_user_received =
			is_user_requested && user.is_data_requested === false;

		if (is_user_received) {
			this.setState({show_children: true});
		}

		if (OFF_SEASON_ENABLED && this.secret_key_enabled) {
			return;
		}

		if (
			this.props.history.location.pathname !== "/register-for-game" &&
			additional_information_required &&
			prevProps.additional_information_required !==
				additional_information_required
		) {
			this.props.history.push("/register-for-game");
		}
	}

	/**
	 * @ignore
	 */
	public render(): React.ReactNode {
		const {show_children} = this.state;
		const {children, gigya_is_ready} = this.props;

		if (IS_LOCAL) {
			return children;
		}

		return show_children && (gigya_is_ready || SECRET_KEY_ENABLED) ? (
			children
		) : (
			<PreloaderContainer>
				<LoadingSpinner />
			</PreloaderContainer>
		);
	}

	private getSSOUser() {
		return this.props.gigyaWrapperInstance
			.getAccountInfo()
			.then((resp: any) => {
				if (resp.errorCode) {
					this.setState({show_children: true});
					return;
				}

				const ssoLoginParams: IGigyaOnLogin = {
					email: resp.profile.email,
					gigya_id: resp.UID,
					gigya_signature: resp.UIDSignature,
					gigya_signature_timestamp: resp.signatureTimestamp,
				};

				this.props.userLogin(ssoLoginParams);

				const ssoUserData: IGigyaUserData = {
					...ssoLoginParams,
					first_name: resp.profile.firstName,
					last_name: resp.profile.lastName,
					birthday: `${resp.profile.birthYear}-${resp.profile.birthMonth}-${resp.profile.birthDay}`,
					postcode: resp.profile.zip,
					team_supported:
						first(get(resp, "data.league.favoriteTeams", ["0"])) ||
						"0",
				};

				this.props.setSSOUser(ssoUserData);
			});
	}
}

const mapStateToProps = (state: IStoreState) => ({
	user: state.user,
	is_logged_in: isLoggedIn(state),
	additional_information_required:
		selectors.isAdditionalInformationRequired(state),
	gigyaWrapperInstance: state.user.gigyaWrapperInstance,
	gigya_is_ready: state.user.gigya_is_ready,
});

const mapDispatchToProps = {
	fetchUser,
	gigyaIsReady,
	setSSOUser,
	userLogin,
	userLogout,
};

export const FetchUser = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(FetchUserComponent)
);

export default FetchUser;
