import styled from "styled-components";
import theme from "../../../assets/css/theme";

export const HelpTitle = styled.h2`
	color: #333;
	font-size: 28px;
	line-height: 34px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 40px;
`;
