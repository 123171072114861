import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IError} from "../../types";

const defaultState: IError = {
	text: "",
};

export const error_managment = createReducer<IError>({}, defaultState)
	.on(actions.errorsShowGlobalError, (state: IError, payload: IError) => ({
		...state,
		...payload,
	}))
	.on(actions.errorsClearGlobalError, (state: IError) => ({
		...defaultState,
	}));

export default error_managment;
