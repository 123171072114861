import styled from "styled-components";
import {Link} from "react-router-dom";
import theme from "../../assets/css/theme";
import {below} from "../../assets/css/media";

export const FooterWrapper = styled.div`
	background-color: ${theme.footer_bg};
	color: ${theme.menu_color};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 360px;
`;

export const FooterMenuWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const TermStyles = `
position: relative;
font-size: 14px;
margin: 20px;
color: ${theme.footer_text_color};
padding-bottom: 10px;

&:after {
	content: "";
	position: absolute;
	width: 100%;
	border-bottom: 1px solid #555555;
	left: 0;
	bottom: 1px;
}
`;
export const TermLink = styled(Link)`
	${TermStyles}
`;

export const TermExternalLink = styled.a`
	${TermStyles}
`;

export const GambleDiv = styled.div`
	position: relative;
	font-size: 14px;
	margin: 20px 0;
	color: ${theme.footer_text_color};
	padding-bottom: 10px;
`;

export const Copywriting = styled.div`
	font-size: 12px;
	line-height: 20px;
	max-width: 940px;
	text-align: center;
	color: ${theme.footer_text_color};
`;

export const FooterImgWrapDiv = styled.div`
	display: flex;
	flex-direction: row;
`;

export const FooterImgDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0 20px;
`;

export const FooterImg = styled.img`
	max-width: 50px;
`;

export const FooterTermsDiv = styled.div`
	display: flex;
	${below.desktop`
		flex-direction: column;
	`}
`;
