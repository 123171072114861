export const getIVWTracking = (pageDetail: string) => {
	const iam_data = {
		st: "ran",
		cp: "dTxRcCpOqKyFz02usspo",
		sv: "i2",
		co: pageDetail,
	};
	if (window.iom && window.iom.c) {
		window.iom.c(iam_data, 1);
	}
};
