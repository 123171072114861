import * as React from "react";
import styled, {css} from "styled-components";
import {connect} from "react-redux";
import {IconArrowSlim} from "../";
import {bindAll, find, get, toPairs} from "lodash";
import {IFormGuide, ISquad, IStoreState} from "../../modules/types";
import {fetchFormGuide} from "../../modules/actions";
import field from "../../assets/img/field-background.png";
import theme from "../../assets/css/theme";
import {getIsSeasonStarted} from "../../modules/selectors";

const HELM_URL = process.env.REACT_APP_SQUADS_URL || "";

interface IIsWin {
	is_win: boolean;
}

const ChevronWrapper = styled.button`
	height: 30px;
	text-align: center;
	outline: none;
	border: none;
	background: #fff;
	cursor: pointer;
	width: 100%;

	svg {
		margin-left: -1px;
	}
`;

const StatsWrapper = styled.div`
	display: flex;
	justify-content: center;
	background: url(${field}) center 0 no-repeat #fff;
	max-width: 100%;
	position: relative;
	@media screen and (max-width: 840px) {
		height: 520px;
	}
	@media screen and (min-width: 840px) {
		background-size: contain;
	}
`;

const Stats = styled.div`
	width: 50%;
	position: relative;
	//z-index: 1;
	@media screen and (max-width: 840px) {
		z-index: 2;
		width: 100%;
		margin-top: 5%;
	}
`;

const StatsTitle = styled.h3`
	color: #ffffff;
	font-family: ${theme.secondary_bold_font};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 20px;
	text-align: center;
	margin-top: 25px;
	text-transform: uppercase;
	margin-bottom: 20px;
	@media screen and (max-width: 840px) {
		font-size: 18px;
	}
`;

const Helm = styled.img`
	width: 260px;
	position: absolute;
	top: 0;
	@media screen and (max-width: 840px) {
		width: 30%;
	}
`;

const HelmAway = styled(Helm)`
	left: 0;
`;

const HelmHome = styled(Helm)`
	right: 0;
`;

const StatsLine = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	margin: 0 auto;
`;

const RankStatsLine = styled(StatsLine)`
	margin-bottom: 20px;
	width: auto;
`;

const H2hScore = styled.p<IIsWin>`
	color: ${({is_win}) => (is_win ? "#fff" : "#999999")};
	font-size: 30px;
	font-weight: 500;
	line-height: 30px;
	text-align: center;
	font-family: ${theme.secondary_bold_font};
	flex: 1;
	@media screen and (max-width: 840px) {
		font-size: 26px;
	}
`;

const RankTitle = styled.div`
	height: 10px;
	width: 60px;
	color: #151515;
	font-family: ${theme.base_font};
	font-size: 10px;
	font-weight: 500;
	line-height: 10px;
	text-align: center;
	text-transform: uppercase;
`;
const RankLabel = styled.div`
	color: #ffffff;
	font-family: ${theme.base_bold_font};
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0.08px;
	line-height: 12px;
	text-align: center;
	width: 33px;
	height: 33px;
	border-radius: 50%;
	background-color: #cad2d8;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const RankLine = styled.div<{line_width: number}>`
	border-radius: 3px;
	min-width: 10px;
	max-width: 115px;
	height: 6px;
	background-color: #cad2d8;
	margin: 0 10px;
	width: ${({line_width}) => `${line_width}px`};
	@media screen and (max-width: 840px) {
		max-width: 50%;
	}
`;

const AwayRank = styled.div<IIsWin>`
	display: flex;
	align-items: center;
	width: 170px;
	justify-content: flex-end;
	@media screen and (max-width: 840px) {
		width: 40%;
	}
	${({is_win}) =>
		is_win
			? css`
					${RankLabel}, ${RankLine} {
						background-color: #003e78;
					}
			  `
			: ""};
`;
const HomeRank = styled.div<IIsWin>`
	display: flex;
	align-items: center;
	width: 170px;
	@media screen and (max-width: 840px) {
		width: 40%;
	}
	${({is_win}) =>
		is_win
			? css`
					${RankLabel}, ${RankLine} {
						background-color: #ffc124;
					}
			  `
			: ""};
`;

const TeamData = styled.div`
	margin-top: 50px;
	padding: 0 20px;
	text-transform: uppercase;
	position: absolute;
	bottom: 50px;
	@media screen and (max-width: 840px) {
		bottom: 20px;
		padding: 0 10px;
	}
`;

const TeamDataHome = styled(TeamData)`
	left: 20px;
`;
const TeamDataAway = styled(TeamData)`
	text-align: right;
	right: 20px;
`;
const TeamState = styled.div`
	color: #333333;
	font-family: ${theme.secondary_bold_font};
	font-size: 16px;
	letter-spacing: 1.6px;
	line-height: 13px;
	margin-bottom: 5px;
	@media screen and (max-width: 840px) {
		font-size: 12px;
	}
`;
const TeamName = styled.div`
	color: #333333;
	font-family: ${theme.secondary_bold_font};
	font-size: 40px;
	font-weight: 500;
	line-height: 42px;
	margin-bottom: 5px;
	@media screen and (max-width: 840px) {
		font-size: 20px;
		line-height: 20px;
	}
`;
const TeamScore = styled.div`
	color: #cccccc;
	font-family: ${theme.secondary_bold_font};
	font-size: 24px;
	font-weight: 500;
	line-height: 20px;
`;

const NoStatsWrapperDiv = styled.div`
	font-size: 11px;
	padding: 20px;
	text-align: center;
`;

const ICON_WIDTH = 10;
const ICON_HEIGHT = 5;

interface IOwn {
	fetchFormGuide: typeof fetchFormGuide;
	form_guide_by_id: {[match_id: string]: IFormGuide};
	squads: ISquad[];
}

interface IProps extends IOwn {
	tip_id: number;
	home: number;
	away: number;
	is_season_started: boolean;
}

interface IState {
	is_open: boolean;
}

class FormGuideComponent extends React.Component<IProps, IState> {
	public state = {
		is_open: false,
	};

	constructor(props: IProps, state: IState) {
		super(props, state);
		bindAll(this, ["toggle", "getRankLine"]);
	}

	public render() {
		const {is_open} = this.state;

		return (
			<React.Fragment>
				<ChevronWrapper onClick={this.toggle}>
					<IconArrowSlim
						color="#000000"
						to={is_open ? "bottom" : "top"}
						height={ICON_HEIGHT}
						width={ICON_WIDTH}
					/>
				</ChevronWrapper>

				{is_open && this.stats}
			</React.Fragment>
		);
	}

	private toggle(event: React.SyntheticEvent<HTMLButtonElement>) {
		event.preventDefault();

		if (!this.state.is_open) {
			const {fetchFormGuide: getForm, tip_id} = this.props;
			getForm({
				id: tip_id,
			});
		}
		this.setState({
			is_open: !this.state.is_open,
		});
	}

	private get stats() {
		const {form_guide_by_id, tip_id, squads} = this.props;
		const stats = form_guide_by_id[tip_id];
		if (!stats || Object.keys(stats).length < 2) {
			return (
				<NoStatsWrapperDiv>Form guide not available.</NoStatsWrapperDiv>
			);
		}

		const {home_squad_id, away_squad_id, ladder} = stats;
		const home_stats = ladder[home_squad_id];
		const away_stats = ladder[away_squad_id];
		const home_squad = find(squads, {id: home_squad_id});
		const away_squad = find(squads, {id: away_squad_id});
		if (!home_squad || !away_squad) {
			return null;
		}

		const [home_squad_state] = home_squad.full_name.split(home_squad.name);
		const [away_squad_state] = away_squad.full_name.split(away_squad.name);

		return (
			<StatsWrapper>
				<HelmAway
					src={`${HELM_URL}${stats.away_squad_id}-helmet-left.png`}
				/>
				<TeamDataHome>
					<TeamState>{away_squad_state}</TeamState>
					<TeamName>{away_squad.name}</TeamName>
					<TeamScore>
						{`${away_stats.wins}-${away_stats.losses}${
							away_stats.draws ? `-${away_stats.draws}` : ""
						}`}
					</TeamScore>
				</TeamDataHome>
				<Stats>
					{this.h2h}
					{this.league_rankings}
				</Stats>
				<HelmHome
					src={`${HELM_URL}${stats.home_squad_id}-helmet-right.png`}
				/>
				<TeamDataAway>
					<TeamState>{home_squad_state}</TeamState>
					<TeamName>{home_squad.name}</TeamName>
					<TeamScore>
						{`${home_stats.wins}-${home_stats.losses}${
							home_stats.draws ? `-${home_stats.draws}` : ""
						}`}
					</TeamScore>
				</TeamDataAway>
			</StatsWrapper>
		);
	}

	private get h2h() {
		const {form_guide_by_id, tip_id, is_season_started} = this.props;
		const stats = form_guide_by_id[tip_id];
		const {home_squad_id, away_squad_id, ladder} = stats;
		const home_h2h = ladder[home_squad_id];
		const away_h2h = ladder[away_squad_id];

		return (
			<React.Fragment>
				{this.h2hLine(
					window.getTranslations(
						"pickem_formguide_dropdown_points_per_game"
					) || "",
					get(home_h2h, "points_per_game"),
					get(away_h2h, "points_per_game"),
					is_season_started
				)}
				{this.h2hLine(
					window.getTranslations(
						"pickem_formguide_dropdown_points_allowed_per_game"
					) || "",
					get(home_h2h, "points_allowed_per_game"),
					get(away_h2h, "points_allowed_per_game"),
					is_season_started
				)}
			</React.Fragment>
		);
	}

	private h2hLine(
		title: string,
		home_score: number,
		away_score: number,
		is_season_started: boolean
	) {
		const is_home_winner = home_score > away_score;
		const is_away_winner = away_score > home_score;

		return (
			<React.Fragment>
				<StatsTitle>{title}</StatsTitle>
				<StatsLine>
					<H2hScore is_win={is_away_winner}>
						{this.getScoreValue(away_score, is_season_started)}
					</H2hScore>
					<H2hScore is_win={is_home_winner}>
						{this.getScoreValue(home_score, is_season_started)}
					</H2hScore>
				</StatsLine>
			</React.Fragment>
		);
	}

	private getScoreValue(score: number, is_season_started: boolean) {
		if (!is_season_started) {
			return "-";
		}

		return score || score === 0 ? score : "NA";
	}

	private get league_rankings() {
		const rank_keys = {
			wins:
				window.getTranslations(
					"pickem_formguide_dropdown_last5_wins"
				) || "",
			losses:
				window.getTranslations(
					"pickem_formguide_dropdown_last5_losses"
				) || "",
		};

		return (
			<React.Fragment>
				<StatsTitle>
					{window.getTranslations("pickem_formguide_dropdown_last5")}
				</StatsTitle>
				{toPairs(rank_keys).map(this.getRankLine)}
			</React.Fragment>
		);
	}

	private getRankLine(arr: string[]) {
		const [key, title] = arr;
		const {form_guide_by_id, tip_id} = this.props;
		const max_place = 5;
		const stats = form_guide_by_id[tip_id];
		const {home_squad_id, away_squad_id, ladder} = stats;
		const home_label = get(ladder, `${[home_squad_id]}.last5.${key}`, "");
		const away_label = get(ladder, `${[away_squad_id]}.last5.${key}`, "");

		const home_score = parseInt(home_label as string, 10);
		const away_score = parseInt(away_label as string, 10);
		const is_home_winner =
			key === "wins" ? home_score > away_score : away_score > home_score;
		const is_away_winner =
			key === "wins" ? away_score > home_score : home_score > away_score;
		const home_width = ((home_score || max_place) / max_place) * 100;
		const away_width = ((away_score || max_place) / max_place) * 100;

		return (
			<RankStatsLine key={key}>
				<AwayRank is_win={is_away_winner}>
					<RankLabel>{away_score || "-"}</RankLabel>
					<RankLine line_width={away_width} />
				</AwayRank>
				<RankTitle>{title}</RankTitle>
				<HomeRank is_win={is_home_winner}>
					<RankLine line_width={home_width} />
					<RankLabel>{home_score || "-"}</RankLabel>
				</HomeRank>
			</RankStatsLine>
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	form_guide_by_id: state.jsons.form_guide.by_id,
	squads: state.squads,
	is_season_started: getIsSeasonStarted(state),
});

const mapDispatchToProps = {
	fetchFormGuide,
};

export const FormGuide = connect(
	mapStateToProps,
	mapDispatchToProps
)(FormGuideComponent);

export default FormGuide;
