export const ContactValidate = {
	name: (value: string) =>
		value === ""
			? window.getTranslations("Bitte teile uns deinen Namen mit")
			: "",
	email: (value: string) => {
		if (value === "") {
			return window.getTranslations("register_validation_email_required");
		}

		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return window.getTranslations("register_text_invalid_address");
		}

		return "";
	},
	category: (value: string) =>
		value === ""
			? window.getTranslations("contact_form_category_required")
			: "",
	question: (value: string) =>
		value === ""
			? window.getTranslations("register_form_label_required")
			: "",
};
