import {identity} from "lodash";
import {IShare} from "../../types";
import {FB_ID} from "modules/utils/constants";

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
const protocol = window.location.protocol;

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: any) => btoa(JSON.stringify(object));
const getLinkForShare = (object: any, type: string) =>
	`${protocol}${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;

export const share = (data: IShare) => {
	if (data.sc === "tw") {
		shareTwitter(data);
	}

	if (data.sc === "fb") {
		shareFB(data);
	}
};

export const shareFB = (data: IShare) => {
	const {sc, league_id, section, round_id, user_id} = data;

	const object: any = {
		sc,
		t: getTimestamp(),
	};

	if (league_id) {
		object.league_id = league_id;
	}

	if (round_id) {
		object.round = round_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	const link = getLinkForShare(object, section);

	if (window.FB && FB_ID) {
		window.FB.ui(
			{
				method: "share",
				display: "popup",
				href: link,
			},
			identity
		);
		return;
	}

	const winWidth = 626;
	const winHeight = 436;
	const winTop = window.screen.height / 2 - winHeight / 2;
	const winLeft = window.screen.width / 2 - winWidth / 2;

	window.open(
		`https://www.facebook.com/sharer/sharer.php?display=popup&u=${link}`,
		"_blank",
		`top=${winTop},left=${winLeft},width=${winWidth}, height=${winHeight},toolbar=0,status=0,scrollbars=no,resizable=1`
	);
};

export const shareTwitter = (data: IShare) => {
	const {sc, league_id, section, round_id, user_id} = data;
	const object: any = {
		sc,
		t: getTimestamp(),
	};

	if (league_id) {
		object.league_id = league_id;
	}

	if (round_id) {
		object.round = round_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	if (league_id) {
		object.league_id = league_id;
	}
	const href = getLinkForShare(object, section);
	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(data.message),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

const iosCopyToClipboard = (el: any) => {
	const oldContentEditable = el.contentEditable;
	const oldReadOnly = el.readOnly;
	const range = document.createRange();

	el.contentEditable = true;
	el.readOnly = false;
	range.selectNodeContents(el);
	const select_from = 0;
	const select_to = 100;
	const s: any = window.getSelection();
	s.removeAllRanges();
	s.addRange(range);

	el.setSelectionRange(select_from, select_to);

	el.contentEditable = oldContentEditable;
	el.readOnly = oldReadOnly;

	document.execCommand("copy");
	el.blur();
};

export const copyToClipboard = (ref: any): Promise<void | object> => {
	return new Promise((resolve, reject) => {
		try {
			if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
				iosCopyToClipboard(ref.current);
			} else {
				ref.current.select();
				document.execCommand("copy");
				ref.current.blur();
			}
			resolve();
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});
};
