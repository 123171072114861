import {IDivisionalStandingsData, IStoreState} from "../types";
import {IDivisionalStandings} from "../reducers/divisional_standings";
import {reduce, get} from "lodash";

export const getGivisionalStandings = ({divisional_standings}: IStoreState) => {
	return reduce(
		divisional_standings,
		(accumulator: IDivisionalStandingsData, item: IDivisionalStandings) => {
			if (get(accumulator, item.conference)) {
				if (get(accumulator[item.conference], item.division)) {
					accumulator[item.conference][item.division].push(item);
				} else {
					accumulator[item.conference][item.division] = [];
					accumulator[item.conference][item.division].push(item);
				}
			} else {
				accumulator[item.conference] = {};
				accumulator[item.conference][item.division] = [];
				accumulator[item.conference][item.division].push(item);
			}

			return accumulator;
		},
		{}
	);
};
