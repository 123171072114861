import * as React from "react";
import {PageAccountWrapper} from "./pageAccountWrapper";
import {AccountUpdateForm} from "./accountUpdate/accountUpdateForm";
import {withHeader} from "../../components";

export const AccountUpdate = () => (
	<PageAccountWrapper
		title={window.getTranslations("myaccount_text_your_account") || ""}
	>
		<AccountUpdateForm />
	</PageAccountWrapper>
);

export default withHeader(AccountUpdate);
