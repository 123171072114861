import {createReducer} from "redux-act";
import {GAME_NAME} from "modules/utils";

export interface IMenuItem {
	url: string;
	title: string | undefined;
}

export interface IMainMenuItem extends IMenuItem {
	only_is_login: boolean;
	sub_menu?: IMenuItem[];
	external?: boolean;
	only_mobile?: boolean;
}

export interface INavigation {
	main_menu: IMainMenuItem[];
}

const SPONSOR_NAME = process.env.REACT_APP_SPONSOR || "";
// const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";
const pickemMenuItem = [
	{
		url: "/pick-em",
		title: "pickem_nav_button_Pick 'Em",
		only_is_login: false,
	},
];

const leagueMenuItems = [
	{
		url: "/leagues",
		title: "pickem_nav_button_leagues",
		only_is_login: true,
		sub_menu: [
			{
				url: "/leagues/your-leagues",
				title: "leagues_header_your_leagues",
			},
			{
				url: "/leagues/join",
				title: "mycomps_header_button_join",
			},
			{
				url: "/leagues/create",
				title: "mycomps_header_button_create",
			},
		],
	},
];

const prizeMenuItem = [
	{
		url: "/prizes",
		title: "pickem_nav_button_prizes",
		only_is_login: false,
	},
];

const helpMenuItem = [
	{
		url: "/help",
		title: "pickem_nav_button_help",
		only_is_login: false,
	},
];

const accountMenuItem = [
	{
		url: "/account/update",
		title: "account_header_title",
		only_is_login: true,
		only_mobile: true,
	},
];

const baseMenuItems = [
	...pickemMenuItem,
	...leagueMenuItems,
	...prizeMenuItem,
	...helpMenuItem,
	...accountMenuItem,
];

// const baseMenuItems =
// 	GAME_LANGUAGE === "MX"
// 		? [
// 				...pickemMenuItem,
// 				...leagueMenuItems,
// 				...helpMenuItem,
// 				...accountMenuItem,
// 		  ]
// 		: [
// 				...pickemMenuItem,
// 				...leagueMenuItems,
// 				...prizeMenuItem,
// 				...helpMenuItem,
// 				...accountMenuItem,
// 		  ];

function getFantasyMenuItem() {
	if (GAME_NAME === "Tabcorp") {
		return [];
	}

	return [
		{
			url: process.env.REACT_APP_NFL_FANTASY_URL || "",
			title: "pickem_nav_button_fantasychallenge",
			only_is_login: false,
			external: true,
		},
	];
}

const sponsorLinkItem =
	SPONSOR_NAME === "tab"
		? [
				{
					url: "https://www.tab.com.au/",
					title: "TAB",
					only_is_login: false,
					external: true,
				},
		  ]
		: [];

let menuItems = baseMenuItems;
if (SPONSOR_NAME === "tab") {
	menuItems = [...baseMenuItems, ...sponsorLinkItem, ...getFantasyMenuItem()];
} else if (process.env.REACT_APP_NFL_FANTASY_URL !== "") {
	menuItems = [...baseMenuItems, ...getFantasyMenuItem()];
}

const defaultState: INavigation = {
	main_menu: menuItems,
};

export const navigation = createReducer<INavigation>({}, defaultState);

export default navigation;
