import * as React from "react";
import Mobile from "./Help/Mobile";
import {GameGuidelines} from "./Help/";
import withHeader from "../components/Header";
import {createFanHubEvent} from "../modules/utils/FanHubTagging";
export class Help extends React.Component {
	public componentDidMount() {
		createFanHubEvent("e9915e45-e2f5-4a57-8da0-04388f004684");
	}
	public render() {
		return (
			<React.Fragment>
				<Mobile />
				<div className="hidden-mobile">
					<GameGuidelines />
				</div>
			</React.Fragment>
		);
	}
}

export default withHeader(Help);
