import {IModelUser} from "modules/types";
import {get} from "lodash";

export enum ConnextraType {
	HOME = "f2p_pickem_homepage",
	REGISTRATION_START = "f2p_pickem_regstart",
	REGISTRATION_CONFIRM = "f2p_pickem_regconfirm",
	LOGIN = "f2p_pickem_loggedin",
	PICKS_CONFIRM = "f2p_pickem_pickconfirm",
	AUTOPICK_CONFIRM = "f2p_pickem_autopickconfirm",
	SHARE_CLICK = "f2p_pickem_shareclick",
	SHARE_CLICK_FACEBOOK = "f2p_pickem_shareclick_facebook",
	SHARE_CLICK_TWITTER = "f2p_pickem_shareclick_twitter",
	LEAGUE_JOIN = "f2p_pickem_leaguejoin",
	LEAGUE_START = "f2p_pickem_leaguestart",
	LEAGUE_CONFIRM = "f2p_pickem_leagueconfirm",
	PICKEM_PAGE = "f2p_pickem_pickempage",
	LEAGUES_PAGE = "f2p_pickem_leaguespage",
	TERMS_PAGE = "f2p_pickem_termspage",
}

const CONNEXTRA_URL = `https://us.connextra.com/dcs/tagController/tag/11935e1e2233/`;

export const createConnextraScriptTag = (type: ConnextraType, user?: IModelUser) => {
	const accountId = get(user, "id", 0).toString();
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (user && user.id) {
		url.searchParams.append("AccountID", accountId);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
