import {css, SimpleInterpolation} from "styled-components";
import {transform} from "lodash";

interface IMediaSizes {
	readonly desktop: number;
}

export const sizes: IMediaSizes = {
	desktop: 960,
};

export const above: any = transform(sizes, (result, value, key) => {
	result[key] = (
		literals: TemplateStringsArray,
		args: SimpleInterpolation[]
	) => css`
		@media (min-width: ${value}px) {
			${css(literals, args)};
		}
	`;
	return result;
});

export const below: any = transform(sizes, (result, value, key) => {
	result[key] = (
		literals: TemplateStringsArray,
		args: SimpleInterpolation[]
	) => css`
		@media (max-width: ${value - 1}px) {
			${css(literals, args)};
		}
	`;
	return result;
});

export default above;
