import * as React from "react";

import {fetchStandingsJSONS} from "../../modules/actions";
import {Radio, SquadAvatar} from "../../components";
import {
	IDivisionalStandingsData,
	IStoreState,
	IUserTips,
} from "../../modules/types";
import {connect} from "react-redux";
import {getGivisionalStandings} from "../../modules/selectors";
import {bindAll, get, map} from "lodash";
import {IDivisionalStandings} from "../../modules/reducers/divisional_standings";
import {
	DivisionalWrapper,
	ItemName,
	ItemValue,
	StandingsWrapper,
	SwitchStandings,
	TableRow,
	Title,
} from "./DivisionalStandingsStyle";

interface IState {
	current_conference: string;
}

interface IProps {
	fetchStandingsJSONS: typeof fetchStandingsJSONS;
	readonly divisional_standings: IDivisionalStandingsData;
	readonly selected_tip: IUserTips;
}

class DivisionalStandingsWidgetComponent extends React.Component<
	IProps,
	IState
> {
	public state = {
		current_conference: "AFC",
	};

	private readonly _first_items = ["ACE", "NCE"];

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleChangeConference", "isSelectedSquad"]);
	}

	private get current_divisions() {
		const {divisional_standings} = this.props;
		const {current_conference} = this.state;

		const selected_conference = get(
			divisional_standings,
			current_conference
		);

		if (selected_conference) {
			return map(selected_conference, (squads_list, conference_name) => (
				<DivisionalWrapper key={conference_name}>
					<TableRow>
						<ItemName>
							{window.getTranslations(
								this.getFullNameConference(conference_name)
							)}
						</ItemName>
						<ItemValue>
							{window.getTranslations(
								"pickem_standingswidget_subheading_w"
							)}
						</ItemValue>
						<ItemValue>
							{window.getTranslations(
								"pickem_standingswidget_subheading_l"
							)}
						</ItemValue>
						<ItemValue>
							{window.getTranslations(
								"pickem_standingswidget_subheading_t"
							)}
						</ItemValue>
						<ItemValue>PCT</ItemValue>
					</TableRow>

					{this.divisionalRow(squads_list)}
				</DivisionalWrapper>
			));
		}

		return null;
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		this.props.fetchStandingsJSONS();
	}

	/**
	 * @ignore
	 */
	public render() {
		const {current_conference} = this.state;

		return (
			<StandingsWrapper>
				<Title>
					{window.getTranslations(
						"pickem_formguide_subheader_standings"
					)}
				</Title>
				<SwitchStandings>
					<Radio
						name="current_conference"
						current_value={current_conference}
						options={[
							{
								value: "AFC",
								text: "AFC",
							},
							{
								value: "NFC",
								text: "NFC",
							},
						]}
						onChange={this.handleChangeConference}
					/>
					{this.current_divisions}
				</SwitchStandings>
			</StandingsWrapper>
		);
	}

	private divisionalRow(squads_list: IDivisionalStandings[]) {
		const AVATAR_WIDTH = 25;

		return squads_list.length
			? squads_list.map(
					(
						{
							id,
							name,
							win,
							loss,
							draw,
							percentage,
						}: IDivisionalStandings,
						key: number
					) => (
						<TableRow
							key={key}
							is_active={this.isSelectedSquad(id)}
						>
							<ItemName>
								<SquadAvatar
									alt={name}
									squad_id={id}
									margin="0 5px 0 0"
									width={AVATAR_WIDTH}
								/>
								{name}
							</ItemName>

							<ItemValue>{win}</ItemValue>
							<ItemValue>{loss}</ItemValue>
							<ItemValue>{draw}</ItemValue>
							<ItemValue>{percentage}</ItemValue>
						</TableRow>
					)
			  )
			: null;
	}

	private isSelectedSquad(id: number) {
		const {selected_tip} = this.props;

		if (selected_tip) {
			return (
				selected_tip.away_squad_id === id ||
				selected_tip.home_squad_id === id
			);
		}

		return false;
	}

	private getFullNameConference(conference: string) {
		const full_conference_names = {
			ACE: "pickem_standingswidget_subheading_AFCeast",
			ACN: "pickem_standingswidget_subheading_AFCnorth",
			ACS: "pickem_standingswidget_subheading_AFCsouth",
			ACW: "pickem_standingswidget_subheading_AFCwest",
			NCE: "pickem_standingswidget_subheading_NFCeast",
			NCN: "pickem_standingswidget_subheading_NFCnorth",
			NCS: "pickem_standingswidget_subheading_NFCsouth",
			NCW: "pickem_standingswidget_subheading_NFCwest",
		};

		return get(full_conference_names, conference);
	}

	private handleChangeConference(event: React.FormEvent<HTMLInputElement>) {
		const {value} = event.target as HTMLInputElement;
		this.setState({current_conference: value});
	}
}

const mapStateToProps = (state: IStoreState) => ({
	divisional_standings: getGivisionalStandings(state),
	selected_tip: get(state, "tips.selected_tip"),
});

const mapDispatchToProps = {
	fetchStandingsJSONS,
};

export const DivisionalStandingsWidget = connect(
	mapStateToProps,
	mapDispatchToProps
)(DivisionalStandingsWidgetComponent);

export default DivisionalStandingsWidget;
