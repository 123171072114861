import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconAdd = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 16 16`}
		{...rest}
	>
		<title>Add</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-242.000000, -23.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<g transform="translate(242.000000, 23.000000)">
					<path
						d="M8,0 C3.56363636,0 0,3.56363636 0,8 C0,12.4363636 3.56363636,16 8,16 C12.4363636,
						16 16,12.4363636 16,8 C16,3.56363636 12.4363636,0 8,0 Z M8,14.7636364 C4.29090909,
						14.7636364 1.23636364,11.7090909 1.23636364,8 C1.23636364,4.29090909 4.29090909,1.23636364 8,
						1.23636364 C11.7090909,1.23636364 14.7636364,4.29090909 14.7636364,8 C14.7636364,
						11.7090909 11.7090909,14.7636364 8,14.7636364 Z M11.2727273,7.34545455 L8.65454545,
						7.34545455 L8.65454545,4.72727273 C8.65454545,4.50909091 8.50909091,4.36363636 8.29090909,
						4.36363636 L7.63636364,4.36363636 C7.41818182,4.36363636 7.27272727,4.50909091 7.27272727,
						4.72727273 L7.27272727,7.34545455 L4.72727273,7.34545455 C4.50909091,7.34545455 4.36363636,
						7.49090909 4.36363636,7.70909091 L4.36363636,8.36363636 C4.36363636,8.58181818 4.50909091,
						8.72727273 4.72727273,8.72727273 L7.34545455,8.72727273 L7.34545455,11.3454545 C7.34545455,
						11.5636364 7.49090909,11.7090909 7.70909091,11.7090909 L8.36363636,11.7090909 C8.50909091,
						11.7090909 8.72727273,11.5636364 8.72727273,11.3454545 L8.72727273,8.72727273 L11.3454545,
						8.72727273 C11.5636364,8.72727273 11.7090909,8.58181818 11.7090909,8.36363636 L11.7090909,
						7.70909091 C11.6363636,7.49090909 11.4909091,7.34545455 11.2727273,7.34545455 Z"
					/>
				</g>
			</g>
		</g>
	</svg>
);

IconAdd.defaultProps = {
	width: 16,
	height: 16,
	color: "#1976D2",
};

export default IconAdd;
