import {createReducer} from "redux-act";
import * as actions from "../../actions";

interface IBroadcast {
	broadcast: string;
	type: string;
	link: string;
}

export interface IOdds {
	id: number;
	link: string;
	decimal: number;
	denominator: number;
	numerator: number;
}

export interface IMatch {
	readonly id: number;
	readonly round: number;
	readonly match: number;
	readonly home_squad_id: number;
	readonly home_squad_odds: number;
	readonly home_squad_odds_id: string;
	readonly away_squad_id: number;
	readonly away_squad_odds: number;
	readonly away_squad_odds_id: string;
	readonly venue_id: number;
	readonly status: "scheduled" | "playing" | "complete";
	readonly date: string;
	readonly is_first: number;
	readonly is_last: 0;
	readonly is_match_day: number;
	readonly is_margin_game: number;
	readonly tipping_hidden: number;
	readonly active: number;
	readonly full_lock: number;
	readonly hashtag: string;
	readonly completed_at: string;
	readonly venue_name: string;
	readonly home_squad_name: string;
	readonly away_squad_name: string;
	readonly home_squad_short_name: string;
	readonly away_squad_short_name: string;
	readonly home_score: number;
	readonly away_score: number;
	readonly broadcasters:
		| ""
		| {
				string: IBroadcast;
		  };
	readonly period: string;
	home_squad_odds_data?: IOdds;
	away_squad_odds_data?: IOdds;
}

export interface IGameWeek {
	readonly id: number;
	readonly status: "scheduled" | "active" | "complete";
	readonly start: string;
	readonly end: string;
	readonly bye_squads: number[];
	readonly is_bye: number;
	readonly lifted_at: number;
	readonly matches: IMatch[];
}

const initialState: IGameWeek[] = [];

export const gameweeks = createReducer<IGameWeek[]>({}, initialState).on(
	actions.fetchGameWeeksJSONSuccess,
	(state, payload: IGameWeek[]) => payload
);
