import {createAction} from "redux-act";
import {
	IErrorValidation,
	IGigyaOnLogin,
	IGigyaUserData,
	IModelUser,
	IUserCreate,
	IUserReducerState,
	IUserRegisterForGame,
} from "../../types";

import {IProfileInfo, IUpdateUserSuccess} from "../../reducers/user";

export const fetchUser = createAction();
export const fetchUserSuccess = createAction<IModelUser>();
export const fetchUserFailed = createAction();

export const setSSOUser = createAction<IGigyaUserData>();

export const userLogin = createAction<IGigyaOnLogin>("User login");
export const userLoginSuccess = createAction<IUserReducerState>(
	"User Log in Event Success"
);
export const userLoginFailed = createAction("User Log in Event Failed");

export const additionalInformationRequired = createAction();

export const updateUser = createAction<object>("Update User");
export const updateUserSuccess = createAction<IUpdateUserSuccess>(
	"Update User Success"
);
export const updateUserFailed = createAction("Update User Failed");

export const userLogout = createAction("User Log out Event");
export const userLogoutSuccess = createAction("User Log Out Event Success");
export const userLogoutFailed = createAction();

export const userRegistration = createAction<IUserCreate>("User registration");
export const userRegistrationSuccess = createAction<IUserReducerState>(
	"User registration Success"
);
export const userRegistrationFailed = createAction("User registration Failed");

export const userRegisterForGame = createAction<IUserRegisterForGame>(
	"User Registration For Game Event"
);

export const userReturningRegisterForGame = createAction<IUserRegisterForGame>(
	"User Return Registration For Game Event"
);
export const userReturningRegisterForGameFailed = createAction<number>(
	"User Return Registration For Game Event Failed"
);

export const checkUsername = createAction<string>("Check Username");
export const checkUsernameSuccess = createAction("Check Username Success");
export const checkUsernameFailed = createAction<IErrorValidation>(
	"Check Username Failed"
);

export const checkEmail = createAction<string>("Check Email");
export const checkEmailSuccess = createAction("Check Email Success");
export const checkEmailFailed = createAction<IErrorValidation>(
	"Check Email Failed"
);

export const showMyProfile = createAction("Show My Profile");
export const showMyProfileSuccess = createAction<IProfileInfo>(
	"Show My Profile Success"
);

export const userDeactivate = createAction("User Deactivate");
export const userDeactivateSuccess = createAction<IProfileInfo>(
	"User Deactivate"
);

export const userUnsubscribe = createAction<object>("User Unsubscribe");
export const userUnsubscribeSuccess = createAction<IProfileInfo>(
	"User Unsubscribe Success"
);
