import * as React from 'react';
import { withHeader } from '../../components';
import HelpPagesWrapper from './helpPagesWrapper';
import styled from 'styled-components';
import { HelpContent, HelpTitle } from './help';
import { Redirect } from "react-router";
import { NavLink } from 'react-router-dom';

const GAME_NAME = process.env.REACT_APP_GAME_NAME || '';

const Content = styled.p`
	line-height: 20px;
	
	a {
		color: #1B48E0;
	}
`;

interface IProps {
	header_hidden?: boolean
}

export class СollectionStatement extends React.Component<IProps> {
	public render() {
		const { header_hidden } = this.props;

		if (GAME_NAME !== 'Tabcorp') {
			return (<Redirect to="/" />);
		}

		return header_hidden ?
			this.content :
			<HelpPagesWrapper title="Collection Statement">
				{this.content}
			</HelpPagesWrapper>;
	}

	private get content() {
		return (
			<React.Fragment>
				<HelpTitle>NFL Pick 'Em Australian Collection Statement</HelpTitle>
				<HelpContent>
					<Content>
						To assist us in providing NFL Pick 'Em, our websites, mobile applications, and other
						online and mobile services (Services) and otherwise conducting our business functions
						and activities, we need to collect personal information about you. By providing your personal
						information, you agree that it will be used and disclosed by NFL Enterprises LLC,
						NFL International LLC, the National Football League, and the other members of the NFL Family
						(collectively, NFL, we, our, or us) in accordance with this statement and our Privacy Policy,
						available at <NavLink to="/help/privacy-policy">https://nflpickem.com.au/help/privacy-policy.</NavLink>If you do not agree, you must not
						provide your personal information, and we may not be able to communicate with you or provide the
						Services to you. We may disclose your personal information to other parties, including Tabcorp,
						FanHub, and to third parties who provide products and services to us or through us in the ordinary
						operation, administration or promotion of our business and otherwise in accordance with our Privacy Policy.
						From time to time, these third parties may be located (and therefore your personal
						information may be disclosed) outside of Australia, including the USA and EU). We may use and
						disclose your personal information for direct marketing purposes, unless you opt out
						(which you can do at any time in accordance with our Privacy Policy). Our Privacy Policy contains
						information about how you may access and seek correction of your personal information,
						how you may complain about a breach of your privacy, and how we will deal with that complaint.
					</Content>
				</HelpContent>
			</React.Fragment>
		)
	}
}

export default withHeader(СollectionStatement);
