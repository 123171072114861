import {createAction} from "redux-act";
import {IError, IFormGuidePayload, IRankItem, IRankQuery} from "../types";
import {IUserReducerState} from "../reducers";
import {IMatchRoundStats} from "../reducers/tips";

export * from "./tips";
export * from "./tipping_stats";
export * from "./league";
export * from "./helps";
export * from "./user";
export * from "./jsons";

export const fetchChecksums = createAction();
export const fetchChecksumsSuccess = createAction<object>();
export const fetchChecksumsFailed = createAction<Error>();

export const errorsGlobalError = createAction<TypeError | Error | string>();
export const errorsShowGlobalError = createAction<IError>();
export const errorsClearGlobalError = createAction();

export const showPlayerModal = createAction<number>();
export const closePlayerModal = createAction();

export const showLoginModal = createAction();
export const closeLoginModal = createAction();

export const acceptTheConditions = createAction("Accept The Conditions");
export const acceptTheConditionsSuccess = createAction<IUserReducerState>(
	"Accept The Conditions Success"
);

export const getRankings = createAction<IRankQuery>("Get Rankings");
export const getRankingsSuccess = createAction<IRankItem[]>(
	"Get Rankings Success"
);
export const getRankingsFailed = createAction<Error>("Get Rankings Failed");
export const clearRankingsLadder = createAction("Clear Rankings Ladder");

export const subscribeToLiveScores = createAction("Subscribe to live scoring");
export const unsubscribeFromLiveScores = createAction(
	"Unsubscribe from live scoring"
);

export const gigyaIsReady = createAction();

export const fetchFormGuide = createAction<{id: number}>();
export const fetchFormGuideSuccess = createAction<IFormGuidePayload>();

export const fetchRoundStats = createAction<{round: number}>();
export const fetchRoundStatsSuccess = createAction<IMatchRoundStats[]>();

export const disableTooltip = createAction();
export const disableTooltipSuccess = createAction<IUserReducerState>();

export const enableTooltip = createAction();
export const enableTooltipSuccess = createAction<IUserReducerState>();

export const showTooltip = createAction();
export const hideTooltip = createAction();
