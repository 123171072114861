import * as React from "react";
import {Content, ContentWithSidebar} from "../../../components";
import HelpWidget from "../helpWidget";

interface IProps {
	children: React.ReactNode;
	title?: string;
}

const helpWidget = <HelpWidget key={"help-widget"} />;

export const HelpPagesWrapper = ({children, title}: IProps) => (
	<Content title={title}>
		<ContentWithSidebar
			section_background="#fff"
			section_padding="20px"
			position="left"
			sidebar_components={[helpWidget]}
		>
			{children}
		</ContentWithSidebar>
	</Content>
);

export default HelpPagesWrapper;
