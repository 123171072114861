import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const Close = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 14 14`}
		{...rest}
	>
		<title>Close</title>

		<defs>
			<polygon
				id="path-1"
				points="13.4141 12 18.2071 7.207 16.7931 5.793 12.0001 10.586 7.2071 5.793 5.7931 7.207 10.5861 12 5.7931 16.793 7.2071 18.207 12.0001 13.414 16.7931 18.207 18.2071 16.793"
			/>
		</defs>
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="Icon-/-Close" transform="translate(-5.000000, -5.000000)">
				<mask id="mask-2" fill="white">
					<use xlinkHref="#path-1" />
				</mask>
				<use id="Fill-1" fill="#fff" xlinkHref="#path-1" />
			</g>
		</g>
	</svg>
);
Close.defaultProps = {
	width: 14,
	height: 14,
	color: "#fff",
};

export default Close;
