import * as React from "react";
import styled from "styled-components";
import * as selectors from "../../../modules/selectors";
import {getIVWTracking} from "../../../modules/selectors";
import {connect} from "react-redux";
import {IAuthState, IStoreState, IModelUser} from "../../../modules/types";
import {each, bindAll, find, map, get, isUndefined, set} from "lodash";
import theme from "../../../assets/css/theme";
import {Input, Select, Label} from "../../../components";

import {RegistrationValidate} from "../../../modules/utils/FormValidations";
import {
	fetchSquadsJSON,
	showMyProfile,
	updateUser,
} from "../../../modules/actions";
import {ISquad} from "../../../modules/reducers/squads";
import {below} from "../../../assets/css/media";
import {createFanHubEvent} from "../../../modules/utils/FanHubTagging";

const Container = styled.div`
	background-color: #ffffff;
	border-radius: 6px;
	padding: 30px;
`;

const Title = styled.h3`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 30px;
`;

// const Description = styled.p`
// 	color: #555555;
// 	font-size: 14px;
// 	line-height: 22px;
// 	font-family: ${theme.base_font};
// 	margin-bottom: 20px;
// `;

export const UpdateAccountLink = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	height: 40px;
	width: 270px;
	border: 1px solid #1b48e0;
	border-radius: 4px;
	background-color: #1b48e0;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	text-transform: uppercase;
	margin: 35px 0;
	cursor: pointer;
`;

const ElementWrapper = styled.div`
	margin-top: 20px;
	width: 50%;

	${below.desktop`
        width: 100%;
    `};
`;

const ErrorBlock = styled.div`
	color: red;
	font-size: 12px;
`;

interface IState {
	form_element: {
		team_name: string;
		team_supported: string;
	};
	form_errors: {
		team_name: string;
	};
	is_updated: boolean;
}

interface IProps extends IAuthState {
	children?: React.ReactNode;
	readonly fetchSquadsJSON: typeof fetchSquadsJSON;
	readonly updateUser: typeof updateUser;
	readonly user: IModelUser;
	readonly squads: ISquad[];
	readonly ivw_tracking: any;
	showMyProfile: typeof showMyProfile;
}

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

class AccountUpdateFormComponent extends React.Component<IProps, IState> {
	public state = {
		form_element: {
			team_name: "",
			team_supported: "",
		},
		form_errors: {
			team_name: "",
		},
		is_updated: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleChange", "handleSubmit", "clearField"]);
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {user, ivw_tracking} = this.props;
		const {team_name, team_supported} = user;

		createFanHubEvent("c3126a42-9d69-43bb-bb62-a9d1b9715b39");

		this.setState({
			form_element: {
				team_name,
				team_supported: team_supported
					? team_supported.toString()
					: "0",
			},
		});
		this.props.fetchSquadsJSON();
		this.props.showMyProfile();

		if (GAME_NAME === "prosieben") {
			ivw_tracking("/us-sport/nfl/nfltippspiel/einstellungen");
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {squads, user} = this.props;
		const {form_errors, form_element, is_updated} = this.state;
		const team_supported = get(user, "profile_info.team_supported");
		const MAX_LENGTH_TEAM_NAME = 40;

		return (
			<Container>
				<Title>
					{window.getTranslations("myaccount_text_your_details")}
				</Title>

				<form onSubmit={this.handleSubmit} onChange={this.handleChange}>
					<ElementWrapper>
						<Label>
							{window.getTranslations("fld_team_name")}*
						</Label>
						<ErrorBlock>{form_errors.team_name}</ErrorBlock>
						<Input
							type="text"
							name="team_name"
							value={form_element.team_name}
							is_error={form_errors.team_name !== ""}
							placeholder={window.getTranslations(
								"fld_team_name"
							)}
							maxLength={MAX_LENGTH_TEAM_NAME}
							clearField={this.clearField}
						/>
					</ElementWrapper>

					{!isUndefined(team_supported) && squads.length ? (
						<ElementWrapper>
							<Label>
								{window.getTranslations(
									"register_form_label_team_supported"
								)}
							</Label>
							<Select
								name="team_supported"
								defaultValue={team_supported}
							>
								<option value="0">
									{window.getTranslations(
										"register_form_option_team_supported_none"
									)}
								</option>
								{map(squads, ({full_name, id}) => (
									<option key={id} value={id}>
										{full_name}
									</option>
								))}
							</Select>
						</ElementWrapper>
					) : null}

					<UpdateAccountLink>
						{is_updated
							? window.getTranslations(
									"account_form_button_updated"
							  )
							: window.getTranslations("myaccount_button_update")}
					</UpdateAccountLink>
				</form>
			</Container>
		);
	}

	private validateFormItem(name: string, value: string) {
		const {form_errors} = this.state;

		if (name === "team_name") {
			set(form_errors, name, RegistrationValidate[name](value));
			this.setState({form_errors});
		}
	}

	private clearField({
		currentTarget,
	}: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const {element_name} = currentTarget.dataset;
		const {form_element} = this.state;

		if (element_name) {
			set(form_element, element_name, "");
			this.setState({form_element});
		}
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, value, checked, type} = event.target as HTMLFormElement;
		const {form_element} = this.state;

		if (type === "checkbox") {
			this.validateFormItem(name, checked ? "1" : "");
			set(form_element, name, checked ? "1" : "0");
			this.setState({form_element});
		} else {
			this.validateFormItem(name, value);
			set(form_element, name, value);
			this.setState({form_element});
		}
	}

	private validateForm() {
		const {form_element} = this.state;

		each(form_element, (value, name) => {
			this.validateFormItem(name, value);
		});
	}

	private get check_is_form_have_error() {
		const {form_errors} = this.state;
		return Boolean(find(form_errors, (error) => error !== ""));
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const {form_element} = this.state;

		this.validateForm();

		if (!this.check_is_form_have_error) {
			this.setState({is_updated: true});
			this.props.updateUser(form_element);
		} else {
			console.log("Error");
		}
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.isLoggedIn(state),
	additional_information_required:
		selectors.isAdditionalInformationRequired(state),
	user: state.user,
	squads: get(state, "squads"),
	ivw_tracking: getIVWTracking,
});

const mapDispatchToProps = {
	fetchSquadsJSON,
	updateUser,
	showMyProfile,
};

export const AccountUpdateForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountUpdateFormComponent);

export default AccountUpdateForm;
