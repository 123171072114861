import * as React from "react";
import {
	Input,
	Label,
	RegularButton,
	Select,
	Textarea,
	withHeader,
} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {connect} from "react-redux";
import {saveContactUs} from "../../modules/actions/helps";
import {
	IContactUsReduser,
	IStoreState,
	IUserReducerState,
} from "../../modules/types";
import {
	getContactUs,
	isDataRequestedContactUs,
	isDataContactUsSended,
} from "../../modules/selectors/contact_us";
import styled from "styled-components";
import {ContactValidate} from "../../modules/utils/FormValidations/Contact";
import {bindAll, each, find, set} from "lodash";
import {getUser, getOmnitureTracking} from "../../modules/selectors";
import theme from "../../assets/css/theme";
import {HelpTitle} from "./help";

const FormDescription = styled.div`
	font-family: ${theme.base_font};
	color: #555;
	font-size: 14px;
	line-height: 22px;
`;

const ErrorBlock = styled.div`
	color: red;
	font-size: 12px;
	padding-bottom: 15px;
`;

const ElementWrapper = styled.div`
	margin: 20px 0;
	max-width: 400px;
	width: 100%;
`;

const ContactForm = styled.form``;

interface IProps {
	contact_us: IContactUsReduser;
	saveContactUs: typeof saveContactUs;
	is_data_requested?: boolean;
	is_sended?: boolean;
	header_hidden?: boolean;
	user?: IUserReducerState;
	readonly omniture_tracking: any;
}

interface IFormElement {
	name: string;
	email: string;
	category: string;
	question: string;
}

interface IState {
	form_element: IFormElement;
	form_errors: IFormElement;
	is_locked: boolean;
}

class ContactUsComponent extends React.Component<IProps, IState> {
	public state = {
		form_element: {
			name: "",
			email: "",
			category: "",
			question: "",
		},
		form_errors: {
			name: "",
			email: "",
			category: "",
			question: "",
		},
		is_locked: false,
	};

	constructor(props: IProps) {
		super(props);

		bindAll(this, ["handleChange", "handleSubmit", "clearField"]);
	}

	public componentDidMount(): void {
		const {user, omniture_tracking} = this.props;

		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("pick em", "helpModal", "ContactUs")
			);
		}
		if (user && user.is_logged_in) {
			this.setState({
				...this.state,
				form_element: {
					...this.state.form_element,
					name: user.first_name,
					email: user.email,
				},
				is_locked: true,
			});
		}
	}

	public render() {
		const {header_hidden} = this.props;

		return header_hidden ? (
			this.contact_content
		) : (
			<HelpPagesWrapper
				title={window.getTranslations("help_list_text_contact") || ""}
			>
				{this.contact_content}
			</HelpPagesWrapper>
		);
	}

	private get contact_content() {
		return (
			<React.Fragment>
				<HelpTitle>
					{window.getTranslations("help_contact_button_contact")}
				</HelpTitle>
				{this.contact_form}
			</React.Fragment>
		);
	}

	private get contact_form() {
		const {
			form_errors,
			form_element: {name, email, question},
			is_locked,
		} = this.state;
		const {is_data_requested, is_sended} = this.props;

		return (
			<React.Fragment>
				<FormDescription>
					{window.getTranslations("help_contact_desc")}
				</FormDescription>

				<ContactForm
					onSubmit={this.handleSubmit}
					onChange={this.handleChange}
				>
					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"register_form_label_first_name"
							)}
							*
						</Label>
						<ErrorBlock>{form_errors.name}</ErrorBlock>
						<Input
							type="text"
							name="name"
							value={name}
							placeholder={window.getTranslations(
								"register_form_label_first_name"
							)}
							clearField={this.clearField}
							is_disabled={is_locked}
						/>
					</ElementWrapper>

					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"register_form_label_email"
							)}
							*
						</Label>
						<ErrorBlock>{form_errors.email}</ErrorBlock>
						<Input
							type="email"
							name="email"
							value={email}
							placeholder={window.getTranslations(
								"register_form_label_email"
							)}
							clearField={this.clearField}
							is_disabled={is_locked}
						/>
					</ElementWrapper>

					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"contact_form_label_category"
							)}
							*
						</Label>
						<ErrorBlock>{form_errors.category}</ErrorBlock>

						<Select name="category">
							<option value="0">
								{window.getTranslations(
									"register_form_placeholder_team_supported"
								)}
							</option>
							<option value="gameplay">
								{window.getTranslations(
									"help_contact_category_gameplay"
								)}
							</option>
							<option value="leagues">
								{window.getTranslations(
									"help_contact_category_leagues"
								)}
							</option>
							<option value="error">
								{window.getTranslations(
									"help_contact_category_error"
								)}
							</option>
							<option value="signin">
								{window.getTranslations(
									"help_contact_category_signin"
								)}
							</option>
						</Select>
					</ElementWrapper>

					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"contact_form_label_question"
							)}
							*
						</Label>
						<ErrorBlock>{form_errors.question}</ErrorBlock>

						<Textarea
							name="question"
							value={question}
							max_width="100%"
							// is_error={question !== ''}
							placeholder={window.getTranslations(
								"contact_form_label_question"
							)}
						/>
					</ElementWrapper>

					<RegularButton
						disabled={
							this.check_is_form_have_error || is_data_requested
						}
						max_width="270px"
					>
						{is_data_requested
							? window.getTranslations(
									"pickem_gamebar_button_saved"
							  )
							: is_sended
							? window.getTranslations(
									"contact_form_button_submittted"
							  )
							: window.getTranslations(
									"contact_form_button_submit"
							  )}
					</RegularButton>
				</ContactForm>
			</React.Fragment>
		);
	}

	private get check_is_form_have_error() {
		const {form_errors} = this.state;
		return Boolean(find(form_errors, (error) => error !== ""));
	}

	private validateFormItem(name: string, value: string) {
		const {form_errors} = this.state;

		if (ContactValidate[name as keyof IFormElement]) {
			set(
				form_errors,
				name,
				ContactValidate[name as keyof IFormElement](value)
			);
			this.setState({form_errors});
		}
	}

	private validateForm() {
		const {form_element} = this.state;

		each(form_element, (value, name) => {
			this.validateFormItem(name, value);
		});
	}

	private clearField({
		currentTarget,
	}: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const {element_name} = currentTarget.dataset;
		const {form_element} = this.state;

		if (element_name) {
			set(form_element, element_name, "");
			this.setState({form_element});
		}
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, value} = event.target as HTMLFormElement;
		const {form_element} = this.state;

		this.validateFormItem(name, value);
		set(form_element, name, value);
		this.setState({form_element});
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const {form_element} = this.state;
		const {saveContactUs: _saveContactUs} = this.props;

		this.validateForm();

		if (!this.check_is_form_have_error) {
			_saveContactUs(form_element);
		}
	}
}

const mapStateToProps = (state: IStoreState) => ({
	contact_us: getContactUs(state),
	user: getUser(state),
	is_data_requested: isDataRequestedContactUs(state),
	is_sended: isDataContactUsSended(state),
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	saveContactUs,
};

export const ContactUs = connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactUsComponent);
export default withHeader(ContactUs);
