import {get} from "lodash";
import {
	HEADER_BACKGROUND,
	HOME_ACTION_BUTTON_BG,
	LADDER_HEADING_COLOUR,
	MAIN_COLOUR,
	PICK_COLOUR,
	SPONSOR_LEAGUE_COLOUR,
	RADIO_COLOUR,
	LEAGUE_BUTTON_HOVER,
	ODDS_BACKGROUND,
} from "modules/utils";

const SPONSOR = process.env.REACT_APP_SPONSOR || "";
const NO_SPONSOR = process.env.REACT_APP_NO_SPONSOR === "true";

const sponsorBGS = {
	tab: "#008542",
	skysports: "#101251",
	canada: HEADER_BACKGROUND,
	pokerstars: "#000000",
	MX: "#000000",
	"888": "#000000",
	default: HEADER_BACKGROUND || "#101251",
};

const getHeaderBg = (sponsor: string) => {
	return get(sponsorBGS, sponsor, sponsorBGS.default);
};

const getOddsBg = (sponsor: string) => {
	if (sponsor === "888") {
		return ODDS_BACKGROUND;
	}
	return getHeaderBg(sponsor);
};

const getLadderBg = (sponsor: string) => {
	if (LADDER_HEADING_COLOUR !== "") {
		return LADDER_HEADING_COLOUR;
	}

	return getHeaderBg(sponsor);
};

const getMenuColor = (sponsor: string) => {
	if (sponsor === "skysports") {
		return "#A6A6B9";
	} else {
		return "#cacaca";
	}
};

const getPresentedColor = (sponsor: string) => {
	if (
		["tab", "skysports", "betcris", "pinty", "pokerstars"].includes(sponsor)
	) {
		return "#ffffff";
	} else {
		return "#555555";
	}
};

const getSponsorLeagueSecondaryColor = (sponsor: string) => {
	if (NO_SPONSOR) {
		return MAIN_COLOUR;
	}
	switch (sponsor) {
		case "pokerstars":
			return "#FBC010";
		case "888":
			return MAIN_COLOUR ?? getHeaderBg(SPONSOR);
		case "canada":
			return MAIN_COLOUR ?? getHeaderBg(SPONSOR);
		case "MX":
			return MAIN_COLOUR ?? getHeaderBg(SPONSOR);
		default:
			return getHeaderBg(SPONSOR);
	}
};

const getSponsorLeagueActiveBg = (sponsor: string) => {
	if (sponsor === "tab") {
		return "#F7FFF3";
	} else if (sponsor === "canada") {
		return "#F0E4D3";
	} else if (sponsor === "pokerstars") {
		return "#FFFCF3";
	} else if (sponsor === "888") {
		return "#FFEDE1";
	} else {
		return "#E4F4FD";
	}
};

const getFormElementsColor = (sponsor: string) => {
	if (sponsor === "canada") {
		return "#008542";
	}
	return "#1b48e0";
};

const theme = {
	primary: MAIN_COLOUR,
	secondary: "#13612D",
	red: "#FF0000",
	body_bg: "#F3F6F7",
	content_width: "1240px",
	base_text_color: "#343232",
	base_font: "All-ProSans, sans-serif",
	base_font_medium_display: "AllProDisaplayB-Medium, sans-serif",
	base_font_medium: "All-ProSans-Medium, sans-serif",
	base_bold_font: "All-ProSans-Bold, sans-serif",
	secondary_bold_font: "AllProDisaplayB-Bold, sans-serif",
	buttons_color: "#1976D2",
	menu_color: getMenuColor(SPONSOR),
	sub_menu_color: "#555555",
	menu_font: "EndzoneSans-Bold, sans-serif",
	rankings_header_bg: "#0B294E",
	header_bg: getHeaderBg(SPONSOR),
	ladder_heading_bg: getLadderBg(SPONSOR),
	odds_bg: getOddsBg(SPONSOR),
	presented_color: getPresentedColor(SPONSOR),
	sponsor_league_color: SPONSOR_LEAGUE_COLOUR ?? getHeaderBg(SPONSOR),
	sponsor_league_secondary_color: getSponsorLeagueSecondaryColor(SPONSOR),
	sponsor_league_secondary_hover_color:
		LEAGUE_BUTTON_HOVER || getSponsorLeagueSecondaryColor(SPONSOR),
	sponsor_league_active_bg_item: getSponsorLeagueActiveBg(SPONSOR),
	ladder_bg_item: "#343232",
	headings_bg: "#393E3F",
	footer_bg: "#151515",
	footer_text_color: "#FAFAFA",
	titles: "#333333",
	descriptions: "#757575",
	placeholder: "#757575",
	primary_hover: "#143CAB",
	home_action_button_bg: HOME_ACTION_BUTTON_BG,
	pick_color: PICK_COLOUR,
	radio_color: RADIO_COLOUR,
	form_elements_color: getFormElementsColor(SPONSOR),
};

export default theme;
