import * as React from "react";
import {withHeader} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {IRules, IStoreState} from "../../modules/types";
import {getPrivacyPolicy} from "../../modules/selectors/jsons";
import {connect} from "react-redux";
import {fetchPrivacyPolicy} from "../../modules/actions";
import {map, get} from "lodash";
import {HelpContent, HelpTitle} from "./help";
import {LoadingSpinner} from "../../components";

interface IProps {
	rules: IRules[];
	fetchPrivacyPolicy: typeof fetchPrivacyPolicy;
	header_hidden?: boolean;
}

class PrivacyPolicyComponent extends React.Component<IProps> {
	public componentDidMount(): void {
		const {fetchPrivacyPolicy: _fetchPrivacyPolicy} = this.props;

		_fetchPrivacyPolicy();
	}

	public render() {
		const {header_hidden} = this.props;

		return header_hidden ? (
			this.rules_content
		) : (
			<HelpPagesWrapper
				title={
					window.getTranslations(
						"pickem_footer_hyperlink_privacypolicy"
					) || ""
				}
			>
				{this.rules_content}
			</HelpPagesWrapper>
		);
	}

	private get rules_content() {
		const {rules} = this.props;
		return rules.length ? (
			<React.Fragment>
				<HelpTitle>
					{window.getTranslations(
						"pickem_footer_hyperlink_privacypolicy"
					)}
				</HelpTitle>

				{map(
					rules,
					(item, key) =>
						!item.tags && (
							<HelpContent
								key={key}
								dangerouslySetInnerHTML={{
									__html: get(item, "text", ""),
								}}
							/>
						)
				)}
			</React.Fragment>
		) : (
			<LoadingSpinner />
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	rules: getPrivacyPolicy(state),
});

const mapDispatchToProps = {
	fetchPrivacyPolicy,
};

export const PrivacyPolicy = connect(
	mapStateToProps,
	mapDispatchToProps
)(PrivacyPolicyComponent);
export default withHeader(PrivacyPolicy);
