import {isString, get} from "lodash";
import {SagaIterator} from "redux-saga";
import {put, call, select} from "redux-saga/effects";
import {ISagaAction} from "../../types";
import ApiErrors from "../../utils/Api/ApiErrors";
import {isLoggedIn} from "../../selectors";
import * as actions from "../../actions";
import {clearUserFromStorage} from "../../utils/user";

type TPayload = TypeError | Error | string;

const defaultErrorHandler = async (payload: TPayload) => {
	if (payload instanceof TypeError) {
		console.error(payload);
		return;
	}

	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}
};

export const errorsManagerSaga = function* ({
	payload,
}: ISagaAction<TPayload>): SagaIterator {
	const onError = {
		*[ApiErrors.AUTH_REQUIRED](error: TPayload): SagaIterator {
			const is_logged_in = yield select(isLoggedIn);

			if (is_logged_in) {
				clearUserFromStorage();
				window.location.reload();
			}

			yield call(defaultErrorHandler, error);
		},
	};

	try {
		const error_code = get(payload, "code");
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);

		yield call(onErrorHandler, payload);
	} catch (err) {
		yield put(
			actions.errorsShowGlobalError({
				text: err.message,
			})
		);
	}
};
