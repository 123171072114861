import * as React from "react";
import styled from "styled-components";
import {IComponentClass, TABS} from "../help";
import {MobileContent} from "./MobileContent";
import {get} from "lodash";
import {IconArrowSlim} from "components";
import {NavLink} from "react-router-dom";
import ExternalIcon from "assets/img/icons/external_dark_grey.png";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";
const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";
const WIDTH_ARROW = 14;

const MobileContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;
const GoToExternalPrivacyPolicy = styled.a`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;
	background: url(${ExternalIcon}) no-repeat 13px center #fff;
	padding-left: 50px;

	&.active {
		background-color: #ffffff;
		border-radius: 2px;

		svg {
			color: #1b48e0;
		}
	}

	svg {
		margin: 0 15px;
	}
`;

const NavLinkStyled = styled(NavLink)`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;

	&.active {
		background-color: #ffffff;
		border-radius: 2px;

		svg {
			color: #1b48e0;
		}
	}

	svg {
		margin: 0 15px;
	}
`;

const getPrivacyPolicyUrl = () => {
	switch (GAME_LANGUAGE) {
		case "DE":
			return "https://www.nfl.com/legal/privacy/de";
		case "MX":
			return "https://www.nfl.com/legal/privacy/es";
		default:
			return "https://www.nfl.com/legal/privacy/";
	}
};

const privacy_policy_url = getPrivacyPolicyUrl();

export const Mobile = () => (
	<MobileContentWrapper className="hidden-desktop">
		{Object.keys(TABS).map((t) => (
			<MobileContent
				key={t}
				name={t}
				ComponentClass={
					get(TABS, t) as React.ComponentType<IComponentClass>
				}
			/>
		))}
		{GAME_LANGUAGE === "AU" ? (
			<NavLinkStyled to="/help/privacy-policy">
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				{window.getTranslations(
					"pickem_footer_hyperlink_privacypolicy"
				)}
			</NavLinkStyled>
		) : (
			<GoToExternalPrivacyPolicy
				href={privacy_policy_url}
				rel="noopener"
				target="_blank"
			>
				{window.getTranslations(
					"pickem_footer_hyperlink_privacypolicy"
				)}
			</GoToExternalPrivacyPolicy>
		)}

		{GAME_NAME === "Tabcorp" && (
			<NavLinkStyled to="/help/collection-statement">
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				Collection Statement
			</NavLinkStyled>
		)}
	</MobileContentWrapper>
);

export default Mobile;
