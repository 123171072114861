import * as React from "react";
import {withHeader} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {ConnextraType, createConnextraScriptTag} from "../../modules/utils/Сonnextra/connextra";
import {connect} from "react-redux";
import {getUser} from "../../modules/selectors";
import {IModelUser, IStoreState} from "../../modules/types";

interface IProps {
	user: IModelUser;
}

export class Terms extends React.Component<IProps> {
	public componentDidMount(): void {
		const {user} = this.props;
		createConnextraScriptTag(ConnextraType.TERMS_PAGE, user);
	}
	public render() {
		return (
			<HelpPagesWrapper title="Terms">
				{window.getTranslations("register_form_terms_conditions_apply")}
			</HelpPagesWrapper>
		);
	}
}

export default withHeader(connect((state: IStoreState) => ({
	user: getUser(state),
}), {})(Terms));
