import * as React from "react";
import styled from "styled-components";
import theme from "../../../assets/css/theme";

const LeagueNotFoundWrapper = styled.div`
	text-align: center;
	font-family: ${theme.base_bold_font};
	text-transform: uppercase;
	font-size: 18px;
	margin-bottom: 20px;
`;

export const LeagueNotFound = () => (
	<LeagueNotFoundWrapper>Leagues not found</LeagueNotFoundWrapper>
);
