import {IStoreState} from "../types";
import {find, last, findLast, head, range, isEmpty, map, filter} from "lodash";
import {createSelector} from "reselect";

export const getActualGameWeek = ({gameweeks}: IStoreState) =>
	find(gameweeks, ({status}) => ["active", "scheduled"].includes(status)) ||
	last(gameweeks);

export const getLastGameWeek = ({gameweeks}: IStoreState) =>
	findLast(gameweeks, ({status}) => ["active", "complete"].includes(status));

export const getScheduledGameWeek = ({gameweeks}: IStoreState) =>
	find(gameweeks, ({status}) => ["scheduled"].includes(status)) ||
	last(gameweeks);

export const getActualGameWeeks = ({gameweeks}: IStoreState) => {
	return gameweeks.filter(
		(gameweek) =>
			gameweek.status === "scheduled" || gameweek.status === "active"
	);
};

export const getScheduledGameWeeks = ({gameweeks}: IStoreState) => {
	return gameweeks.filter((gameweek) => gameweek.status === "scheduled");
};

export const getGameWeekById = ({gameweeks}: IStoreState, gw_id: number) =>
	find(gameweeks, ({id}) => gw_id === id) || last(gameweeks);

export const getGameWeeks = ({gameweeks}: IStoreState) => gameweeks;

export const getFirstGameWeek = ({gameweeks}: IStoreState) => head(gameweeks);

export const getIsSeasonStarted = createSelector(
	getFirstGameWeek,
	(gameweek) => Boolean(gameweek) && gameweek?.status !== "scheduled"
);

const NO_OF_ROUNDS = 18;
export const getGameWeekIdsForLeagueCreate = createSelector(
	getScheduledGameWeek,
	getScheduledGameWeeks,
	(gameweek, gameweeks) => {
		if (isEmpty(gameweeks) && gameweek) {
			const defaultIds = range(1, NO_OF_ROUNDS + 1);
			return filter(defaultIds, (id) => id > gameweek.id);
		}

		return map(gameweeks, (gw) => gw.id);
	}
);
