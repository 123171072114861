import * as React from "react";
import {Exist, ProsibenFooter} from "../";
import {
	FooterWrapper,
	FooterMenuWrapper,
	GambleDiv,
	TermLink,
	TermExternalLink,
	Copywriting,
	FooterImgDiv,
	FooterImg,
	FooterImgWrapDiv,
	FooterTermsDiv,
} from "./FooterStyle";
import SponsorImage from "../SponsorImage";
import nflLogo from "../../assets/img/mini-logo.png";
import {GAME_NAME, IS_DE, IS_RTL} from "modules/utils";
import styled from "styled-components";
import {Syndicates} from "modules/utils/enums";

const SECRET_KEY_ENABLED = JSON.parse(
	process.env.REACT_APP_SECRET_KEY || "false"
);

const Iframe = styled.iframe`
	height: 260px;
	width: 100%;

	@media screen and (max-width: 850px) {
		height: 360px;
	}
	@media screen and (max-width: 440px) {
		height: 390px;
	}
	@media screen and (max-width: 335px) {
		height: 404px;
	}
	@media screen and (max-width: 260px) {
		height: 440px;
	}
`;

export const Footer = () => {
	return (
		<React.Fragment>
			<FooterWrapper>
				<FooterMenuWrapper>
					<FooterImgWrapDiv>
						<Exist when={GAME_NAME !== Syndicates.MX}>
							<FooterImgDiv>
								<FooterImg
									src={nflLogo}
									alt={window.getTranslations(
										"pickem_footer_hyperlink_nfl"
									)}
								/>
							</FooterImgDiv>
						</Exist>

						<SponsorImage
							position="footer"
							landing={true}
							showPresentedBy={false}
							showWhiteImage={true}
						/>
					</FooterImgWrapDiv>
					{GAME_NAME === Syndicates.AU && (
						<GambleDiv>
							{window.getTranslations(
								"pickem_betting_text_gamble_responsibly_tab"
							)}
						</GambleDiv>
					)}

					<Exist when={!SECRET_KEY_ENABLED}>
						<FooterTermsDiv>
							<Exist when={IS_DE && !IS_RTL}>
								<TermExternalLink
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.nfl.com/legal/privacy/de"
								>
									Datenschutzbestimmungen
								</TermExternalLink>
							</Exist>
							<TermLink to="/help/rules">
								{window.getTranslations("global_footer_terms")}
							</TermLink>
						</FooterTermsDiv>
					</Exist>

					<Copywriting
						dangerouslySetInnerHTML={{
							__html:
								window.getTranslations("global_footer_desc") ||
								"",
						}}
					/>
				</FooterMenuWrapper>
			</FooterWrapper>
			<Exist when={IS_DE && !IS_RTL}>
				<ProsibenFooter />
			</Exist>
			<Exist when={IS_RTL}>
				<Iframe
					src="https://www.sport.de/genius-footer/"
					title="footer"
					className={"rtl-footer"}
				/>
			</Exist>
		</React.Fragment>
	);
};

export default Footer;
