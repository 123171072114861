import {ISponsorLadderPayload} from "./../../reducers/league/index";
import * as _ from "lodash";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import {ILadderPayload, ILeagueCreate, ISagaAction} from "../../types";
import {Api, ApiErrors} from "../../utils";
import * as actions from "../../actions";
import {getLeagueLadder} from "../../selectors";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

export const createLeagueSaga = function* (
	action: ISagaAction<ILeagueCreate>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.League.create,
			action.payload
		);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		if (GAME_NAME === "UK" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(actions.createLeagueSuccess(result.id));
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const fetchLadderSaga = function* (
	action: ISagaAction<ILadderPayload>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.League.ladder,
			action.payload
		);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		yield put(actions.fetchLadderSuccess(result));
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const fetchMoreLadderSaga = function* (
	action: ISagaAction<ILadderPayload>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.League.ladder,
			action.payload
		);
		const ladder = yield select(getLeagueLadder);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		const response = {
			...result,
			ladder: [...ladder, ...result.ladder],
		};

		yield put(actions.fetchLadderSuccess(response));
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};

export const updateLeagueSaga = function* (
	action: ISagaAction<ILeagueCreate>
): SagaIterator {
	try {
		const {
			result: {id: updated_league_id},
			errors,
		} = yield call(Api.League.update, action.payload);

		ApiErrors.checkOnApiError({errors});

		if (GAME_NAME === "UK" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(actions.showLeague(updated_league_id));
		yield put(actions.updateLeagueSuccess());
	} catch (e) {
		yield put(actions.updateLeagueFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const fetchSponsorLadderSaga = function* (
	action: ISagaAction<ISponsorLadderPayload>
): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(
			Api.League.sponsor_ladder,
			action.payload
		);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			throw new ApiErrors(errors[0].text, errors[0].code);
		}

		yield put(actions.showSponsorLadderSuccess(result));
	} catch (e) {
		console.log(e);
		yield put(actions.errorsGlobalError(e));
	}
};
