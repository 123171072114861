import * as React from "react";
import theme from "../../../assets/css/theme";
import styled from "styled-components";
import {connect} from "react-redux";
import {showMyProfile, updateUser} from "../../../modules/actions";
import {IModelUser, IStoreState} from "../../../modules/types";
import {FormCircleCheckbox} from "../../../components";
import {UpdateAccountLink} from "./../accountUpdate/accountUpdateForm";
import {bindAll, set} from "lodash";

const FormStyle = styled.form`
	background-color: #ffffff;
	border-radius: 6px;
	padding: 30px;
`;

const Title = styled.h3`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 30px;
`;

const Description = styled.p`
	color: #555555;
	font-size: 14px;
	line-height: 22px;
	font-family: ${theme.base_font};
	margin-bottom: 60px;
`;

const WrapperRadio = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	border-bottom: 1px solid #eeeeee;
`;

const LabelStyled = styled.label`
	color: #151515;
	font-size: 14px;
`;

interface IState {
	form_element: object;
	is_updated: boolean;
}

interface IProps {
	children?: React.ReactNode;
	showMyProfile: typeof showMyProfile;
	updateUser: typeof updateUser;
	readonly user: IModelUser;
}

class CommunicationsPageComponent extends React.Component<IProps, IState> {
	public state = {
		form_element: {},
		is_updated: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleChange", "handleSubmit"]);
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		this.props.showMyProfile();
	}

	/**
	 * @ignore
	 */
	public render() {
		const {
			user: {profile_info},
		} = this.props;
		const {is_updated} = this.state;

		return profile_info ? (
			<FormStyle
				onChange={this.handleChange}
				onSubmit={this.handleSubmit}
			>
				<Title>
					{window.getTranslations("myaccount_text_communications")}
				</Title>
				<Description />

				{/*<WrapperRadio>*/}
				{/*<LabelStyled>*/}
				{/*{'Toggle all conmunications & Notifications'}*/}
				{/*</LabelStyled>*/}
				{/*<FormCircleCheckbox*/}
				{/*name="disable_notifications"*/}
				{/*checked={Boolean(profile_info.disable_notifications)}*/}
				{/*value="1"*/}
				{/*/>*/}
				{/*</WrapperRadio>*/}

				<WrapperRadio>
					<LabelStyled>
						{window.getTranslations(
							"account_communications_text_game_emails"
						)}
					</LabelStyled>

					<FormCircleCheckbox
						name="news"
						value="1"
						checked={Boolean(profile_info.news)}
					/>
				</WrapperRadio>

				<WrapperRadio>
					<LabelStyled>
						{window.getTranslations(
							"account_communications_text_result_emails"
						)}
					</LabelStyled>

					<FormCircleCheckbox
						name="weekly_results"
						value="1"
						checked={Boolean(profile_info.weekly_results)}
					/>
				</WrapperRadio>

				<WrapperRadio>
					<LabelStyled>
						{window.getTranslations(
							"account_communications_text_reminder_emails"
						)}
					</LabelStyled>

					<FormCircleCheckbox
						name="weekly_reminders"
						value="1"
						checked={Boolean(profile_info.weekly_reminders)}
					/>
				</WrapperRadio>

				<UpdateAccountLink>
					{is_updated
						? window.getTranslations("account_form_button_updated")
						: window.getTranslations("myaccount_button_update")}
				</UpdateAccountLink>
			</FormStyle>
		) : null;
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, checked} = event.target as HTMLFormElement;
		const {form_element} = this.state;

		set(form_element, name, checked ? "1" : "0");
		this.setState({form_element});
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const {form_element} = this.state;

		this.setState({is_updated: true});
		this.props.updateUser(form_element);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	user: state.user,
});

const mapDispatchToProps = {
	updateUser,
	showMyProfile,
};

export const CommunicationsPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(CommunicationsPageComponent);

export default CommunicationsPage;
