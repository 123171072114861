import styled from "styled-components";
import theme from "../../../assets/css/theme";

export const HelpContent = styled.div`
	margin-bottom: 30px;
	line-height: 22px;
	> span,
	> div > span,
	font {
		color: #555555 !important;
		font-family: ${theme.base_font}!important;
		font-size: 14px !important;
		line-height: 22px !important;
	}

	table {
		width: auto !important;
		td,
		tr {
			border: 1px solid #ccc;
			text-align: center;
			padding: 5px;

			span,
			font {
				font-size: 14px;
				color: #555555 !important;
			}
		}
	}

	p,
	div {
		color: #555;
		font-size: 14px;
		line-height: 22px;
		padding-bottom: 10px;
	}

	h2 {
		color: #333;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -0.1px;
		font-family: ${theme.base_bold_font};
		margin-bottom: 20px;
	}
`;
