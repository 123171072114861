import * as React from "react";
import styled from "styled-components";
import {
	CheckboxStyled,
	Exist,
	Input,
	Label,
	SponsorImage,
} from "../../components";
import {NavLink} from "react-router-dom";

import {
	CreateAccountButton,
	CheckboxWrapper,
	ElementWrapper,
	ErrorBlock,
	LandingWrapper,
	Logo,
	RegisterForm,
	WelcomeBlock,
	WelcomeText,
} from "./RegisrationStyles";
import {
	emailTerms,
	getSponsorText,
} from "components/TranslationsData/hardcodedTerms";

const InputDescription = styled.div`
	color: #757575;
	font-size: 12px;
	margin-bottom: 5px;
	font-weight: 300;
`;

const MAX_LENGTH_TEAM_NAME = 40;
const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";
const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";

export interface IFormRegisterForGameState {
	form_element?: {
		team_name: string;
		email_notifications?: string;
		news?: string;
		terms: string;
		sponsor_notifications: string;
	};
	form_errors?: {
		team_name: string;
		terms: string;
	};
}

interface IState extends IFormRegisterForGameState {
	handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
	handleChange: (event: React.FormEvent<HTMLFormElement>) => void;
	isReturningUser: boolean;
}

export const defaultRegisterForGameState = {
	form_element: {
		team_name: "",
		email_notifications: "0",
		news: "0",
		terms: "0",
		sponsor_notifications: "0",
	},
	form_errors: {
		team_name: "",
		terms: "",
	},
};

const getWelcomeText = (isReturningUser: boolean) =>
	isReturningUser
		? window.getTranslations("register_returninguser_returning_heading")
		: window.getTranslations("register_form_checkbox_details");
export default function FormRegisterForGame({
	form_element = defaultRegisterForGameState.form_element,
	form_errors = defaultRegisterForGameState.form_errors,
	handleSubmit,
	handleChange,
	isReturningUser,
}: IState) {
	const sponsorInfoText = getSponsorText(GAME_LANGUAGE);
	return (
		<LandingWrapper>
			<WelcomeBlock>
				<Logo />
				<SponsorImage landing={true} />
				<WelcomeText>{getWelcomeText(isReturningUser)}</WelcomeText>
				<RegisterForm onSubmit={handleSubmit} onChange={handleChange}>
					{!isReturningUser && (
						<ElementWrapper>
							<Label>
								{window.getTranslations(
									"register_form_textbox_team_name"
								)}
							</Label>
							<InputDescription>
								{window.getTranslations(
									"register_form_text_team_name"
								)}
							</InputDescription>
							<ErrorBlock>{form_errors.team_name}</ErrorBlock>
							<Input
								type="text"
								name="team_name"
								is_error={form_errors.team_name !== ""}
								placeholder={window.getTranslations(
									"fld_team_name"
								)}
								maxLength={MAX_LENGTH_TEAM_NAME}
								defaultValue={form_element.team_name}
								required={true}
							/>
						</ElementWrapper>
					)}

					<ElementWrapper>
						<CheckboxWrapper>
							<CheckboxStyled name="terms" value="0" />
							<Label htmlFor="terms">
								{GAME_NAME === "Tabcorp" ? (
									<React.Fragment>
										I have read and agree to the NFL's{" "}
										<NavLink to="/help/privacy-policy">
											Privacy Policy
										</NavLink>{" "}
										and{" "}
										<NavLink to="/help/collection-statement">
											Collection Statement
										</NavLink>
									</React.Fragment>
								) : (
									<React.Fragment>
										{window.getTranslations(
											"register_form_label_terms_agree"
										)}{" "}
										<NavLink
											to="/help/rules"
											target="_blank"
											rel="noopener noreferrer"
										>
											{" "}
											{window.getTranslations(
												"register_form_terms_service"
											)}
											*
										</NavLink>
										{window.getTranslations(
											"register_form_label_terms_nfl_service"
										)}
									</React.Fragment>
								)}
							</Label>
						</CheckboxWrapper>
						<ErrorBlock>{form_errors.terms}</ErrorBlock>
					</ElementWrapper>

					{isReturningUser ? (
						<ElementWrapper>
							<CheckboxWrapper>
								<CheckboxStyled
									name="news"
									is_checked={form_element.news === "1"}
								/>
								<Label htmlFor="news">
									{emailTerms(GAME_LANGUAGE)}
								</Label>
							</CheckboxWrapper>
						</ElementWrapper>
					) : (
						<ElementWrapper>
							<CheckboxWrapper>
								<CheckboxStyled
									name="email_notifications"
									is_checked={
										form_element.email_notifications === "1"
									}
								/>
								<Label htmlFor="email_notifications">
									{emailTerms(GAME_LANGUAGE)}
								</Label>
							</CheckboxWrapper>
						</ElementWrapper>
					)}

					{GAME_NAME === "prosieben" ? (
						<ElementWrapper>
							<CheckboxWrapper>
								<CheckboxStyled
									name="sponsor_notifications"
									is_checked={
										form_element.sponsor_notifications ===
										"1"
									}
								/>
								<Label htmlFor="sponsor_notifications">
									Ich bin damit einverstanden, dass mich die
									ProSiebenSat. 1 Sports GmbH mit ihrem
									NFL-E-Mail-Newsletter über
									NFL-Programm-Highlights auf den Sendern der
									ProSiebenSat.1 Mediengruppe (ProSieben,
									ProSiebenMaxx, Sat. 1 und
									NFL-Kollektions-Angebote (wie z.B. T-Shirts
									und Sweatshirts, Sammlerstücke und Caps) des
									Shops
									<a
										href="HTTPS://RANFOOTBALL.SHOP/"
										target="_blank"
										rel="noopener noreferrer"
									>
										RANFOOTBALL.SHOP
									</a>
									; Betreiber: Mahagony Apparel GmbH & Co. KG)
									informiert.
								</Label>
							</CheckboxWrapper>
						</ElementWrapper>
					) : (
						<Exist when={GAME_NAME !== "FR"}>
							<ElementWrapper>
								<CheckboxWrapper>
									<CheckboxStyled
										name="sponsor_notifications"
										is_checked={
											form_element.sponsor_notifications ===
											"1"
										}
									/>
									<Label htmlFor="sponsor_notifications">
										{sponsorInfoText}
									</Label>
								</CheckboxWrapper>
							</ElementWrapper>
						</Exist>
					)}
					<CreateAccountButton>
						{window.getTranslations("register_form_start_picking")}
					</CreateAccountButton>
				</RegisterForm>
			</WelcomeBlock>
		</LandingWrapper>
	);
}
