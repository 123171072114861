import {SagaIterator} from "redux-saga";
import {takeLatest} from "redux-saga/effects";
import * as actions from "../actions";
import * as user from "./user";
import * as squads from "./squads";
import * as errors from "./error_managment";
import * as settings from "./settings";
import * as rankings from "./rankings";
import * as live_scores from "./live_scores";
import * as gameweeks from "./gameweeks";
import * as league from "./league";
import * as show_banner from "./show_banner";
import * as tips from "./tips";
import * as leagues from "./leagues";
import * as tipping_stats from "./tipping_stats";
import * as jsons from "./jsons";
import * as divisional_standings from "./divisional_standings";
import * as contact_us from "./contact_us";

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
	yield takeLatest(actions.userLogin, user.postUserLoginSaga);
	yield takeLatest(actions.userLogout, user.postUserLogoutSaga);
	yield takeLatest(actions.acceptTheConditions, user.acceptTheConditionsSaga);
	yield takeLatest(actions.userRegistration, user.registrationUserSaga);
	yield takeLatest(actions.userRegisterForGame, user.registerForGameSaga);
	yield takeLatest(
		actions.userReturningRegisterForGame,
		user.returningRegisterForGameSaga
	);

	yield takeLatest(actions.fetchUser, user.fetchUserSaga);
	yield takeLatest(actions.showMyProfile, user.showMyProfileSaga);

	yield takeLatest(actions.checkUsername, user.checkUsernameSaga);
	yield takeLatest(actions.checkEmail, user.checkEmailSaga);

	yield takeLatest(actions.userDeactivate, user.deactivateUserSaga);

	yield takeLatest(actions.userUnsubscribe, user.userUnsubscribeSaga);

	yield takeLatest(actions.fetchChecksums, settings.fetchChecksumsJSONSaga);
	yield takeLatest(actions.fetchSquadsJSON, squads.fetchSquadsSaga);

	yield takeLatest(actions.errorsGlobalError, errors.errorsManagerSaga);

	yield takeLatest(actions.getRankings, rankings.fetchRankingsSaga);

	yield takeLatest(
		actions.subscribeToLiveScores,
		live_scores.fetchLiveScoresSaga
	);

	yield takeLatest(actions.fetchGameWeeksJSON, gameweeks.fetchGameWeeksSaga);
	yield takeLatest(actions.fetchBannerJSON, show_banner.fetchShowBannerSaga);

	yield takeLatest(actions.createLeague, league.createLeagueSaga);
	yield takeLatest(actions.updateLeague, league.updateLeagueSaga);

	yield takeLatest(actions.showMyTips, tips.showMyTipsSaga);
	yield takeLatest(actions.saveMyTips, tips.saveMyTipsSaga);
	yield takeLatest(actions.saveMyListTips, tips.saveMyListTipsSaga);
	yield takeLatest(actions.autopickMyTips, tips.autopickMyTipsSaga);
	yield takeLatest(actions.freeAutopickMyTips, tips.freeAutopickMyTipsSaga);

	yield takeLatest(actions.showMyLeagues, leagues.showMyLeaguesSaga);
	yield takeLatest(
		actions.showLeaguesForJoin,
		leagues.showMyLeaguesForJoinSaga
	);

	yield takeLatest(actions.fetchSnapshot, tipping_stats.fetchSnapshotSaga);
	yield takeLatest(actions.joinToLeague, leagues.joinToLeagueSaga);
	yield takeLatest(actions.leaveLeague, leagues.postLeagueLeaveSaga);
	yield takeLatest(actions.showLeague, leagues.getLeagueByIdSaga);
	yield takeLatest(actions.fetchLadder, league.fetchLadderSaga);
	yield takeLatest(actions.showSponsorLadder, league.fetchSponsorLadderSaga);

	yield takeLatest(actions.fetchMoreLadder, league.fetchMoreLadderSaga);

	yield takeLatest(actions.inviteViaEmail, leagues.postInvitesSendViaEmail);

	yield takeLatest(actions.fetchPrizes, jsons.fetchPrizesJSON);
	yield takeLatest(actions.fetchGuidelines, jsons.fetchGuidelinesJSON);
	yield takeLatest(actions.fetchRules, jsons.fetchRulesJSON);
	yield takeLatest(actions.fetchFAQS, jsons.fetchFAQSJSON);
	yield takeLatest(actions.fetchPrivacyPolicy, jsons.fetchPrivacyPolicyJSON);

	yield takeLatest(
		actions.fetchStandingsJSONS,
		divisional_standings.fetchDivisionalStandingsJSONSaga
	);
	yield takeLatest(actions.fetchFormGuide, jsons.fetchFormGuideJSON);

	yield takeLatest(actions.fetchRoundStats, jsons.fetchRoundStatsJSON);

	yield takeLatest(
		actions.fetchTranslationsJSON,
		jsons.fetchTranslationsJSONSaga
	);
	yield takeLatest(actions.disableTooltip, user.disableTooltipSaga);
	yield takeLatest(actions.enableTooltip, user.enableTooltipSaga);

	yield takeLatest(actions.updateUser, user.updateUserSaga);

	yield takeLatest(actions.saveContactUs, contact_us.postContactUsSaga);

	yield takeLatest(actions.searchAndJoin, leagues.searchAndJoinSaga);

	yield takeLatest(actions.showJoinedUsers, leagues.showJoinedUsersSaga);
	yield takeLatest(
		actions.showMoreJoinedUsers,
		leagues.showMoreJoinedUsersSaga
	);
	yield takeLatest(
		actions.removeFromLeague,
		leagues.postRemoveFromLeagueSaga
	);
}
