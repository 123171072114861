import {
	ContactUs,
	FAQs,
	GameGuidelines,
	GameRules,
	PrizesPool,
	PrivacyPolicy,
} from "../";

const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";

export interface IComponentClass {
	header_hidden?: boolean;
}

// No prizes for MX version
const prizes =
	GAME_LANGUAGE === "MX"
		? {}
		: {
				help_contact_category_prizes: PrizesPool,
		  };

export const TABS = {
	pickem_footer_hyperlink_howtoplay: GameGuidelines,
	help_list_label_rules: GameRules,
	...prizes,
	help_contact_button_faq: FAQs,
	help_list_text_contact: ContactUs,
	pickem_footer_hyperlink_privacypolicy: PrivacyPolicy,
};
console.log(TABS);
