import {IModelUser} from "../../types/user";

export const saveUserToStorage = (
	user: IModelUser,
	session_id?: string
): void => {
	localStorage.setItem("user", JSON.stringify(user));
	localStorage.setItem("is_authorized", "true");

	if (session_id !== undefined) {
		localStorage.setItem("sid", session_id);
	}
};

export const clearUserFromStorage = (): void => {
	localStorage.removeItem("user");
	localStorage.removeItem("is_authorized");
	localStorage.removeItem("sid");
};

export const getUserObjectFromStorage = (): IModelUser => {
	return JSON.parse(localStorage.getItem("user") || "{}");
};
