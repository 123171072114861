import * as React from "react";
import {ISquad, IStoreState, IUserTips} from "../../modules/types";
import moment from "moment-timezone";

import {
	RoundProgress,
	SquadAvatar,
	FormGuide,
	IconArrowSlim,
	TooltipBubble,
} from "../../components";
import RadioButton from "./radioButton";
import {
	TipWrapper,
	MatchBlock,
	OpponentWrapper,
	HomeSquad,
	AwaySquad,
	SquadName,
	SquadInfo,
	SquadWrapper,
	PriceBar,
	SquadPoints,
	SkyBetBarImg,
	OdsItem,
	BettingPartner,
	MarginWrapper,
	MarginInputWrapper,
	MarginButton,
	MarginDescription,
	MarginInput,
	MatchComplete,
	MatchLive,
	MarginInfo,
	FinalMargin,
	BroadcastLogo,
	SkyBetTipLink,
} from "./TipItemStyle";

import {connect} from "react-redux";
import {
	clearSelectedTip,
	saveMyTips,
	setSelectedTip,
	disableTooltip,
} from "../../modules/actions";
import {
	bindAll,
	isNaN,
	toInteger,
	debounce,
	// values,
	get,
	isUndefined,
	find,
} from "lodash";
import {
	formatOdds,
	germanOddsStr,
	getCurrencyLabel,
	isMobile,
} from "../../modules/utils";
import tab_white from "../../assets/img/sponsor/tab_logo.png";
import pokerstarsLogo from "../../assets/img/sponsor/pokerstars_white.png";
import uk_sponsor from "../../assets/img/sponsor/888-logo-white.svg";
import {tooltipsText} from "../../components/TooltipBubble/tooltipsText";
import {ExternalLink} from "components/ExternalLink";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";
const MAX_MARGIN = 100;
const MIN_MARGIN = 0;
const SAVE_DELAY = 200;
const GAME_NAME = process.env.REACT_APP_GAME_NAME;

let BET_LINK = "";
if (GAME_NAME === "Tabcorp") {
	BET_LINK =
		"//ad.doubleclick.net/ddm/clk/451490051;255503323;s?https://www.tab.com.au/sports/betting/American%20Football/competitions/NFL/matches/";
} else if (GAME_NAME === "prosieben") {
	BET_LINK = "//www.skybet.de/";
}

const getRoundProgressProps = (tip: IUserTips) => ({
	home_color: tip.away_squad ? tip.away_squad.color : "",
	away_color: tip.home_squad ? tip.home_squad.color : "",
	percents: tip.home_percent,
});

const getAwaySquadSelector = (away_is_checked: boolean) =>
	away_is_checked ? "checked" : "";

const hideAwaySquadRadioButton = (tipStatus: string, isChecked: boolean) =>
	tipStatus !== "scheduled" && !isChecked;

const getMatchDateVenue = (
	id: number,
	match_date: string,
	venue_name: string
) => {
	if (match_date.includes(":38")) {
		return "TBD";
	} else {
		return `${match_date} - ${venue_name}`;
	}
};

interface IProps {
	saveMyTips: typeof saveMyTips;
	setSelectedTip: typeof setSelectedTip;
	clearSelectedTip: typeof clearSelectedTip;
	readonly tip: IUserTips;
	index: number;
	show_tooltips: boolean;
	disableTooltip: typeof disableTooltip;
	is_first: boolean;
	readonly not_select_tips: boolean;
	squads: ISquad[];
}

interface IState {
	predict_margin?: number;
	bubbles: {
		tip_bubble: boolean;
		margin_bubble: boolean;
		form_guide_bubble: boolean;
		total_tips_bubble: boolean;
		leagues_bubble: boolean;
	};
	is_mobile: boolean;
	currentBubble: number;
}

class TipItemComponent extends React.Component<IProps, IState> {
	public state = {
		predict_margin: 0,
		bubbles: {
			tip_bubble: false,
			margin_bubble: false,
			form_guide_bubble: false,
			total_tips_bubble: false,
			leagues_bubble: false,
		},
		currentBubble: 0,
		is_mobile: isMobile(),
	};

	public saveMarginDebounced: () => void;

	constructor(props: IProps, state: IState) {
		super(props, state);
		bindAll(this, [
			"handleCurrentBubbleIndex",
			"handleSaveTip",
			"increaseMargin",
			"decreaseMargin",
			"saveMargin",
			"changeMargin",
			"hoverTip",
			"leaveHoverTip",
			"closeBubble",
			"checkBubblesState",
		]);
		this.saveMarginDebounced = debounce(this.saveMargin, SAVE_DELAY);
	}

	public componentDidMount(): void {
		const {
			tip: {is_margin_game, predict_margin},
			index,
			show_tooltips,
		} = this.props;
		const is_show_bubbles = show_tooltips && index === 0;

		if (is_margin_game) {
			this.setState({
				predict_margin,
			});
		}

		if (is_show_bubbles) {
			this.setState({
				bubbles: {
					tip_bubble: true,
					margin_bubble: Boolean(is_margin_game),
					form_guide_bubble: true,
					total_tips_bubble: true,
					leagues_bubble: true,
				},
			});
		}
	}

	public componentDidUpdate(prevProps: any): void {
		const prevTooltips = prevProps.show_tooltips;
		const {
			index,
			show_tooltips,
			tip: {is_margin_game, status},
		} = this.props;
		const is_show_bubbles = show_tooltips && index === 0;
		if (index === 0 && prevTooltips !== show_tooltips) {
			if (is_show_bubbles) {
				this.setState({
					bubbles: {
						tip_bubble: true,
						margin_bubble:
							Boolean(is_margin_game) && status === "scheduled",
						form_guide_bubble: true,
						total_tips_bubble: true,
						leagues_bubble: true,
					},
				});
			}
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {tip, index, show_tooltips} = this.props;
		const is_absolute = show_tooltips && index === 0;
		const {is_margin_game} = tip;
		const {
			bubbles: {form_guide_bubble},
			currentBubble,
		} = this.state;
		return (
			<TipWrapper
				is_absolute={is_absolute}
				onMouseEnter={this.hoverTip}
				onMouseLeave={this.leaveHoverTip}
				data-id={tip.id}
			>
				{this.league_bubble_wrap}
				<MatchInfo
					broadcastLogo={this.broadcast_logo}
					currentBubble={currentBubble}
					handleClose={this.closeBubble}
					handleUpdateTip={this.handleCurrentBubbleIndex}
					period={this.period}
					showToolTips={this.props.show_tooltips}
					tip={this.props.tip}
					totalTipsBubble={this.state.bubbles.total_tips_bubble}
				/>
				<OpponentWrapper>
					{this.away_squad}
					<RoundProgress {...getRoundProgressProps(tip)} />
					{this.home_squad}
				</OpponentWrapper>
				{is_margin_game ? this.margin : null}
				<FormGuide
					tip_id={tip.id}
					home={tip.home_squad_id}
					away={tip.away_squad_id}
				/>
				{(form_guide_bubble || currentBubble === 2) && (
					<TooltipBubble
						extraSelector={"form_guide_bubble_tip"}
						bubble_name={tooltipsText.form_guide_bubble.bubble_name}
						onClose={this.closeBubble}
						updateTip={this.handleCurrentBubbleIndex}
						text={
							window.getTranslations(
								tooltipsText.form_guide_bubble.textKey
							) || ""
						}
						tipIndex={tooltipsText.form_guide_bubble.tipIndex}
						hidden={!show_tooltips || currentBubble !== 2}
						to={"top"}
					/>
				)}
				{this.ukOddsBar}
				{this.tabcorpOddsBar}
				{this.skybetOddsBar}
			</TipWrapper>
		);
	}

	public handleCurrentBubbleIndex() {
		const stepValue =
			!this.props.tip.is_margin_game && !this.state.currentBubble ? 2 : 1;
		this.setState({
			currentBubble:
				this.state.currentBubble === 4
					? 0
					: this.state.currentBubble + stepValue,
		});
	}

	private get ukOddsBar() {
		const {tip} = this.props;
		const {status, home_squad_odds_data, away_squad_odds_data} = tip;

		if (
			GAME_NAME !== "UK" ||
			status === "complete" ||
			!home_squad_odds_data?.link ||
			!away_squad_odds_data?.link
		) {
			return null;
		}

		return (
			<PriceBar className="uk-brand with-shadow-top">
				<OdsItem>
					<ExternalLink href={away_squad_odds_data.link}>
						{getCurrencyLabel()}
						{formatOdds(away_squad_odds_data.decimal)}
					</ExternalLink>
				</OdsItem>
				<BettingPartner>
					<img src={uk_sponsor} alt="888" />
				</BettingPartner>
				<OdsItem>
					<ExternalLink href={home_squad_odds_data.link}>
						{getCurrencyLabel()}
						{formatOdds(home_squad_odds_data.decimal)}
					</ExternalLink>
				</OdsItem>
			</PriceBar>
		);
	}

	private get skybetOddsBar() {
		const {tip, squads} = this.props;
		const {
			away_squad_odds,
			away_squad_id,
			home_squad_id,
			home_squad_odds,
			status,
			away_squad_odds_id,
			home_squad_odds_id,
		} = tip;

		const home_squad = find(squads, {id: home_squad_id});
		const away_squad = find(squads, {id: away_squad_id});
		if (
			GAME_NAME !== "prosieben" ||
			status === "complete" ||
			!away_squad_odds_id ||
			!home_squad_odds_id
		) {
			return null;
		}
		if (!home_squad || !away_squad) {
			return null;
		}
		return GAME_NAME === "prosieben" ? (
			<PriceBar>
				<OdsItem>
					<SkyBetTip
						id={away_squad_odds_id}
						label={formatOdds(away_squad_odds)}
					/>
				</OdsItem>
				<BettingPartner>
					<SkyBetBarImg src={pokerstarsLogo} alt="Pokerstars" />
				</BettingPartner>
				<OdsItem>
					<SkyBetTip
						id={home_squad_odds_id}
						label={formatOdds(home_squad_odds)}
					/>
				</OdsItem>
			</PriceBar>
		) : null;
	}

	private get tabcorpOddsBar() {
		const {tip, squads} = this.props;
		const {
			away_squad_id,
			home_squad_id,
			status,
			away_squad_odds_id,
			home_squad_odds_id,
		} = tip;
		const home_squad = find(squads, {id: home_squad_id});
		const away_squad = find(squads, {id: away_squad_id});
		if (
			GAME_NAME !== "Tabcorp" ||
			status === "complete" ||
			!away_squad_odds_id ||
			!home_squad_odds_id
		) {
			return null;
		}
		if (!home_squad || !away_squad) {
			return null;
		}
		const [home_squad_state] = home_squad.full_name.split(home_squad.name);
		const [away_squad_state] = away_squad.full_name.split(away_squad.name);
		const home_squad_state_trim = encodeURIComponent(
			home_squad_state.replace(/(^[\s]+|[\s]+$)/g, "")
		);
		const away_squad_state_trim = encodeURIComponent(
			away_squad_state.replace(/(^[\s]+|[\s]+$)/g, "")
		);

		return (
			<PriceBar>
				<OdsItem>
					<a
						href={`${BET_LINK}${home_squad_state_trim}${encodeURIComponent(
							" v "
						)}${away_squad_state_trim}?propId=${away_squad_odds_id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						${formatOdds(tip.away_squad_odds)}
					</a>
				</OdsItem>
				<BettingPartner>
					<img src={tab_white} alt="Tab" />
				</BettingPartner>
				<OdsItem>
					<a
						href={`${BET_LINK}${away_squad_state_trim}${encodeURIComponent(
							" v "
						)}${home_squad_state_trim}?propId=${home_squad_odds_id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						${formatOdds(tip.home_squad_odds)}
					</a>
				</OdsItem>
			</PriceBar>
		);
	}

	private get broadcast_logo() {
		const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";
		const {
			tip: {broadcasters},
		} = this.props;

		if (isUndefined(broadcasters)) {
			return null;
		}
		const BROADCAST_URL = process.env.REACT_APP_BROADCAST_URL || "";

		const link =
			broadcasters !== ""
				? get(broadcasters, `${GAME_LANGUAGE}.link`, "")
				: "";
		const broadcast_name =
			broadcasters !== ""
				? get(broadcasters, `${GAME_LANGUAGE}.broadcast`, "")
				: "";
		return broadcasters !== "" && broadcast_name !== "" ? (
			<BroadcastLogo href={link} target="_blank">
				<img
					src={`${BROADCAST_URL}${broadcast_name.replace(
						" ",
						""
					)}.png`}
					alt={broadcast_name}
				/>
			</BroadcastLogo>
		) : null;
	}

	private get league_bubble_wrap() {
		const {
			bubbles: {leagues_bubble},
			currentBubble,
		} = this.state;

		return (
			(leagues_bubble || currentBubble === 4) && (
				<TooltipBubble
					extraSelector={`navbar-tip ${GAME_NAME}`}
					bubble_name={tooltipsText.leagues_bubble.bubble_name}
					onClose={this.closeBubble}
					updateTip={this.handleCurrentBubbleIndex}
					text={
						window.getTranslations(
							tooltipsText.leagues_bubble.textKey
						) || ""
					}
					tipIndex={tooltipsText.leagues_bubble.tipIndex}
					hidden={!this.props.show_tooltips || currentBubble !== 4}
					to={"top"}
				/>
			)
		);
	}

	private get period() {
		const {
			tip: {period},
		} = this.props;

		const periods = {
			Q1: "pickem_livematchup_heading_live_first",
			Q2: "pickem_livematchup_heading_live_second",
			Q3: "pickem_livematchup_heading_live_third",
			Q4: "pickem_livematchup_heading_live_fourt",
		};

		return get(periods, period, "pickem_livematchup_heading_live");
	}

	private get home_squad() {
		const {tip} = this.props;
		const home_is_checked =
			tip.predict_winner_squad_id === tip.home_squad_id;
		const is_wone = tip.home_score >= tip.away_score;

		return (
			<HomeSquad
				status={tip.status}
				is_wone={is_wone}
				position="home"
				className={home_is_checked ? "checked" : ""}
			>
				<SquadPoints
					is_lose={
						tip.status !== "scheduled"
							? !is_wone
							: this.biggest_percent === "away"
					}
				>
					{tip.status === "scheduled" ? (
						<React.Fragment>
							{tip.home_percent}
							<span>%</span>
						</React.Fragment>
					) : (
						<span>{tip.home_score}</span>
					)}
				</SquadPoints>
				<SquadInfo>
					<SquadName className="hidden-mobile">
						{tip.home_squad_name}
					</SquadName>

					<SquadWrapper>
						<SquadAvatar
							alt={tip.home_squad_name}
							squad_id={tip.home_squad_id}
							margin="0 20px"
						/>
						<SquadName className="hidden-desktop">
							{tip.home_squad_name}
						</SquadName>
					</SquadWrapper>

					<RadioButton
						name={tip.id}
						value={tip.home_squad_id}
						is_checked={home_is_checked}
						onChange={this.handleSaveTip}
						hidden={tip.status !== "scheduled" && !home_is_checked}
						if_wone={is_wone}
						status={tip.status}
						is_pulse={this.is_pulse}
					/>
				</SquadInfo>
			</HomeSquad>
		);
	}

	private get away_squad() {
		const {index, tip, show_tooltips} = this.props;
		const away_is_checked =
			tip.predict_winner_squad_id === tip.away_squad_id;
		const {
			bubbles: {tip_bubble},
			currentBubble,
		} = this.state;
		const is_wone = tip.home_score <= tip.away_score;
		return (
			<AwaySquad
				status={tip.status}
				is_wone={is_wone}
				className={getAwaySquadSelector(away_is_checked)}
				position="away"
			>
				<SquadInfo>
					{index === 0 && (tip_bubble || currentBubble === 0) && (
						<TooltipBubble
							bubble_name={tooltipsText.tip_bubble.bubble_name}
							onClose={this.closeBubble}
							updateTip={this.handleCurrentBubbleIndex}
							text={
								window.getTranslations(
									tooltipsText.tip_bubble.textKey
								) || ""
							}
							to={"left"}
							tipIndex={tooltipsText.tip_bubble.tipIndex}
							hidden={!show_tooltips || currentBubble !== 0}
						/>
					)}

					<RadioButton
						name={tip.id}
						value={tip.away_squad_id}
						is_checked={away_is_checked}
						onChange={this.handleSaveTip}
						hidden={hideAwaySquadRadioButton(
							tip.status,
							away_is_checked
						)}
						if_wone={is_wone}
						status={tip.status}
						is_pulse={this.is_pulse}
					/>

					<SquadWrapper>
						<SquadAvatar
							alt={tip.away_squad_name}
							squad_id={tip.away_squad_id}
							margin="0 20px"
						/>
						<SquadName className="hidden-desktop">
							{tip.away_squad_name}
						</SquadName>
					</SquadWrapper>

					<SquadName className="hidden-mobile">
						{tip.away_squad_name}
					</SquadName>
				</SquadInfo>

				<SquadPoints
					is_lose={
						tip.status !== "scheduled"
							? !is_wone
							: this.biggest_percent === "home"
					}
				>
					{tip.status === "scheduled" ? (
						<React.Fragment>
							{tip.away_percent}
							<span>%</span>
						</React.Fragment>
					) : (
						<span>{tip.away_score}</span>
					)}
				</SquadPoints>
			</AwaySquad>
		);
	}

	private get biggest_percent() {
		const {tip} = this.props;
		return tip.home_percent &&
			tip.away_percent &&
			tip.home_percent > tip.away_percent
			? "home"
			: "away";
	}

	private get is_pulse() {
		const {
			is_first,
			tip: {predict_winner_squad_id},
			not_select_tips,
		} = this.props;
		return is_first && !predict_winner_squad_id && not_select_tips;
	}

	private handleSaveTip(event: React.ChangeEvent<HTMLInputElement>) {
		const {name, value} = event.target as HTMLInputElement;
		const {predict_margin} = this.state;

		if (this.is_pulse) {
			createFanHubEvent("78cbb611-9e4a-43ec-80eb-2be057f94ffe");
		}

		createFanHubEvent("c5ff0389-0daf-44f1-91cb-465c429dad8b");

		const {saveMyTips: save} = this.props;

		save({
			match_id: parseInt(name, 0),
			predict_winner_squad_id: parseInt(value, 0),
			predict_margin,
		});
	}

	private get final_margin() {
		const {
			tip: {
				predict_winner_squad_id,
				home_squad_id,
				home_score,
				away_score,
				predict_margin,
			},
		} = this.props;

		const piked_squad =
			predict_winner_squad_id === home_squad_id ? "home" : "away";
		const winner_squad = home_score > away_score ? "home" : "away";
		const you_win = piked_squad === winner_squad;
		// return piked_squad === 'home' ? (home_score - away_score) - predict_margin : (away_score - home_score) - predict_margin;
		if (you_win) {
			return piked_squad === "home"
				? home_score - away_score - predict_margin
				: away_score - home_score - predict_margin;
		} else {
			return piked_squad === "home"
				? away_score - home_score + predict_margin
				: home_score - away_score + predict_margin;
		}
	}

	private get margin_bubble() {
		const {
			bubbles: {margin_bubble},
			is_mobile,
			currentBubble,
		} = this.state;
		const {
			tip: {status},
		} = this.props;
		return (
			(margin_bubble || currentBubble === 1) && (
				<TooltipBubble
					extraSelector={
						status === "complete" || status === "playing"
							? "margin_bubble"
							: ""
					}
					bubble_name={tooltipsText.margin_bubble.bubble_name}
					onClose={this.closeBubble}
					updateTip={this.handleCurrentBubbleIndex}
					text={
						window.getTranslations(
							tooltipsText.margin_bubble.textKey
						) || ""
					}
					tipIndex={tooltipsText.margin_bubble.tipIndex}
					hidden={!this.props.show_tooltips || currentBubble !== 1}
					to={is_mobile ? "top" : "left"}
				/>
			)
		);
	}

	private get margin() {
		const {
			tip: {
				predict_margin: current_margin,
				status,
				predict_winner_squad_id,
				home_squad_id,
				home_squad_name,
				away_squad_name,
			},
		} = this.props;
		const {predict_margin: new_marging} = this.state;
		const piked_squad_name =
			predict_winner_squad_id === home_squad_id
				? home_squad_name
				: away_squad_name;

		const predict_margin = new_marging !== 0 ? new_marging : current_margin;
		return (
			<React.Fragment>
				{status !== "scheduled" ? (
					predict_winner_squad_id ? (
						<React.Fragment>
							<MarginInfo>
								{window.getTranslations(
									"pickem_form_team_selection_points",
									[
										{points: predict_margin.toString()},
										{team: piked_squad_name},
									]
								)}
							</MarginInfo>
							{this.margin_bubble}
							{status === "complete" ? (
								<FinalMargin>
									{window.getTranslations(
										"pickem_form_margin_points",
										[
											{
												points: Math.abs(
													this.final_margin
												).toString(),
											},
										]
									)}
								</FinalMargin>
							) : null}
						</React.Fragment>
					) : (
						<div>{this.margin_bubble}</div>
					)
				) : (
					<React.Fragment>
						<MarginDescription>
							{window.getTranslations(
								"pickem_matchup_text_selectedmargin"
							)}
						</MarginDescription>
						<MarginWrapper>
							<MarginButton
								onClick={this.decreaseMargin}
								to={"left"}
								disabled={predict_margin === MIN_MARGIN}
							>
								<IconArrowSlim color="#1B48E0" to="left" />
								<span>
									{window.getTranslations(
										"pickem_matchup_text_lower"
									)}
								</span>
							</MarginButton>
							<MarginInputWrapper>
								<MarginInput
									aria-label={window.getTranslations(
										"pickem_matchup_text_selectedmargin"
									)}
									name="selectedmargin"
									type="text"
									value={predict_margin}
									onChange={this.changeMargin}
								/>
								{this.margin_bubble}
							</MarginInputWrapper>
							<MarginButton
								onClick={this.increaseMargin}
								to={"right"}
								disabled={predict_margin === MAX_MARGIN}
							>
								<span>
									{window.getTranslations(
										"pickem_matchup_text_higher"
									)}
								</span>{" "}
								<IconArrowSlim color="#1B48E0" to="right" />
							</MarginButton>
						</MarginWrapper>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}

	private changeMargin(e: React.SyntheticEvent<HTMLInputElement>) {
		const {value} = e.currentTarget;
		let predict_margin = toInteger(value);
		if (isNaN(predict_margin)) {
			return;
		}

		if (predict_margin < MIN_MARGIN) {
			predict_margin = MIN_MARGIN;
		}

		if (predict_margin > MAX_MARGIN) {
			predict_margin = MAX_MARGIN;
		}

		this.setState({
			predict_margin,
		});
		this.saveMarginDebounced();
	}

	private increaseMargin(event: React.SyntheticEvent<HTMLButtonElement>) {
		event.preventDefault();
		const {predict_margin} = this.state;

		this.setState({
			predict_margin: predict_margin + 1,
		});
		this.saveMarginDebounced();
	}

	private decreaseMargin(event: React.SyntheticEvent<HTMLButtonElement>) {
		event.preventDefault();
		const {predict_margin} = this.state;
		const new_predict_margin = predict_margin - 1;

		if (new_predict_margin >= 0) {
			this.setState({
				predict_margin: new_predict_margin,
			});
			this.saveMarginDebounced();
		}
	}

	private saveMargin() {
		const {tip, saveMyTips: save} = this.props;
		const {predict_margin} = this.state;

		if (tip.predict_winner_squad_id) {
			save({
				predict_winner_squad_id: tip.predict_winner_squad_id,
				match_id: tip.id,
				predict_margin,
			});
		}
	}

	private hoverTip() {
		const {tip} = this.props;
		this.props.setSelectedTip(tip);
	}

	private leaveHoverTip() {
		this.props.clearSelectedTip();
	}

	private closeBubble(event: React.SyntheticEvent<HTMLButtonElement>) {
		event.preventDefault();
		const {bubble} = event.currentTarget.dataset as {
			bubble:
				| "tip_bubble"
				| "margin_bubble"
				| "form_guide_bubble"
				| "total_tips_bubble"
				| "leagues_bubble";
		};

		if (bubble) {
			this.setState(
				{
					bubbles: {
						...this.state.bubbles,
						[bubble]: !this.state.bubbles[bubble],
					},
				},
				this.checkBubblesState
			);
		}
	}

	private checkBubblesState() {
		// const {bubbles} = this.state;
		// const showed = values(bubbles).includes(true);
		// if (!showed) {
		this.props.disableTooltip();
		// }
	}
}

interface ISkyBetTip {
	id: string;
	label: string;
}

function SkyBetTip({id, label}: ISkyBetTip) {
	return (
		<SkyBetTipLink
			href={`${BET_LINK}?addAffiliateSelectionId=${id}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{germanOddsStr(`${label}`)}
		</SkyBetTipLink>
	);
}

interface IMatchInfo {
	broadcastLogo: JSX.Element | null;
	currentBubble: number;
	handleClose: (
		event: React.SyntheticEvent<HTMLButtonElement, Event>
	) => void;
	handleUpdateTip: () => void;
	period: string;
	showToolTips: boolean;
	tip: IUserTips;
	totalTipsBubble: boolean;
}

function MatchInfo({
	broadcastLogo,
	currentBubble,
	handleClose,
	handleUpdateTip,
	period,
	showToolTips,
	tip,
	totalTipsBubble,
}: IMatchInfo) {
	const {status, date, venue_name, id} = tip;
	const match_date = moment(date)
		.tz(moment.tz.guess())
		.format("h:mmA, ddd, D MMM YYYY z");
	const match_date_venue = getMatchDateVenue(id, match_date, venue_name);

	return (
		<MatchBlock>
			{(totalTipsBubble || currentBubble === 3) && (
				<TooltipBubble
					bubble_name={tooltipsText.total_tips_bubble.bubble_name}
					onClose={handleClose}
					updateTip={handleUpdateTip}
					text={
						window.getTranslations(
							tooltipsText.total_tips_bubble.textKey
						) || ""
					}
					tipIndex={tooltipsText.total_tips_bubble.tipIndex}
					hidden={!showToolTips || currentBubble !== 3}
					to={"bottom"}
				/>
			)}
			{status === "complete" ? (
				<MatchComplete>
					{window.getTranslations(
						"pickem_livematchup_heading_matchcomplete"
					)}
				</MatchComplete>
			) : (
				""
			)}
			{status === "playing" ? (
				<MatchLive>{window.getTranslations(period)}</MatchLive>
			) : (
				""
			)}
			{status === "scheduled" ? `${match_date_venue}` : ""}
			{broadcastLogo}
		</MatchBlock>
	);
}

const mapStateToProps = (state: IStoreState) => ({
	squads: state.squads,
});

const mapDispatchToProps = {
	saveMyTips,
	setSelectedTip,
	clearSelectedTip,
	disableTooltip,
};

export const TipItem = connect(
	mapStateToProps,
	mapDispatchToProps
)(TipItemComponent);

export default TipItem;
