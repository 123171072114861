import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import GigyaWrapper from "gigya-api-wrapper";
import {Link} from "react-router-dom";
import theme from "../../assets/css/theme";
import logo from "../../assets/img/mini-logo.png";
import {isLoggedIn} from "../../modules/selectors";
import {below} from "../../assets/css/media";
import {IShowBanner, IStoreState} from "../../modules/types";
import {IModelUser} from "../../modules/types";
import {
	HeaderNavigation,
	SubNavigation,
	MobileNavigation,
	SponsorImage,
	TopProsibenHeader,
	Exist,
} from "../";
import {Redirect} from "react-router";
import UserAvatar from "../../assets/img/icons/Avatar.svg";
import {bindAll, debounce} from "lodash";
import {IS_DE, IS_RTL, isMobile, OFF_SEASON_ENABLED} from "modules/utils";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";

const Iframe = styled.iframe`
	margin-bottom: -2px;
`;

const ContentHeader = styled.div`
	width: 100%;
	position: sticky;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 20px 0 0;
	display: flex;
	align-items: center;
	height: 62px;
	justify-content: space-between;
	background: ${theme.header_bg};
	color: white;

	${below.desktop`${css`
		position: sticky;
		top: 0;
		z-index: 10;
		padding: 0;
	`}`};
`;

const LogoInfo = styled.div`
	text-align: center;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0 10px;
	font-size: 12px;
	font-family: ${theme.menu_font};

	img {
		height: 35px;
		width: auto;
	}
`;

const UserAvatarImg = styled.img`
	height: 30px;
	width: 30px;
	margin-left: 30px;

	${below.desktop`
		display: none;
	`};
`;

const HeaderBlock = styled.div`
	display: flex;
	align-items: center;

	${below.desktop`
		flex: 2;
		// justify-content: space-between;
	`};
`;

const HeaderBlockCenter = styled(HeaderBlock)`
	margin-left: auto;
	flex: 0;

	div {
		white-space: nowrap;
		color: #ffffff;
	}
`;

const HeaderBlockLast = styled(HeaderBlock)`
	// ${below.desktop`
	// 	justify-content: flex-end;
	// `};
`;

const BannerDiv = styled.div`
	font-family: ${theme.base_font};
	background: ${theme.primary ?? "#143cab"};
	color: #fff;
	text-align: center;
	padding: 10px;
	font-size: 12px;
	line-height: 16px;
`;

const LoginButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	color: #ffffff;
	font-size: 16px;
	text-transform: capitalize;
`;

interface IProps extends RouteComponentProps {
	is_logged_in: boolean;
	gigyaWrapperInstance: typeof GigyaWrapper;
	user: IModelUser;
	show_banner: IShowBanner;
}

interface IState {
	is_mobile: boolean;
}

class HeaderComponent extends React.Component<IProps, IState> {
	public state = {
		is_mobile: isMobile(),
	};

	private onResizeDebounced?: () => void;

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleLogin"]);
		// bindAll(this, ['handleShareFB', 'handleShareTW']);
	}

	public componentDidMount(): void {
		const debounce_time = 100;
		this.onResizeDebounced = debounce(this.onResize, debounce_time);
		window.addEventListener("resize", this.onResizeDebounced);
	}

	private get loginText() {
		return window.getTranslations("fld_login") || "";
	}

	/**
	 * @ignore
	 */
	public render() {
		if (this.is_not_playing_game) {
			return <Redirect to="/register-for-game" />;
		}
		return (
			<React.Fragment>
				{this.props.show_banner.show ? (
					<BannerDiv
						dangerouslySetInnerHTML={{
							__html: this.props.show_banner.text,
						}}
					/>
				) : null}
				{process.env.REACT_APP_GAME_NAME === "prosieben" && (
					<TopProsibenHeader />
				)}
				<Exist when={IS_RTL}>
					<Iframe
						src="https://www.sport.de/genius-header/"
						height="72px"
						width="100%"
						title="header"
					/>
				</Exist>
				<ContentHeader>
					<HeaderBlock>
						<LogoInfo>
							<Link to="/">
								<img
									src={logo}
									alt={
										IS_DE ? "NFL Tippspiel" : "NFL Tipping"
									}
								/>
							</Link>
							<p>{IS_DE ? "Tippspiel" : "Pick’em"}</p>
						</LogoInfo>

						<HeaderNavigation />
					</HeaderBlock>

					<HeaderBlockCenter>
						{process.env.REACT_APP_SPONSOR && (
							<SponsorImage
								position="header"
								showWhiteImage={true}
								showPresentedBy={!this.state.is_mobile}
								landing={false}
							/>
						)}
					</HeaderBlockCenter>

					<Exist when={!OFF_SEASON_ENABLED}>
						<HeaderBlockLast>
							{this.props.is_logged_in ? (
								<Link to="/account/update">
									<UserAvatarImg
										src={UserAvatar}
										alt="User Avatar"
									/>
								</Link>
							) : (
								<LoginButton
									aria-label={"User Avatar"}
									onClick={this.handleLogin}
								>
									{/*<UserAvatarImg*/}
									{/*	src={UserAvatar}*/}
									{/*	alt="User Avatar"*/}
									{/*/>*/}
									{this.loginText}
								</LoginButton>
							)}
							<MobileNavigation />
						</HeaderBlockLast>
					</Exist>
				</ContentHeader>
				<SubNavigation />
			</React.Fragment>
		);
	}

	public handleLogin() {
		createFanHubEvent("03896baa-ee71-4d7b-917d-cf373f5363bd");
		this.props.gigyaWrapperInstance.gigya.sso.login({
			authFlow: "redirect",
		});
		return;
	}

	private readonly onResize = () => {
		const is_mobile = isMobile();

		this.setState({
			is_mobile,
		});
	};

	private get is_not_playing_game() {
		const {
			user: {playing_games},
			match: {url},
			is_logged_in,
		} = this.props;
		const is_game_in_this = Boolean(
			playing_games &&
				playing_games.find((game) => game === "nfl_tipping")
		);

		return (
			is_logged_in &&
			!is_game_in_this &&
			url !== "/register-for-game" &&
			url.indexOf("/help") &&
			url.indexOf("/prizes")
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	show_banner: state.show_banner,
	is_logged_in: isLoggedIn(state),
	user: state.user,
	gigyaWrapperInstance: state.user.gigyaWrapperInstance,
});

export const Header = withRouter(connect(mapStateToProps)(HeaderComponent));

export const withHeader =
	(WrappedComponent: React.ComponentType<any>) => (props: any) => {
		return (
			<React.Fragment>
				<Header {...props} />
				<WrappedComponent {...props} />
			</React.Fragment>
		);
	};
export default withHeader;
