import * as React from "react";
import styled from "styled-components";

interface ISquadImg {
	width: number | undefined;
	margin: string | undefined;
}

const SquadImg = styled.img`
	width: ${({width}: ISquadImg) => (width ? `${width}.png` : "70px")};
	margin: ${({margin}: ISquadImg) => margin || "0"};
`;

const SQUADS_URL = process.env.REACT_APP_SQUADS_URL || "";

interface IProps {
	alt: string;
	squad_id: number;
	width?: number;
	margin?: string;
}

export const SquadAvatar = ({alt, squad_id, width, margin}: IProps) => (
	<SquadImg
		alt={alt}
		src={`${SQUADS_URL}${squad_id}.svg`}
		width={width}
		margin={margin}
	/>
);

export default SquadAvatar;
