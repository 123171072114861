import {SagaIterator} from "redux-saga";

import {call, race, take, select, all} from "redux-saga/effects";

import {
	differenceBy,
	toPairs,
	last,
	first,
	flow,
	partial,
	get,
	identity,
	isEmpty,
} from "lodash";

import {unsubscribeFromLiveScores} from "../../actions";
import {getChecksums} from "../../selectors";

import {fetchChecksumsJSONSaga} from "../settings";
import {fetchSquadsSaga} from "../squads";
import {fetchGameWeeksSaga} from "../gameweeks";
import {fetchSnapshotSaga} from "../tipping_stats";
import {showMyTipsSaga} from "../tips";

import {delay} from "../../utils";

const mapChecksumsToSaga = {
	squads: fetchSquadsSaga,
	rounds: fetchGameWeeksSaga,
	states: [fetchSnapshotSaga, showMyTipsSaga],
};

const WAIT = 30000; // Half minute

export const fetchLiveScoresSaga = function* (): SagaIterator {
	const {stopped} = yield race({
		wait: call(delay, WAIT), // Wait for a half minute
		stopped: take(unsubscribeFromLiveScores),
	});

	if (stopped) {
		return null;
	}

	const [old_checksums] = yield all([
		select(getChecksums),
		call(fetchChecksumsJSONSaga),
	]);

	const new_checksums = yield select(getChecksums);

	console.log(
		differenceBy(toPairs(old_checksums), toPairs(new_checksums), last)
	);

	const requestsForChanges = differenceBy(
		toPairs(old_checksums),
		toPairs(new_checksums),
		last
	)
		.map(flow([first, partial(get, mapChecksumsToSaga)]))
		.filter(identity);

	if (!isEmpty(requestsForChanges)) {
		yield all(requestsForChanges.map((request) => call(request)));
	}

	yield call(fetchLiveScoresSaga);
};

export default fetchLiveScoresSaga;
