import * as React from "react";
import styled from "styled-components";
import {TABS} from "../accounts_tab";
import {MobileContent} from "./MobileContent";

const MobileContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Mobile = () => (
	<MobileContentWrapper className="hidden-desktop">
		{Object.keys(TABS).map((t) => (
			<MobileContent
				key={t}
				name={t}
				ComponentClass={TABS[t as keyof typeof TABS]}
			/>
		))}
	</MobileContentWrapper>
);

export default Mobile;
