import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import * as actions from "../../actions";
import Api from "../../utils/Api";

export const fetchSnapshotSaga = function* (): SagaIterator {
	try {
		const {result} = yield call(Api.TippingStats.show_snapshot);

		yield put(actions.fetchSnapshotSuccess(result));
	} catch (err) {
		yield put(actions.fetchGameWeeksJSONFailed(err));
		yield put(actions.errorsGlobalError(err));
	}
};

export default fetchSnapshotSaga;
