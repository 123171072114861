import * as React from "react";
import styled from "styled-components";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {bindAll} from "lodash";

import moment from "moment";

import {IconCalendar} from "../../components";
import {Moment} from "moment";

const DatetimeWrapper = styled.div`
	position: relative;
	width: 100%;

	svg {
		position: absolute;
		top: 13px;
		right: 10px;
		pointer-events: none;
	}
`;

const DatetimeStyled = styled(Datetime)`
	width: 100%;

	input {
		height: 40px;
		width: 100%;
		border: 1px solid #cccccc;
		color: #757575;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 0 10px;
		margin-top: 5px;
		cursor: pointer;
	}

	.rdtPicker {
		border-radius: 5px;
		box-shadow: 0 1px 3px #868686;
	}

	td {
		text-align: center;
		vertical-align: middle;
	}

	.rdtPicker td.rdtActive {
		background-color: #105bfb;
		color: #ffffff;
		border-radius: 5px;

		&:hover {
			background-color: #105bfb;
			color: #ffffff;
		}
	}

	.rdtPicker td.rdtActive.rdtToday:before {
		border-bottom-color: #000000;
	}

	.rdtPicker td.rdtToday:before {
		border-bottom-color: #cccccc;
	}

	.rdtNext span,
	.rdtPrev span {
	}

	.rdtSwitch {
		font-weight: 700;
		vertical-align: middle;
	}

	.rdtPicker .rdtTimeToggle,
	.rdtPicker .rdtTime .rdtSwitch {
		outline: none;
		border: none;
		background: #ffffff;
		color: #cccccc;
		border-radius: 3px;
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.5px;
		line-height: 1.5;
		padding: 10px;
		height: 36px;
		cursor: pointer;
		width: auto;
		&:hover {
			background: #105bfb;
		}
		&:hover {
			background: #105bfb;
		}
	}

	thead td {
		padding-bottom: 10px;
	}

	thead > tr:first-child > th {
		padding: 10px 0;
	}

	.rdtPicker thead tr:first-child th:hover,
	.rdtPicker thead tr:first-child th:hover {
		background-color: transparent;
	}

	.rdtCounter .rdtBtn:hover {
		background-color: transparent;
	}
`;

interface IProps {
	readonly name: string;
	readonly value: string;
	readonly placeholder: string | undefined;
	readonly onChange?: (value: string | Moment) => void;
}

export class FormDateField extends React.Component<IProps> {
	constructor(props: any) {
		super(props);

		bindAll(this, ["checkIsValidDate"]);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {name, value, placeholder, onChange} = this.props;

		const ICON_SIZE = 25;

		return (
			<DatetimeWrapper>
				<DatetimeStyled
					inputProps={{
						name,
						value,
						placeholder,
						readOnly: true,
					}}
					dateFormat="YYYY-MM-DD"
					timeFormat={false}
					isValidDate={this.checkIsValidDate}
					initialValue={value}
					onChange={onChange}
				/>
				<IconCalendar
					width={ICON_SIZE}
					height={ICON_SIZE}
					color={"#555555"}
				/>
			</DatetimeWrapper>
		);
	}

	private checkIsValidDate(currentData: Moment) {
		const YEAR = process.env.REACT_APP_MINIMUM_AGE_FOR_REGISTRATION || 0;
		const maxDateMoment = moment(new Date()).subtract(YEAR, "year");

		return currentData.isBefore(maxDateMoment);
	}
}

export default FormDateField;
