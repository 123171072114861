import * as React from "react";
import styled from "styled-components";
import theme from "../../assets/css/theme";

const ScoreTable = styled.div`
	display: flex;
	padding: 1em 1.5em;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	max-width: 400px;
	margin: ${({margin}: ITable) => margin || "0 auto 1em"};
	li {
		font-family: ${theme.base_font};
		opacity: 0.95;
		color: #1b1b1b;
		font-size: 0.875em;
		margin-bottom: 0.25em;

		&:first-child {
			font-weight: bold;
			padding-bottom: 0.5em;
		}
	}
`;
const LeftSide = styled.ul`
	flex: 1 1 100%;
	border-right: 2px solid #ebebeb;
	border-radius: 3px;
	text-align: left;
`;
const RightSide = styled.ul`
	flex: 1 1 100%;
	text-align: center;
`;
interface ITable {
	margin?: string;
}
export const TablePrize = ({margin}: ITable) => (
	<ScoreTable margin={margin}>
		<LeftSide>
			<li>Final Position</li>
			<li>1st</li>
			<li>2nd</li>
			<li>3rd</li>
			<li>4th</li>
			<li>5th</li>
			<li>6th</li>
			<li>7th - 8th</li>
			<li>9th - 10th</li>
			<li>11th - 15th</li>
			<li>16st - 20th</li>
			<li>21st - 25th</li>
			<li>26th - 35th</li>
			<li>36th - 50th</li>
			<li>51st - 70th</li>
			<li>71st - 100th</li>
			<li>101st - 150th</li>
			<li>151st - 250th</li>
			<li>251st - 400th</li>
			<li>401st - 650th</li>
			<li>651st - 1000th</li>
		</LeftSide>
		<RightSide>
			<li>Payout</li>
			<li>$5,000</li>
			<li>$4,500</li>
			<li>$3,000</li>
			<li>$2,000</li>
			<li>$1,000</li>
			<li>$1,000</li>
			<li>$700</li>
			<li>$500</li>
			<li>$350</li>
			<li>$300</li>
			<li>$250</li>
			<li>$200</li>
			<li>$150</li>
			<li>$100</li>
			<li>$70</li>
			<li>$50</li>
			<li>$45</li>
			<li>$40</li>
			<li>$35</li>
			<li>$30</li>
		</RightSide>
	</ScoreTable>
);
