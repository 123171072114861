import styled from "styled-components";
import {below} from "../../../assets/css/media";

export const LeagueBodyRow = styled.div`
	max-width: 720px;
	height: 40px;
	display: flex;
	position: relative;
	overflow: hidden;

	&:first-child {
		&:after {
			content: "";
			background: linear-gradient(
				45deg,
				#eee 0%,
				#eee 90%,
				transparent 100%
			);
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
		}
	}

	${below.desktop`
		max-width: 90%;
	`};
`;
