import {ISponsoredLeagueLadderResponse} from "./../../reducers/league/index";
import {createAction} from "redux-act";
import {
	IInvitesEmails,
	ILadderPayload,
	ISponsorLadderPayload,
	ILeagueJoinReducer,
	ILeagueStateReducer,
	ILeaveLeague,
	IMyLeague,
	ILeagueLadderResponse,
	IRemoveFromLeague,
	IJoinedUsersPayload,
	IJoinedUsersResponse,
} from "../../reducers";
import {ILeagueCreate, ILeagueUpdate} from "../../types";
import {IActionSearch, ILoadMore} from "../../sagas/leagues";

export const fetchLadder = createAction<ILadderPayload>();
export const fetchLadderSuccess = createAction<ILeagueLadderResponse>();
export const fetchMoreLadder = createAction<ILadderPayload>();

export const showSponsorLadder = createAction<ISponsorLadderPayload>();
export const showSponsorLadderSuccess =
	createAction<ISponsoredLeagueLadderResponse>("Show Sponsor Success");
export const showSponsorLadderFailed = createAction<ISponsorLadderPayload>();

export const createLeague = createAction<ILeagueCreate>("Create League");
export const createLeagueSuccess = createAction<number>("Create League Succes");

export const updateLeague = createAction<ILeagueUpdate>(
	"Update League By Id Event"
);
export const updateLeagueSuccess = createAction("Update League By Id Success");
export const updateLeagueFailed = createAction("Update League By Id Failed");
export const clearLeaguesState = createAction("Update League By Id Failed");

export const clearLeagueFlags = createAction("Clear League Flags");

export const showMyLeagues = createAction<ILoadMore>("Show My Leagues");
export const showMyLeaguesSuccess = createAction<ILeagueStateReducer>(
	"Show My Leagues Success"
);
export const showMyLeaguesFailed = createAction("Show My Leagues Failed");

export const showLeaguesForJoin = createAction<IActionSearch & ILoadMore>(
	"Show Join Leagues"
);
export const showLeaguesForJoinSuccess = createAction<ILeagueJoinReducer>(
	"Show Join Leagues Success"
);
export const showLeaguesForJoinFailed = createAction(
	"Show Join Leagues Failed"
);

export const showLeague = createAction<number>("Show League By Id Event");
export const showLeagueSuccess = createAction<IMyLeague>(
	"Show League By Id Success"
);
export const showLeagueFailed = createAction("Show League By Id Failed");

export const leaveLeague = createAction<ILeaveLeague>("Leave League");
export const leaveLeagueSuccess = createAction<ILeaveLeague>(
	"Leave League Success"
);
export const leaveLeagueFailed = createAction("Leave League Failed");

export const showLeaguesForJoinMore = createAction(
	"Load More Leagues For Join"
);

export const joinToLeague = createAction<string>("Join To League");
export const joinToLeagueSuccess = createAction<string>(
	"Join To League By Code Success"
);
export const joinToLeagueFailed = createAction<string>(
	"Join To League By Code Failed"
);
export const clearLeaguesForJoin = createAction("Clear Leagues For Join State");
export const searchAndJoin = createAction<string>("Search And Join By Code");

export const inviteViaEmail = createAction<IInvitesEmails>(
	"Invite Via Email Event"
);
export const inviteViaEmailSuccess = createAction(
	"Invite Via Email Event Success"
);
export const inviteViaEmailFailed = createAction(
	"Invite Via Email Event Failed"
);
export const inviteViaEmailClear = createAction("Invite Via Email Event Clear");

export const showJoinedUsers =
	createAction<IJoinedUsersPayload>("Show Joined Users");
export const showMoreJoinedUsers = createAction<IJoinedUsersPayload>(
	"Show More Joined Users"
);
export const showJoinedUsersSuccess = createAction<IJoinedUsersResponse>(
	"Show Joined Users Success"
);
export const showJoinedUsersFailed = createAction("Show Joined Users Failed");

export const removeFromLeague =
	createAction<IRemoveFromLeague>("Remove From League");
export const removeFromLeagueSuccess = createAction<IRemoveFromLeague>(
	"Remove From League Success"
);
export const removeFromLeagueFailed = createAction("Remove From League Failed");
