import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconInfo = ({width, height, color}: IProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 24 24`}
	>
		<defs>
			<path
				id="path-1-info"
				d="M12 20a8 8 0 100-16 8 8 0 000 16zm0-18c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm1 9h-2v6h2v-6zm-1-4.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
			/>
		</defs>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<mask id="mask-2-info" fill={color}>
				<use xlinkHref="#path-1-info" />
			</mask>
			<use fillRule="nonzero" xlinkHref="#path-1-info" />
			<g mask="url(#mask-2-info)">
				<g>
					<path d="M0 0H24V24H0z" />
				</g>
			</g>
		</g>
	</svg>
);

IconInfo.defaultProps = {
	width: 24,
	height: 24,
	color: "#FFF",
};

export default IconInfo;
