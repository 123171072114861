import {ILeague, IRequestData} from "../../types";
import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface IJoinLeague {
	id: number;
	commissioner: number;
	name: string;
	status: "scheduled" | "active" | "complete" | "playing";
	privacy: "public" | "private";
	start_round: number;
	code: string;
	first_name: string;
	last_name: string;
	num_teams: number;
	avatar_version: number;
	start_at: Date;
	invited: number;
}

export interface ILeagueJoinReducer extends IJoinLeagueState {
	items: ILeague[];
	next: boolean;
}

export interface IJoinState extends IRequestData {
	is_joined: boolean;
}

export interface IJoinedCodes {
	[code: string]: IJoinState;
}

export interface IJoinLeagueState extends IRequestData {
	joined_codes?: IJoinedCodes;
}

export interface ILeaveLeague {
	league_id: number;
	league_code: string;
}

const initialState: ILeagueJoinReducer = {
	items: [],
	next: false,
	is_data_requested: false,
	joined_codes: {},
};

export const join_leagues = createReducer({}, initialState)
	.on(actions.showLeaguesForJoin, (state: ILeagueJoinReducer) => {
		return {
			...state,
			is_data_requested: true,
		};
	})
	.on(
		actions.showLeaguesForJoinSuccess,
		(state: ILeagueJoinReducer, payload: ILeagueJoinReducer) => {
			return {
				...state,
				items: [...state.items, ...payload.items],
				next: payload.next,
				is_data_requested: false,
			};
		}
	)
	.on(actions.showLeaguesForJoinFailed, (state: ILeagueJoinReducer) => ({
		...state,
		is_data_requested: false,
	}))
	.on(
		actions.joinToLeague,
		(state: ILeagueJoinReducer, league_code: string) => ({
			...state,
			joined_codes: {
				...state.joined_codes,
				[league_code]: {
					is_data_requested: true,
					is_joined: false,
				},
			},
		})
	)
	.on(
		actions.joinToLeagueSuccess,
		(state: ILeagueJoinReducer, payload: string) => ({
			...state,
			joined_codes: {
				...state.joined_codes,
				[payload]: {
					is_data_requested: false,
					is_joined: true,
				},
			},
		})
	)
	.on(
		actions.joinToLeagueFailed,
		(state: ILeagueJoinReducer, payload: string) => ({
			...state,
			joined_codes: {
				...state.joined_codes,
				[payload]: {
					is_data_requested: false,
					is_joined: false,
				},
			},
		})
	)
	.on(actions.clearLeaguesForJoin, (state: ILeagueJoinReducer) => ({
		...initialState,
		is_data_requested: true,
		joined_codes: state.joined_codes,
	}))
	.on(
		actions.leaveLeague,
		(state: ILeagueJoinReducer, payload: ILeaveLeague) => ({
			...state,
			joined_codes: {
				...state.joined_codes,
				[payload.league_code]: {
					is_data_requested: true,
					is_joined: true,
				},
			},
		})
	)
	.on(
		actions.leaveLeagueSuccess,
		(state: ILeagueJoinReducer, payload: ILeaveLeague) => ({
			...state,
			is_success: true,
			joined_codes: {
				...state.joined_codes,
				[payload.league_code]: {
					is_data_requested: false,
					is_joined: false,
				},
			},
		})
	)
	.on(actions.leaveLeagueFailed, (state: ILeagueJoinReducer) => ({
		...initialState,
		is_data_requested: false,
	}));
