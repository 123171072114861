import {ISaveMyTips, IStoreState, ITip, IUserTips} from "../types";
import {get, reduce} from "lodash";
import {IGameWeek} from "../reducers";
import {IMatch} from "../reducers/gameweeks";

export const getUserTips = (
	{tips, squads}: IStoreState,
	actual_round: IGameWeek | undefined
) => {
	const matches = get(actual_round, "matches");
	const {tips_data, match_round_stats} = tips;

	if (!matches) {
		return [];
	}

	return reduce(
		matches,
		(accumulator: IUserTips[], matche: IMatch) => {
			const find_tip = tips_data.find(
				(element) => get(element, "id") === get(matche, "id")
			) || {
				id: 0,
				predict_winner_squad_id: 0,
				is_draw: 0,
				is_correct: 0,
				is_autogenerate: 0,
				hidden: 0,
				predict_margin: 0,
			};

			const find_stats = match_round_stats.find(
				(element) => get(element, "match_id") === get(matche, "id")
			);

			accumulator.push({
				...find_tip,
				...matche,
				...find_stats,
				home_squad: squads.find(
					(element) => element.id === matche.home_squad_id
				),
				away_squad: squads.find(
					(element) => element.id === matche.away_squad_id
				),
			});

			return accumulator;
		},
		[]
	);
};

export const getSaveStatus = ({tips}: IStoreState) => tips.tips_saved;

export const getTipsData = ({tips}: IStoreState): ITip[] => tips.tips_data;

export const getSelectedTips = ({tips}: IStoreState): ISaveMyTips[] =>
	tips.selected_tips;
