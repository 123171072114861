import * as React from "react";
import styled, {css} from "styled-components";
import {connect} from "react-redux";
import {IMyLeague, IStoreState} from "../../../modules/types";
import theme from "../../../assets/css/theme";
import {copyToClipboard, delay, share} from "../../../modules/utils";
import {bindAll, get, partial} from "lodash";
import {showLeague} from "../../../modules/actions";
import {getMyLeague} from "../../../modules/selectors";
import InvitesLeagueEmails from "./invitesViaEmails";
import {below} from "../../../assets/css/media";
import {
	LeagueAboutLink,
	RegularButtonOutline,
	ShareLeague,
} from "../../../components";
import {createFanHubEvent} from "../../../modules/utils/FanHubTagging";

interface IInviteLeagueWrapper {
	shadow?: boolean;
}

const InviteLeagueWrapper = styled.div<IInviteLeagueWrapper>`
	padding: 20px 30px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow: ${({shadow}) => (shadow ? "0 0 10px 0 #E0E0E0" : "none")};
	border-radius: 4px;

	${below.desktop`
		width: 100%;
	`};
`;

const InvitesTitle = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 28px;
	line-height: 34px;
	color: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 35px;

	${below.desktop`
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 10px;
	`};
`;

const InvitesDescription = styled.div`
	font-family: ${theme.base_font};
	font-size: 14px;
	line-height: 22px;
	color: #555;
	> p {
		font-family: ${theme.base_bold_font};
	}
	margin-bottom: 30px;
`;

const SkipMobileText = styled.span``;

const SkipDesktopText = styled.span``;

const SkipButton = styled.div`
	color: #757575;
	font-family: ${theme.base_font};
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
	line-height: 22px;
	${SkipMobileText} {
		display: none;
	}

	${below.desktop`
		${SkipDesktopText}{
			display: none;
		};
	`};

	${below.desktop`
		${SkipMobileText}{
			display: block;
		};
	`};
`;

const HiddenInput = styled.input`
	opacity: 0;
	position: absolute;
	pointer-events: none;
	z-index: -1;
`;

interface ICopyButton {
	copied: boolean;
}

const CopyButton = styled(RegularButtonOutline)<ICopyButton>`
	transition: all 250ms ease-in-out;
	margin-bottom: 25px;
	font-family: ${theme.base_bold_font};

	${({copied}) =>
		copied &&
		css`
			background-color: ${theme.primary};
			color: #fff;
		`};

	${below.desktop`
		max-width: 100%;
	`};
`;

interface IProps {
	children?: React.ReactNode;
	league_created_id?: number | null;
	league_id?: number;
	showLeague: typeof showLeague;
	league: IMyLeague;
	shadow?: boolean;
	clear?: () => void;
}

interface IState {
	copied_link: boolean;
	copied_code: boolean;
	clipboard: string;
}

class InvitesLeagueComponent extends React.Component<IProps, IState> {
	public state = {
		copied_link: false,
		copied_code: false,
		clipboard: "",
	};

	public hiddenInput: React.RefObject<HTMLInputElement>;

	constructor(props: IProps) {
		super(props);
		this.hiddenInput = React.createRef();

		bindAll(this, ["copyLink", "copyCode", "handleShare"]);
	}

	/**
	 * Hide message for copy button
	 */
	public hideMessage() {
		const THREE_SECONDS = 1000;
		delay(THREE_SECONDS).then(() => {
			this.setState({
				copied_link: false,
				copied_code: false,
			});
		});
	}

	/**
	 * Copy league code
	 */
	public copyCode(clipboard_text: string) {
		this.setState({clipboard: clipboard_text}, () => {
			copyToClipboard(this.hiddenInput).then(() => {
				this.setState(
					{
						copied_code: true,
					},
					this.hideMessage
				);
			});
		});
	}

	/**
	 * Copy league code
	 */
	public copyLink(clipboard_text: string) {
		this.setState({clipboard: clipboard_text}, () => {
			createFanHubEvent("2da84e5d-bd0f-47a8-b918-412344c6d1a3");
			copyToClipboard(this.hiddenInput).then(() => {
				this.setState(
					{
						copied_link: true,
					},
					this.hideMessage
				);
			});
		});
	}

	/**
	 * Method for sharing Twitter and Facebook
	 */
	public handleShare(type_share: "tw" | "fb", comp_code: string) {
		const {league_id, league_created_id} = this.props;
		createFanHubEvent("df0c2d7f-f6a8-47d5-93f4-df8663224421");

		share({
			sc: type_share,
			league_id: league_created_id || league_id,
			section: "league",
			message: `${window.getTranslations(
				"competitioninvite_invites_text_twitter"
			)} ${comp_code}.`,
		});
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount() {
		const {showLeague: show, league_created_id, league_id} = this.props;

		if (league_created_id) {
			show(league_created_id);
		} else if (league_id) {
			show(league_id);
		}
	}

	/**
	 * Fetch league by id
	 */
	public componentDidUpdate(prevProps: IProps) {
		const {showLeague: show, league_created_id} = this.props;

		if (
			league_created_id &&
			league_created_id !== prevProps.league_created_id
		) {
			show(league_created_id);
		}
	}

	/**
	 * Clear league ID
	 */
	public componentWillUnmount() {
		if (this.props.clear) {
			this.props.clear();
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {clipboard, copied_code, copied_link} = this.state;
		const {league, league_created_id, shadow, clear} = this.props;
		const league_class = get(league, "class");

		if (league_class === "overall" || league_class === "club") {
			return null;
		}

		const code = get(league, "code") || "Unknown";
		const link = `${window.location.origin}/leagues/join/${league.code}`;
		return (
			<InviteLeagueWrapper shadow={shadow}>
				<InvitesTitle>
					{window.getTranslations("leaguecreate_form_text_invite")}
					{league_created_id ? (
						<SkipButton
							onClick={clear}
							as={LeagueAboutLink(league_created_id)}
						>
							<SkipMobileText>
								{window.getTranslations(
									"leagueinvite_form_invite_skip"
								)}
							</SkipMobileText>
							<SkipDesktopText>
								{window.getTranslations(
									"leagueinvite_form_invite_skip_now"
								)}
							</SkipDesktopText>
						</SkipButton>
					) : null}
				</InvitesTitle>
				<InvitesDescription>
					{window.getTranslations(
						"leagueinvite_invites_text_invite_friends"
					)}
					{league.name !== "" && (
						<p>
							{window.getTranslations(
								"leagueinvite_invites_text_code",
								[{League: league.name}]
							)}{" "}
							{code}
						</p>
					)}
				</InvitesDescription>

				<CopyButton
					copied={copied_code}
					onClick={partial(this.copyCode, code)}
				>
					{copied_code
						? window.getTranslations(
								"leagueinvite_form_button_copied"
						  )
						: window.getTranslations(
								"leagueinvite_invites_text_copy_code"
						  )}
				</CopyButton>

				<HiddenInput ref={this.hiddenInput} defaultValue={clipboard} />

				{league.user_status === "commissioner" ? (
					<InvitesLeagueEmails league={league} />
				) : null}

				<ShareLeague
					comp_code={code}
					copied_link={copied_link}
					onCopy={partial(this.copyLink, link)}
					onShare={this.handleShare}
					title={window.getTranslations(
						"leagueinvite_invites_header_email"
					)}
				/>
			</InviteLeagueWrapper>
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	league: getMyLeague(state) as IMyLeague,
});

const mapDispatchToProps = {
	showLeague,
};

export const InvitesLeague = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvitesLeagueComponent);
export default InvitesLeague;
