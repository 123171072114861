import * as React from "react";
import {withHeader} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {connect} from "react-redux";
import {IFAQ, IStoreState} from "../../modules/types";
import {fetchFAQS} from "../../modules/actions";
import {get, map} from "lodash";
import {getOmnitureTracking} from "../../modules/selectors";
import {getFAQs} from "../../modules/selectors/jsons";
import {HelpContent, HelpSubTitle, HelpTitle} from "./help";
import {LoadingSpinner} from "../../components";

interface IProps {
	faqs: IFAQ[];
	fetchFAQS: typeof fetchFAQS;
	header_hidden?: boolean;
	readonly omniture_tracking: any;
}

class FAQsComponent extends React.Component<IProps> {
	public componentDidMount(): void {
		const {fetchFAQS: _fetchFAQS, omniture_tracking} = this.props;

		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("pick em", "helpModal", "FAQs")
			);
		}
		_fetchFAQS();
	}

	public render() {
		const {header_hidden} = this.props;

		return header_hidden ? (
			this.faqs_content
		) : (
			<HelpPagesWrapper
				title={
					window.getTranslations("help_contact_button_rules") || ""
				}
			>
				{this.faqs_content}
			</HelpPagesWrapper>
		);
	}

	private get faqs_content() {
		const {faqs} = this.props;

		return faqs.length ? (
			<React.Fragment>
				<HelpTitle>FAQs</HelpTitle>

				{map(faqs, (item, key) => (
					<React.Fragment key={key}>
						<HelpSubTitle>{get(item, "title")}</HelpSubTitle>
						<HelpContent
							dangerouslySetInnerHTML={{
								__html: get(item, "content", ""),
							}}
						/>
					</React.Fragment>
				))}
			</React.Fragment>
		) : (
			<LoadingSpinner />
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	faqs: getFAQs(state),
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	fetchFAQS,
};

export const FAQs = connect(mapStateToProps, mapDispatchToProps)(FAQsComponent);
export default withHeader(FAQs);
