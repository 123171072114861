import styled, {css} from "styled-components";
import theme from "../../assets/css/theme";
import {get} from "lodash";
import {IDictionary, TGameName} from "modules/types";
import {MAIN_COLOUR} from "modules/utils";
interface IActive {
	is_active: boolean;
}

const GAME_NAME = (process.env.REACT_APP_GAME_NAME as TGameName) || "";

interface ITheme {
	color: string;
	imgWidth: string;
	shadedColor: string;
}

const multiTheme = (gameName: TGameName) => {
	const themes: IDictionary<ITheme> = {
		Tabcorp: {
			color: "#161616",
			imgWidth: "30px",
			shadedColor: "#F3FCF7",
		},
		prosieben: {
			color: "#000",
			imgWidth: "97px",
			shadedColor: "#E4F4FD",
		},
		UK: {
			color: MAIN_COLOUR,
			imgWidth: "47px",
			shadedColor: "#FFF0E6",
		},
		Mexico: {
			color: "rgb(0, 92, 151)",
			imgWidth: "97px",
			shadedColor: "#E4F4FD",
		},
		default: {
			color: "#101251",
			imgWidth: "97px",
			shadedColor: "#E4F4FD",
		},
	};
	const defaultKey = "default";
	return get(themes, gameName, themes[defaultKey]);
};

export const MultiWrapperDiv = styled.div`
	@media screen and (max-width: 840px) {
		margin: 10px;
	}
`;

export const OddsGeneratorWrapperDiv = styled.div`
	background: #fafafa;
	padding: 20px;
`;

export const OddsGeneratorheaderDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: ${multiTheme(GAME_NAME).color};
	height: 110px;
	color: #ffffff;
	border-radius: 4px;
	margin-top: 25px;
	box-sizing: border-box;
	padding: 0 10px;
`;

export const TitleH5 = styled.h5`
	font-size: 12px;
	margin-bottom: 10px;
`;

export const DescriptionDiv = styled.div`
	font-size: 10px;
`;

export const TipsWrapperDiv = styled.div`
	max-width: 600px;
	margin: 0 auto 20px;
`;

export const TipItemWrapperDiv = styled.div`
	display: flex;
	height: 40px;
	border-bottom: 1px solid #d6d6d6;
	font-size: 12px;
	position: relative;
`;

export const TipItemDiv = styled.div<IActive>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 50%;
	background: ${({is_active}) =>
		is_active ? multiTheme(GAME_NAME).shadedColor : "#fff"};
	padding: 0 10px;
`;

export const SeparatorDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #cccccc;
	font-size: 8px;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
`;

export const TeamInfoDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const OddsValueDiv = styled.div<IActive>`
	height: 20px;
	width: 40px;
	box-sizing: border-box;
	border: 1px solid ${multiTheme(GAME_NAME).color};
	border-radius: 4px;
	font-size: 10px;
	${({is_active}) =>
		is_active
			? css`
					color: #fff;
					background: ${multiTheme(GAME_NAME).color};
			  `
			: css`
					color: #151515;
			  `};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BigLabel = styled.p`
	color: #757575;
	font-family: ${theme.base_font};
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	margin: 0 0 10px;
`;

export const SmallLabel = styled(BigLabel)`
	letter-spacing: 0.07px;
	line-height: 14px;
	font-size: 10px;
`;

export const FlexibleDiv = styled.div`
	display: flex;
	> div:first-child {
		margin-right: 10px;
	}

	> div:last-child {
		margin-left: auto;
	}
`;

export const FlexibleInputsDiv = styled.div`
	display: flex;
	margin-bottom: 10px;
	input {
		flex: 1;
		max-width: 190px;
		background-color: #ffffff;
		border: 1px solid #cccccc;
		height: 40px;
		padding: 0 10px;
		border-radius: 4px;
		color: #757575;
		font-size: 14px;
		margin-right: 10px;
		box-sizing: border-box;
	}
`;

export const LinkOut = styled.a<IActive>`
	max-width: 190px;
	flex: 1;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 14px;
	justify-content: center;
	text-transform: uppercase;
	${({is_active}) =>
		is_active
			? css`
					border: 1px solid ${multiTheme(GAME_NAME).color};
					color: #fff;
					background-color: ${multiTheme(GAME_NAME).color};
			  `
			: css`
					border: 1px solid #cccccc;
					background-color: #fafafa;
					color: #cccccc;
					cursor: default;
			  `};
`;

export const PlaceBetDisabledButton = styled.button`
	border: 1px solid #cccccc;
	background-color: #fafafa;
	color: #cccccc;
	cursor: default;
	max-width: 190px;
	flex: 1;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 14px;
	justify-content: center;
	text-transform: uppercase;
`;

export const OddsWrapperDiv = styled.div`
	max-width: 400px;
	margin: 20px auto 0;
`;

export const LogosDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	img {
		width: ${multiTheme(GAME_NAME).imgWidth};
		margin: 0 5px;
	}
`;
