import {
	HTTPClient as HTTPClientCore,
	IRequestConfig,
} from "modules/utils/HTTPClient";
import * as qs from "qs";
import axios, {AxiosError} from "axios";
import {CANCEL} from "redux-saga";
import {ApiErrors} from "./ApiErrors";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest(config: IRequestConfig): Promise<any> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest({
			...config,
			params: {
				...config.params,
			},
			cancelToken: source.token,
		});

		(request as any)[CANCEL] = () => source.cancel();

		return request;
	}
}

const onCatchNetworkError = (response: AxiosError) =>
	Promise.reject(response.response?.data).catch((err) =>
		ApiErrors.checkOnApiError(err)
	);

const APIClient = new HTTPClient({
	baseURL: process.env.REACT_APP_API_URL || "",
	withCredentials: true,
	transformRequest: [(data) => qs.stringify(data)],
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_SPORT_URL || "",
	onCatchNetworkError,
});

const JSONCommonClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_URL || "",
	onCatchNetworkError,
});

export const Api = {
	JSON: {
		show_banner: () => JSONClient.get("banner.json"),
		squads: () => JSONClient.get("squads.json"),
		checksums: () => JSONClient.get("checksums.json"),
		gameweeks: () =>
			JSONClient.get(`rounds.json?v=${new Date().getTime()}`),
		divisional_standings: () => JSONClient.get("ladder.json"),
		prizes: () => JSONClient.get("nfl_tipping_prizes.json"),
		faq: () => JSONClient.get("nfl_tipping_faq.json"),
		guidelines: () => JSONClient.get("nfl_tipping_game_guidelines.json"),
		rules: () => JSONClient.get("nfl_tipping_game_rules.json"),
		privacy_policy: () => JSONClient.get("privacy_policy.json"),
		form_quide: (params: {id: number}) =>
			JSONCommonClient.get(`formguide/${params.id}.json`),
		round_stats: (params: {round: number}) =>
			JSONCommonClient.get(`round_stats/${params.round}.json`),
		langs: (params: {lang: string}) =>
			JSONClient.get(`langs/${params.lang}.json`),
	},
	Auth: {
		login: (params: object) => APIClient.post("login", params),
		logout: () => APIClient.post("logout"),
		SSO: (params: object) => APIClient.post("nfl_login", params),
		registration: (params: object) =>
			APIClient.post("nfl_login/registration", params),
		register_for_game: (params: object) =>
			APIClient.post("nfl_login/register_for_game", params),
	},
	User: {
		show_my: () => APIClient.get("user/show_my"),
		update: (params: object) => APIClient.post("user/update", params),
		register_for_game: (params?: object) =>
			APIClient.post("user/recover", params),
		create: (params: object) => APIClient.post("user/create", params),
		check_username: (params: object) =>
			APIClient.get("nfl_login/check_username", params),
		check_email: (params: object) =>
			APIClient.get("user/check_email", params),
		show_my_profile: () => APIClient.get("user/show_my_profile"),
		deactivate: () => APIClient.post("user/deactivate"),
		unsubscribe: (params: object) => APIClient.post("unsubscribe", params),
	},
	League: {
		create: (params: object) =>
			APIClient.post("tipping_league/create", params),
		my_list: (params: object) =>
			APIClient.get("tipping_league/show_my", params),
		my_list_stats: (params: object) =>
			APIClient.get("tipping_league/show_my_stats", params),
		show: (params: object) => APIClient.get("tipping_league/show", params),
		ladder: (params: object) =>
			APIClient.get("tipping_league/ladder", params),
		update: (params: object) =>
			APIClient.post("tipping_league/update", params),
		sponsor_ladder: (params: object) =>
			APIClient.get("tipping_league/sponsor_ladder", params),
	},
	Joins: {
		show_for_join: (params: object) =>
			APIClient.get("tipping_join/show_for_join", params),
		join: (params: object) => APIClient.post("tipping_join/join", params),
		leave: (params: object) => APIClient.post("tipping_join/leave", params),
		invite: (params: object) =>
			APIClient.post("tipping_join/invite", params),
		show_joined_users: (params: object) =>
			APIClient.get("tipping_join/show_joined_users", params),
		delete: (params: object) =>
			APIClient.post("tipping_join/delete", params),
	},
	Rankings: {
		show: (params: object) =>
			APIClient.get("tipping_stats/show_user_rankings", params),
	},
	Tips: {
		save: (params: object) => APIClient.post("tip/save", params),
		save_list: (params: object) => APIClient.post("tip/save_list", params),
		show_my_list: (params: object) =>
			APIClient.get("tip/show_my_list", params),
		autopick: (params: object) => APIClient.post("tip/autopick", params),
		free_autopick: (params: object) =>
			APIClient.post("tip/free_autopick", params),
	},
	TippingStats: {
		show_snapshot: () => APIClient.get("tipping_stats/show_snapshot"),
	},
	Contact: {
		save: (params: object) => APIClient.post("contact", params),
	},
};

export * from "./ApiErrors";

export default Api;
