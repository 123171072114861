import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import * as actions from "../../actions";
import Api from "../../utils/Api";

export const fetchSquadsSaga = function* (): SagaIterator {
	try {
		const squads = yield call(Api.JSON.squads);
		yield put(actions.fetchSquadsJSONSuccess(squads));
	} catch (err) {
		yield put(actions.fetchSquadsJSONFailed(err));
	}
};

export default fetchSquadsSaga;
