import {createAction} from "redux-act";
import {
	IContactUsReduser,
	IFAQReducer,
	IGuidelineReducer,
	IPrizesReducer,
	IRulesReducer,
} from "../../reducers";
import {IPrivacyPolicyReducer} from "../../reducers/jsons";

export const saveContactUs = createAction<IContactUsReduser>(
	"Save Contact Us Form"
);
export const saveContactUsSuccess = createAction(
	"Save Contact Us Form Success"
);
export const saveContactUsFailed = createAction("Save Contact Us Form Failed");
export const clearContactUsSuccess = createAction();

export const fetchFAQS = createAction("Fetch FAQS");
export const fetchFAQSSuccess = createAction<IFAQReducer>("Fetch FAQS Success");

export const fetchPrizes = createAction("Fetch Prizes");
export const fetchPrizesSuccess = createAction<IPrizesReducer>(
	"Fetch Prizes Success"
);

export const fetchGuidelines = createAction("Fetch Guidelines");
export const fetchGuidelinesSuccess = createAction<IGuidelineReducer>(
	"Fetch Guidelines Success"
);

export const fetchRules = createAction("Fetch Rules");
export const fetchRulesSuccess = createAction<IRulesReducer>(
	"Fetch Rules Success"
);

export const fetchPrivacyPolicy = createAction("Fetch Privacy Policy");
export const fetchPrivacyPolicySuccess = createAction<IPrivacyPolicyReducer>(
	"Fetch Privacy Policy Success"
);
