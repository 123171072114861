import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import * as actions from "../../actions";
import Api from "../../utils/Api";
import {ISagaAction} from "../../types";

interface IParams {
	readonly limit: number;
	readonly offset: number;
}

export const fetchRankingsSaga = function* ({
	payload,
}: ISagaAction<IParams>): SagaIterator {
	try {
		const {result} = yield call(Api.Rankings.show, payload);
		yield put(actions.getRankingsSuccess(result));
	} catch (err) {
		yield put(actions.getRankingsFailed(err));
	}
};

export default fetchRankingsSaga;
