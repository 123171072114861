import * as React from "react";

import {Content, ContentWithSidebar} from "../../../components";

import AccountWidget from "../accountWidget";

interface IProps {
	children: React.ReactNode;
	title: string;
}

const accountWidget = <AccountWidget key={"account-widget"} />;

export const PageAccountWrapper = ({children, title}: IProps) => (
	<div>
		<Content title={title}>
			<ContentWithSidebar
				position="left"
				sidebar_components={[accountWidget]}
			>
				{children}
			</ContentWithSidebar>
		</Content>
	</div>
);

export default PageAccountWrapper;
