import * as React from "react";
import styled from "styled-components";

const Footer = styled.div`
	background-color: #041b3a;
	padding: 15px;
	color: #fff;

	.footer-seventv ul {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.footer-three-to-one {
		display: flex;
	}
	@media (max-width: 479px) {
		.footer-three-to-one {
			flex-direction: column;
		}
	}
	.footer-three-to-one div:first-of-type {
		flex: 0 0 75%;
	}
	@media (max-width: 775px) {
		.footer-three-to-one div:first-of-type {
			flex: 0 0 50%;
		}
	}
	.footer-three-to-one div:first-of-type ul {
		columns: 3;
	}
	@media (max-width: 987px) {
		.footer-three-to-one div:first-of-type ul {
			columns: 2;
		}
	}
	@media (max-width: 775px) {
		.footer-three-to-one div:first-of-type ul {
			columns: 1;
		}
	}
	.footer-content > section {
		border-top: 2px solid rgba(255, 255, 255, 0.2);
		margin-bottom: 15px;
	}
	@media (max-width: 775px) {
		.footer .footer-content > section {
			box-sizing: border-box;
		}
	}
	ul {
		line-height: 1.8em;
	}
	ul a {
		display: block;
		color: #fff;
		font-size: 14px;
	}
	ul a:hover {
		text-decoration: none;
		color: #fff;
	}
	.footer-title {
		font-family: "Ciutadella-Bold", Arial, Helvetica, sans-serif;
		display: block;
		text-transform: uppercase;
		font-size: 21pt;
		font-weight: normal;
		margin: 25px 0;
	}
	@media (min-width: 480px) {
		.footer-link {
			font-size: 14px;
		}
	}
	.footer-colorbox-item {
		display: inline-block;
		margin: 5px;
		height: 55px;
		width: 55px;
	}
	.footer-colorbox-item a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
	.partners .teaser-gradient {
		display: none;
	}
	.app {
		margin: 5px;
		height: 55px;
		width: 55px;
		display: inline-block;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.breadcrumb {
		font-size: 15px;
		text-decoration: none;
		color: #fff;
		padding-top: 10px;
		padding-bottom: 20px;
	}
	.breadcrumb-item {
		color: #1757df;
	}
	.breadcrumb-item:hover,
	.breadcrumb-item:visited {
		text-decoration: none;
		color: #1757df;
	}
	.breadcrumb-item.active {
		color: #fff;
	}
	.breadcrumb-item.active:hover {
		color: #fff;
	}
	.breadcrumb-item + .breadcrumb-item::before {
		padding-right: 5px;
		padding-left: 5px;
		content: ">";
		display: inline-block;
	}

	.social-twitter {
		background-color: #1279c0;
	}

	.social-facebook {
		background-color: #3d6da5;
	}

	.app-prosieben.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_p7.jpg");
	}

	.app-sat1.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_s1.png");
	}

	.app-k1.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_k1.png");
	}

	.app-sixx.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_sixx.png");
	}

	.app-sat1gold.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_s1gold.png");
	}

	.app-prosiebenmaxx.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_p7maxx.jpg");
	}

	.app-k1doku.visible {
		background-image: url("https://s.7tv.de/img/magellan/footer-apps/app_k1doku.png");
	}

	.app-7tv,
	.app-red,
	.app-galileo {
		display: none;
	}
`;

export const ProsibenFooter = () => (
	<Footer className="footer">
		<div className="footer-content narrow-wrapper">
			<nav className="breadcrumb" data-tid="1065">
				<span className="breadcrumb-item active">Startseite</span>
			</nav>
			<section className="footer-three-to-one">
				<div>
					<span className="footer-title">Network</span>
					<ul>
						<li>
							<a
								href="https://www.prosiebenmaxx.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1066"
							>
								ProSieben MAXX
							</a>
						</li>
						<li>
							<a
								href="https://www.sat1.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1067"
							>
								SAT.1
							</a>
						</li>
						<li>
							<a
								href="https://www.sixx.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1068"
							>
								sixx
							</a>
						</li>
						<li>
							<a
								href="https://www.kabeleins.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1069"
							>
								kabel eins
							</a>
						</li>
						<li>
							<a
								href="https://www.kabeleinsdoku.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1070"
							>
								kabel eins Doku
							</a>
						</li>
						<li>
							<a
								href="https://www.sat1gold.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1071"
							>
								SAT.1 Gold
							</a>
						</li>
						<li>
							<a
								href="https://www.maxdome.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1072"
							>
								Maxdome
							</a>
						</li>
						<li>
							<a
								href="https://www.wetter.com"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1073"
							>
								wetter.com
							</a>
						</li>
						<li>
							<a
								href="https://www.fem.com"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1074"
							>
								fem.com
							</a>
						</li>
						<li>
							<a
								href="https://www.joyn.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1075"
							>
								Joyn
							</a>
						</li>
						<li>
							<a
								href="https://www.fyve.de/"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1076"
							>
								FYVE
							</a>
						</li>
						<li>
							<a
								href="https://www.advopedia.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1077"
							>
								Advopedia
							</a>
						</li>
						<li>
							<a
								href="https://www.autoplenum.de"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1078"
							>
								Autoplenum
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/fussball/bundesliga/bundesliga-spielplan"
								data-tid="1079"
							>
								Fußball Bundesliga Spielplan
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/fussball/bundesliga/bundesliga-tabelle"
								data-tid="1080"
							>
								Bundesliga Tabelle
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/datenbank/fussball/bundesliga/live-konferenz/"
								data-tid="1081"
							>
								Bundesliga Live Konferenz
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/fussball/zweite-liga/zweite-liga-spielplan"
								data-tid="1082"
							>
								Zweite Liga Spielplan
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/fussball/dritte-liga/dritte-liga-spielplan"
								data-tid="1083"
							>
								Dritte Liga Spielplan
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/esport/virtual-bundesliga"
								data-tid="1084"
							>
								Virtual Bundesliga
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/fussball/champions-league/champions-league-spielplan"
								data-tid="1085"
							>
								Spielplan Champions League
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/datenbank/fussball/champions-league/live-konferenz/"
								data-tid="1086"
							>
								Champions League Live Konferenz
							</a>
						</li>
					</ul>
				</div>
				<div>
					<span className="footer-title">Service</span>
					<ul>
						<li>
							<a
								href="https://www.prosiebensat1-jobs.com/Praktikant-mwd-fr-die-Redaktion-rande-de-j11886.html"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1087"
							>
								Jobs
							</a>
						</li>
						<li>
							<a
								href="https://shop.ran.de/?utm_source=ran&utm_medium=footer"
								target="_blank"
								rel="noopener noreferrer"
								data-tid="1088"
							>
								ranShop
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/service/impressum"
								data-tid="1089"
							>
								Impressum
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/nutzungsbedingungen"
								data-tid="1090"
							>
								Nutzungsbedingungen
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/datenschutzbestimmungen"
								data-tid="1091"
							>
								Datenschutz und Cookies
							</a>
						</li>
						<li>
							<a
								href="https://ran.de/kontakt-und-hilfe"
								data-tid="1092"
							>
								Kontakt &amp; Hilfe
							</a>
						</li>
					</ul>
				</div>
			</section>
			<section className="footer-three-to-one">
				<div>
					<span className="footer-title">Apps</span>
					<span>
						<a
							href=" https://www.prosieben.de/app"
							title="ProSieben APP"
							target="_blank"
							className="app app-prosieben visible"
							rel="noopener noreferrer"
							data-tid="1093"
						/>
					</span>
					<span>
						<a
							href="https://www.sat1.de/app"
							title="SAT.1 APP"
							target="_blank"
							className="app app-sat1 visible"
							rel="noopener noreferrer"
							data-tid="1094"
						/>
					</span>
					<span>
						<a
							href="https://www.kabeleins.de/app"
							title="kabel eins APP"
							target="_blank"
							className="app app-k1 visible"
							rel="noopener noreferrer"
							data-tid="1095"
						/>
					</span>
					<span>
						<a
							href="https://www.sixx.de/app"
							title="sixx APP"
							target="_blank"
							className="app app-sixx visible"
							rel="noopener noreferrer"
							data-tid="1096"
						/>
					</span>
					<span>
						<a
							href=" https://www.sat1gold.de/app"
							title="SAT.1 Gold APP"
							target="_blank"
							className="app app-sat1gold visible"
							rel="noopener noreferrer"
							data-tid="1097"
						/>
					</span>
					<span>
						<a
							href="https://www.prosiebenmaxx.de/app"
							title="ProSieben MAXX APP"
							target="_blank"
							className="app app-prosiebenmaxx visible"
							rel="noopener noreferrer"
							data-tid="1098"
						/>
					</span>
					<span>
						<a
							href="https://www.kabeleinsdoku.de/app"
							title="kabel eins Doku APP"
							target="_blank"
							className="app app-k1doku visible"
							rel="noopener noreferrer"
							data-tid="1099"
						/>
					</span>
				</div>
				<div>
					<span className="footer-title">Social</span>
					<div className="footer-colorbox-item social-facebook">
						<a
							href="https://www.facebook.com/ransport/"
							target="_blank"
							rel="noopener noreferrer"
							data-tid="1100"
						>
							<svg
								fill="currentColor"
								preserveAspectRatio="xMidYMid meet"
								height="35"
								width="35"
								viewBox="0 0 40 40"
							>
								<g>
									<path d="m29.4 0.3v5.9h-3.5q-1.9 0-2.6 0.8t-0.7 2.4v4.2h6.6l-0.9 6.6h-5.7v16.9h-6.8v-16.9h-5.7v-6.6h5.7v-4.9q0-4.1 2.3-6.4t6.2-2.3q3.3 0 5.1 0.3z" />
								</g>
							</svg>
						</a>
					</div>
					<div className="footer-colorbox-item social-twitter">
						<a
							href=" https://twitter.com/ransport"
							target="_blank"
							rel="noopener"
							data-tid="1101"
						>
							<svg
								fill="currentColor"
								preserveAspectRatio="xMidYMid meet"
								height="35"
								width="35"
								viewBox="0 0 40 40"
							>
								<g>
									<path d="m37.7 9.1q-1.5 2.2-3.7 3.7 0.1 0.3 0.1 1 0 2.9-0.9 5.8t-2.6 5.5-4.1 4.7-5.7 3.3-7.2 1.2q-6.1 0-11.1-3.3 0.8 0.1 1.7 0.1 5 0 9-3-2.4-0.1-4.2-1.5t-2.6-3.5q0.8 0.1 1.4 0.1 1 0 1.9-0.3-2.5-0.5-4.1-2.5t-1.7-4.6v0q1.5 0.8 3.3 0.9-1.5-1-2.4-2.6t-0.8-3.4q0-2 0.9-3.7 2.7 3.4 6.6 5.4t8.3 2.2q-0.2-0.9-0.2-1.7 0-3 2.1-5.1t5.1-2.1q3.2 0 5.3 2.3 2.4-0.5 4.6-1.7-0.8 2.5-3.2 3.9 2.1-0.2 4.2-1.1z" />
								</g>
							</svg>
						</a>
					</div>
				</div>
			</section>
		</div>
	</Footer>
);

export default ProsibenFooter;
