import styled from "styled-components";

interface IOverlay {
	gameName?: string;
}

export const Overlay = styled.div<IOverlay>`
	position: fixed;
	// top: ${(props) => (props.gameName === "prosieben" ? "120px" : "60px")};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.6);
`;

export default Overlay;
