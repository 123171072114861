import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface IErrorValidation {
	code: number;
	name: string;
	message: string;
}

export interface IValidations {
	readonly username_error: IErrorValidation | null;
	readonly email_error: IErrorValidation | null;
}

const defaultState: IValidations = {
	username_error: null,
	email_error: null,
};

const emailValidationIsFailed = (
	state: IValidations,
	payload: IErrorValidation
) => ({
	...state,
	email_error: payload,
});

const emailValidationIsSuccess = (state: IValidations) => ({
	...state,
	email_error: null,
});

const checkUsernameIsFailed = (
	state: IValidations,
	payload: IErrorValidation
) => ({
	...state,
	username_error: payload,
});

const checkUsernameIsSuccess = (state: IValidations) => ({
	...state,
	username_error: null,
});

export const validations = createReducer<IValidations>({}, defaultState)
	.on(actions.checkEmailFailed, emailValidationIsFailed)
	.on(actions.checkUsernameFailed, checkUsernameIsFailed)
	.on(actions.checkEmailSuccess, emailValidationIsSuccess)
	.on(actions.checkUsernameSuccess, checkUsernameIsSuccess);

export default validations;
