import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface IShowBanner {
	readonly show: number;
	readonly text: string;
}

const initialState: IShowBanner = {
	show: 0,
	text: "",
};

export const show_banner = createReducer<IShowBanner>({}, initialState).on(
	actions.fetchBannerJSONSuccess,
	(state, payload: IShowBanner) => payload
);
