import styled from "styled-components";
import theme from "./../../assets/css/theme";

export const TooltipBubbleMobileDiv = styled.div`
	color: #fff;
	height: 380px;
	margin: 0 1%;
	position: fixed;
	top: 160px;
	width: 98%;
	z-index: 3;
`;

export const ContentWrapDiv = styled.div`
	background: #007dc3;
	box-sizing: border-box;
	height: 345px;
	padding: 35px 30px;
`;

export const TipImg = styled.img`
	width: 100%;
`;

export const TipButtonDiv = styled.div`
	display: flex;
	justify-content: center;
`;

export const TipButton = styled.button`
	background-color: #1b48e0;
	border: none;
	color: #fff;
	height: 40px;
	text-transform: uppercase;
	width: 200px;
`;

export const CircleWrapDiv = styled.div`
	display: flex;
	justify-content: center;
	margin: 30px 0;
`;

export const ActiveCirlceDiv = styled.div`
	background-color: #fff;
	border-radius: 50%;
	height: 8px;
	margin: 0 4px;
	width: 8px;
`;

export const InActiveCirlceDiv = styled.div`
	background-color: #fff;
	border-radius: 50%;
	height: 8px;
	margin: 0 4px;
	width: 8px;
	opacity: 0.5;
`;

export const TipTitleDiv = styled.div`
	font-size: 16px;
	font-family: ${theme.base_bold_font};
	letter-spacing: -0.1px;
	line-height: 20px;
	margin: 20px 0;
	text-align: center;
`;

export const TipTextDiv = styled.div`
	font-size: 14px;
	letter-spacing: 0;
	line-height: 22px;
	text-align: center;
`;
