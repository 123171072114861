import * as React from "react";
import {
	CrossPromoWrapDiv,
	CloseButtonWrapDiv,
	CloseButton,
	CrossPromoDiv,
	IntroDiv,
	SubIntroDiv,
	ChallengeLink,
	NFLImg,
	ImgDividerDiv,
	TabImg,
	SkyBetImg,
	ImgWrapDiv,
	ChallengeWrapDiv,
	ChallengeImg,
	RanImg,
	SiteTitle,
} from "./CrossPromoStyle";
import {Close} from "./../Icons/Close";

import nflLogo from "../../assets/img/mini-logo.png";
import tab_white from "../../assets/img/sponsor/tab_logo.png";
import challenge from "../../assets/img/Challenge@2x.png";
import skybet from "../../assets/img/sponsor/skybet-logo.png";
import ran_logo from "../../assets/img/sponsor/ran_white.png";

import {Exist} from "components/Exist";
import {IS_DE} from "modules/utils";

const SPONSOR = process.env.REACT_APP_SPONSOR || "";
const challengeLink = process.env.REACT_APP_NFL_FANTASY_URL || "";

interface IProps {
	handleClose: () => void;
	isSavePicks: boolean;
}

const CrossPromoPopup = ({handleClose, isSavePicks}: IProps) => {
	return (
		<CrossPromoWrapDiv>
			<CloseButtonWrapDiv>
				<CloseButton onClick={handleClose}>
					<Close />
				</CloseButton>
			</CloseButtonWrapDiv>
			<CrossPromoDiv>
				<ImgWrapDiv>
					<NFLImg
						src={nflLogo}
						alt={window.getTranslations(
							"pickem_footer_hyperlink_nfl"
						)}
					/>
					<ImgDividerDiv />
					<Exist when={SPONSOR === "tab"}>
						<TabImg src={tab_white} alt={SPONSOR} />
					</Exist>
					<Exist when={SPONSOR === "skybet"}>
						<SkyBetImg src={skybet} alt={SPONSOR} />
					</Exist>
					<Exist when={IS_DE}>
						<RanImg src={ran_logo} alt={SPONSOR} />
					</Exist>
				</ImgWrapDiv>

				<ChallengeWrapDiv>
					<Exist when={IS_DE}>
						<SiteTitle>fantasy manager</SiteTitle>
					</Exist>
					<Exist when={!IS_DE}>
						<ChallengeImg
							src={challenge}
							alt={window.getTranslations(
								"pickem_nav_button_fantasychallenge"
							)}
						/>
					</Exist>
				</ChallengeWrapDiv>

				{isSavePicks ? (
					<React.Fragment>
						<SubIntroDiv>
							{window.getTranslations(
								"pickem_crosspromotion_savepicks"
							)}
						</SubIntroDiv>
					</React.Fragment>
				) : (
					<React.Fragment>
						<IntroDiv>
							{window.getTranslations(
								"pickem_crosspromotion_promotionpopup"
							)}
						</IntroDiv>
						<SubIntroDiv>
							{window.getTranslations(
								"pickem_crosspromotion_subtext_promotionpopup"
							)}
						</SubIntroDiv>
					</React.Fragment>
				)}
				<ChallengeLink
					target="_blank"
					rel="noopener noreferrer"
					href={challengeLink}
				>
					{window.getTranslations(
						"pickem_nav_button_fantasychallenge"
					)}
				</ChallengeLink>
			</CrossPromoDiv>
		</CrossPromoWrapDiv>
	);
};

export default CrossPromoPopup;
