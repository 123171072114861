import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import * as actions from "../../actions";
import Api from "../../utils/Api";

export const fetchGameWeeksSaga = function* (): SagaIterator {
	try {
		const result = yield call(Api.JSON.gameweeks);
		yield put(actions.fetchGameWeeksJSONSuccess(result));
	} catch (err) {
		yield put(actions.fetchGameWeeksJSONFailed(err));
		yield put(actions.errorsGlobalError(err));
	}
};

export default fetchGameWeeksSaga;
