import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface IDivisionalStandings {
	readonly conference: string;
	readonly division: string;
	readonly draw: number;
	readonly full_name: string;
	readonly games: number;
	readonly id: number;
	readonly loss: number;
	readonly margin: number;
	readonly name: string;
	readonly percentage: number;
	readonly points: number;
	readonly short_name: string;
	readonly win: number;
}

const initialState: IDivisionalStandings[] = [];

export const divisional_standings = createReducer<IDivisionalStandings[]>(
	{},
	initialState
).on(
	actions.fetchStandingsJSONSuccess,
	(state, payload: IDivisionalStandings[]) => payload
);
