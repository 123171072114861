import styled, {css} from "styled-components";
import theme from "../../assets/css/theme";
import {below} from "../../assets/css/media";

interface ITooltipBubble {
	to: string;
	bubble_name: string;
}

export const TooltipBubbleStyled = styled.div<ITooltipBubble>`
	position: absolute;
	color: #fff;
	border-radius: 10px;
	background-color: #007dc3;
	width: 220px;
	height: 100px;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	z-index: 4;
	font-family: ${theme.base_font};
	${getPosition} &:after {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-width: 8px;

		${getArrow};
	}

	&.navbar-tip {
		top: 70px;
		left: 90px;
		position: fixed;
	}
	&.navbar-tip.prosieben {
		top: 130px;
	}
	&.form_guide_bubble_tip {
		left: 50%;
		transform: translateX(-50%);
	}
	&.margin_bubble {
		right: 0;
	}
	${below.desktop`
		box-sizing: border-box;
    height: 350px;
    left: 0 !important;
    width: 100%;
    transform: translateX(0px) !important;;
		top: 0 !important;;
		&:after {
			display:none;
		}
	`};
`;

export const Button = styled.button`
	background: none;
	outline: none;
	border-radius: 0;
	border: none;
	color: #fff;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	padding: 0;
	${below.desktop`
		margin-top: -30px;
	`};
`;

export const TooltipWrapDiv = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TooltipTextDiv = styled.div``;
export const NextTipButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	color: #fff;
	text-decoration: underline;
	font-size: 11px;
	height: 20px;
`;

export const TooltipMobImgDiv = styled.div`
	margin-top: -100px;
`;
export const TooltipMobImg = styled.img`
	width: 100% !important;
`;

function getArrow({to}: ITooltipBubble) {
	if (to === "top") {
		return css`
			bottom: 100%;
			left: 50%;
			border-bottom-color: #007dc3;
			margin-left: -9px;
		`;
	}
	if (to === "left") {
		return css`
			right: 100%;
			top: 50%;
			border-right-color: #007dc3;
			margin-top: -8px;
		`;
	}
	if (to === "bottom") {
		return css`
			top: 100%;
			left: 50%;
			border-top-color: #007dc3;
			margin-left: -8px;
		`;
	}
	if (to === "right") {
		return css`
			left: 100%;
			top: 50%;
			border-left-color: #007dc3;
			margin-top: -8px;
		`;
	}

	return "";
}

function getPosition({bubble_name}: ITooltipBubble) {
	if (bubble_name === "form_guide_bubble") {
		return css`
			left: 50%;
			transform: translateX(-50%);
		`;
	}
	if (bubble_name === "margin_bubble") {
		return css`
			top: 50%;
			right: -100%;
			transform: translateY(-50%);
		`;
	}
	if (bubble_name === "tip_bubble") {
		return css`
			top: 50%;
			left: 50px;
			transform: translateY(-50%);
		`;
	}
	return "";
}
