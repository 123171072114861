import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconWarning = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 10 10`}
		{...rest}
	>
		<title>Warning</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-1100.000000, -992.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<g transform="translate(0.000000, 320.000000)">
					<g transform="translate(700.000000, 71.000000)">
						<g transform="translate(220.000000, 42.000000)">
							<g transform="translate(0.000000, 504.000000)">
								<g transform="translate(0.000000, 40.000000)">
									<g transform="translate(180.000000, 15.000000)">
										<path
											d="M9.73374205,7.73479818 L6.15507836,1.12904445 C5.52007889,
                                            -0.0430148174 4.48041309,-0.0430148174 3.84496918,1.12904445 L0.266083265,
                                            7.73479818 C-0.368693984,8.90628065 0.17175001,9.86538506 1.46752671,
                                            9.86538506 L8.53274305,9.86538506 C9.82874197,9.86538506 10.3686304,
                                            8.90628065 9.73374205,7.73479818 Z M5.00007915,2.94654369 C5.32330111,
                                            2.94654369 5.58552311,3.21890903 5.58552311,3.55437638 C5.58574533,
                                            3.57191113 5.58207867,3.58806155 5.58074534,3.60490413 L5.58363422,
                                            3.60490413 L5.35018997,6.54854937 L5.34807886,6.54854937 C5.33307888,
                                            6.73589427 5.18396789,6.88309383 5.00007915,6.88309383 C4.81585709,
                                            6.88309383 4.6667461,6.73589427 4.65174611,6.54854937 L4.64974611,
                                            6.54854937 L4.4166352,3.60490413 L4.41930186,3.60490413 C4.41796853,
                                            3.58806155 4.41441298,3.57191113 4.41441298,3.55437638 C4.41452409,
                                            3.21902439 4.67641276,2.94654369 5.00007915,2.94654369 Z M5.00030137,
                                            8.5646987 C4.67674607,8.5646987 4.41474628,8.29244872 4.41474628,
                                            7.95709673 C4.41474628,7.6212833 4.67663496,7.34891796 5.00030137,
                                            7.34891796 C5.32352333,7.34891796 5.58574533,7.6212833 5.58574533,
                                            7.95709673 C5.58563422,8.29244872 5.32352333,8.5646987 5.00030137,
                                            8.5646987 Z"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

IconWarning.defaultProps = {
	width: 10,
	height: 10,
	color: "#424242",
};

export default IconWarning;
