import * as React from "react";
import theme from "../../../assets/css/theme";
import styled from "styled-components";
import {RegularButton} from "../../../components";
import {connect} from "react-redux";
import {userDeactivate} from "../../../modules/actions";
import {bindAll} from "lodash";

const Container = styled.div`
	background-color: #ffffff;
	border-radius: 6px;
	padding: 30px;
`;

const Title = styled.h3`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 30px;
`;

const Description = styled.p`
	color: #555555;
	font-size: 14px;
	line-height: 22px;
	font-family: ${theme.base_font};
	margin-bottom: 20px;
`;

const ButtonStyled = styled(RegularButton)`
	background: #ff0000;
	width: 270px;
	margin-top: 30px;
`;

interface IProps {
	children?: React.ReactNode;
	userDeactivate: typeof userDeactivate;
}

class DeactivatePage extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleDeactivate"]);
	}

	/**
	 * @ignore
	 */
	public render() {
		return (
			<Container>
				<Title>
					{window.getTranslations("account_text_header_deactivate")}
				</Title>
				<Description>
					{window.getTranslations("account_text_deactivate_message")}
				</Description>
				<ButtonStyled onClick={this.handleDeactivate}>
					{window.getTranslations("account_form_button_deactivate")}
				</ButtonStyled>
			</Container>
		);
	}

	private handleDeactivate() {
		const is_confirm = window.confirm(
			window.getTranslations("account_form_alert_deactivate")
		);

		if (is_confirm) {
			this.props.userDeactivate();
		}
	}
}

const mapDispatchToProps = {
	userDeactivate,
};

export default connect(null, mapDispatchToProps)(DeactivatePage);
