import * as React from "react";
import styled from "styled-components";
import {RegularButton} from "../../../components/Buttons";
import theme from "../../../assets/css/theme";
import {below} from "../../../assets/css/media";
import {IFunc0} from "../../../modules/types";

const LeaveButtonWrapper = styled(RegularButton)`
	background-color: ${theme.red};
	border-color: ${theme.red};
	position: absolute;
	top: 50%;
	right: 25px;
	height: 32px;
	transform: translateY(-50%);
	max-width: 170px;

	${below.desktop`
		max-width: 80px;
		height: 32px;
	`};
`;

interface ILeaveButton {
	readonly font_size?: string;
}

const LeaveButtonTextDesktop = styled.span`
	display: block;
	font-size: ${({font_size}: ILeaveButton) => font_size || "14px"};

	${below.desktop`
		display:none;
	`};
`;

const LeaveButtonTextMobile = styled.span`
	display: none;
	font-size: ${({font_size}: ILeaveButton) => font_size || "14px"};

	${below.desktop`
		display:block;
	`};
`;

interface IProps {
	onClick: IFunc0;
}

export const LeaveButton = ({onClick}: IProps) => (
	<LeaveButtonWrapper onClick={onClick}>
		<LeaveButtonTextDesktop
			font_size={window.LANG_KEY === "de" ? "11px" : "14px"}
		>
			{window.getTranslations("pickem_alert_save_button_leave")}
		</LeaveButtonTextDesktop>
		<LeaveButtonTextMobile
			font_size={window.LANG_KEY === "de" ? "11px" : "14px"}
		>
			{window.getTranslations("pickem_alert_save_button_leave")}
		</LeaveButtonTextMobile>
	</LeaveButtonWrapper>
);

export default LeaveButton;
