import {createAction} from "redux-act";
import {
	IAutopickMyTips,
	ISaveMyTips,
	IShowMyTips,
	IUserTips,
} from "../../types";
import {ITip} from "../../reducers/tips";

export const selectTip = createAction<ISaveMyTips>("Select Tip");
export const selectTips = createAction<ISaveMyTips[]>("Select Tips");

export const showMyTips = createAction<IShowMyTips>("Show My Tips");
export const showMyTipsSuccess = createAction<ITip[]>(
	"User registration Success"
);

export const saveMyTips = createAction<ISaveMyTips>("Save My Tips");
export const saveMyTipsSuccess = createAction("Save My Tips Success");

export const saveMyListTips = createAction<ISaveMyTips[]>("Save My Tips");
export const saveMyListTipsSuccess = createAction("Save My Tips Success");

export const autopickMyTips = createAction<IAutopickMyTips>("Autopick MyTips");
export const autopickMyTipsSuccess = createAction("Autopick MyTips Success");

export const freeAutopickMyTips = createAction<IAutopickMyTips>(
	"Free Autopick MyTips"
);
export const freeAutopickMyTipsSuccess = createAction(
	"Free Autopick MyTips Success"
);

export const setSelectedTip = createAction<IUserTips>("Set Selected Tip");
export const clearSelectedTip = createAction("Clear Selected Tip");
