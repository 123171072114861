import * as React from "react";
import styled from "styled-components";
import {IMyLeague, IStoreState} from "../../../modules/types";
import {
	getMyLeague,
	isLeagueDataRequested,
} from "../../../modules/selectors/leagues";
import {showLeague} from "../../../modules/actions";
import theme from "../../../assets/css/theme";
import {connect} from "react-redux";
import CreateLeagueForm from "../../Leagues/createLeague/createLeagueForm";
import {bindAll} from "lodash";
import LoadingSpinner from "../../../components/AppLoading";
import {LeagueToggleButton, LeagueWrapper} from "components";

const AboutLeagueWrapper = styled(LeagueWrapper)``;

const LeagueName = styled.div`
	color: #333;
	letter-spacing: -0.1px;
	line-height: 36px;
	font-size: 30px;
	font-family: ${theme.base_font_medium_display};
	margin-bottom: 25px;
	text-transform: uppercase;
`;

const LeagueTitle = styled.h4`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LeagueDescription = styled.div`
	font-size: 14px;
	letter-spacing: -.1px;
	line-height: 16px;
	font-family: ${theme.base_font}
	color: #555;
	margin-bottom: 20px;
`;

const LeagueField = styled.div`
	margin-bottom: 15px;
`;

const LeagueFieldLabel = styled.div`
	color: #333;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
`;

const LeagueFieldValue = styled.div`
	color: #757575;
	font-family: ${theme.base_font};
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
`;

const EditButton = styled(LeagueToggleButton)``;

interface IProps {
	children?: React.ReactNode;
	league: IMyLeague;
	league_created_id?: number;
	league_id?: number;
	showLeague: typeof showLeague;
	shadow?: boolean;
	background?: string;
	is_data_requested: boolean;
	onBlurBackground?: (prop: boolean) => void;
}

interface IState {
	create_form_showing: boolean;
}

class LeagueAboutComponent extends React.Component<IProps, IState> {
	public state = {
		create_form_showing: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["showLeagueForm", "closeLeagueForm"]);
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount(): void {
		const {league_id, showLeague: show} = this.props;

		if (league_id) {
			show(league_id);
		}
	}

	/**
	 * Fetch league by id
	 */
	public componentDidUpdate(prevProps: IProps) {
		const {showLeague: show, league_created_id} = this.props;

		if (
			league_created_id &&
			league_created_id !== prevProps.league_created_id
		) {
			show(league_created_id);
		}
	}

	public showLeagueForm() {
		const {onBlurBackground} = this.props;

		this.setState({create_form_showing: true});

		if (onBlurBackground) {
			onBlurBackground(true);
		}
	}

	public closeLeagueForm() {
		const {onBlurBackground} = this.props;
		this.setState({create_form_showing: false});

		if (onBlurBackground) {
			onBlurBackground(false);
		}
	}

	public get update_league() {
		const {league} = this.props;

		return (
			<CreateLeagueForm
				league={league}
				closeForm={this.closeLeagueForm}
			/>
		);
	}

	public get about_league() {
		const {league, league_created_id, shadow, background} = this.props;
		// const { create_form_showing } = this.state;
		const is_commissioner = league.user_status === "commissioner";

		return (
			<AboutLeagueWrapper shadow={shadow} background={background}>
				<LeagueTitle>
					{league_created_id
						? window.getTranslations(
								"leaguecreate_form_text_congratulations"
						  )
						: is_commissioner
						? window.getTranslations(
								"leaguesettings_form_header_league_settings"
						  )
						: window.getTranslations("aboutcompetition_title_th")}

					{is_commissioner ? (
						<EditButton onClick={this.showLeagueForm}>
							Edit
						</EditButton>
					) : null}
				</LeagueTitle>

				<LeagueName>{league.name}</LeagueName>
				<LeagueDescription>{league.description}</LeagueDescription>
				<LeagueField>
					<LeagueFieldLabel>
						{window.getTranslations(
							"leagueinvite_dropdown_starting_gameweek"
						)}
					</LeagueFieldLabel>
					<LeagueFieldValue>
						{window.getTranslations("pickem_gamebar_game_week")}{" "}
						{league.start_round}
					</LeagueFieldValue>
				</LeagueField>
				<LeagueField>
					<LeagueFieldLabel>
						{window.getTranslations("fld_privacy")}
					</LeagueFieldLabel>
					<LeagueFieldValue>
						{league.privacy === "public"
							? window.getTranslations(
									"competitioncreate_form_toggle_public"
							  )
							: window.getTranslations(
									"competitioncreate_form_toggle_private"
							  )}
					</LeagueFieldValue>
				</LeagueField>
			</AboutLeagueWrapper>
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {create_form_showing} = this.state;
		const {is_data_requested} = this.props;

		if (is_data_requested) {
			return <LoadingSpinner />;
		}

		return !create_form_showing ? this.about_league : this.update_league;
	}
}

const mapStateToProps = (state: IStoreState) => ({
	league: getMyLeague(state) as IMyLeague,
	is_data_requested: isLeagueDataRequested(state),
});

const mapDispatchToProps = {
	showLeague,
};

export const LeagueAbout = connect(
	mapStateToProps,
	mapDispatchToProps
)(LeagueAboutComponent);
export default LeagueAbout;
