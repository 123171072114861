import styled, {css} from "styled-components";
import theme from "../../assets/css/theme";
import {Label} from "../index";
import {LADDER_HEADING_COLOUR} from "modules/utils";

export const FlexCell = styled.div`
	display: flex;
	flex-direction: column;
	height: 40px;
	justify-content: center;
	padding: 0 10px;
	white-space: nowrap;
`;

export const PageFooter = styled.footer`
	width: 100%;
	display: flex;
	max-width: 1240px;
	margin: 0 auto 20px;
	justify-content: flex-end;
	padding: 0 20px;
	box-sizing: border-box;
	> div {
		flex: 1;
	}
`;

export const MobilePageFooter = styled.footer`
	display: block;
	width: 100%;
`;

export const CustomLabel = styled(Label)`
	font-size: 12px;
	font-family: ${theme.base_font};
`;

export const TableHead = styled.tr`
	align-items: center;
	background: #fafafa;
	height: 40px;
	border-bottom: 1px solid #eee;

	> div {
		padding: 0 10px;
		box-sizing: border-box;
		//flex: 1;
	}

	color: #333333;
	font-family: ${theme.base_font};
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
`;
export const LadderWrapper = styled.div`
	overflow: hidden;
	width: 100%;
	@media screen and (max-width: 640px) {
		overflow-x: auto;
	}
`;

export const LadderTable = styled.table`
	border-collapse: separate;
	width: 100%;
`;

export const TeamName = styled.span`
	font-family: ${theme.menu_font};
	color: #505050;
`;

export const TableBody = styled.tbody`
	margin-bottom: 20px;
`;

interface ITableBodyRow {
	is_active?: boolean;
	is_sponsor_league: boolean;
}

const getTableRowStyles = ({is_active, is_sponsor_league}: ITableBodyRow) => {
	if (is_sponsor_league && is_active) {
		return css`
			border-bottom: 1px solid ${theme.sponsor_league_secondary_color};
			background: ${theme.sponsor_league_active_bg_item};
		`;
	}
	if (is_active) {
		return css`
			border-bottom: 1px solid ${theme.sponsor_league_secondary_color};
			background: ${theme.sponsor_league_active_bg_item};
		`;
	}
	return css`
		border-bottom: 1px solid #eee;
		background: #fff;

		&:nth-child(even) {
			background: #fafafa;
		}
	`;
};

export const TableBodyRow = styled.tr<ITableBodyRow>`
	font-family: ${theme.base_font};
	align-items: center;
	height: 40px;
	font-size: 12px;
	line-height: 14px;

	${getTableRowStyles}
	> div {
		padding: 0 10px;
		//flex: 1;/
		box-sizing: border-box;
	}
`;

interface ILeagueNameProps {
	is_sponsor_league: boolean;
}

export const LeagueName = styled.div<ILeagueNameProps>`
	background: ${({is_sponsor_league}) =>
		is_sponsor_league ? theme.ladder_heading_bg : LADDER_HEADING_COLOUR};
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	padding: 0 20px;
	box-sizing: border-box;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	span {
		color: #fff;
		font-family: ${theme.base_font};
		font-size: 12px;
		line-height: 14px;
		font-weight: 500;
	}

	@media screen and (max-width: 840px) {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
`;

interface ITableCellProps {
	value_align?: string;
	is_active?: boolean;
	cell_width?: string;
	is_sponsor_league?: boolean;
	is_sticky?: boolean;
	first_column?: true;
}

const getStickyCellStyles = ({is_sticky, first_column}: ITableCellProps) =>
	is_sticky &&
	css`
		background-color: inherit;
		position: sticky;
		left: ${first_column ? "0px" : "100px"};
		min-width: ${first_column ? "100px" : null};
		max-width: 100px;
		box-shadow: #dcdcdc 4px 0px 6px -2px;
	`;

export const TableHeadCell = styled.th<ITableCellProps>`
	text-align: ${({value_align}) => value_align || "left"};
	cursor: pointer;
	color: ${({is_sponsor_league, is_active}) =>
		is_sponsor_league && is_active
			? theme.sponsor_league_secondary_color
			: is_active
			? `${theme.primary || "#1B48E0"}`
			: "#505050"};
	width: ${({cell_width}) => (cell_width ? `${cell_width}%` : "auto")};
	font-family: ${theme.base_font};
	font-size: 12px;
	border-bottom: inherit;

	${getStickyCellStyles}
	svg {
		display: ${({is_active}) => (is_active ? "inline" : "none")};
		color: ${({is_sponsor_league}) =>
			is_sponsor_league
				? theme.sponsor_league_secondary_color
				: `${theme.primary || "#1B48E0"}`};
		width: 10px;
		margin: 0 5px;
	}

	@media screen and (max-width: 840px) {
		svg {
			display: none;
		}
	}
`;
export const TableBodyCell = styled.td<ITableCellProps>`
	text-align: ${({value_align}) => value_align || "left"};
	width: ${({cell_width}) => (cell_width ? `${cell_width}%` : "auto")};
	font-weight: 500;
	border-bottom: inherit;
	${getStickyCellStyles} @media screen and(max-width: 840 px) {
		width: ${({cell_width}) => (cell_width ? `${cell_width}%` : "auto")};
	}

	strong {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	span {
		color: #757575;
		font-weight: normal;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

export const FiltersWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	@media screen and (max-width: 840px) {
		margin: 0 20px 20px;
	}

	select {
		margin-top: 0;
		width: 160px;
		margin-right: 10px;
		@media screen and (max-width: 840px) {
			width: 100%;
		}
	}
`;

export const FiltersRight = styled(FiltersWrapper)`
	margin: 0;
	margin-right: 20px;
	flex: 1;

	& > div {
		width: 100%;
	}

	@media screen and (max-width: 840px) {
		margin: 0;
		margin-right: 14px;
	}

	input {
		margin-bottom: 0;
	}
`;
