import * as _ from "lodash";
import * as React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import * as actions from "../../modules/actions";

import {IStoreState} from "../../modules/types";

interface IProps {
	readonly error: string;
	readonly clearError: () => void;
}

interface IModelWrapper {
	readonly is_open?: boolean;
}

const ModalWrapper = styled.div`
	position: fixed;
	display: ${({is_open}: IModelWrapper) => (is_open ? "flex" : "none")};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
	background: rgba(255, 255, 255, 0.25);
	align-items: center;
	justify-content: center;
`;

const MessageBox = styled.div`
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
	padding: 1.5em;
	font-size: 1.25em;
	color: #000;
	background-color: #fff;
`;

const GlobalErrorsComponent = ({error, clearError}: IProps) => (
	<ModalWrapper is_open={!_.isEmpty(error)} onClick={clearError}>
		<MessageBox dangerouslySetInnerHTML={{__html: error}} />
	</ModalWrapper>
);

const mapStateToProps = (state: IStoreState) => ({
	error: _.get(state, "error_managment.text", ""),
});

const mapDispatchToProps = {
	clearError: actions.errorsClearGlobalError,
};

export const GlobalErrors = connect(
	mapStateToProps,
	mapDispatchToProps
)(GlobalErrorsComponent);

export default GlobalErrors;
