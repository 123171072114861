import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconLock = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 16 23`}
		{...rest}
	>
		<title>Lock</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-6.000000, -25.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<path
					d="M8.43280456,33.0070733 C8.50120705,25.4779966 9.12307475,25 14.2357432,25 C19.4445012,
					25 19.9900504,25.4940468 20.0516883,33.4443818 C19.4433976,32.8795844 18.7589145,
					32.3985835 18.015801,32.0192813 C17.9979406,26.6564267 17.7487719,27.0415004 14.2357432,
					27.0415004 C10.7750054,27.0415004 10.4809958,26.6681585 10.4637596,31.7824028 C9.73205704,
					32.0999264 9.05001388,32.5133061 8.43280456,33.0070733 Z M14,48 C9.581722,48 6,44.4595498 6,
					40.0921831 C6,35.7248165 9.581722,32.1843662 14,32.1843662 C18.418278,32.1843662 22,35.7248165 22,
					40.0921831 C22,44.4595498 18.418278,48 14,48 Z M14.9999286,40.0098243 C15.4206258,
					39.7188123 15.6969697,39.2535356 15.6969697,38.7245786 C15.6969697,37.8422988 14.937024,
					37.1267518 14,37.1267518 C13.062976,37.1267518 12.3030303,
					37.8422988 12.3030303,38.7245786 C12.3030303,39.2535356 12.5793742,39.7188123 13.0000714,
					40.0098243 L13.0000714,42.4755514 C13.0000714,42.9956069 13.4476758,43.4170607 14,
					43.4170607 C14.5523242,43.4170607 14.9999286,42.9956069 14.9999286,
					42.4755514 L14.9999286,40.0098243 Z"
				/>
			</g>
		</g>
	</svg>
);

IconLock.defaultProps = {
	width: 16,
	height: 23,
	color: "#E0E0E0",
};

export default IconLock;
