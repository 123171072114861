import {SagaIterator} from "redux-saga";
import {call, delay, put} from "redux-saga/effects";
import {IEmail, ISagaAction} from "../../types";
import Api, {ApiErrors} from "../../utils/Api";
import * as actions from "../../actions";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

export interface IContactUSSagaAction extends IEmail {
	readonly name: string;
	readonly category: string;
	readonly question: string;
	readonly is_success: boolean;
}

export const postContactUsSaga = function* (
	action: ISagaAction<IContactUSSagaAction>
): SagaIterator {
	try {
		const {errors} = yield call(Api.Contact.save, action.payload);

		ApiErrors.checkOnApiError(errors);

		if (GAME_NAME === "UK" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(actions.saveContactUsSuccess());
		yield delay(2000);
		yield put(actions.clearContactUsSuccess());
	} catch (e) {
		yield put(actions.saveContactUsFailed());
		yield put(actions.errorsGlobalError(e));
	}
};
