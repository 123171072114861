import {SagaIterator} from "redux-saga";
import {call, put} from "redux-saga/effects";
import * as actions from "../../actions";
import {Api} from "../../utils";

export const fetchChecksumsJSONSaga = function* (): SagaIterator {
	try {
		const checksums = yield call(Api.JSON.checksums);
		yield put(actions.fetchChecksumsSuccess(checksums));
	} catch (err) {
		yield put(actions.fetchChecksumsFailed(err));
	}
};
