import styled from "styled-components";
import * as React from "react";
import {LeagueFooter, LeagueHeader} from "../myLeagues";
import {
	IJoinLeague,
	IJoinState,
	ILeaveLeague,
} from "../../../modules/reducers/leagues";
import {bindAll, partial, get} from "lodash";
import LoadingSpinner from "../../../components/AppLoading";
import {below} from "../../../assets/css/media";
import LeaveButton from "./LeaveButton";
import JoinButton from "./JoinButton";
import {createFanHubEvent} from "../../../modules/utils/FanHubTagging";

interface ILeagueWrapper {
	is_joined?: boolean;
}

const LeagueWrapper = styled.div<ILeagueWrapper>`
	height: 80px;
	background-color: ${({is_joined}) => (is_joined ? "#E0E0E0" : "#FAFAFA")};
	box-shadow: 0 1px 10px 1px #e0e0e0;
	margin-bottom: 10px;
	border-radius: 4px;
	position: relative;

	${below.desktop`
		background-color:#FAFAFA;
	`};
`;

interface IProps {
	readonly league: IJoinLeague;
	readonly join_state: IJoinState;
	readonly onJoin: (league_id: string, name: string) => void;
	readonly onLeave: (params: ILeaveLeague) => void;
}

class LeagueComponent extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		bindAll(this, ["handleJoin", "handleLeave"]);
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		createFanHubEvent("66b953ae-df96-4517-94a2-fce4afec65e0");
	}

	/**
	 * @ignore
	 */

	public handleJoin(code: string, name: string) {
		const {onJoin, join_state} = this.props;

		if (!join_state || (join_state && !join_state.is_joined)) {
			if (window._satellite) {
				window._satellite.track("joinLeague");
			}
			createFanHubEvent("f8bd5646-22df-4e77-bf7e-2eab32e86bdc");
			onJoin(code, name);
		}
	}

	/**
	 * @ignore
	 */

	public handleLeave(league_id: number, league_code: string) {
		const {onLeave, join_state} = this.props;

		if (join_state) {
			onLeave({league_id, league_code});
		}
	}

	/**
	 * @ignore
	 */

	public render() {
		const {league, join_state} = this.props;
		const is_joined = get(join_state, "is_joined", false);
		const is_data_requested = get(join_state, "is_data_requested", false);

		return (
			<LeagueWrapper is_joined={is_joined}>
				{is_data_requested ? <LoadingSpinner /> : null}
				<LeagueHeader
					color="transparent"
					status_color="#757575"
					width="70%"
					name={league.name}
					privacy={league.privacy}
					status={league.status}
				/>
				<LeagueFooter
					width="70%"
					color="transparent"
					players={league.num_teams}
					start_round={league.start_round}
				/>

				{is_joined ? (
					<LeaveButton
						onClick={partial(
							this.handleLeave,
							league.id,
							league.code
						)}
					/>
				) : (
					<JoinButton
						onClick={partial(
							this.handleJoin,
							league.code,
							league.name
						)}
					/>
				)}
			</LeagueWrapper>
		);
	}
}

export const League = LeagueComponent;
export default League;
