import * as React from "react";
import styled from "styled-components";
import theme from "../../assets/css/theme";

import {ContentHeader} from "../../components";

const ContentWrapper = styled.section`
	max-width: ${theme.content_width};
	margin: 20px auto;
	overflow: auto;

	figure,
	table {
		background: #fff;
		overflow: auto;
	}
`;

interface IWrapperSkeleton {
	readonly children: React.ReactNode;
	readonly title: string | undefined;
	readonly header_background?: string;
	readonly header_background_mobile?: string;
}

export const Content = ({
	children,
	title,
	header_background,
	header_background_mobile,
}: IWrapperSkeleton) => (
	<React.Fragment>
		<ContentHeader
			header_background={header_background}
			header_background_mobile={header_background_mobile}
		>
			<span>{title}</span>
		</ContentHeader>
		<ContentWrapper>{children}</ContentWrapper>
	</React.Fragment>
);
