import {isEmpty} from "lodash";
import ApiErrors from "../../utils/Api/ApiErrors";

interface IError {
	text: string;
	code: number;
}

interface IApiResponse {
	errors: IError[];
	success: 0 | 1;
}

export const responseCheck = async (response: IApiResponse) => {
	try {
		const {errors, success} = response;
		const has_errors = !isEmpty(errors);

		if (has_errors && !success) {
			const [{text, code}] = errors;
			throw new ApiErrors(text, code);
		}
	} catch (err) {
		// console.error(err);
		// yield put(actions.showGlobalError(e));
		throw err;
	}
};
