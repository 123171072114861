import * as React from "react";
import {ISponsoredLeagueLadderResponse} from "modules/types";
import styled from "styled-components";
import theme from "../../assets/css/theme";
import nfl_icon from "../../assets/img/ladder/nfl.png";
import skysports_nfl from "../../assets/img/ladder/skysports_nfl.png";
import skysports from "../../assets/img/ladder/skysports.png";

const getSponsorName = (name: string) => {
	if (name === "skysports") {
		return "Sky Sports";
	} else if (name === "skynfl") {
		return "Sky Sports NFL";
	} else {
		return "NFL";
	}
};

const getImageFromString = (name: string) => {
	if (name === "skysports") {
		return skysports;
	} else if (name === "skynfl") {
		return skysports_nfl;
	} else {
		return nfl_icon;
	}
};
const SponsoredLadderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 20px;
	@media screen and (max-width: 600px) {
		overflow-x: scroll;
	}
`;

const SponsoredLadderItemDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 267px;
	height: 91px;
	&:nth-child(2) {
		margin: 0 15px;
	}
`;
const SponsoredLadderTopDiv = styled.div`
	display: flex;
	justify-content: space-between;
	height: 39px;
	width: 267px;
	border-radius: 4px 4px 0 0;
	background-color: ${theme.header_bg};
`;
const SponsoredLadderNameDiv = styled.div`
	color: #fff;
	font-family: ${theme.base_font_medium_display};
	font-size: 16px;
	letter-spacing: 0;
	line-height: 39px;
	text-indent: 10px;
`;
const SponsoredLadderSponsorDiv = styled.div``;
const SponsoredLadderSponsorImg = styled.img`
	max-width: 25px;
	padding: 5px;
`;
const SponsoredLadderBottomDiv = styled.div`
	background-color: #fff;
	display: flex;
	justify-content: space-around;
	text-align: center;
	flex: 1;
`;
const SponsoredLadderStatDiv = styled.div`
	height: 50px;
	width: 50px;
	color: #333333;
	font-family: ${theme.base_font};
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const SponsoredLadderStatTitleDiv = styled.div``;
const SponsoredLadderStatValueDiv = styled.div`
	font-size: 14px;
	margin-top: 5px;
`;

interface IProps {
	sponsored_rank: ISponsoredLeagueLadderResponse;
}
export const SpnsoredLadder = ({sponsored_rank}: IProps) => {
	return sponsored_rank.ladder.length ? (
		<SponsoredLadderWrapper>
			{sponsored_rank.ladder.map((item) => (
				<SponsoredLadderItemDiv key={item.id}>
					<SponsoredLadderTopDiv>
						<SponsoredLadderNameDiv>
							{item.rank} . {getSponsorName(item.sponsor)}
						</SponsoredLadderNameDiv>
						<SponsoredLadderSponsorDiv>
							<SponsoredLadderSponsorImg
								src={getImageFromString(item.sponsor)}
								alt={item.sponsor}
							/>
						</SponsoredLadderSponsorDiv>
					</SponsoredLadderTopDiv>
					<SponsoredLadderBottomDiv>
						<SponsoredLadderStatDiv>
							<SponsoredLadderStatTitleDiv>
								Wk Pts
							</SponsoredLadderStatTitleDiv>
							<SponsoredLadderStatValueDiv>
								{item.round_points}
							</SponsoredLadderStatValueDiv>
						</SponsoredLadderStatDiv>
						<SponsoredLadderStatDiv>
							<SponsoredLadderStatTitleDiv>
								Wk Margin
							</SponsoredLadderStatTitleDiv>
							<SponsoredLadderStatValueDiv>
								{item.round_margin}
							</SponsoredLadderStatValueDiv>
						</SponsoredLadderStatDiv>
						<SponsoredLadderStatDiv>
							<SponsoredLadderStatTitleDiv>
								Tot Points
							</SponsoredLadderStatTitleDiv>
							<SponsoredLadderStatValueDiv>
								{item.sum_points}
							</SponsoredLadderStatValueDiv>
						</SponsoredLadderStatDiv>
						<SponsoredLadderStatDiv>
							<SponsoredLadderStatTitleDiv>
								Tot Margin
							</SponsoredLadderStatTitleDiv>
							<SponsoredLadderStatValueDiv>
								{item.sum_margin}
							</SponsoredLadderStatValueDiv>
						</SponsoredLadderStatDiv>
					</SponsoredLadderBottomDiv>
				</SponsoredLadderItemDiv>
			))}
		</SponsoredLadderWrapper>
	) : null;
};

export default SpnsoredLadder;
