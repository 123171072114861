import React, {Fragment, useEffect} from "react";
import {Syndicates} from "modules/utils/enums";
import {GAME_NAME} from "modules/utils";
import {useHistory} from "react-router-dom";

export const HOCAnalytics: React.FC = ({children}) => {
	const history = useHistory();
	const track = () => {
		if ([Syndicates.MX].includes(GAME_NAME as Syndicates) && window.fbq) {
			window.fbq("track", "PageView");
		}
	};

	useEffect(() => {
		track();
		history.listen(track);
	}, [history]);

	return <Fragment>{children}</Fragment>;
};
