import styled from "styled-components";
import theme from "../../../assets/css/theme";

interface ILeagueBodyCell {
	is_bold?: boolean;
}

export const LeagueBodyCell = styled.div<ILeagueBodyCell>`
	font-size: 12px;
	line-height: 14px;
	color: ${theme.titles};
	font-family: ${({is_bold}) =>
		is_bold ? theme.base_bold_font : theme.base_font};
	align-items: flex-end;
	justify-content: flex-start;
	display: flex;
	background-color: white;
	flex: 1;
	padding: 10px;
`;
