import AccountUpdateForm from "./accountUpdate/accountUpdateForm";
import NflAccountPage from "./nflAccount/nflAccountPage";
import CommunicationsPage from "./communications/communicationsPage";
import DeactivatePage from "./deactivateAccount/deactivatePage";

export const TABS = {
	help_text_update_details: AccountUpdateForm,
	myaccount_text_communications: CommunicationsPage,
	"NFL Account": NflAccountPage,
	myaccount_button_deactivate_account: DeactivatePage,
};
