import styled from "styled-components";
import theme from "../../assets/css/theme";
import promoBG from "../../assets/img/ModalBG@2x.png";
import {below} from "../../assets/css/media";

const SPONSOR = process.env.REACT_APP_SPONSOR || "";

export const CrossPromoWrapDiv = styled.div`
	height: 450px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -218px;
	margin-left: -250px;
	width: 500px;
	z-index: 2;
	${below.desktop`
	margin-left: -160px;
	width: 320px;
	`};
`;

export const CloseButtonWrapDiv = styled.div`
	height: 24px;
	position: absolute;
	right: 12px;
	top: 12px;
`;

export const CloseButton = styled.button`
	background: transparent;
	border: none;
	float: right;
`;

export const CrossPromoDiv = styled.div`
	background-image: url(${promoBG});
	background-size: cover;
	height: 436px;
	width: 500px;
	${below.desktop`
	width: 320px;
	`};
`;

export const IntroDiv = styled.div`
	color: #ffffff;
	font-family: ${theme.base_bold_font};
	font-size: 16px;
	letter-spacing: -0.1px;
	line-height: 22px;
	text-align: center;
	max-width: 300px;
	margin: 0 auto 20px;
`;

export const SubIntroDiv = styled.div`
	color: #ffffff;
	font-family: ${theme.base_font};
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 22px;
	text-align: center;
	max-width: 380px;
	margin: 0 auto 20px;
	${below.desktop`
		width: 300px;
	`};
`;

export const ChallengeLink = styled.a`
	border-radius: 4px;
	background-color: #037cc2;
	display: block;
	color: #ffffff;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	letter-spacing: 0;
	line-height: 40px;
	margin: 20px auto 0;
	text-align: center;
	height: 40px;
	text-transform: uppercase;
	width: ${SPONSOR === "skybet" ? "260px" : "200px"};
`;

export const NFLImg = styled.img`
	height: 125px;
	width: 91px;
`;
export const ImgDividerDiv = styled.div`
	background-color: #fff;
	height: 50px;
	margin: 30px 25px 0;
	width: 1px;
`;
export const TabImg = styled.img`
	max-height: 120px;
	max-width: 150px;
`;
export const SkyBetImg = styled.img`
	max-width: 130px;
	margin: auto 0;
`;

export const RanImg = styled.img`
	max-width: 70px;
	margin: auto 0;
`;

export const ImgWrapDiv = styled.div`
	padding-top: 40px;
	text-align: center;
	display: flex;
	justify-content: center;
`;
export const ChallengeWrapDiv = styled.div`
	display: flex;
	justify-content: center;
	margin: 20px 0;
`;
export const ChallengeImg = styled.img`
	max-width: 305px;
	${below.desktop`
	width: 280px;
	`};
`;
export const SiteTitle = styled.h2`
	color: #ffffff;
	font-family: "AllProDisaplayB-Bold";
	font-size: 48px;
	font-weight: bold;
	letter-spacing: 0.75px;
	line-height: 58px;
	text-align: center;
	text-transform: uppercase;
`;
