import * as React from "react";
import styled, {css} from "styled-components";
import RegularButton from "./RegularButton";
import {bindAll, partial} from "lodash";
import {IModelUser, IStoreState} from "../../modules/types";
import {getUser, getUserId} from "../../modules/selectors";
import {connect} from "react-redux";
import {share} from "../../modules/utils";
import facebook_icon from "../../assets/img/icons/Facebook.svg";
import twitter_icon from "../../assets/img/icons/Twitter.svg";
import share_icon from "../../assets/img/icons/Share.svg";
import share_icon_grey from "../../assets/img/icons/Share-grey.svg";
import theme from "../../assets/css/theme";
import close_icon from "../../assets/img/icons/close_Icon.svg";
import {below} from "../../assets/css/media";
import SponsorImage from "../SponsorImage";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../../modules/utils/Сonnextra/connextra";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";

const GAME_NAME = process.env.REACT_APP_WEBSITE_NAME;

interface IShareButtonWrapper {
	gamebar?: boolean;
}

const ShareButtonWrapper = styled(RegularButton)<IShareButtonWrapper>`
	background-image: url("${share_icon}");
	background-repeat: no-repeat;
	background-position: center left 15px;
	max-width: 220px;
	height: 40px;
	padding: 0 20px 0 40px;
	margin: 0 auto;
	position: relative;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	${({gamebar}) =>
		gamebar &&
		css`
			margin: 0;
			max-width: 40px;
			padding: 0 20px;
			background-position: center left 10px;
		`};

	${({gamebar}) =>
		gamebar &&
		below.desktop &&
		css`
			height: 32px;
			width: 32px;
			font-size: 10px;
		`};
`;

const CloseIcon = styled.div`
	background: url("${close_icon}") center no-repeat;
	width: 24px;
	height: 24px;
	position: absolute;
	right: 0;
	top: -30px;
	cursor: pointer;
`;

const ShareIcon = styled.div`
	min-width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% 100%;
`;

const FacebookShare = styled(ShareIcon)`
	background-image: url("${facebook_icon}");
	width: 50px;
	height: 50px;
	cursor: pointer;
`;

const TwitterShare = styled(ShareIcon)`
	background-image: url("${twitter_icon}");
	width: 50px;
	height: 50px;
	cursor: pointer;
`;

const Share = styled.img`
	margin-top: 5px;
	width: 20px;
	margin-bottom: 10px;
`;

const SocialsShare = styled.div`
	border-radius: 4px;
	max-width: 500px;
	width: 100%;
	height: 276px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	z-index: 2;
	position: relative;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	top: 50%;
	box-sizing: border-box;
	padding: 20px;
`;

const SocialShareText = styled.div`
	color: #333;
	letter-spacing: -0.1px;
	line-height: 20px;
	font-size: 16px;
	font-family: ${theme.base_bold_font};
	max-width: 300px;
	word-break: break-word;
	text-align: center;
`;

const InconsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	${FacebookShare} {
		margin-right: 20px;
	}
`;

const ShareLayout = styled.div`
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
`;

interface IProps {
	children?: string;
	disabled?: boolean;
	actual_gameweek?: number;
	user_id?: number;
	user: IModelUser;
	section: "league" | "general" | "predictions" | "results";
	actual_gameweek_status?: "scheduled" | "active" | "complete";
	score?: number;
	gamebar?: boolean;
}

interface IState {
	socials_shown: boolean;
}

class ShareButtonComponent extends React.Component<IProps, IState> {
	public state = {
		socials_shown: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["showSocials", "handleShare", "hideSocials"]);
	}

	public showSocials() {
		const {user} = this.props;
		if (window._satellite) {
			window._satellite.track("showSocials");
		}
		createConnextraScriptTag(ConnextraType.SHARE_CLICK, user);

		this.setState({
			socials_shown: true,
		});
	}

	public hideSocials() {
		this.setState({
			socials_shown: false,
		});
	}

	public handleShare(sc: "tw" | "fb") {
		const {
			section,
			actual_gameweek_status,
			actual_gameweek,
			score,
			user_id,
			user,
		} = this.props;

		let message = "";

		if (actual_gameweek_status) {
			if (actual_gameweek_status !== "complete") {
				message = `${window.getTranslations(
					"pickem_share_twitter_picks_ive_entered"
				)} ${GAME_NAME} ${window.getTranslations(
					"pickem_share_twitter_picks_picks_for"
				)} ${actual_gameweek}! ${window.getTranslations(
					"pickem_share_twitter_picks_check_out"
				)}!`;
			} else {
				message = `${window.getTranslations(
					"pickem_share_twitter_results_nfl_pickem"
				)} ${actual_gameweek} ${window.getTranslations(
					"pickem_share_twitter_results_is_over"
				)} ${score}! ${window.getTranslations(
					"pickem_share_twitter_results_check_out"
				)}!`;
			}
		} else {
			message = `${window.getTranslations(
				"social_button_twitter_pick_page"
			)}`;
		}
		if (window._satellite) {
			window._satellite.track("socialShare", {
				platform: sc,
			});
		}

		createFanHubEvent("e646e416-809e-4bd6-827e-64600fc9bf48");

		createConnextraScriptTag(
			sc === "fb"
				? ConnextraType.SHARE_CLICK_FACEBOOK
				: ConnextraType.SHARE_CLICK_TWITTER,
			user
		);

		share({
			sc,
			section,
			message,
			user_id,
			round_id: actual_gameweek,
		});
	}

	public render() {
		const {socials_shown} = this.state;
		const {children, disabled, gamebar} = this.props;
		return (
			<React.Fragment>
				<ShareButtonWrapper
					aria-label="share"
					gamebar={gamebar}
					disabled={disabled}
					onClick={this.showSocials}
				>
					{children}
				</ShareButtonWrapper>
				{socials_shown && (
					<ShareLayout>
						<SocialsShare>
							<CloseIcon onClick={this.hideSocials} />
							<Share src={share_icon_grey} role="presentation" />
							<SocialShareText>
								{window.getTranslations(
									"social_modal_popup_modal"
								)}
								!
							</SocialShareText>
							<SponsorImage
								className="less-margin"
								landing={true}
							/>
							<InconsWrapper>
								<FacebookShare
									onClick={partial(this.handleShare, "fb")}
								/>
								<TwitterShare
									onClick={partial(this.handleShare, "tw")}
								/>
							</InconsWrapper>
						</SocialsShare>
					</ShareLayout>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	user_id: getUserId(state),
	user: getUser(state),
});

const ShareButton = connect(mapStateToProps)(ShareButtonComponent);
export default ShareButton;
