import * as React from "react";
import {withHeader} from "../../components";
import LeagueAbout from "./leagueAbout/leagueAbout";
import styled from "styled-components";
import {bindAll, get, has, partial} from "lodash";
import above, {below} from "../../assets/css/media";
import theme from "../../assets/css/theme";
import chevron from "../../assets/img/icons/chevron.svg";
import {BrowserRouterProps} from "react-router-dom";
import {InvitesLeague} from "./leagueAbout/invitesLeague";
import {connect} from "react-redux";
import {clearLeaguesState} from "../../modules/actions/league";

interface IBlur {
	blur: boolean;
}

const AboutWrapper = styled.div<IBlur>`
	display: flex;
	padding: 40px 5%;
	> :first-child {
		flex: 1.5;
		max-width: 920px;
	}
	> :last-child {
		flex: 1;
	}

	${above.desktop`
		background-color: ${({blur}: IBlur) => (blur ? "rgba(0,0,0,.5)" : "#fff")};
	`};

	${below.desktop`
		flex-flow: column;
		background-color: #fff;
	`};
`;

interface IToggleSection {
	opened: boolean;
	blur?: boolean;
}

const MobileTitle = styled.div<IToggleSection>`
	${above.desktop`
		display:none;
	`};

	padding: 20px;
	font-size: 16px;
	line-height: 16px;
	color: #555;
	font-family: ${theme.base_font};
	position: relative;
	width: 100%;
	cursor: pointer;
	box-sizing: border-box;

	${below.desktop`
		padding: 20px 0;
	`};
	&:after {
		content: "";
		background: url("${chevron}") center no-repeat;
		transform: ${({opened}) =>
			opened
				? "rotate(0deg) translateY(-50%)"
				: "rotate(180deg) translateY(-50%)"};
		position: absolute;
		right: 0;
		top: 50%;
		width: 10px;
		height: 10px;
	}
`;

const ToggleSection = styled.div<IToggleSection>`
	display: flex;
	align-items: flex-start;

	${above.desktop`
		z-index: ${({blur}: IBlur) => (blur ? -1 : 1)};
	`};

	${below.desktop`
		flex-flow: column;

		>:last-child{
			display: ${({opened}: IToggleSection) => (opened ? "block" : "none")};
			padding: 0;
		}
	`};
`;

interface ISections {
	invite_opened: boolean;
	about_opened: boolean;
}

interface IState {
	sections: ISections;
	blur: boolean;
}

interface IProps extends BrowserRouterProps {
	clearLeaguesState: typeof clearLeaguesState;
}

class AboutComponent extends React.Component<IProps, IState> {
	public state = {
		sections: {
			invite_opened: false,
			about_opened: false,
		},
		blur: false,
	};

	constructor(props: IProps) {
		super(props);

		bindAll(this, ["toggleSection", "onBlurBackground"]);
	}

	public componentWillUnmount(): void {
		const {clearLeaguesState: _clearLeaguesState} = this.props;
		_clearLeaguesState();
	}

	public onBlurBackground(blur: boolean) {
		this.setState({blur});
	}

	public render() {
		const league_id = get(this.props, "match.params.comp_id", 0);
		const {sections, blur} = this.state;

		return (
			<AboutWrapper blur={blur}>
				<ToggleSection blur={blur} opened={sections.invite_opened}>
					<MobileTitle
						opened={sections.invite_opened}
						onClick={partial(this.toggleSection, "invite_opened")}
					>
						{window.getTranslations(
							"leaguecreate_form_text_invite"
						)}
					</MobileTitle>

					<InvitesLeague shadow={false} league_id={league_id} />
				</ToggleSection>

				<ToggleSection opened={sections.about_opened}>
					<MobileTitle
						opened={sections.about_opened}
						onClick={partial(this.toggleSection, "about_opened")}
					>
						{window.getTranslations("aboutcompetition_title_th")}
					</MobileTitle>

					<LeagueAbout
						onBlurBackground={this.onBlurBackground}
						background="#FAFAFA"
						shadow={false}
						league_id={league_id}
					/>
				</ToggleSection>
			</AboutWrapper>
		);
	}

	public toggleSection(section: string) {
		this.setState(({sections}) => {
			return {
				sections: {
					...sections,
					[section]: !has(sections, section),
				},
			};
		});
	}
}

const mapDispatchToProps = {
	clearLeaguesState,
};

export const About = withHeader(
	connect(null, mapDispatchToProps)(AboutComponent)
);
export default About;
