import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface IChecksums {
	draft_players?: string;
	squads?: string;
	settings?: string;
}

const initialState: IChecksums = {};

export const checksums = createReducer<IChecksums>({}, initialState).on(
	actions.fetchChecksumsSuccess,
	(state, payload) => ({
		...state,
		...payload,
	})
);
