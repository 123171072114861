import styled from "styled-components";
import {below} from "../../assets/css/media";
import theme from "../../assets/css/theme";

interface ILeagueWrapper {
	shadow?: boolean;
	background?: string;
}

export const LeagueWrapper = styled.div<ILeagueWrapper>`
	background: ${({background}) => (background ? background : "#fff")};
	border-radius: 4px;
	padding: 20px;
	width: 100%;
	max-width: 386px;
	box-sizing: border-box;
	box-shadow: ${({shadow}) => (shadow ? "0 0 10px 0 #E0E0E0;" : "none")};

	${below.desktop`
		max-width: 100%;
		background: #fff;
		padding: 0;
	`};
`;

export const LeagueToggleButton = styled.div`
	font-size: 14px;
	color: ${theme.placeholder};
	line-height: 22px;
	font-family: ${theme.base_font};
	border-bottom: 1px solid ${theme.placeholder};
	cursor: pointer;
`;

export * from "./ShareLeague";
