import * as React from "react";
import styled from "styled-components";
import {RegularButtonOutline} from "../../../components/Buttons";
import {below} from "../../../assets/css/media";
import {IFunc0} from "../../../modules/types";

const JoinButtonWrapper = styled(RegularButtonOutline)`
	position: absolute;
	top: 50%;
	right: 25px;
	height: 32px;
	transform: translateY(-50%);

	${below.desktop`
		max-width:80px;
	`};
`;

interface IJoinButton {
	readonly font_size?: string;
}

const JoinButtonTextDesktop = styled.span`
	display: block;
	font-size: ${({font_size}: IJoinButton) => font_size || "14px"};

	${below.desktop`
		display:none;
	`};
`;

const JoinButtonTextMobile = styled.span`
	display: none;
	font-size: ${({font_size}: IJoinButton) => font_size || "14px"};

	${below.desktop`
		display:block;
	`};
`;

interface IProps {
	onClick: IFunc0;
}

export const JoinButton = ({onClick}: IProps) => (
	<JoinButtonWrapper aria-label="join-league" onClick={onClick}>
		<JoinButtonTextDesktop
			font_size={window.LANG_KEY === "de" ? "12px" : "14px"}
		>
			{window.getTranslations("mycomps_header_button_join")}
		</JoinButtonTextDesktop>
		<JoinButtonTextMobile
			font_size={window.LANG_KEY === "de" ? "7px" : "14px"}
		>
			{window.getTranslations("competitionjoin_list_button_join")}
		</JoinButtonTextMobile>
	</JoinButtonWrapper>
);
export default JoinButton;
