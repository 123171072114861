import * as React from "react";
import styled, {css} from "styled-components";
import skysports from "../../assets/img/sponsor/skysports-logo.png";
import canada_secondary from "../../assets/img/sponsor/canada/sponsor_canada_secondary.png";
import canada_primary from "../../assets/img/sponsor/canada/sponsor_ca_primary.png";
import tab from "../../assets/img/sponsor/tab_logo_green.png";
import tab_white from "../../assets/img/sponsor/tab_logo.png";
import pokerstars from "../../assets/img/sponsor/pokerstars.png";
import pokerstars_white from "../../assets/img/sponsor/pokerstars_white.png";
import logo_888_white from "../../assets/img/sponsor/888-logo-white.png";
import logo_888 from "../../assets/img/sponsor/888-logo.svg";
import theme from "../../assets/css/theme";
import {get} from "lodash";
import {FooterImgDiv} from "../Footer/FooterStyle";
import {GAME_NAME} from "modules/utils";
import {Syndicates} from "modules/utils/enums";
import sponsor_logo_mx from "../../assets/img/sponsor/mx/sponsor_logo_mx.svg";
import sponsor_logo_white_mx from "../../assets/img/sponsor/mx/sponsor_logo_white_mx.svg";
import sponsor_footer_mx from "../../assets/img/sponsor/mx/sponsor_footer_mx.svg";
import tab_logo_green_footer from "assets/img/sponsor/tab_logo_green_footer.png";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";

const SPONSOR_NAME = process.env.REACT_APP_SPONSOR || "";
const SPONSOR_URL = process.env.REACT_APP_SPONSOR_URL || "";
const NO_SPONSOR = process.env.REACT_APP_NO_SPONSOR === "true";

const getWhiteOrBlackLogo = (
	whiteLogo: string,
	blackLogo: string,
	isWhite: boolean
) => {
	if (isWhite) {
		return whiteLogo;
	}
	return blackLogo;
};

const sponsorsMapper = {
	canada: (isWhite: boolean) =>
		getWhiteOrBlackLogo(canada_secondary, canada_primary, isWhite),
	tab: (isWhite: boolean) => getWhiteOrBlackLogo(tab_white, tab, isWhite),
	pokerstars: (isWhite: boolean) =>
		getWhiteOrBlackLogo(pokerstars_white, pokerstars, isWhite),
	"888": (isWhite: boolean) =>
		getWhiteOrBlackLogo(logo_888_white, logo_888, isWhite),
	MX: (isWhite: boolean) =>
		getWhiteOrBlackLogo(sponsor_logo_white_mx, sponsor_logo_mx, isWhite),
	DEFAULT_SPONSOR: (isWhite: boolean) => skysports,
} as const;

const sponsorsMapperHeader = {
	...sponsorsMapper,
	"888": (isWhite: boolean) => logo_888,
};

const footerImages = {
	MX: sponsor_footer_mx,
	tab: tab_logo_green_footer,
};

const getSponsorImageHeader = (
	sponsorName: string,
	showWhiteImage: boolean
) => {
	const mapperFunction = get(
		sponsorsMapperHeader,
		sponsorName,
		sponsorsMapper.DEFAULT_SPONSOR
	);
	return mapperFunction(showWhiteImage);
};

const getSponsorImage = (
	sponsorName: string,
	showWhiteImage: boolean,
	position: string
) => {
	if (position === "header") {
		return getSponsorImageHeader(sponsorName, showWhiteImage);
	}

	const footerImg = get(footerImages, sponsorName);
	if (position === "footer" && footerImg) {
		return footerImg;
	}

	const mapperFunction = get(
		sponsorsMapper,
		sponsorName,
		sponsorsMapper.DEFAULT_SPONSOR
	);
	return mapperFunction(showWhiteImage);

	// switch (sponsorName) {
	// 	case 'pinty':
	// 		return pinty;
	// 	case 'tab':
	// 		return getWhiteOrBlackLogo(tab_white, tab, showWhiteImage);
	// 	case 'pokerstars':
	// 		return getWhiteOrBlackLogo(pokerstars_white, pokerstars, showWhiteImage);
	// 	case '888':
	// 		return getWhiteOrBlackLogo(logo_888_white, logo_888, showWhiteImage);
	// 	default:
	// 		return skysports;
	// }
};

const StyledImgTab = styled.img<IProps>`
	${({landing, position}) => {
		if (landing) {
			return css`
				height: 50px;
				width: auto;
			`;
		}
		if (position === "league") {
			return css`
				height: 30px;
				width: auto;
			`;
		}
		return css`
			height: 20px;
			width: auto;
		`;
	}}
`;

const StyledImgDefault = styled.img<IProps>`
	${({position}) => {
		if (position === "header") {
			return css`
				width: ${SPONSOR_NAME === "MX" ? "130px" : "80px"};
			`;
		}
		if (position === "league") {
			return css`
				width: 70px;
			`;
		}
		return css`
			width: 150px;
		`;
	}}
`;

const StyledImgSkySports = styled.img<IProps>`
	margin: 0 5px;
	width: ${({position}) => (position === "header" ? "110px" : "135px")};
`;

const StyledImgPoker = styled.img<IProps>`
	${({position}) => {
		if (position === "header") {
			return css`
				width: 150px;
			`;
		}
		if (position === "league") {
			return css`
				width: 120px;
			`;
		}
		return css`
			width: 135px;
		`;
	}}
`;

const StyledImgEight = styled.img<IProps>`
	${({position}) => {
		switch (position) {
			case "division_standings":
				return css`
					width: 24px;
				`;
			case "header":
				return css`
					width: 56px;
				`;
			case "league":
				return css`
					height: 28px;
				`;
			default:
				return css`
					width: 60px;
				`;
		}
	}}
`;

interface IPresentBlock {
	readonly position: string;
}

const PresentedBlock = styled.div`
	font-size: 10px;
	color: ${({position}: IPresentBlock) =>
		position === "header" ? theme.presented_color : "#555555"};
	margin: 20px 10px 10px 0;
`;

const PresentedBlockHorizontal = styled(PresentedBlock)`
	margin-top: ${({position}: IPresentBlock) =>
		position === "header" ? "10px" : "30px"};
	margin-bottom: ${({position}: IPresentBlock) =>
		position === "header" ? "10px" : "20px"};

	&.less-margin {
		margin: 0 0 3px;
	}
`;

const Wrapper = styled(FooterImgDiv)`
	flex-direction: row;
	align-items: center;

	&.${Syndicates.AU} {
		img {
			height: 50px;
		}
	}

	&.landing {
		&.${Syndicates.CA} {
			margin-top: 20px;
			gap: 5px;
		}
		&.${Syndicates.UK} {
			margin-top: 10px;
		}
		&.${Syndicates.AU} {
			margin-top: 20px;
		}
	}

	@media screen and (max-width: 840px) {
		flex-direction: column;
	}
`;

interface IProps {
	landing?: boolean;
	position?: string;
	showPresentedBy?: boolean;
	showWhiteImage?: boolean;
	className?: string;
	isFooter?: boolean;
}

const sponsorPresentedBlockMapper = {
	tab: PresentedBlock,
	skysports: PresentedBlockHorizontal,
	pokerstars: PresentedBlockHorizontal,
	"888": PresentedBlockHorizontal,
	DEFAULT_SPONSOR: PresentedBlockHorizontal,
};

const sponsorImageComponentsMapper = {
	tab: StyledImgTab,
	skysports: StyledImgSkySports,
	pokerstars: StyledImgPoker,
	"888": StyledImgEight,
	DEFAULT_SPONSOR: StyledImgDefault,
};

const sponsor_class_name = {
	canada: "less-margin",
};

export const SponsorImage = ({
	landing = false,
	position = "",
	showPresentedBy = true,
	showWhiteImage = false,
	className = "",
}: IProps) => {
	if (!SPONSOR_NAME || NO_SPONSOR) {
		return null;
	}

	const ImageComponent = get(
		sponsorImageComponentsMapper,
		SPONSOR_NAME,
		sponsorImageComponentsMapper.DEFAULT_SPONSOR
	);
	const PresentedComponent = get(
		sponsorPresentedBlockMapper,
		SPONSOR_NAME,
		sponsorPresentedBlockMapper.DEFAULT_SPONSOR
	);
	const class_name = get(sponsor_class_name, SPONSOR_NAME, className);

	const callFanHubEvent = () => {
		createFanHubEvent("a9400e1f-0b7a-404f-beff-8f1ac96fcc86");
	};

	return (
		<Wrapper className={`${landing ? "landing" : ""} ${GAME_NAME}`}>
			{showPresentedBy && (
				<PresentedComponent className={class_name} position={position}>
					Presented by
				</PresentedComponent>
			)}
			<a
				href={SPONSOR_URL}
				target="_blank"
				rel="noopener noreferrer"
				onClick={callFanHubEvent}
			>
				<ImageComponent
					position={position}
					landing={landing}
					src={getSponsorImage(
						SPONSOR_NAME,
						showWhiteImage,
						position
					)}
					alt={SPONSOR_NAME}
				/>
			</a>
		</Wrapper>
	);
};

export default SponsorImage;
