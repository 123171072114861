import * as React from "react";
import theme from "../../../assets/css/theme";
import styled from "styled-components";

const NFL_ACCOUNT_URL = process.env.REACT_APP_NFL_ACCOUNT_URL
	? `${process.env.REACT_APP_NFL_ACCOUNT_URL}?redirectUrl=${window.location.href}`
	: "";

const Container = styled.div`
	background-color: #ffffff;
	border-radius: 6px;
	padding: 30px;
`;

const Title = styled.h3`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 30px;
`;

const Description = styled.p`
	color: #555555;
	font-size: 14px;
	line-height: 22px;
	font-family: ${theme.base_font};
	margin-bottom: 20px;
`;

const GoToNRLAccount = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #1b48e0;
	height: 40px;
	width: 270px;
	color: #1b48e0;
	border-radius: 4px;
	font-family: ${theme.base_bold_font};
	text-transform: uppercase;
	font-size: 14px;
`;

export const NflAccountPage = () => (
	<Container>
		<Title>NFL Account</Title>
		<Description>
			{window.getTranslations("account_text_nfl_account")}
		</Description>

		<GoToNRLAccount href={NFL_ACCOUNT_URL || ""} target="_blank">
			{window.getTranslations("myaccount_button_update_nfl_account")}
		</GoToNRLAccount>
	</Container>
);

export default NflAccountPage;
