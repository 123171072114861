import * as React from "react";
import Mobile from "./YourAccount/Mobile";
import {AccountUpdate} from "./YourAccount/AccountUpdate";
import withHeader from "../components/Header";

export const YourAccount = () => (
	<React.Fragment>
		<Mobile />
		<div className="hidden-mobile">
			<AccountUpdate />
		</div>
	</React.Fragment>
);

export default withHeader(YourAccount);
