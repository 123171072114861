import styled from "styled-components";
import header_bg_mobile from "../../assets/img/header_bg_mobile.png";
import header_bg from "../../assets/img/header_bg.png";
import {below} from "../../assets/css/media";
import theme from "../../assets/css/theme";
import {Syndicates} from "../../modules/utils/enums";
import header_bg_mx from "../../assets/img/header_bg_mx.png";

interface IContentHeader {
	header_background?: string;
	header_background_mobile?: string;
}

const getHeaderBackground = ({header_background}: IContentHeader) => {
	const GAME_NAME = process.env.REACT_APP_GAME_NAME;

	if (GAME_NAME === Syndicates.MX) {
		return `background: url('${header_bg_mx}')
		no-repeat; background-size: 100% 100%;`;
	}

	return `background: ${
		header_background ? `url(${header_background})` : `url(${header_bg})`
	}
		no-repeat; background-size: 100% 100%;`;
};

const getMobileHeaderBackground = ({
	header_background_mobile,
}: IContentHeader) => {
	const GAME_NAME = process.env.REACT_APP_GAME_NAME;

	if (GAME_NAME === Syndicates.MX) {
		return `background-image: url('${header_bg_mx}'); background-size: auto;`;
	}

	return `background-image: ${
		header_background_mobile
			? `url(${header_background_mobile})`
			: `url(${header_bg_mobile})`
	};`;
};

export const ContentHeader = styled.div<IContentHeader>`
	height: 140px;
	color: #ffffff;
	font-size: 60px;
	letter-spacing: -0.2px;
	text-transform: uppercase;
	font-family: ${theme.base_font_medium_display};
	position: relative;
	${getHeaderBackground}

	> span {
		position: absolute;
		left: 80px;
		bottom: 25px;
	}

	${below.desktop`
		${getMobileHeaderBackground}
		background-size: cover;
		height: 100px;
		font-size: 24px;

		>span{
			left: 30px;
			bottom: 20px;
		}
	`};
`;

export default ContentHeader;
