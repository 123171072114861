import * as React from "react";
import {ModalBlock, withHeader} from "../../components";
import styled from "styled-components";
import {bindAll, partial, toInteger} from "lodash";
import {connect} from "react-redux";
import {
	showJoinedUsers,
	showMoreJoinedUsers,
	showLeague,
	removeFromLeague,
} from "../../modules/actions/league";
import {
	getLeagueJoinedUsers,
	getMyLeague,
} from "../../modules/selectors/leagues";
import {IJoinedUser, IMyLeague, IStoreState} from "../../modules/types";
import {Redirect, RouteComponentProps} from "react-router";
import {RegularButton, RegularButtonOutline} from "../../components";
import nflLogo from "../../assets/img/mini-logo.png";

const UsersWrapper = styled.div`
	padding: 40px 5%;
`;

const UserWrapper = styled.div`
	box-sizing: border-box;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	padding: 30px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
	max-width: 1000px;
	margin: auto;
	margin-bottom: 20px;

	@media screen and (max-width: 840px) {
		flex-direction: column;
	}
`;

const UserDetails = styled.div`
	display: flex;
	align-items: center;

	@media screen and (max-width: 840px) {
		margin-bottom: 10px;
	}
`;

const Logo = styled.img`
	margin-right: 20px;
`;

const Name = styled.h4`
	margin-bottom: 5px;
`;

const Team = styled.p`
	color: #757575;
`;

const ModalContent = styled.div`
	padding: 30px;

	h2 {
		font-size: 20px;
		text-align: center;
		margin-bottom: 40px;
	}
`;

const ModalButtons = styled.div`
	display: flex;
	justify-content: center;

	button:first-child {
		margin-right: 20px;
	}
`;

const LoadMoreButton = styled(RegularButton)`
	margin: auto;
`;

interface IRouteParams {
	comp_id: string;
}

interface IState {
	remove_user_id?: number;
}

interface IProps extends RouteComponentProps<IRouteParams> {
	showLeague: typeof showLeague;
	showJoinedUsers: typeof showJoinedUsers;
	showMoreJoinedUsers: typeof showMoreJoinedUsers;
	removeFromLeague: typeof removeFromLeague;
	league: IMyLeague;
	joined_users: IJoinedUser[];
}

class UsersComponent extends React.Component<IProps> {
	public state: IState = {
		remove_user_id: undefined,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, [
			"handleRemove",
			"setRemoveUserId",
			"clearRemoveUserId",
			"showMore",
		]);
	}

	public componentDidMount() {
		const {
			match: {
				params: {comp_id},
			},
			showLeague: fetchLeague,
			showJoinedUsers: fetchJoinedUsers,
		} = this.props;
		const id = toInteger(comp_id);
		fetchLeague(id);
		fetchJoinedUsers({league_id: id});
	}

	public render() {
		const {league, joined_users} = this.props;
		const {id, user_status, status} = league;

		if (id && (user_status !== "commissioner" || status !== "scheduled")) {
			return <Redirect to={`/league/${id}/ladder`} />;
		}

		return (
			<UsersWrapper>
				{joined_users.map((user) => (
					<UserWrapper key={user.id}>
						<UserDetails>
							<Logo src={nflLogo} alt="NFL Logo" width="30" />
							<div>
								<Name>
									{user.first_name} {user.last_name[0]}
								</Name>
								<Team>{user.team_name}</Team>
							</div>
						</UserDetails>
						{user.status !== "commissioner" ? (
							<RegularButton
								max_width={"220px"}
								onClick={partial(this.setRemoveUserId, user.id)}
							>
								{window.getTranslations(
									"competitionmanage_users_button_remove"
								)}
							</RegularButton>
						) : null}
					</UserWrapper>
				))}
				{this.load_more}
				{this.modal}
			</UsersWrapper>
		);
	}

	private get load_more() {
		const {league, joined_users} = this.props;
		const {num_teams = 0} = league;

		if (
			!num_teams ||
			!joined_users.length ||
			num_teams <= joined_users.length
		) {
			return null;
		}

		return (
			<LoadMoreButton max_width={"220px"} onClick={this.showMore}>
				{window.getTranslations(
					"competitionmanage_users_button_view_more"
				)}
			</LoadMoreButton>
		);
	}

	private get modal() {
		const {remove_user_id} = this.state;

		return (
			<ModalBlock
				show_close={true}
				is_open={!!remove_user_id}
				onClose={this.clearRemoveUserId}
			>
				<ModalContent>
					<h2>
						{window.getTranslations(
							"competitionmanage_users_alert_remove"
						)}
					</h2>
					<ModalButtons>
						<RegularButton
							max_width={"220px"}
							onClick={this.handleRemove}
						>
							{window.getTranslations(
								"competitionmanage_users_button_yes"
							)}
						</RegularButton>
						<RegularButtonOutline
							max_width={"220px"}
							onClick={this.clearRemoveUserId}
						>
							{window.getTranslations(
								"competitionmanage_users_button_no"
							)}
						</RegularButtonOutline>
					</ModalButtons>
				</ModalContent>
			</ModalBlock>
		);
	}

	private handleRemove() {
		const {remove_user_id} = this.state;
		const {league, removeFromLeague: remove} = this.props;
		if (remove_user_id) {
			remove({league_id: league.id, user_id: remove_user_id});
			this.clearRemoveUserId();
		}
	}

	private setRemoveUserId(remove_user_id: number) {
		this.setState(() => ({
			remove_user_id,
		}));
	}

	private clearRemoveUserId() {
		this.setState(() => ({
			remove_user_id: undefined,
		}));
	}

	private showMore() {
		const {
			league,
			joined_users,
			showMoreJoinedUsers: fetchMoreJoinedUsers,
		} = this.props;

		fetchMoreJoinedUsers({
			league_id: league.id,
			offset: joined_users.length,
		});
	}
}

const mapStateToProps = (state: IStoreState) => ({
	league: getMyLeague(state) as IMyLeague,
	joined_users: getLeagueJoinedUsers(state),
});

const mapDispatchToProps = {
	showLeague,
	showJoinedUsers,
	showMoreJoinedUsers,
	removeFromLeague,
};

export const Users = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(UsersComponent)
);
export default Users;
