import * as React from "react";
import styled from "styled-components";

const mapDirectionToDegrees = {
	left: 0,
	top: 90,
	right: 180,
	bottom: 270,
};

type Direction = "top" | "bottom" | "left" | "right";

interface IPropSide {
	to: Direction;
}

interface IProps {
	width?: number;
	height?: number;
	color?: string;
	to?: Direction;
}

const SvgContainer = styled.svg`
	transform: rotate(${({to}: IPropSide) => mapDirectionToDegrees[to]}deg);
`;

const WIDTH = 14;
const HEIGHT = 24;

export const IconCalendar = ({
	width = WIDTH,
	height = HEIGHT,
	to = "left",
	color = "#8294A5",
	...rest
}: IProps) => (
	<SvgContainer
		to={to}
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 14 24`}
		{...rest}
	>
		<title>Icon Calendar</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-4.000000, 0.000000)" fill={color}>
				<path
					d="M18,6 L20,6 L20,20 L4,20 L4,6 L6,6 L6,4 L8,4 L8,6 L16,6 L16,4 L18,4 L18,6 Z M19,19 L19,9 L5,9 L5,19 L19,19 Z M7,11 L9,11 L9,13 L7,13 L7,11 Z M11,11 L13,11 L13,13 L11,13 L11,11 Z M15,11 L17,11 L17,13 L15,13 L15,11 Z M7,15 L9,15 L9,17 L7,17 L7,15 Z M11,15 L13,15 L13,17 L11,17 L11,15 Z M15,15 L17,15 L17,17 L15,17 L15,15 Z"
					fillRule="nonzero"
				/>
			</g>
		</g>
	</SvgContainer>
);

export default IconCalendar;
