import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconRemove = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 24 24`}
		{...rest}
	>
		<title>Remove</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-420.000000, -28.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<g transform="translate(420.000000, 28.000000)">
					<path
						transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000)"
						d="M12,0 C5.34545455,0 0,5.34545455 0,12 C0,18.6545455 5.34545455,24 12,24 C18.6545455,
						24 24,18.6545455 24,12 C24,5.34545455 18.6545455,0 12,0 Z M12,22.1454545 C6.43636364,
						22.1454545 1.85454545,17.5636364 1.85454545,12 C1.85454545,6.43636364 6.43636364,
						1.85454545 12,1.85454545 C17.5636364,1.85454545 22.1454545,6.43636364 22.1454545,
						12 C22.1454545,17.5636364 17.5636364,22.1454545 12,22.1454545 Z M16.9090909,
						11.0181818 L12.9818182,11.0181818 L12.9818182,7.09090909 C12.9818182,6.76363636 12.7636364,
						6.54545455 12.4363636,6.54545455 L11.4545455,6.54545455 C11.1272727,6.54545455 10.9090909,
						6.76363636 10.9090909,7.09090909 L10.9090909,11.0181818 L7.09090909,11.0181818 C6.76363636,
						11.0181818 6.54545455,11.2363636 6.54545455,11.5636364 L6.54545455,12.5454545 C6.54545455,
						12.8727273 6.76363636,13.0909091 7.09090909,13.0909091 L11.0181818,13.0909091 L11.0181818,
						17.0181818 C11.0181818,17.3454545 11.2363636,17.5636364 11.5636364,17.5636364 L12.5454545,
						17.5636364 C12.7636364,17.5636364 13.0909091,17.3454545 13.0909091,17.0181818 L13.0909091,
						13.0909091 L17.0181818,13.0909091 C17.3454545,13.0909091 17.5636364,12.8727273 17.5636364,
						12.5454545 L17.5636364,11.5636364 C17.4545455,11.2363636 17.2363636,11.0181818 16.9090909,
						11.0181818 Z"
					/>
				</g>
			</g>
		</g>
	</svg>
);

IconRemove.defaultProps = {
	width: 24,
	height: 24,
	color: "#9B9B9B",
};

export default IconRemove;
