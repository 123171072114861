import {Api, ApiErrors} from "../../utils/Api";
import {call, put, select, take} from "redux-saga/effects";
import * as actions from "../../actions";
import {
	IEmail,
	ILeaveLeague,
	ISagaAction,
	IRemoveFromLeague,
	IJoinedUsersPayload,
} from "../../types";
import {SagaIterator} from "redux-saga";
import {assign, get, gt, size} from "lodash";
import {getLeagueJoinedUsers} from "modules/selectors";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

export interface IActionSearch {
	value?: string;
}

export interface ILoadMore {
	readonly offset?: number | undefined;
	readonly query?: string | undefined;
}

export const showMyLeaguesForJoinSaga = function* (
	action: ISagaAction<IActionSearch & ILoadMore>
): SagaIterator {
	try {
		const LIMIT = 10;
		const ZERO_OFFSET = 0;
		const query = get(action, "payload.value");
		const load_more_query = get(action, "query", "");
		const load_more_offset = get(action, "offset", ZERO_OFFSET);

		const {result, errors} = yield call(Api.Joins.show_for_join, {
			offset: load_more_offset,
			q: query,
		});

		ApiErrors.checkOnApiError(errors);

		yield put(
			actions.showLeaguesForJoinSuccess({
				items: result || [],
				next: gt(size(result), 0),
			})
		);

		const new_offset =
			query === load_more_query || !query
				? load_more_offset + LIMIT
				: ZERO_OFFSET;

		assign(action, {
			offset: new_offset || LIMIT,
			query,
		});

		yield take(actions.showLeaguesForJoinMore);
		yield call(showMyLeaguesForJoinSaga, action);
	} catch (e) {
		yield put(actions.showLeaguesForJoinFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const showMyLeaguesSaga = function* (
	action: ISagaAction<ILoadMore>
): SagaIterator {
	try {
		const LIMIT = 15;
		const ZERO_OFFSET = 0;
		const offset = get(action, "offset", ZERO_OFFSET);
		const {result, errors} = yield call(Api.League.my_list_stats, {
			offset,
			limit: LIMIT,
		});

		ApiErrors.checkOnApiError({errors});

		yield put(
			actions.showMyLeaguesSuccess({
				next: result.length,
				items: result,
			})
		);
	} catch (e) {
		console.error(e);
		yield put(actions.showMyLeaguesFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const joinToLeagueSaga = function* (
	action: ISagaAction<string>
): SagaIterator {
	const code: string = action.payload;
	try {
		const {errors} = yield call(Api.Joins.join, {code});

		ApiErrors.checkOnApiError({errors});

		yield put(actions.joinToLeagueSuccess(code));
	} catch (e) {
		yield put(actions.joinToLeagueFailed(code));
		yield put(actions.errorsGlobalError(e));
	}
};

export const postLeagueLeaveSaga = function* (
	action: ISagaAction<ILeaveLeague>
): SagaIterator {
	try {
		const {errors} = yield call(Api.Joins.leave, {
			league_id: action.payload.league_id,
		});
		ApiErrors.checkOnApiError({errors});

		yield put(actions.leaveLeagueSuccess(action.payload));
	} catch (e) {
		yield put(actions.leaveLeagueFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const getLeagueByIdSaga = function* (
	action: ISagaAction<number>
): SagaIterator {
	try {
		const {result, errors} = yield call(Api.League.show, {
			id: action.payload,
		});

		ApiErrors.checkOnApiError({errors});

		yield put(actions.showLeagueSuccess(result));
	} catch (e) {
		yield put(actions.showLeagueFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export interface IEmailInvites {
	readonly league_id: number;
	readonly invites: IEmail[];
}

export const postInvitesSendViaEmail = function* (
	action: ISagaAction<IEmailInvites>
): SagaIterator {
	try {
		const {result, errors} = yield call(Api.Joins.invite, action.payload);

		ApiErrors.checkOnApiError({errors});
		console.log("--=--result", result);

		if (GAME_NAME === "UK" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(actions.inviteViaEmailSuccess());
	} catch (e) {
		yield put(actions.inviteViaEmailFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const searchAndJoinSaga = function* (
	action: ISagaAction<string>
): SagaIterator {
	try {
		const query = get(action, "payload");
		const {result, errors} = yield call(Api.Joins.show_for_join, {
			q: query,
		});

		ApiErrors.checkOnApiError({errors});

		yield put(
			actions.showLeaguesForJoinSuccess({
				items: result || [],
				next: gt(size(result), 0),
			})
		);

		yield call(joinToLeagueSaga, action);
	} catch (e) {
		yield put(actions.showLeaguesForJoinFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const showJoinedUsersSaga = function* (
	action: ISagaAction<IJoinedUsersPayload>
): SagaIterator {
	try {
		const {result, errors} = yield call(
			Api.Joins.show_joined_users,
			action.payload
		);

		ApiErrors.checkOnApiError({errors});

		yield put(actions.showJoinedUsersSuccess(result));
	} catch (e) {
		yield put(actions.showJoinedUsersFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const showMoreJoinedUsersSaga = function* (
	action: ISagaAction<IJoinedUsersPayload>
): SagaIterator {
	try {
		const {result, errors} = yield call(
			Api.Joins.show_joined_users,
			action.payload
		);

		ApiErrors.checkOnApiError({errors});

		const joined_users = yield select(getLeagueJoinedUsers);

		const response = [...joined_users, ...result];

		yield put(actions.showJoinedUsersSuccess(response));
	} catch (e) {
		yield put(actions.showJoinedUsersFailed());
		yield put(actions.errorsGlobalError(e));
	}
};

export const postRemoveFromLeagueSaga = function* (
	action: ISagaAction<IRemoveFromLeague>
): SagaIterator {
	try {
		const {errors} = yield call(Api.Joins.delete, action.payload);

		ApiErrors.checkOnApiError({errors});

		yield put(actions.removeFromLeagueSuccess(action.payload));
	} catch (e) {
		yield put(actions.removeFromLeagueFailed());
		yield put(actions.errorsGlobalError(e));
	}
};
