import * as React from "react";
import {Accordion} from "../../../components/Accordion";
import {MobileSectionHeader} from "../../../components/MobileSectionHeader";
import styled from "styled-components";
import {below} from "../../../assets/css/media";

const ContentWrapper = styled.div`
	padding: 30px 20px;

	${below.desktop`
        padding: 0;
    `};
`;

interface IProps {
	name: string;
	ComponentClass: React.ComponentType;
}

export const MobileFooter = ({name, ComponentClass}: IProps) => (
	<Accordion>
		{(toggleState, state) => (
			<React.Fragment>
				<Accordion.ToggleIcon
					onClick={toggleState}
					state={state}
					scope_on_click={true}
				>
					<MobileSectionHeader>
						{window.getTranslations(name) || ""}
					</MobileSectionHeader>
				</Accordion.ToggleIcon>
				<Accordion.Content state={state}>
					<ContentWrapper>
						<ComponentClass />
					</ContentWrapper>
				</Accordion.Content>
			</React.Fragment>
		)}
	</Accordion>
);

export default MobileFooter;
