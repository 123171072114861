export interface IFormElements {
	team_name: string;
	terms: string;
}

export const RegistrationValidate = {
	// terms: (value: string) => value === '0' ? window.getTranslations('register_validation_terms_checked') : '',
	terms: (value: string) =>
		value === "0"
			? window.getTranslations("register_form_label_required")
			: "",
	team_name: (value: string) =>
		value === ""
			? window.getTranslations("register_form_label_required")
			: "",
};

export const CreateLeagueValidate = {
	name: (value: string) =>
		value === ""
			? window.getTranslations("register_form_label_required")
			: "",
};
