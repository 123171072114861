import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {Content} from "../../components";
import {connect} from "react-redux";
import {IStoreState} from "../../modules/types";
import * as selectors from "../../modules/selectors";
import {userUnsubscribe} from "../../modules/actions";
import styled from "styled-components";
import {withHeader} from "../index";

const Description = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: #ffffff;
	min-height: 300px;
`;

interface IProps extends RouteComponentProps {
	userUnsubscribe: typeof userUnsubscribe;
	is_logged_in: boolean;
}

const getParameterByName = (name: string, url: string) => {
	name = name.replace(/[[\]]/g, "\\$&");
	const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
	const results = regex.exec(url);
	const TWO = 2;

	if (!results) {
		return null;
	}

	if (!results[TWO]) {
		return "";
	}

	return decodeURIComponent(results[TWO].replace(/\+/g, " "));
};

const UnsubscribeComponent = (props: IProps) => {
	const {
		userUnsubscribe: _userUnsubscribe,
		location: {search},
	} = props;

	const email = getParameterByName("email", search);
	const token = getParameterByName("token", search);

	_userUnsubscribe({
		email,
		token,
	});

	return (
		<Content title="">
			<Description>
				{window.getTranslations("account_unsubscribe_success")}
			</Description>
		</Content>
	);
};

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.isLoggedIn(state),
});

const mapDispatchToProps = {
	userUnsubscribe,
};

export const Unsubscribe = withRouter(
	withHeader(
		connect(mapStateToProps, mapDispatchToProps)(UnsubscribeComponent)
	)
);

export default Unsubscribe;
