import * as React from "react";
import {connect} from "react-redux";
import {bindAll, each, get, set} from "lodash";
import {IStoreState} from "../modules/types";
import {RegistrationValidate} from "../modules/utils/FormValidations";

import {isNeedRegisterForGame} from "../modules/selectors";
import {Redirect} from "react-router";
import {userReturningRegisterForGame} from "../modules/actions";
import FormRegisterForGame, {
	defaultRegisterForGameState,
} from "./Registration/FormRegisterForGame";

interface IDispatchProps {
	fetchAllJSON?: () => void;
}

interface IProps {
	userReturningRegisterForGame: typeof userReturningRegisterForGame;
	is_need_register_for_game: boolean;
}

class ReturningUserComponent extends React.Component<IProps & IDispatchProps> {
	public state = defaultRegisterForGameState;
	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleChange", "handleSubmit"]);
	}

	public render() {
		const {is_need_register_for_game} = this.props;
		return is_need_register_for_game ? (
			<FormRegisterForGame
				isReturningUser={true}
				handleSubmit={this.handleSubmit}
				handleChange={this.handleChange}
			/>
		) : (
			<Redirect to="/pick-em" />
		);
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, checked, type, value} = event.target as HTMLFormElement;
		const {form_element} = this.state;

		if (type === "checkbox") {
			this.validateFormItem(name, checked ? "1" : "");
			set(form_element, name, checked ? "1" : "0");
			this.setState({form_element});
		} else {
			this.validateFormItem(name, value);
			set(form_element, name, value);
			this.setState({form_element});
		}
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		this.validateForm();
		if (this.is_terms_checked) {
			this.props.userReturningRegisterForGame(this.state.form_element);
		} else {
			this.validateForm();
			console.log("Error");
		}
	}

	private get is_terms_checked() {
		const {form_errors, form_element} = this.state;
		return form_errors.terms === "" && form_element.terms === "1";
	}

	private validateFormItem(name: string, value: string) {
		const {form_errors} = this.state;

		if (name === "terms" || name === "team_name") {
			set(form_errors, name, RegistrationValidate[name](value));
			this.setState({form_errors});
		}
	}

	private validateForm() {
		const {form_element} = this.state;

		each(form_element, (value, name) => {
			this.validateFormItem(name, value);
		});
	}
}

const mapStateToProps = (state: IStoreState) => ({
	user: get(state, "user"),
	is_need_register_for_game: isNeedRegisterForGame(state),
});

const mapDispatchToProps = {
	userReturningRegisterForGame,
};

const ReturningUser = connect(
	mapStateToProps,
	mapDispatchToProps
)(ReturningUserComponent);

export default ReturningUser;
