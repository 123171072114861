import * as React from "react";
import styled, {css} from "styled-components";
import theme from "../../assets/css/theme";

interface ILoadingComponent {
	readonly with_cover_bg?: boolean;
	readonly position?: string;
}

const LoadingSpinnerStyled = styled.div`
	margin: 1em auto;
	width: 100px;
	height: 40px;
	text-align: center;
	font-size: 10px;

	> div {
		background-color: ${theme.primary};
		height: 100%;
		width: 6px;
		display: inline-block;
		margin: 0 3px;

		-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
		animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}

	.rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	.rect3 {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	.rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}

	.rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}

	@-webkit-keyframes sk-stretchdelay {
		0%,
		40%,
		100% {
			-webkit-transform: scaleY(0.4);
		}
		20% {
			-webkit-transform: scaleY(1);
		}
	}

	@keyframes sk-stretchdelay {
		0%,
		40%,
		100% {
			transform: scaleY(0.4);
			-webkit-transform: scaleY(0.4);
		}
		20% {
			transform: scaleY(1);
			-webkit-transform: scaleY(1);
		}
	}
`;

const LoadingSpinnerWrapper = styled.div`
	pointer-events: none;
	${({with_cover_bg, position}: ILoadingComponent) =>
		with_cover_bg
			? css`
					position: absolute;
					display: flex;
					align-items: ${position ? position : "flex-start"};
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					z-index: 5;
					user-select: none;
					background-color: rgba(218, 218, 218, 0.35);
			  `
			: ""};
`;

export const LoadingSpinner = ({
	with_cover_bg,
	position,
}: ILoadingComponent) => (
	<LoadingSpinnerWrapper with_cover_bg={with_cover_bg} position={position}>
		<LoadingSpinnerStyled>
			<div className="rect1" />
			<div className="rect2" />
			<div className="rect3" />
			<div className="rect4" />
			<div className="rect5" />
		</LoadingSpinnerStyled>
	</LoadingSpinnerWrapper>
);

export default LoadingSpinner;
