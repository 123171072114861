import * as React from "react";
import {PageAccountWrapper} from "./pageAccountWrapper";
import {CommunicationsPage} from "./communications/communicationsPage";
import {withHeader} from "../../components";

export const Communications = () => (
	<PageAccountWrapper
		title={window.getTranslations("myaccount_text_communications") || ""}
	>
		<CommunicationsPage />
	</PageAccountWrapper>
);

export default withHeader(Communications);
