import * as React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {bindAll} from "lodash";
import {connect} from "react-redux";
import {IStoreState} from "../../../modules/types";
import GigyaWrapper from "gigya-api-wrapper";
import {IconArrowSlim, IconWarning} from "../../../components";

import ExternalIcon from "../../../assets/img/icons/external_dark_grey.png";
import ClearIcon from "../../../assets/img/icons/clear-grey.svg";

const Wrapper = styled.div`
	display: block;
	margin: 20px 0;
`;

const NavLinkStyled = styled(NavLink)`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;

	&.active {
		background-color: #ffffff;

		svg {
			color: #1b48e0;
		}
	}

	svg {
		margin: 0 15px;
	}
`;

const DeactivateNavLinkStyled = styled(NavLinkStyled)`
	&.active {
		svg {
			color: #ff0000;
		}
	}
`;

const OtherLinks = styled.a`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;
	padding-left: 50px;
`;

const NFLLink = styled(NavLinkStyled)`
	background: url(${ExternalIcon}) no-repeat 13px center;
	padding-left: 50px;
`;

const LogOutLink = styled(OtherLinks)`
	background: url(${ClearIcon}) no-repeat 13px center;
`;

interface IProps {
	gigyaWrapperInstance: typeof GigyaWrapper;
}

class AccountWidgetComponent extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleLogOut"]);
	}

	/**
	 * @ignore
	 */
	public render() {
		const WIDTH_ARROW = 14;
		const WIDTH_WARNING = 16;
		const WIDTH_HEIGHT = 16;

		return (
			<Wrapper>
				<NavLinkStyled to="/account/update">
					<IconArrowSlim
						color="#555555"
						to="right"
						width={WIDTH_ARROW}
					/>
					{window.getTranslations("help_text_update_details")}
				</NavLinkStyled>
				<NavLinkStyled to="/account/communications">
					<IconArrowSlim
						color="#555555"
						to="right"
						width={WIDTH_ARROW}
					/>
					{window.getTranslations("myaccount_text_communications")}
				</NavLinkStyled>

				<NFLLink to="/account/nrl-account">NFL Account</NFLLink>
				<DeactivateNavLinkStyled to="/account/deactivate-account">
					<IconWarning
						color="#555555"
						width={WIDTH_WARNING}
						height={WIDTH_HEIGHT}
					/>
					{window.getTranslations(
						"myaccount_button_deactivate_account"
					)}
				</DeactivateNavLinkStyled>
				<LogOutLink href="" onClick={this.handleLogOut}>
					{window.getTranslations("account_form_button_signout")}
				</LogOutLink>
			</Wrapper>
		);
	}

	private handleLogOut(
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) {
		event.preventDefault();
		this.props.gigyaWrapperInstance.logout();
	}
}

const mapStateToProps = (state: IStoreState) => ({
	gigyaWrapperInstance: state.user.gigyaWrapperInstance,
});

export const AccountWidget = connect(mapStateToProps)(AccountWidgetComponent);

export default AccountWidget;
