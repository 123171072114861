import * as React from "react";
import {connect} from "react-redux";
import {
	IJoinedCodes,
	IJoinLeague,
	ILeaveLeague,
	IModelUser,
	IPrizes,
	IRules,
	IStoreState,
} from "../../modules/types";
import {
	clearLeaguesForJoin,
	fetchPrizes,
	fetchRules,
	joinToLeague,
	leaveLeague,
	searchAndJoin,
	showLeaguesForJoin,
	showLeaguesForJoinMore,
} from "../../modules/actions";
import {
	getJoinedCodes,
	getJoinLeagues,
	getOmnitureTracking,
	hasNextLoadMore,
	isJoinLeaguesDataRequested,
} from "../../modules/selectors";
import {bindAll, debounce, get, isEmpty, map, size, trim} from "lodash";
import League from "./joinLeagues/league";
import {LoadMoreButton} from "../../components/Buttons/LoadMoreButton";
import {Redirect, RouteComponentProps} from "react-router";
import {LeagueNav, LeagueNavButtons, LeagueNotFound} from "./myLeagues";
import {IActionSearch} from "../../modules/sagas/leagues";
import {
	Content,
	ContentWithSidebar,
	LeagueCreateLink,
	LoadingSpinner,
	PrizesLink,
	RegularButton,
	RulesLink,
	SearchField,
	Widget,
	withHeader,
} from "../../components";
import {getPrizes, getRules} from "../../modules/selectors/jsons";
import header_bg from "../../assets/img/header_bg.png";
import header_bg_mobile from "../../assets/img/header_bg_mobile.png";
import header_bg_uk from "../../assets/img/header_bg_league_uk.jpg";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../../modules/utils/Сonnextra/connextra";

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

interface ICode {
	readonly code?: string | undefined;
}

interface IProps extends RouteComponentProps<ICode> {
	showLeaguesForJoin: typeof showLeaguesForJoin;
	showLeaguesForJoinMore: typeof showLeaguesForJoinMore;
	clearLeaguesForJoin: typeof clearLeaguesForJoin;
	joinToLeague: typeof joinToLeague;
	leaveLeague: typeof leaveLeague;
	fetchPrizes: typeof fetchPrizes;
	fetchRules: typeof fetchRules;
	searchAndJoin: typeof searchAndJoin;
	join_leagues: IJoinLeague[];
	is_data_requested: boolean;
	has_next: boolean;
	joined_codes: IJoinedCodes;
	prizes: IPrizes[];
	rules: IRules[];
	readonly omniture_tracking: any;
	user: IModelUser;
}

class JoinLeagueComponent extends React.Component<IProps> {
	private readonly search: (params: IActionSearch) => void;

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleJoin", "handleLeave", "handleSearch"]);

		const HALF_ONE_SEC = 500;
		this.search = debounce(this.searchFunc, HALF_ONE_SEC);
	}

	/**
	 * handleJoin
	 * @description join a league
	 */

	public handleJoin(league_code: string) {
		const {joinToLeague: join} = this.props;

		join(league_code);
	}

	/**
	 * handleLeave
	 * @description leave a league
	 */

	public handleLeave(league_leave: ILeaveLeague) {
		const {leaveLeague: leave} = this.props;

		leave(league_leave);
	}

	public get leagues() {
		const {join_leagues, joined_codes, has_next, is_data_requested} =
			this.props;

		if (
			this.code_url &&
			joined_codes[this.code_url] &&
			!get(joined_codes[this.code_url], "is_joined")
		) {
			this.showLeaguesForJoin();
			return <Redirect to={`/leagues/join`} />;
		}

		return (
			<React.Fragment>
				{size(join_leagues) && !is_data_requested ? (
					map(join_leagues, (league: IJoinLeague) => (
						<League
							key={league.id}
							league={league}
							onJoin={this.handleJoin}
							onLeave={this.handleLeave}
							join_state={joined_codes[league.code]}
						/>
					))
				) : (
					<LeagueNotFound />
				)}

				{has_next ? (
					<LoadMoreButton onClick={this.loadMore}>
						{window.getTranslations("global_term_load_more")}
					</LoadMoreButton>
				) : null}
			</React.Fragment>
		);
	}

	/**
	 *
	 * @ignore
	 */
	public handleSearch(e: React.SyntheticEvent<EventTarget>) {
		const target = e.target;
		const value = trim(get(target, "value"));

		this.search({
			value,
		});
	}

	/**
	 * @description get league code from routing
	 */

	public get code_url(): string {
		const {
			match: {params},
		} = this.props;

		return get(params, "code", "");
	}

	/**
	 * @description get search leagues field
	 */

	get searchField() {
		return (
			<SearchField
				placeholder={window.getTranslations(
					"help_faq_placeholder_search"
				)}
				defaultValue={this.code_url}
				onChange={this.handleSearch}
			/>
		);
	}

	/**
	 * @ignore
	 */

	public render() {
		const {is_data_requested, prizes, rules} = this.props;

		return (
			<Content
				title={window.getTranslations("mycomps_header_button_join")}
				header_background={
					GAME_NAME === "UK" ? header_bg_uk : header_bg
				}
				header_background_mobile={
					GAME_NAME === "UK" ? header_bg_uk : header_bg_mobile
				}
			>
				<ContentWithSidebar
					sidebar_components={[
						<Widget
							key="Prizes"
							widget={{
								title: window.getTranslations(
									"pickem_nav_button_prizes"
								),
								type: "prizes",
								content: prizes,
								more: window.getTranslations(
									"prizes_form_text_more_prizes"
								),
								more_link: PrizesLink,
							}}
						/>,
						<Widget
							key="Scoring"
							widget={{
								title: window.getTranslations(
									"pickem_gamebar_text_scoring"
								),
								type: "scores",
								content: rules,
								more: window.getTranslations(
									"widget_leaguepage_text_find_more"
								),
								more_link: RulesLink,
							}}
						/>,
					]}
				>
					{is_data_requested ? <LoadingSpinner /> : null}

					{this.searchField}

					{this.leagues}

					<LeagueNav
						title={window.getTranslations(
							"createleague_leaguepage_create_your_own"
						)}
						description={`& ${window.getTranslations(
							"leaguecreate_form_text_invite"
						)}`}
						buttons={
							<LeagueNavButtons justify_end={true}>
								<LeagueCreateLink>
									<RegularButton
										aria-label={"create league"}
										font_size={
											window.LANG_KEY === "de"
												? "12px"
												: "14px"
										}
									>
										{window.getTranslations(
											"mycomps_header_button_create"
										)}
									</RegularButton>
								</LeagueCreateLink>
							</LeagueNavButtons>
						}
					/>
				</ContentWithSidebar>
			</Content>
		);
	}

	/**
	 * @ignore
	 */
	public clearLeaguesForJoin() {
		const {clearLeaguesForJoin: clearJoins} = this.props;

		clearJoins();
	}

	/**
	 * Clear Join To League Model
	 */
	public componentWillUnmount() {
		this.clearLeaguesForJoin();
	}

	/**
	 * @ignore
	 */
	public showLeaguesForJoin(params: IActionSearch = {}) {
		const {showLeaguesForJoin: show} = this.props;

		show(params);
	}

	public fetchJSONS() {
		const {fetchRules: _fetchRules, fetchPrizes: _fetchPrizes} = this.props;
		_fetchPrizes();
		_fetchRules();
	}

	/**
	 * @ignore
	 */

	public componentDidMount(): void {
		const {
			match: {params},
			searchAndJoin: _searchAndJoin,
			user,
			omniture_tracking,
		} = this.props;
		const value = get(params, "code", "");

		if (!isEmpty(params.code)) {
			_searchAndJoin(value || "");
		} else {
			this.showLeaguesForJoin();
		}
		this.fetchJSONS();
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("leagues", "join a league", "landing", user)
			);
		}
		createConnextraScriptTag(ConnextraType.LEAGUE_JOIN, user);
	}

	private readonly loadMore = () => {
		const {showLeaguesForJoinMore: loadMore} = this.props;

		loadMore();
	};

	private searchFunc(params: IActionSearch) {
		this.clearLeaguesForJoin();
		this.showLeaguesForJoin(params);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	join_leagues: getJoinLeagues(state),
	is_data_requested: isJoinLeaguesDataRequested(state),
	has_next: hasNextLoadMore(state),
	joined_codes: getJoinedCodes(state),
	prizes: getPrizes(state),
	rules: getRules(state),
	omniture_tracking: getOmnitureTracking,
	user: state.user,
});

const mapDispatchToProps = {
	showLeaguesForJoin,
	showLeaguesForJoinMore,
	joinToLeague,
	leaveLeague,
	clearLeaguesForJoin,
	fetchPrizes,
	fetchRules,
	searchAndJoin,
};

export default withHeader(
	connect(mapStateToProps, mapDispatchToProps)(JoinLeagueComponent)
);
