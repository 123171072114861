import * as React from "react";

interface IProps {
	width: number;
	height: number;
	color: string;
}

export const IconGrab = ({width, height, ...rest}: IProps) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 10 20`}
		{...rest}
	>
		<title>Grab</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-9.000000, -30.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<path
					d="M11,34 C9.8954305,34 9,33.1045695 9,32 C9,30.8954305 9.8954305,30 11,30 C12.1045695,
					30 13,30.8954305 13,32 C13,33.1045695 12.1045695,34 11,34 Z M17,34 C15.8954305,34 15,33.1045695 15,
					32 C15,30.8954305 15.8954305,30 17,30 C18.1045695,30 19,30.8954305 19,32 C19,33.1045695 18.1045695,
					34 17,34 Z M11,50 C9.8954305,50 9,49.1045695 9,48 C9,46.8954305 9.8954305,46 11,46 C12.1045695,
					46 13,46.8954305 13,48 C13,49.1045695 12.1045695,50 11,50 Z M17,50 C15.8954305,50 15,49.1045695 15,
					48 C15,46.8954305 15.8954305,46 17,46 C18.1045695,46 19,46.8954305 19,48 C19,49.1045695 18.1045695,
					50 17,50 Z M11,42 C9.8954305,42 9,41.1045695 9,40 C9,38.8954305 9.8954305,38 11,38 C12.1045695,
					38 13,38.8954305 13,40 C13,41.1045695 12.1045695,42 11,42 Z M17,42 C15.8954305,42 15,41.1045695 15,
					40 C15,38.8954305 15.8954305,38 17,38 C18.1045695,38 19,38.8954305 19,40 C19,41.1045695 18.1045695,
					42 17,42 Z"
				/>
			</g>
		</g>
	</svg>
);

IconGrab.defaultProps = {
	width: 10,
	height: 20,
	color: "#E0E0E0",
};

export default IconGrab;
