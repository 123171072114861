import * as React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import styled from "styled-components";
import {below} from "../assets/css/media";
import theme from "../assets/css/theme";
import LandingBackgroundMobile from "../assets/img/landing-background-mobile.jpg";
// Icons
import LandingBackground from "../assets/img/landing-background.jpg";
import canadaLandingBackground from "../assets/img/canadaLandingBackground.jpg";
import {NFLLogo, SponsorImage, withHeader} from "../components";
import {userLogin} from "../modules/actions";
import * as selectors from "../modules/selectors";
import {getOmnitureTracking, getIVWTracking} from "../modules/selectors";
import {IAuthState, IStoreState} from "../modules/types";
import GigyaWrapper from "gigya-api-wrapper";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../modules/utils/Сonnextra/connextra";
import {get} from "lodash";
import {createFanHubEvent} from "../modules/utils/FanHubTagging";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

const getLandingBackground = () => {
	const variantsOfBackground = {
		Canada: canadaLandingBackground,
	};

	return get(variantsOfBackground, GAME_NAME, LandingBackground);
};

const getMobileLandingBackground = () => {
	const variantsOfBackground = {
		Canada: canadaLandingBackground,
	};

	return get(variantsOfBackground, GAME_NAME, LandingBackgroundMobile);
};

const SECRET_KEY_ENABLED = JSON.parse(
	process.env.REACT_APP_SECRET_KEY || "false"
);

const OFF_SEASON_ENABLED = JSON.parse(
	process.env.REACT_APP_OFF_SEASON || "false"
);

const LandingWrapper = styled.div`
	background: url(${getLandingBackground()});
	height: 950px;
	background-size: 100% 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: ${theme.base_font};

	${below.desktop`
		background-image: url(${getMobileLandingBackground()});
	`};
`;

const Logo = styled(NFLLogo)`
	margin-top: -160px;
`;

const WelcomeBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 430px;
	width: 380px;
	background-color: #ffffff;
	border-radius: 6px;
	padding: 40px 20px 28px;

	${below.desktop`
		width: 340px;
	`};
`;

const WelcomeText = styled.div`
	color: #333333;
	font-size: 16px;
	max-width: 340px;
	text-align: center;
	font-family: ${theme.base_bold_font};
	text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	letter-spacing: -0.1px;
	line-height: 20px;
	margin-top: 20px;
`;

const SecretText = styled.div`
	font-size: 56px;
	color: #fff;
	letter-spacing: 2px;
	font-family: AllProDisaplayB-Bold;
	max-width: 600px;
	text-align: center;
	line-height: 60px;
`;

const DETextSize = styled.span`
	font-size: 42px;
	line-height: 1;
`;

interface IProps extends IAuthState {
	userLogin: typeof userLogin;
	gigyaWrapperInstance: typeof GigyaWrapper;
	readonly omniture_tracking: any;
	readonly ivw_tracking: any;
}

const SECRET_COUNT = 10;

/**
 * Login page component
 */
class LandingScreenComponent extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		this.handleRegister = this.handleRegister.bind(this);
		this.increaseSecretKey = this.increaseSecretKey.bind(this);
		createConnextraScriptTag(ConnextraType.HOME);
	}

	public get secret_count(): number {
		return Number(localStorage.getItem("secret_count"));
	}

	public increaseSecretKey() {
		const secret_count = this.secret_count + 1;
		localStorage.setItem("secret_count", secret_count.toString());

		if (secret_count >= SECRET_COUNT) {
			window.location.reload();
		}
	}

	public handleRegister({
		currentTarget,
	}: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		const {type} = currentTarget.dataset;
		createConnextraScriptTag(
			type === "" ? ConnextraType.REGISTRATION_START : ConnextraType.LOGIN
		);
		createFanHubEvent("9932e071-86e6-43d4-9b9f-48decd0aa07e");
		if (GAME_NAME === "Canada") {
			console.log("handleRegister");
			window.ftag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflpi0/nflpi002+standard",
				event_callback: () => {
					console.log("DC-10179195/nflpi0/nflpi002+standard");
					this.props.gigyaWrapperInstance.gigya.sso.login({
						authFlow: "redirect",
					});
				},
			});
		} else {
			this.props.gigyaWrapperInstance.gigya.sso.login({
				authFlow: "redirect",
			});
		}
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {is_logged_in, ivw_tracking} = this.props;
		createFanHubEvent("d95ee7d9-62fb-4b6d-950d-1b0c35d842d4");

		if (GAME_NAME === "prosieben" && !is_logged_in) {
			ivw_tracking("/us-sport/nfl/nfltippspiel");

			window.ga("send", {
				hitType: "pageview",
				page: "/us-sport/nfl/tippspiel",
			});
		}
		if (GAME_NAME === "Canada") {
			window.ftag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflpi0/nflpi003+standard",
				event_callback() {
					console.log("DC-10179195/nflpi0/nflpi003+standard");
				},
			});
		}
	}

	public get secret_key_enabled() {
		const {is_logged_in} = this.props;

		return (
			!!SECRET_KEY_ENABLED &&
			!is_logged_in &&
			this.secret_count < SECRET_COUNT
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {is_logged_in, omniture_tracking} = this.props;

		if (is_logged_in) {
			return <Redirect to="/pick-em" />;
		}
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("pick em", "login", "landing")
			);
		}

		const current_lang = process.env.REACT_APP_GAME_LANGUAGE || "";

		const message =
			{
				AU: (
					<span>
						Coming Soon! <br /> <br />
						The 2024 NFL Season kicks off in September. Please check
						back closer to the date to play in 2024.
					</span>
				),
				DE: (
					<DETextSize>
						BALD GEHT’S WIEDER LOS! <br /> <br />
						Die NFL Saison 2024 startet im September. Bitte schau zu
						einem späteren Zeitpunkt noch einmal vorbei, um dieses
						Jahr mitzuspielen.
					</DETextSize>
				),
				GB: (
					<span>
						Coming Soon! <br /> <br />
						The 2024 NFL Season kicks off in September. Please check
						back closer to the date to play in 2024.
					</span>
				),
				CA: (
					<span>
						Coming Soon!! <br /> <br />
						The 2024 NFL Season kicks off in September. Please check
						back closer to the date to play in 2024.
					</span>
				),
				MX: (
					<span>
						Coming Soon!! <br /> <br />
						The 2024 NFL Season kicks off in September. Please check
						back closer to the date to play in 2024.
					</span>
				),
			}[current_lang] || null;

		if (this.checkOnOffSeason) {
			return (
				<LandingWrapper>
					<WelcomeBlock>
						<Logo onClick={this.increaseSecretKey} />
						<SponsorImage landing={true} />
						<WelcomeText>
							{window.getTranslations(
								"landing.off_season.modal_thanks"
							)}
						</WelcomeText>
					</WelcomeBlock>
				</LandingWrapper>
			);
		}

		if (!this.secret_key_enabled) {
			return <Redirect to="/pick-em" />;
		}

		return (
			<LandingWrapper>
				<SecretText onClick={this.increaseSecretKey}>
					{message}
				</SecretText>
			</LandingWrapper>
		);
	}

	private get checkOnOffSeason(): boolean {
		return OFF_SEASON_ENABLED && this.secret_count < SECRET_COUNT;
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.isLoggedIn(state),
	gigyaWrapperInstance: state.user.gigyaWrapperInstance,
	omniture_tracking: getOmnitureTracking,
	ivw_tracking: getIVWTracking,
});

const mapDispatchToProps = {
	userLogin,
};

export const LandingScreen = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(LandingScreenComponent)
);

export default LandingScreen;
