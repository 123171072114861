import * as React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {Route, RouteProps as RoutePropsTypes} from "react-router-dom";
import {isLoggedIn, isNeedRegisterForGame} from "../../modules/selectors";
import {IStoreState} from "../../modules/types";
import {get} from "lodash";
import {OFF_SEASON_ENABLED} from "modules/utils";

interface IModelState {
	readonly is_authenticated?: boolean;
	readonly is_need_register_for_game: boolean;
}

type Props = RoutePropsTypes & IModelState;

const PrivateRouteComponent = ({
	is_authenticated,
	is_need_register_for_game,
	location,
	...rest
}: Props) => {
	const pathname = get(location, "pathname", "");

	if (OFF_SEASON_ENABLED) {
		return <Redirect to="/" />
	}

	if (is_need_register_for_game && pathname !== "/returning-user") {
		return <Redirect to="/returning-user" />;
	}

	return is_authenticated ? <Route {...rest} /> : <Redirect to="/" />;
};

const mapStateToProps = (state: IStoreState) => ({
	is_authenticated: isLoggedIn(state),
	is_need_register_for_game: isNeedRegisterForGame(state),
});

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
