import styled from "styled-components";
import theme from "../../assets/css/theme";
import RegularButton from "./RegularButton";
import {below} from "../../assets/css/media";

export const SecondaryButton = styled(RegularButton)`
	background: ${theme.primary};
	border: 1px solid ${theme.primary};
	color: white;
	${below.desktop`
		margin 1em auto;
	`};
`;

export default SecondaryButton;
