import * as React from "react";
import styled from "styled-components";
import {below} from "../../assets/css/media";

const Wrapper = styled.div`
	transform: rotate(-90deg);
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		${below.desktop`
			width: 20px;
		`};
	}
`;

const defaultValues = {
	percents: 50,
	width: 54,
	stroke_width: 14,
	home_color: "#003E78",
	away_color: "#FFC124",
};

interface IProps {
	percents?: number;
	width?: number;
	stroke_width?: number;
	home_color?: string;
	away_color?: string;
}
export const RoundProgress = (props: IProps) => {
	const {
		percents = defaultValues.percents,
		width = defaultValues.width,
		stroke_width = defaultValues.stroke_width,
		home_color = defaultValues.home_color,
		away_color = defaultValues.away_color,
	} = props;

	const HUNDRED = 100;
	const ONE = 1;
	const TWO = 2;

	const cxcy = +width / TWO;
	const RADIUS = +width / TWO - +stroke_width / TWO;
	const PLACE = TWO * Math.PI * RADIUS;
	const progress = percents / HUNDRED;
	const offset = PLACE * (ONE - progress);

	return (
		<Wrapper>
			<svg width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
				<circle
					cx={cxcy}
					cy={cxcy}
					r={RADIUS}
					fill="none"
					stroke={home_color}
					strokeWidth={stroke_width}
				/>
				<line
					x1={width}
					y1={width / 2}
					x2={width - stroke_width}
					y2={width / 2}
					strokeWidth="2"
					stroke="white"
				/>
				<circle
					cx={cxcy}
					cy={cxcy}
					r={RADIUS}
					fill="none"
					stroke="white"
					strokeWidth={stroke_width}
					strokeDasharray={PLACE}
					strokeDashoffset={offset - 0.5}
				/>
				<circle
					cx={cxcy}
					cy={cxcy}
					r={RADIUS}
					fill="none"
					stroke={away_color}
					strokeWidth={stroke_width}
					strokeDasharray={PLACE}
					strokeDashoffset={offset + 0.5}
				/>
			</svg>
		</Wrapper>
	);
};

export default RoundProgress;
