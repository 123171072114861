import styled from "styled-components";
import * as React from "react";
import {LeagueBodyCell, LeagueBodyRow, LeagueFooter, LeagueHeader} from "./";
import {LeagueAboutLink, LeagueLadder} from "../../../components";
import {ILeague} from "../../../modules/reducers/leagues";
import {below} from "../../../assets/css/media";
import {IGameWeek} from "../../../modules/reducers";
import {get} from "lodash";
import {isMobile} from "../../../modules/utils";
import Chevron from "components/Icons/Chevron";
import theme from "assets/css/theme";

const GAME_LANGUAGE = process.env.REACT_APP_GAME_LANGUAGE || "";

const LeagueWrapper = styled.div`
	height: 160px;
	background-color: #fff;
	box-shadow: 0 0 10px 0 #e0e0e0;
	margin-bottom: 30px;
	border-radius: 4px;

	&:last-child {
		margin-bottom: 0;
	}

	${below.desktop`
		border-radius: 0;
		margin-bottom: 10px;
	`};
`;

interface IState {
	is_mobile: boolean;
}

interface IProps {
	league: ILeague;
	actual_round?: IGameWeek;
}

const LeagueBody = styled.div`
	position: relative;
`;

const LeagueBodyNav = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 52px;
	position: absolute;
	border-radius: 50%;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	transition: 0.2s;

	${below.desktop`
		right: 10px;
	`};

	&:hover {
		background-color: #e0e0e0;
	}
`;

const LeagueBodyNavTab = styled(LeagueBodyNav)`
	&:hover {
		color: ${theme.header_bg};
	}
`;

class LeagueComponent extends React.Component<IProps, IState> {
	public state = {
		is_mobile: isMobile(),
	};

	/**
	 * @ignore
	 */
	public leagueBody({
		id,
		round_points,
		total_points,
		rank,
		total_margin,
		status,
		start_round,
		class: leagueClass,
	}: ILeague) {
		const league_blocked = status === "scheduled";
		const {actual_round} = this.props;
		const {is_mobile} = this.state;
		const actual_round_id = get(actual_round, "id", 0);
		const display_round =
			actual_round_id > start_round ? actual_round_id : start_round;
		const is_sponsor_league = leagueClass === "overall";
		const pointsTxt = GAME_LANGUAGE === "MX" ? "Puntos" : "Points";
		return (
			<LeagueBody>
				<LeagueBodyRow>
					<LeagueBodyCell is_bold={true}>
						{window.getTranslations(
							"competitionjoin_list_placeholder_week"
						)}{" "}
						{display_round} {pointsTxt}
					</LeagueBodyCell>
					<LeagueBodyCell is_bold={true}>
						{window.getTranslations("mycomps_list_total_points")}
					</LeagueBodyCell>
					<LeagueBodyCell is_bold={true}>
						{window.getTranslations(
							"pickem_formguide_dropdown_league_rankings"
						)}
					</LeagueBodyCell>
					<LeagueBodyCell is_bold={true}>
						{window.getTranslations(
							"pickem_gambar_text_tiebreaker"
						)}
					</LeagueBodyCell>
				</LeagueBodyRow>

				<LeagueBodyRow>
					<LeagueBodyCell>
						{!league_blocked ? round_points : "NA"}
					</LeagueBodyCell>
					<LeagueBodyCell>
						{!league_blocked ? total_points : "NA"}
					</LeagueBodyCell>
					<LeagueBodyCell>
						{!league_blocked ? rank : "NA"}
					</LeagueBodyCell>
					<LeagueBodyCell>
						{!league_blocked ? total_margin : "NA"}
					</LeagueBodyCell>
				</LeagueBodyRow>

				{this.getLeagueBodyNavLink(
					id,
					league_blocked,
					is_mobile,
					is_sponsor_league
				)}
			</LeagueBody>
		);
	}

	public getLeagueBodyNavLink(
		id: number,
		league_blocked: boolean,
		is_mobile: boolean,
		is_sponsor_league: boolean
	): React.ReactElement {
		if (!league_blocked || is_mobile) {
			return is_sponsor_league ? (
				<LeagueBodyNavTab as={LeagueLadder(id)}>
					<Chevron to="right" width={18} height={12} />
				</LeagueBodyNavTab>
			) : (
				<LeagueBodyNav as={LeagueLadder(id)}>
					<Chevron to="right" width={18} height={12} />
				</LeagueBodyNav>
			);
		} else {
			return is_sponsor_league ? (
				<LeagueBodyNavTab as={LeagueAboutLink(id)}>
					<Chevron to="right" width={18} height={12} />
				</LeagueBodyNavTab>
			) : (
				<LeagueBodyNav as={LeagueAboutLink(id)}>
					<Chevron to="right" width={18} height={12} />
				</LeagueBodyNav>
			);
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {league} = this.props;
		const is_commissioner = league.user_status === "commissioner";
		const is_sponsor_league = league.class === "overall";

		return (
			<LeagueWrapper>
				<LeagueHeader
					name={league.name}
					privacy={league.privacy}
					status={league.status}
					is_commissioner={is_commissioner}
					is_sponsor_league={is_sponsor_league}
				/>

				{this.leagueBody(league)}

				<LeagueFooter
					players={league.num_teams}
					start_round={league.start_round}
				/>
			</LeagueWrapper>
		);
	}
}

export const League = LeagueComponent;
export default League;
