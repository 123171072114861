import * as React from "react";
import {withHeader} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {connect} from "react-redux";
import {IGuideline, IStoreState} from "../../modules/types";
import {getGuidelines} from "../../modules/selectors/jsons";
import {getIVWTracking, getOmnitureTracking} from "../../modules/selectors";
import {fetchGuidelines} from "../../modules/actions";
import {get, map} from "lodash";
import {HelpContent, HelpTitle} from "./help";
import {LoadingSpinner} from "../../components";
const GAME_NAME = process.env.REACT_APP_GAME_NAME;
interface IProps {
	guidelines: IGuideline[];
	fetchGuidelines: typeof fetchGuidelines;
	header_hidden?: boolean;
	readonly ivw_tracking: any;
	readonly omniture_tracking: any;
}

class GameGuidelinesComponent extends React.Component<IProps> {
	public componentDidMount(): void {
		const {
			fetchGuidelines: _fetchGuidelines,
			ivw_tracking,
			omniture_tracking,
		} = this.props;
		if (GAME_NAME === "prosieben") {
			ivw_tracking("/us-sport/nfl/nfltippspiel/hilfe");

			window.ga("send", {
				hitType: "pageview",
				page: "/us-sport/nfl/tippspiel/hilfe",
			});
		}
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("pick em", "helpModal", "GameGuidelines")
			);
		}
		_fetchGuidelines();
	}

	public render() {
		const {header_hidden} = this.props;

		return header_hidden ? (
			this.guidelines_content
		) : (
			<HelpPagesWrapper
				title={
					window.getTranslations("help_contact_button_rules") || ""
				}
			>
				{this.guidelines_content}
			</HelpPagesWrapper>
		);
	}

	private get guidelines_content() {
		const {guidelines} = this.props;

		return guidelines.length ? (
			<React.Fragment>
				<HelpTitle>
					{window.getTranslations("help_list_label_guidelines")}
				</HelpTitle>

				{map(guidelines, (item, key) => (
					<HelpContent
						key={key}
						dangerouslySetInnerHTML={{
							__html: get(item, "content", ""),
						}}
					/>
				))}
			</React.Fragment>
		) : (
			<LoadingSpinner />
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	guidelines: getGuidelines(state),
	ivw_tracking: getIVWTracking,
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	fetchGuidelines,
};

export const GameGuidelines = connect(
	mapStateToProps,
	mapDispatchToProps
)(GameGuidelinesComponent);
export default withHeader(GameGuidelines);
