import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IAuthState, IGigyaUserData, IRequestData} from "../../types";
import {IModelUser} from "../../types/user";
import {getUserObjectFromStorage} from "../../utils/user";
import GigyaWrapper from "gigya-api-wrapper";

const GIGYA_API_KEY = process.env.REACT_APP_GIGYA_API_KEY || "";

export interface IProfileInfo {
	user_id: number;
	team_supported: number;
	disable_notifications: number;
	news: number;
	weekly_results: number;
	weekly_reminders: number;
	recovered_at: number | null;
	disable_tooltip: number;
}

export interface IUpdateUserSuccess {
	user: any;
	team_supported: string | undefined;
}

export interface IUserReducerState
	extends IModelUser,
		IRequestData,
		IAuthState {
	readonly gigyaWrapperInstance: typeof GigyaWrapper | null;
	readonly gigyaSSOUserData: IGigyaUserData | null;
	readonly gigya_is_ready: boolean;
	readonly profile_info: IProfileInfo | null;
}

const LANG_KEY = process.env.REACT_APP_LANG_KEY || "";

const defaultState: IUserReducerState = {
	is_logged_in: JSON.parse(localStorage.getItem("is_authorized") || "false"),
	additional_information_required: false,
	gigyaWrapperInstance: new GigyaWrapper({
		apiKey: GIGYA_API_KEY,
		lang: LANG_KEY,
		containerID: "gigya-login-wrapper",
		autoLogin: true,
		debug: false,
		https: window.location.protocol === "https:",
	}),
	gigyaSSOUserData: null,
	gigya_is_ready: false,
	profile_info: null,
	...getUserObjectFromStorage(),
};

const onUserRequested = (state: IUserReducerState) => ({
	...state,
	is_data_requested: true,
});

const onGigyaIsReady = (state: IUserReducerState) => ({
	...state,
	gigya_is_ready: true,
});

const onUserReceived = (state: IUserReducerState, payload: IModelUser) => ({
	...state,
	...payload,
	is_logged_in: true,
	additional_information_required: false,
	is_data_requested: false,
});

const onLostUserData = (state: IUserReducerState) => ({
	...state,
	is_logged_in: false,
	is_data_requested: false,
});

export const user = createReducer<IUserReducerState>({}, defaultState)
	.on(actions.gigyaIsReady, onGigyaIsReady)
	.on(actions.userLogin, onUserRequested)
	.on(actions.fetchUser, onUserRequested)
	.on(actions.userLoginSuccess, onUserReceived)
	.on(actions.fetchUserSuccess, onUserReceived)
	.on(actions.userLoginFailed, onLostUserData)
	.on(actions.userLogoutSuccess, onLostUserData)
	.on(actions.userRegistrationSuccess, onUserReceived)
	.on(actions.additionalInformationRequired, (state) => ({
		...state,
		additional_information_required: true,
	}))
	.on(actions.setSSOUser, (state, payload) => ({
		...state,
		gigyaSSOUserData: payload,
	}))
	.on(
		actions.acceptTheConditionsSuccess,
		(state: IUserReducerState, payload: IModelUser) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.disableTooltipSuccess,
		(state: IUserReducerState, payload: IModelUser) => ({
			...state,
			...payload,
		})
	)
	.on(
		actions.enableTooltipSuccess,
		(state: IUserReducerState, payload: IModelUser) => ({
			...state,
			...payload,
		})
	)
	.on(actions.showTooltip, (state: IUserReducerState) => ({
		...state,
		disable_tooltip: 0,
	}))
	.on(actions.hideTooltip, (state: IUserReducerState) => ({
		...state,
		disable_tooltip: 1,
	}))
	.on(
		actions.showMyProfileSuccess,
		(state: IUserReducerState, payload: IProfileInfo) => ({
			...state,
			profile_info: payload,
		})
	)
	.on(
		actions.updateUserSuccess,
		(
			state: IUserReducerState,
			{user: new_user_data, team_supported}: IUpdateUserSuccess
		) => ({
			...state,
			...new_user_data,
			profile_info: {
				team_supported,
			},
		})
	);

export default user;
