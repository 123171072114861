import * as React from "react";
import {ReactNode} from "react";
import styled from "styled-components";
import theme from "../../assets/css/theme";
import {below} from "../../assets/css/media";
import {Ad} from "../Ad";
import {Exist} from "components";
import {Syndicates} from "modules/utils/enums";
import mx_advert from "assets/img/sponsor/mx/mx_advert.png";

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

interface IWrapperWithSidebar {
	readonly offset_up?: boolean;
	readonly padding?: string;
	readonly position: string;
	readonly section_background?: string;
	readonly section_padding?: string;
}

const WrapperWithSidebar = styled.main`
	display: flex;
	flex-direction: ${({position}: IWrapperWithSidebar) =>
		position === "right" ? "row" : "row-reverse"};
	width: 100%;
	max-width: ${theme.content_width};
	margin: 50px auto;
	box-sizing: border-box;
	padding: 0 20px;
	position: relative;
	align-items: flex-start;
	top: ${({offset_up}: IWrapperWithSidebar) => (offset_up ? "-185px" : 0)};

	${below.desktop`
		width: 100%;
		margin: 0;
		padding: 0;
		flex-direction: column;
	`} section {
		flex: 69%;
		width: 69%;
		${({position}: IWrapperWithSidebar) =>
			position === "right"
				? "margin-right: 2.5%;"
				: "margin-left: 2.5%;"};
		background-color: ${({section_background}: IWrapperWithSidebar) =>
			section_background ? section_background : "transparent"};
		padding: ${({section_padding}: IWrapperWithSidebar) =>
			section_padding ? section_padding : 0};
		border-radius: 4px;
		box-shadow: 0 1px 2px 1px #ececec;
		${below.desktop`
			flex: 1;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		`};
	}
	aside {
		flex: 28.5%;
		${below.desktop`
			flex: 1;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
			margin-top: 10px;
		`};
		div:first-child {
			margin-top: 0;
		}
	}

	&.low-margin-bottom {
		margin-bottom: 0;
	}
`;

interface IWrapperSkeleton {
	readonly children: React.ReactNode;
	readonly empty_sidebar?: boolean;
	readonly offset_up?: boolean;
	readonly sidebar_components?: ReactNode[];
	readonly padding?: string;
	readonly position?: string;
	readonly section_background?: string;
	readonly section_padding?: string;
	readonly hidden_sidebar_on_mobile?: boolean;
	className?: string;
}

export const ContentWithSidebar = ({
	children,
	empty_sidebar,
	sidebar_components = [],
	offset_up,
	padding,
	position = "right",
	section_background,
	section_padding,
	hidden_sidebar_on_mobile,
	className,
}: IWrapperSkeleton) => (
	<WrapperWithSidebar
		padding={padding}
		offset_up={offset_up}
		position={position}
		section_background={section_background}
		section_padding={section_padding}
		className={className}
	>
		<section>{children}</section>
		<aside className={hidden_sidebar_on_mobile ? "hidden-mobile" : ""}>
			<Exist when={GAME_NAME !== "UK" && GAME_NAME !== Syndicates.MX}>
				<Ad />
			</Exist>
			<Exist when={GAME_NAME === Syndicates.MX}>
				<a
					href="https://mexico.littlecaesars.com/es-mx/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={mx_advert} alt="" />
				</a>
			</Exist>
			{empty_sidebar
				? null
				: sidebar_components.map((component) => component)}
		</aside>
	</WrapperWithSidebar>
);
