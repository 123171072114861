import React from "react";

interface IProps {
	when: boolean;
}
export const Exist: React.FC<IProps> = ({when, children}) => {
	if (!when) {
		return null;
	}

	return <React.Fragment>{children}</React.Fragment>;
};
