import * as React from "react";
import styled from "styled-components";
import logo from "../../assets/img/Primary-Raw.svg";
import logo_de from "../../assets/img/Logo1.svg";
import {GAME_NAME} from "modules/utils";
import {Syndicates} from "modules/utils/enums";

const NFLLogoImg = styled.img`
	width: 183px;
	height: 208px;
`;

export const NFLLogo = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
	<NFLLogoImg {...props} />
);

NFLLogo.defaultProps = {
	src: [Syndicates.DE, Syndicates.DE_RTL].includes(GAME_NAME as Syndicates) ? logo_de : logo,
	alt: "NFL Logo",
};
