import * as React from "react";
import {Redirect} from "react-router";
import {withHeader} from "../../components";
import HelpPagesWrapper from "./helpPagesWrapper";
import {connect} from "react-redux";
import {IPrizes, IStoreState} from "../../modules/types";
import {getOmnitureTracking} from "../../modules/selectors";
import {getPrizes} from "../../modules/selectors/jsons";
import {fetchPrizes} from "../../modules/actions";
import {get, map} from "lodash";
import {HelpContent, HelpTitle} from "./help";
import {LoadingSpinner} from "../../components";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";

interface IProps {
	prizes: IPrizes[];
	fetchPrizes: typeof fetchPrizes;
	header_hidden?: boolean;
	readonly omniture_tracking: any;
}

export class PrizesPoolComponent extends React.Component<IProps> {
	public componentDidMount(): void {
		const {fetchPrizes: _fetchPrizes, omniture_tracking} = this.props;

		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("pick em", "helpModal", "PrizesPool")
			);
		}
		createFanHubEvent("87ce9836-7427-4788-a9e4-7a60dd07fee1");
		_fetchPrizes();
	}

	public render() {
		const {header_hidden} = this.props;
		if (process.env.REACT_APP_GAME_NAME === "Mexico") {
			return <Redirect to="/help" />;
		}

		return header_hidden ? (
			this.prizes_content
		) : (
			<HelpPagesWrapper
				title={window.getTranslations("pickem_nav_button_prizes" || "")}
			>
				{this.prizes_content}
			</HelpPagesWrapper>
		);
	}

	private get prizes_content() {
		const {prizes} = this.props;

		return prizes.length ? (
			<React.Fragment>
				<HelpTitle>
					{window.getTranslations("pickem_nav_button_prizes")}
				</HelpTitle>
				<div className="prize-wrapper">
					{map(
						prizes,
						(item, key) =>
							!item.tags && (
								<HelpContent
									key={key}
									dangerouslySetInnerHTML={{
										__html: get(item, "text", ""),
									}}
								/>
							)
					)}
				</div>
			</React.Fragment>
		) : (
			<LoadingSpinner />
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	prizes: getPrizes(state),
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	fetchPrizes,
};

export const PrizesPool = connect(
	mapStateToProps,
	mapDispatchToProps
)(PrizesPoolComponent);
export default withHeader(PrizesPool);
