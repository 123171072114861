import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IRequestData} from "../../types";

export interface ILeague {
	id: number;
	commissioner: number;
	first_name: string;
	last_name: string;
	name: string;
	status: "scheduled" | "active" | "complete" | "playing";
	privacy: "private" | "public";
	code: string;
	start_round: number;
	coaches_box: number;
	num_teams: number;
	avatar_version: number;
	start_at: Date;
	user_status: "commissioner" | "user";
	avg_score: number;
	rank: number;
	round_points: number;
	total_points: number;
	total_margin: number;
	class: string;
}

export interface IMyLeague {
	id: number;
	commissioner: number;
	name: string;
	status: "scheduled" | "active" | "complete" | "playing";
	class: "overall" | any;
	class_id: number;
	rank: number;
	avg_score: number;
	avg_margin: number;
	score: number;
	avg_last_round_score: number;
	avg_last_round_margin: number;
	last_round_score: number;
	start_round: number;
	privacy: "private" | "public";
	code: string;
	num_teams: number;
	coaches_box: number;
	avatar_version: number;
	regenerated_from: number;
	description: string;
	start_at: Date;
	user_status: "commissioner" | "user";
	total: number;
}

export interface ILeagueStateReducer extends IRequestData {
	items: ILeague[];
	next: boolean;
}

const initialState: ILeagueStateReducer = {
	items: [],
	next: false,
	is_data_requested: false,
};

export const my_leagues = createReducer({}, initialState)
	.on(actions.showMyLeagues, (state: ILeagueStateReducer) => ({
		...state,
		is_data_requested: true,
	}))
	.on(
		actions.showMyLeaguesSuccess,
		(state: ILeagueStateReducer, payload: ILeagueStateReducer) => ({
			...state,
			items: [...payload.items],
			next: payload.next,
			is_data_requested: false,
		})
	)
	.on(actions.showMyLeaguesFailed, (state: ILeagueStateReducer) => ({
		...state,
		is_data_requested: false,
	}));
