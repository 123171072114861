import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import * as actions from "../../actions";
import Api from "../../utils/Api";

export const fetchShowBannerSaga = function* (): SagaIterator {
	try {
		const result = yield call(Api.JSON.show_banner);

		if (result) {
			yield put(actions.fetchBannerJSONSuccess(result));
		}
	} catch (err) {
		yield put(actions.fetchBannerJSONFailed(err));
		yield put(actions.errorsGlobalError(err));
	}
};

export default fetchShowBannerSaga;
