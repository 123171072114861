import styled from "styled-components";
import theme from "../../assets/css/theme";
import {below} from "../../assets/css/media";
import {NFLLogo} from "../../components";

import {NavLink} from "react-router-dom";

import LandingBackground from "../../assets/img/landing-background.jpg";
import LandingBackgroundMobile from "../../assets/img/landing-background-mobile.jpg";
import canadaRegistrationBackground from "../../assets/img/canadaRegistrationBackground.jpg";
import {get} from "lodash";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

const getLandingBackground = () => {
	const variantsOfBackground = {
		Canada: canadaRegistrationBackground,
	};

	return get(variantsOfBackground, GAME_NAME, LandingBackground);
};

const getMobileLandingBackground = () => {
	const variantsOfBackground = {
		Canada: canadaRegistrationBackground,
	};

	return get(variantsOfBackground, GAME_NAME, LandingBackgroundMobile);
};

export const LandingWrapper = styled.div`
	background: url(${getLandingBackground()});
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: ${theme.base_font};

	${below.desktop`
		background-image: url(${getMobileLandingBackground()}) no-repeat;
	`};
`;

export const Logo = styled(NFLLogo)`
	margin-top: -105px;
`;

export const WelcomeBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 380px;
	background-color: #ffffff;
	border-radius: 6px;
	margin: 200px 0 50px 0;

	${below.desktop`
		width: 340px;
	`};
`;

export const WelcomeText = styled.div`
	color: #333333;
	font-size: 16px;
	max-width: 340px;
	text-align: center;
	font-family: ${theme.base_bold_font};
	text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	letter-spacing: -0.1px;
	line-height: 20px;
	margin: 30px 20px 20px;
`;

export const CreateAccountButton = styled.button`
	display: block;
	color: #ffffff;
	height: 40px;
	width: 202.67px;
	border: 1px solid ${theme.form_elements_color};
	border-radius: 4px;
	background-color: ${theme.form_elements_color};
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	text-transform: uppercase;
	margin: 35px auto;
	cursor: pointer;
	text-align: center;
`;

export const LoginBlock = styled.div`
	color: #151515;
	margin-bottom: 40px;
	font-size: 13px;
	padding: 0 20px;
	text-align: center;
`;

export const SignIn = styled(NavLink)`
	text-decoration: underline;
`;

export const RegisterForm = styled.form`
	width: 100%;
`;

export const ElementWrapper = styled.div`
	margin: 20px 20px;
`;

export const ErrorBlock = styled.div`
	color: red;
	font-size: 12px;
`;

export const CheckboxWrapper = styled.div`
	display: flex;
`;
