import {IStoreState, IUserReducerState} from "../types";
import {eq} from "lodash";

export const isLoggedIn = ({user}: IStoreState) => user.is_logged_in;
export const isAdditionalInformationRequired = ({user}: IStoreState) =>
	user.additional_information_required;
export const isConditionsAreAccepted = ({user}: IStoreState) => user;
export const isNeedRegisterForGame = ({user}: IStoreState): boolean =>
	eq(user.recovered, 0);
export const getUserId = (state: IStoreState): number => state.user.id;
export const getUser = ({user}: IStoreState): IUserReducerState => user;
export const getSSOUser = ({user}: IStoreState) => user.gigyaSSOUserData;
