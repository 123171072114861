import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {ISaveMyTips, IUserTips} from "../../types";

export interface ITip {
	id: number;
	predict_winner_squad_id: number;
	is_draw: number;
	is_correct: number;
	is_autogenerate: number;
	hidden: number;
	predict_margin: number;
}

export interface IMatchRoundStats {
	away_percent: number;
	away_squad_id: number;
	away_squad_name: string;
	count_away: number;
	count_draw: number;
	count_home: number;
	draw_percent: number;
	home_percent: number;
	home_squad_id: number;
	home_squad_name: string;
	match_id: number;
}

export interface ITipsState {
	tips_data: ITip[] | [];
	selected_tip: IUserTips | null;
	tips_saved: boolean;
	match_round_stats: IMatchRoundStats[] | [];
	selected_tips: ISaveMyTips[];
}

const initialState: ITipsState = {
	tips_data: [],
	match_round_stats: [],
	selected_tips: [],
	selected_tip: null,
	tips_saved: false,
};

// const saveTipsSuccess = (state: ITipsState) => ({
// 	...state,
// 	tips_saved: false,
// });

const saveTips = (state: ITipsState) => ({
	...state,
	tips_saved: false,
});

const saveTipsSuccess = (state: ITipsState) => ({
	...state,
	tips_saved: true,
});

export const tips = createReducer({}, initialState)
	.on(actions.selectTip, (state: ITipsState, payload: ISaveMyTips) => {
		const selected_tips = state.selected_tips;
		selected_tips.push(payload);
		return {
			...state,
			selected_tips,
		};
	})
	.on(actions.selectTips, (state: ITipsState, payload: ISaveMyTips[]) => {
		return {
			...state,
			selected_tips: payload,
		};
	})
	.on(actions.showMyTipsSuccess, (state: ITipsState, payload: ITip[]) => ({
		...state,
		tips_data: payload,
		tips_saved: false,
	}))
	.on(actions.setSelectedTip, (state: ITipsState, payload: IUserTips) => ({
		...state,
		selected_tip: payload,
	}))
	.on(actions.clearSelectedTip, (state: ITipsState) => ({
		...state,
		selected_tip: null,
	}))
	.on(
		actions.fetchRoundStatsSuccess,
		(state: ITipsState, payload: IMatchRoundStats[]) => ({
			...state,
			match_round_stats: payload,
		})
	)
	.on(actions.saveMyListTips, saveTips)
	.on(actions.saveMyTips, saveTips)
	.on(actions.autopickMyTips, saveTips)
	.on(actions.saveMyTipsSuccess, saveTipsSuccess)
	.on(actions.saveMyListTipsSuccess, saveTipsSuccess)
	.on(actions.autopickMyTipsSuccess, saveTipsSuccess);

// .on(actions.saveMyTipsSuccess, saveTipsSuccess)
// .on(actions.autopickMyTipsSuccess, saveTipsSuccess)
// .on(actions.saveMyListTipsSuccess, saveTipsSuccess)
