import {createReducer} from "redux-act";
import * as actions from "../../actions";

interface ISnapshotRound {
	id: number;
	status: string;
	start: string;
	end: string;
	bye_squads: string;
	is_bye: number;
	lifted_at: number;
}

export interface ISnapshot {
	last_round: ISnapshotRound;
	next_round: ISnapshotRound;
	correct_last_round: number;
	avg_last_round: number;
	user_correct_total: string;
	rank_last_round: number;
	overall_rank: number;
	total_users_ranked: number;
	matches: number;
	total_margin: number;
}

const defaultSnapshotRound = {
	id: 0,
	status: "",
	start: "",
	end: "",
	bye_squads: "",
	is_bye: 0,
	lifted_at: 0,
};

const initialState: ISnapshot = {
	last_round: defaultSnapshotRound,
	next_round: defaultSnapshotRound,
	correct_last_round: 0,
	avg_last_round: 0,
	user_correct_total: "",
	rank_last_round: 0,
	overall_rank: 0,
	total_users_ranked: 0,
	matches: 0,
	total_margin: 0,
};

export const snapshot = createReducer<ISnapshot>({}, initialState).on(
	actions.fetchSnapshotSuccess,
	(state, payload: ISnapshot) => payload
);

export default snapshot;
