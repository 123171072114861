import * as React from "react";
import {IStoreState} from "../../modules/types";
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import GigyaWrapper from "gigya-api-wrapper";
import styled from "styled-components";
import {connect} from "react-redux";
import theme from "../../assets/css/theme";
import {map, bindAll} from "lodash";
import {IconArrowSlim} from "../../components";

import {above} from "../../assets/css/media";
import {isLoggedIn} from "../../modules/selectors";
import {IMainMenuItem, INavigation} from "../../modules/reducers/navigation";
import MenuHamburgerIcon from "../../assets/img/icons/menu_hamburger.svg";
import CloseIcon from "../../assets/img/icons/close_Icon.svg";

const BaseMenuWrapper = styled.div`
	font-family: ${theme.base_font};
	margin-left: auto;
	${above.desktop`
		display: none;
	`};
`;

interface IMobileMenu {
	readonly is_show: boolean;
	readonly is_logged_in: boolean;
}

const MobileMenu = styled.div`
	position: fixed;
	left: 0;
	top: 62px;
	width: 100%;
	height: calc(100% - 62px);
	background: #2a7dc3;
	overflow-x: scroll;
	display: ${({is_show}: IMobileMenu) => (is_show ? "block" : "none")};
`;

interface INavItem {
	readonly hidden: boolean;
}

const NavItem = styled(NavLink)`
	display: ${({hidden}: INavItem) => (hidden ? "none" : "flex")};
	height: 50px;
	align-items: center;
	justify-content: center;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	text-transform: uppercase;
	position: relative;

	svg {
		top: 18px;
		margin-left: 15px;
	}

	&.active {
		background: #3fbaf9;
	}
`;

const SubNavItem = styled(NavItem)`
	font-size: 12px;
	background: #2f8ad2;
	text-transform: initial;
	font-family: ${theme.base_font};
`;

const ExternalNavItem = styled.a`
	display: ${({hidden}: INavItem) => (hidden ? "none" : "flex")};
	height: 50px;
	align-items: center;
	justify-content: center;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	text-transform: uppercase;
	position: relative;
`;

const ButtonNavItem = styled.button`
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: center;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	text-transform: uppercase;
	position: relative;
	background: none;
	outline: none;
	margin: 0 auto;
	border: none;
`;

const MenuHamburger = styled.img`
	width: 30px;
	height: 30px;
	margin-right: 10px;
	cursor: pointer;
`;

const CloseBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 64px;
	height: 64px;
`;

const CloseLogoutBlock = styled(CloseBlock)`
	position: absolute;
	left: 10px;
`;

const CloseBlockIcon = styled.img`
	width: 17px;
	height: 17px;
	cursor: pointer;
`;

const LogoutWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	height: 70px;
	width: 100%;
	background: #161616;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LogOut = styled.button`
	border: none;
	text-transform: uppercase;
	background: transparent;
	color: #fff;
	font-size: 16px;
	font-family: ${theme.base_bold_font};
`;

const OFF_SEASON_ENABLED = JSON.parse(
	process.env.REACT_APP_OFF_SEASON || "false"
);

interface IState {
	show_menu: boolean;
}

interface IProps extends RouteComponentProps {
	readonly is_logged_in: boolean;
	readonly navigation: INavigation;
	gigyaWrapperInstance: typeof GigyaWrapper;
}

export class MobileNavigationComponent extends React.Component<IProps, IState> {
	public state = {
		show_menu: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["ToggleMenu", "handleLogOut", 'handleLogin']);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {
			is_logged_in,
			navigation: {main_menu},
		} = this.props;
		const {show_menu} = this.state;
		const WIDTH_ARROW = 14;

		if (OFF_SEASON_ENABLED) {
			return null;
		}

		return (
			<BaseMenuWrapper>
				{!show_menu ? (
					<MenuHamburger
						src={MenuHamburgerIcon}
						alt="Hamburger Menu"
						onClick={this.ToggleMenu}
					/>
				) : (
					<CloseBlock>
						<CloseBlockIcon
							src={CloseIcon}
							alt="Close"
							color="#ffffff"
							onClick={this.ToggleMenu}
						/>
					</CloseBlock>
				)}

				<MobileMenu is_show={show_menu} is_logged_in={is_logged_in}>
					{map(main_menu, (item: IMainMenuItem, key: number) => (
						<React.Fragment key={key}>
							{item.external ? (
								<ExternalNavItem
									href={item.url}
									key={key}
									target="_blank"
									hidden={item.url === ""}
								>
									{window.getTranslations(item.title)}
								</ExternalNavItem>
							) : (
								<NavItem
									exact={true}
									to={item.url}
									hidden={item.only_is_login && !is_logged_in}
								>
									{window.getTranslations(item.title)}

									{item.sub_menu ? (
										<IconArrowSlim
											color="#ffffff"
											to="bottom"
											width={WIDTH_ARROW}
										/>
									) : null}
								</NavItem>
							)}

							{item.sub_menu
								? map(item.sub_menu, (sub_item, sub_key) => (
										<SubNavItem
											key={sub_key}
											to={sub_item.url}
											hidden={
												item.only_is_login &&
												!is_logged_in
											}
										>
											{window.getTranslations(
												sub_item.title
											)}
										</SubNavItem>
								  ))
								: null}
						</React.Fragment>
					))}
					{is_logged_in ? (
						this.logout_block
					) : (
						<ButtonNavItem onClick={this.handleLogin}>
							{window.getTranslations("fld_login")}
						</ButtonNavItem>
					)}
				</MobileMenu>
			</BaseMenuWrapper>
		);
	}

	public handleLogin() {
		this.props.gigyaWrapperInstance.gigya.sso.login({
			authFlow: "redirect",
		});
		return;
	}

	private get logout_block() {
		return (
			<LogoutWrapper>
				<CloseLogoutBlock>
					<CloseBlockIcon
						src={CloseIcon}
						alt="Close"
						color="#ffffff"
						onClick={this.ToggleMenu}
					/>
				</CloseLogoutBlock>
				<LogOut onClick={this.handleLogOut}>
					{window.getTranslations("account_form_button_signout")}
				</LogOut>
			</LogoutWrapper>
		);
	}

	private handleLogOut() {
		this.props.gigyaWrapperInstance.logout();
	}

	private ToggleMenu() {
		const {show_menu} = this.state;
		this.setState({show_menu: !show_menu});
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: isLoggedIn(state),
	navigation: state.navigation,
	gigyaWrapperInstance: state.user.gigyaWrapperInstance,
});

export const MobileNavigation = withRouter(
	connect(mapStateToProps)(MobileNavigationComponent)
);

export default MobileNavigation;
