import * as React from "react";
import {
	ErrorField,
	Input,
	Label,
	LeagueToggleButton,
	LeagueWrapper,
	Radio,
	RegularButton,
	Select,
	Textarea,
} from "../../../components";
import {bindAll, each, find, map, set} from "lodash";
import styled from "styled-components";
import {below} from "../../../assets/css/media";
import theme from "../../../assets/css/theme";
import {CreateLeagueValidate} from "modules/utils/FormValidations/Registration";
import {IGameWeek, IMyLeague} from "modules/reducers";
import {connect} from "react-redux";
import {
	createLeague,
	fetchGameWeeksJSON,
	updateLeague,
} from "../../../modules/actions";
import {
	getGameWeekIdsForLeagueCreate,
	getScheduledGameWeek,
	getUser,
} from "../../../modules/selectors";
import {IFunc0, IModelUser, IStoreState} from "../../../modules/types";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../../../modules/utils/Сonnextra/connextra";
import {createFanHubEvent} from "../../../modules/utils/FanHubTagging";

const CreateLeagueWrapper = styled(LeagueWrapper)`
	${below.desktop`
		padding:28px 17px;
	`};
`;

const Title = styled.h4`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${below.desktop`
		font-size: 20px;
		line-height: 24px;
	`};
`;

const Description = styled.div`
	color: #555555;
	font-size: 14px;
	font-family: ${theme.base_font};
	line-height: 22px;
`;

const CreateForm = styled.form``;

const ElementWrapper = styled.div`
	margin: 20px 0;
`;

const CancelButton = styled(LeagueToggleButton)``;

interface ICreateLeagueForm {
	name: string;
	privacy?: string;
	start_round?: string;
	description?: string;
}

interface IState {
	form_element: ICreateLeagueForm;
	form_errors: ICreateLeagueForm;
}

interface IProps {
	gameweek_ids: number[] | [];
	actual_gameweek?: IGameWeek;
	createLeague: typeof createLeague;
	fetchGameWeeksJSON: typeof fetchGameWeeksJSON;
	updateLeague: typeof updateLeague;
	league?: IMyLeague;
	closeForm?: IFunc0;
	user: IModelUser;
}

class CreateLeagueFormComponent extends React.Component<IProps, IState> {
	public state = {
		form_element: {
			name: "",
			privacy: "private",
			start_round: "",
			description: "",
		},
		form_errors: {
			name: "",
		},
	};

	constructor(props: IProps) {
		super(props);

		bindAll(this, [
			"handleChange",
			"handleSubmit",
			"clearField",
			"initFormElement",
		]);
	}

	/**
	 * @ignore
	 */
	public componentWillMount(): void {
		this.checkGameweek();
	}

	public initFormElement() {
		const {league} = this.props;

		if (league && league.id) {
			this.setState({
				form_element: {
					name: league.name,
					privacy: league.privacy,
					start_round: league.start_round.toString(),
					description: league.description,
				},
			});
		}
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {fetchGameWeeksJSON: fetchGameWeeks, user} = this.props;
		createConnextraScriptTag(ConnextraType.LEAGUE_START, user);

		createFanHubEvent("44573bae-dcb5-4bda-88e3-c5b57eb88a2b");

		fetchGameWeeks();
		this.initFormElement();
	}

	public componentDidUpdate(
		prevProps: Readonly<IProps>,
		prevState: Readonly<IState>,
		snapshot?: any
	): void {
		if (prevProps.league !== this.props.league) {
			this.initFormElement();
		}
	}

	public render() {
		const {form_errors, form_element} = this.state;
		const {gameweek_ids, league, closeForm} = this.props;

		return (
			<CreateLeagueWrapper>
				<Title>
					{window.getTranslations(
						league
							? window.getTranslations(
									"myleagues_header_update_league"
							  )
							: "competitioncreate_form_button_create"
					)}
					{league && (
						<CancelButton onClick={closeForm}>
							{window.getTranslations(
								"leagueinvite_form_button_cancel"
							)}
						</CancelButton>
					)}
				</Title>
				<Description>
					{window.getTranslations("createleague_form_text_form")}
					{/*<p>Fill out the short form below to create</p>*/}
					{/*your very own league.*/}
				</Description>
				<CreateForm
					onSubmit={this.handleSubmit}
					onChange={this.handleChange}
				>
					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"competitioncreate_form_label_league_name"
							)}
						</Label>
						{form_errors.name !== "" ? (
							<ErrorField>{form_errors.name}</ErrorField>
						) : null}
						<Input
							name="name"
							type="text"
							value={form_element.name}
							is_error={form_errors.name !== ""}
							placeholder={window.getTranslations(
								"competitioncreate_form_label_league_name"
							)}
							clearField={this.clearField}
						/>
					</ElementWrapper>

					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"leagueinvite_dropdown_starting_gameweek"
							)}
							*
						</Label>
						<Select
							name="start_round"
							value={form_element.start_round}
						>
							{map(gameweek_ids, (id) => (
								<option key={id} value={id}>
									{window.getTranslations(
										"pickem_gamebar_game_week"
									)}{" "}
									{id}
								</option>
							))}
						</Select>
					</ElementWrapper>
					<ElementWrapper>
						<Label>{window.getTranslations("fld_privacy")}*</Label>
						<Radio
							name="privacy"
							current_value={form_element.privacy}
							options={[
								{
									value: "private",
									text: window.getTranslations("private"),
								},
								{
									value: "public",
									text: window.getTranslations("public"),
								},
							]}
						/>
					</ElementWrapper>

					<ElementWrapper>
						<Label>
							{window.getTranslations(
								"leaguecreate_form_label_description"
							)}
						</Label>
						<Textarea
							value={form_element.description}
							name="description"
						/>
					</ElementWrapper>
					<RegularButton max_width="200px">
						{window.getTranslations(
							league
								? "account_form_button_update"
								: "mycomps_header_button_create"
						)}
					</RegularButton>
				</CreateForm>
			</CreateLeagueWrapper>
		);
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, value} = event.target as HTMLFormElement;

		const {form_element} = this.state;

		this.validateFormItem(name, value);

		set(form_element, name, value);
		this.setState({form_element});
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		const {
			createLeague: create,
			updateLeague: update,
			league,
			user,
		} = this.props;
		const {form_element} = this.state;

		this.validateForm();

		if (!this.check_is_form_have_error) {
			if (league) {
				update({
					id: league.id,
					...form_element,
				});
			} else {
				if (window._satellite) {
					window._satellite.track("createLeague");
				}

				createFanHubEvent("2efe5dcb-89bf-4c73-a052-140182cdb7ac");

				createConnextraScriptTag(ConnextraType.LEAGUE_CONFIRM, user);
				create(form_element);
			}
		}
	}

	private validateForm() {
		const {form_element} = this.state;

		each(form_element, (value, name) => {
			this.validateFormItem(name, value);
		});
	}

	private validateFormItem(name: string, value: string) {
		const {form_errors} = this.state;

		if (name === "name") {
			set(form_errors, name, CreateLeagueValidate[name](value));
			this.setState({form_errors});
		}
	}

	private clearField({
		currentTarget,
	}: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const {element_name} = currentTarget.dataset;
		const {form_element} = this.state;

		if (element_name) {
			set(form_element, element_name, "");
			this.setState({form_element});
		}
	}

	private get check_is_form_have_error() {
		const {form_errors} = this.state;
		return Boolean(find(form_errors, (error) => error !== ""));
	}

	private checkGameweek() {
		const {actual_gameweek} = this.props;
		const {form_element} = this.state;

		if (form_element.start_round === "" && actual_gameweek) {
			form_element.start_round = actual_gameweek.id.toString();
			this.setState({form_element});
		}
	}
}

const mapStateToProps = (state: IStoreState) => ({
	gameweek_ids: getGameWeekIdsForLeagueCreate(state),
	actual_gameweek: getScheduledGameWeek(state),
	user: getUser(state),
});

const mapDispatchToProps = {
	createLeague,
	updateLeague,
	fetchGameWeeksJSON,
};

export const CreateLeagueForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateLeagueFormComponent);
export default CreateLeagueForm;
