import styled, {css} from "styled-components";
import theme from "../../../assets/css/theme";
import * as React from "react";
import {below} from "../../../assets/css/media";
import {SponsorImage} from "components";

const SPONSOR = process.env.REACT_APP_SPONSOR || "";

const LeagueHeaderName = styled.p`
	color: #fafafa;
	font-size: 14px;
	line-height: 17px;
	font-family: ${theme.base_bold_font};
	margin-right: 15px;

	${below.desktop`
		font-size: 12px;
	`};
`;

interface ILeagueHeaderPrivacy {
	status_color?: string;
}

const LeagueHeaderPrivacy = styled.p<ILeagueHeaderPrivacy>`
	font-family: ${theme.base_font};
	color: ${({status_color}) => (status_color ? status_color : "#ccc")};
	font-size: 12px;
	line-height: 14px;
	text-transform: capitalize;
`;

const LeagueHeaderUserStatus = styled.div`
	margin-left: auto;
	font-size: 12px;
	color: #ccc;
	line-height: 22px;
`;

interface ILeagueHeaderWrapper {
	is_scheduled: boolean;
	color?: string;
	width?: string;
	is_sponsor_league?: boolean;
}

const LeagueHeaderWrapper = styled.div<ILeagueHeaderWrapper>`
	background-color: #000;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom: 1px solid #000;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 10px;
	box-sizing: border-box;
	${({is_scheduled}) =>
		is_scheduled &&
		css`
			background-color: #555;
			border-color: #555;
		`}
	${({is_sponsor_league}) =>
		is_sponsor_league &&
		css`
			background-color: ${theme.sponsor_league_color};
			border-color: ${SPONSOR === "pokerstars"
				? "#FBC010"
				: theme.sponsor_league_color};
		`}
	${({color}) =>
		color &&
		css`
			background-color: ${color};
			border-color: ${color};

			${LeagueHeaderName} {
				color: #151515;
			}
		`}
	width: ${({width}) => (width ? width : "100%")};

	${below.desktop`
		border-radius: 0;
	`};
`;

interface IProps {
	name: string;
	privacy: "private" | "public";
	is_commissioner?: boolean;
	status: "scheduled" | "active" | "complete" | "playing";
	color?: string;
	width?: string;
	status_color?: string;
	is_sponsor_league?: boolean;
}

export const LeagueHeader = ({
	name,
	privacy,
	is_commissioner,
	status,
	color,
	width,
	status_color,
	is_sponsor_league,
}: IProps) => (
	<LeagueHeaderWrapper
		width={width}
		color={color}
		is_scheduled={status === "scheduled"}
		is_sponsor_league={is_sponsor_league}
	>
		<LeagueHeaderName>{name}</LeagueHeaderName>
		<LeagueHeaderPrivacy status_color={status_color}>
			{privacy} {window.getTranslations("competitionjoin_th_league")}
		</LeagueHeaderPrivacy>
		<LeagueHeaderUserStatus>
			{is_commissioner &&
				window.getTranslations("leagues_myleague_text_commissioner")}
		</LeagueHeaderUserStatus>
		{is_sponsor_league && (
			<SponsorImage
				position="league"
				showPresentedBy={false}
				showWhiteImage={true}
			/>
		)}
	</LeagueHeaderWrapper>
);
