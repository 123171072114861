import * as React from "react";
import * as selectors from "../modules/selectors";
import {getOmnitureTracking} from "../modules/selectors";
import {connect} from "react-redux";
import {
	IAuthState,
	IGigyaUserData,
	IModelUser,
	IStoreState,
} from "../modules/types";
import {bindAll, each, find, omit, get, set} from "lodash";
import {Redirect} from "react-router";
import {withHeader} from "../components";
import {RegistrationValidate} from "../modules/utils/FormValidations";
import {userRegistration} from "../modules/actions";
import FormRegisterForGame, {
	IFormRegisterForGameState,
	defaultRegisterForGameState,
} from "./Registration/FormRegisterForGame";
import {
	ConnextraType,
	createConnextraScriptTag,
} from "../modules/utils/Сonnextra/connextra";
import {createFanHubEvent} from "../modules/utils/FanHubTagging";

const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";

interface IProps extends IAuthState {
	userRegistration: typeof userRegistration;
	ssoUser: IGigyaUserData | null;
	user: IModelUser;
	readonly omniture_tracking: any;
}

class RegisterForGameComponent extends React.Component<
	IProps,
	IFormRegisterForGameState
> {
	public state = defaultRegisterForGameState;

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["handleChange", "handleSubmit"]);
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		createFanHubEvent("9932e071-86e6-43d4-9b9f-48decd0aa07e");

		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				this.props.omniture_tracking("pick em", "login", "register 2")
			);
		}
		if (this.props.user) {
			this.setState({
				...this.state,
				form_element: {
					...this.state.form_element,
					team_name: this.props.user.team_name,
				},
			});
		}

		if (GAME_NAME === "Canada") {
			window.ftag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflpi0/nflpi004+standard",
				event_callback: () => {
					console.log("DC-10179195/nflpi0/nflpi004+standard");
				},
			});
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {form_errors, form_element} = this.state;

		const {is_logged_in} = this.props;

		if (is_logged_in && this.check_for_playing_game) {
			return <Redirect to="/pick-em" />;
		}

		return (
			<FormRegisterForGame
				isReturningUser={false}
				form_errors={form_errors}
				form_element={form_element}
				handleSubmit={this.handleSubmit}
				handleChange={this.handleChange}
			/>
		);
	}

	private get check_for_playing_game() {
		const {
			user: {playing_games},
		} = this.props;
		return playing_games.find((game) => game === "nfl_tipping");
	}

	private validateFormItem(name: string, value: string) {
		const {form_errors} = this.state;
		if (name === "terms" || name === "team_name") {
			set(form_errors, name, RegistrationValidate[name](value));
			this.setState({form_errors});
		}
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, value, checked, type} = event.target as HTMLFormElement;
		const {form_element} = this.state;

		if (type === "checkbox") {
			this.validateFormItem(name, checked ? "1" : "");
			set(form_element, name, checked ? "1" : "0");
			this.setState({form_element});
		} else {
			this.validateFormItem(name, value);
			set(form_element, name, value);
			this.setState({form_element});
		}
	}

	private validateForm() {
		const {form_element} = this.state;

		each(form_element, (value, name) => {
			this.validateFormItem(name, value);
		});
	}

	private get check_is_form_have_error() {
		const {form_errors} = this.state;
		return Boolean(find(form_errors, (error) => error !== ""));
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const {form_element} = this.state;
		const {userRegistration: registerForGame, ssoUser} = this.props;

		this.validateForm();

		if (!this.check_is_form_have_error && ssoUser) {
			const data = omit(form_element, ["terms"]);

			if (GAME_NAME === "Canada") {
				window.ftag("event", "conversion", {
					allow_custom_scripts: true,
					send_to: "DC-10179195/nflpi0/nflpi004+standard",
					event_callback: () => {
						console.log("DC-10179195/nflpi0/nflpi004+standard");
					},
				});
			}

			registerForGame({
				...ssoUser,
				...data,
			});
			createConnextraScriptTag(ConnextraType.REGISTRATION_CONFIRM);
			createFanHubEvent("8d6ca05a-b461-4b04-a466-186e1aa76648");
		} else {
			console.log("Error");
		}
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: selectors.isLoggedIn(state),
	ssoUser: selectors.getSSOUser(state),
	user: get(state, "user"),
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	userRegistration,
};

export const RegisterForGame = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(RegisterForGameComponent)
);

export default RegisterForGame;
