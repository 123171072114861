import * as React from "react";
const ukEmailTerms = (
	<div>
		<p>
			Yes, I would like to receive email communications from NFL to keep
			up-to-date with the latest ticket news, NFL events, NFL products and
			services, and much more.
		</p>
		<p>
			See{" "}
			<a
				href="https://www.nfl.com/legal/privacy/"
				target="_blank"
				rel="noopener noreferrer"
			>
				NFL’s Privacy Policy
			</a>{" "}
			for more information. You may withdraw your consent and stop
			receiving these messages at any time.
		</p>
	</div>
);

const caEmailTerms = (
	<div>
		<p>
			Click here if you consent to receive emails from the NFL about all
			NFL news, including news about its events, products and services,
			and any offers relating to those events, products and services. You
			may withdraw your consent and stop receiving the emails at any time.
			Please see NFL’s Privacy Policy for more information about how the
			NFL processes your personal data.
		</p>
	</div>
);

export const emailTerms = (gameLang: string) => {
	if (gameLang === "GB") {
		return ukEmailTerms;
	} else if (gameLang === "CA") {
		return caEmailTerms;
	} else {
		return window.getTranslations("register_form_label_accept_emails");
	}
};

export const getSponsorText = (gameLang: string) => {
	if (gameLang === "AU") {
		return "I would like to receive promotional information and offers directly from NFL's partners and other third parties who deal with NFL from time to time, including Tabcorp. I can always ask NFL to no longer share my information for these purposes, though I may need to unsubscribe directly from third parties who have already received this information.";
	} else if (gameLang === "CA") {
		return "I would like to receive promotional information and offers directly from NFL's partners and other third parties who deal with NFL from time to time. I can always ask NFL to no longer share my information for these purposes, though I may need to unsubscribe directly from third parties who have already received this information.";
	} else if (gameLang === "GB") {
		return "Email me NFL newsletters, offers and updates, including information about this game. I can always withdraw this consent";
	} else {
		return window.getTranslations(
			"register_returninguser_returning_sponsor_checkbox"
		);
	}
};
