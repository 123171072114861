import * as React from "react";
import {Close} from "../Icons/Close";
import {
	TooltipBubbleStyled,
	TooltipWrapDiv,
	TooltipTextDiv,
	NextTipButton,
	Button,
} from "./TooltipBublleStyle";
import {isUndefined} from "lodash";

interface IProps {
	onClose: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	updateTip?: () => void;
	to: string;
	text: string;
	bubble_name: string;
	hidden?: boolean;
	tipIndex: number;
	extraSelector?: string;
}

export const TooltipBubble = ({
	onClose,
	updateTip,
	to,
	text,
	bubble_name,
	hidden,
	tipIndex,
	extraSelector = "",
}: IProps) => {
	const updateAndClose = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		if (!isUndefined(updateTip)) {
			updateTip();
		}
		onClose(event);
	};
	return !hidden ? (
		<TooltipBubbleStyled
			className={`hidden-mobile ${extraSelector}`}
			to={to}
			bubble_name={bubble_name}
		>
			<TooltipWrapDiv>
				<Button onClick={onClose} data-bubble={bubble_name}>
					<Close />
				</Button>
				<TooltipTextDiv>{text}</TooltipTextDiv>
				{!isUndefined(updateTip) && (
					<NextTipButton
						onClick={tipIndex === 4 ? updateAndClose : updateTip}
						data-bubble={bubble_name}
					>
						{tipIndex === 4
							? window.getTranslations(
									"tutorial_button_finish_tour"
							  )
							: window.getTranslations(
									"tutorial_button_next_tip"
							  )}
					</NextTipButton>
				)}
			</TooltipWrapDiv>
		</TooltipBubbleStyled>
	) : null;
};

export default TooltipBubble;
