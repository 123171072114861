import * as React from "react";
import styled from "styled-components";
import theme from "../../assets/css/theme";

const RadioStyled = styled.input`
	display: none;
`;

const RadioButtonWrapper = styled.div`
	position: relative;
	display: inline-flex;
	width: 100%;

	&.disabled {
		&:after {
			content: "";
			background: #fff;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.3;
		}
	}

	@media screen and (max-width: 840px) {
		width: 100%;
	}
`;

interface IRadioButtonStyled {
	readonly isFirst: boolean;
	readonly isLast: boolean;
}

const RadioButtonStyled = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	border-top: 1px solid ${theme.radio_color};
	border-bottom: 1px solid ${theme.radio_color};
	background-color: #ffffff;
	color: ${theme.radio_color};
	flex: 1;
	cursor: pointer;
	font-weight: bold;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	text-transform: uppercase;

	@media screen and (max-width: 840px) {
		min-width: auto;
	}

	${({isFirst}: IRadioButtonStyled) =>
		isFirst
			? `border-left: 1px solid ${theme.radio_color};` +
			  "border-radius: 6px 0 0 6px;"
			: ""};

	${({isLast}: IRadioButtonStyled) =>
		isLast
			? `border-right: 1px solid ${theme.radio_color};` +
			  "border-radius: 0 6px 6px 0;"
			: ""};

	${RadioStyled}:checked + & {
		background-color: ${theme.radio_color};
		color: #ffffff;
	}
`;

interface IOption {
	readonly value: number | string;
	readonly text: string | undefined;
}

interface IProps {
	readonly name: string;
	readonly disabled?: boolean;
	readonly options: IOption[];
	readonly current_value: string;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const Radio = ({
	name,
	options,
	disabled,
	current_value,
	onChange,
}: IProps) => (
	<RadioButtonWrapper className={Boolean(disabled) ? "disabled" : ""}>
		{options
			? options.map(({value, text}, index) => (
					<React.Fragment key={index}>
						<RadioStyled
							type="radio"
							id={`${name}_${value}`}
							name={name}
							value={value}
							checked={value === current_value}
							onChange={onChange}
						/>
						<RadioButtonStyled
							htmlFor={`${name}_${value}`}
							isFirst={index === 0}
							isLast={index === options.length - 1}
						>
							{text}
						</RadioButtonStyled>
					</React.Fragment>
			  ))
			: null}
	</RadioButtonWrapper>
);

export default Radio;
