import * as React from "react";
import {
	ILeague,
	IPrizes,
	IRules,
	IStoreState,
	IModelUser,
} from "../modules/types";
import {connect} from "react-redux";
import {
	fetchGameWeeksJSON,
	fetchPrizes,
	fetchRules,
	showMyLeagues,
} from "../modules/actions";
import {
	getMyLeagues,
	isLeaguesDataRequested,
} from "modules/selectors/leagues";
import {
	getLastGameWeek,
	getActualGameWeeks,
	getOmnitureTracking,
	getIVWTracking,
} from "../modules/selectors";
import {map, size} from "lodash";
import {League, LeagueNav, LeagueNavButtons} from "./Leagues/myLeagues";
import {
	Content,
	ContentWithSidebar,
	LeagueCreateLink,
	LeagueJoinLink,
	LoadingSpinner,
	PrizesLink,
	RegularButton,
	RulesLink,
	Widget,
	withHeader,
} from "../components";
import {getFAQs, getPrizes, getRules} from "../modules/selectors/jsons";
import {IGameWeek} from "../modules/reducers";
import header_bg from "../assets/img/header_bg.png";
import header_bg_mobile from "../assets/img/header_bg_mobile.png";
import header_bg_uk from "../assets/img/header_bg_league_uk.jpg";
import {ConnextraType, createConnextraScriptTag} from "../modules/utils/Сonnextra/connextra";

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

interface IProps {
	showMyLeagues: typeof showMyLeagues;
	fetchPrizes: typeof fetchPrizes;
	fetchRules: typeof fetchRules;
	fetchGameWeeksJSON: typeof fetchGameWeeksJSON;
	leagues: ILeague[];
	is_data_requested: boolean;
	prizes: IPrizes[];
	rules: IRules[];
	readonly omniture_tracking: any;
	readonly ivw_tracking: any;
	user: IModelUser;
	actual_round?: IGameWeek;
}

class LeaguesComponent extends React.Component<IProps> {
	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {
			omniture_tracking,
			showMyLeagues: show,
			fetchPrizes: _fetchPrizes,
			fetchRules: _fetchRules,
			fetchGameWeeksJSON: fetchGameWeeks,
			ivw_tracking,
			user,
		} = this.props;
		_fetchPrizes();
		_fetchRules();
		fetchGameWeeks();
		show({});
		if (window._satellite) {
			window._satellite.track(
				"virtual_pv",
				omniture_tracking("leagues", "your leagues", "landing", user)
			);
		}

		if (GAME_NAME === "prosieben") {
			ivw_tracking("/us-sport/nfl/nfltippspiel/ligen");

			window.ga("send", {
				hitType: "pageview",
				page: "/us-sport/nfl/tippspiel/ligen",
			});
		}
		console.log("satellite tracking page load");

		createConnextraScriptTag(ConnextraType.LEAGUES_PAGE, user);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {leagues, is_data_requested, prizes, rules, actual_round} =
			this.props;

		return (
			<Content
				title={window.getTranslations("leagues_header_your_leagues")}
				header_background={
					GAME_NAME === "UK" ? header_bg_uk : header_bg
				}
				header_background_mobile={
					GAME_NAME === "UK" ? header_bg_uk : header_bg_mobile
				}
			>
				<ContentWithSidebar
					padding="0"
					sidebar_components={[
						<Widget
							key="Prizes"
							widget={{
								title: window.getTranslations(
									"pickem_nav_button_prizes"
								),
								content: prizes,
								type: "prizes",
								more: window.getTranslations(
									"global_term_load_more"
								),
								more_link: PrizesLink,
							}}
						/>,
						<Widget
							key="Scoring"
							widget={{
								title: window.getTranslations(
									"pickem_gamebar_text_scoring"
								),
								type: "scores",
								content: rules,
								more: window.getTranslations(
									"widget_leaguepage_text_find_more"
								),
								more_link: RulesLink,
							}}
						/>,
					]}
				>
					<LeagueNav
						title={window.getTranslations("mycomps_form_text_info")}
						description={window.getTranslations(
							"invites_form_text_compete"
						)}
						buttons={
							<LeagueNavButtons>
								<LeagueJoinLink>
									<RegularButton
										font_size={
											window.LANG_KEY === "de"
												? "12px"
												: "14px"
										}
									>
										{window.getTranslations(
											"competitionjoin_header_join_league"
										)}
									</RegularButton>
								</LeagueJoinLink>

								<LeagueCreateLink>
									<RegularButton
										font_size={
											window.LANG_KEY === "de"
												? "12px"
												: "14px"
										}
									>
										{window.getTranslations(
											"competitioncreate_header_create_league"
										)}
									</RegularButton>
								</LeagueCreateLink>
							</LeagueNavButtons>
						}
					/>

					{is_data_requested ? <LoadingSpinner /> : null}

					{size(leagues)
						? map(leagues, (league: ILeague, key: number) => (
								<League
									key={key}
									league={league}
									actual_round={actual_round}
								/>
						  ))
						: null}
				</ContentWithSidebar>
			</Content>
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	leagues: getMyLeagues(state),
	omniture_tracking: getOmnitureTracking,
	ivw_tracking: getIVWTracking,
	is_data_requested: isLeaguesDataRequested(state),
	prizes: getPrizes(state),
	faqs: getFAQs(state),
	rules: getRules(state),
	gameweeks: getActualGameWeeks(state),
	user: state.user,
	actual_round: getLastGameWeek(state),
});

const mapDispatchToProps = {
	fetchGameWeeksJSON,
	showMyLeagues,
	fetchPrizes,
	fetchRules,
	fetch,
};

export const Leagues = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(LeaguesComponent)
);
export default Leagues;
