import * as React from "react";
import {Close} from "../Icons/Close";
import {Button} from "./TooltipBublleStyle";
import {
	TooltipBubbleMobileDiv,
	TipButtonDiv,
	TipButton,
	TipTitleDiv,
	TipTextDiv,
	ContentWrapDiv,
	TipImg,
	CircleWrapDiv,
	InActiveCirlceDiv,
	ActiveCirlceDiv,
} from "./TooltipBubbleMobileStyle";
import {tooltipsText} from "./tooltipsText";

import tipBubble from "./../../assets/img/icons/tooltip-mobile/tip_bubble.png";
import formGuideBubble from "./../../assets/img/icons/tooltip-mobile/form_guide_bubble.png";
import marginBubble from "./../../assets/img/icons/tooltip-mobile/margin_bubble.png";
import totalTipsBubble from "./../../assets/img/icons/tooltip-mobile/total_tips_bubble.png";
import leaguesTipsBubble from "./../../assets/img/icons/tooltip-mobile/leagues_bubble.png";
import {get} from "lodash";
import {IDictionary} from "modules/types";

const getTipImage = (str: string) => {
	const imgs: IDictionary<string> = {
		form_guide_bubble: formGuideBubble,
		margin_bubble: marginBubble,
		tip_bubble: tipBubble,
		total_tips_bubble: totalTipsBubble,
		leagues_bubble: leaguesTipsBubble,
	};
	return get(imgs, str, "");
};

interface IProps {
	onClose: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const TooltipBubbleMobile: React.FC<IProps> = ({onClose}: IProps) => {
	const tipIndexes = [
		"tip_bubble",
		"margin_bubble",
		"form_guide_bubble",
		"total_tips_bubble",
		"leagues_bubble",
	];
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const tipData = tipIndexes.map((item) => get(tooltipsText, item));
	const handleContinueButton = (
		event: React.SyntheticEvent<HTMLButtonElement>
	) => {
		currentIndex === 4 ? onClose(event) : setCurrentIndex(currentIndex + 1);
	};
	return (
		<TooltipBubbleMobileDiv className="hidden-desktop">
			<Button onClick={onClose}>
				<Close />
			</Button>
			<ContentWrapDiv>
				<TipImg
					alt={
						window.getTranslations(tipData[currentIndex].mobText) ||
						""
					}
					src={getTipImage(tipIndexes[currentIndex])}
				/>
				<TipTitleDiv>
					{window.getTranslations(tipData[currentIndex].mobText) ||
						""}
				</TipTitleDiv>
				<TipTextDiv>
					{window.getTranslations(tipData[currentIndex].textKey) ||
						""}
				</TipTextDiv>
				<CircleWrapDiv>
					{tipIndexes.map((_, index) =>
						currentIndex === index ? (
							<ActiveCirlceDiv key={index} />
						) : (
							<InActiveCirlceDiv key={index} />
						)
					)}
				</CircleWrapDiv>
				<TipButtonDiv>
					<TipButton onClick={handleContinueButton}>
						{currentIndex === 4
							? window.getTranslations(
									"tutorial_button_finish_tour"
							  )
							: window.getTranslations(
									"tutorial_button_next_tip"
							  )}
					</TipButton>
				</TipButtonDiv>
			</ContentWrapDiv>
		</TooltipBubbleMobileDiv>
	);
};
