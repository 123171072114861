import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IRequestData, IRankItem} from "../../types";
import {concat} from "lodash";

export interface IRankings {
	readonly items: IRankItem[] | [];
	readonly next: boolean;
	readonly is_data_requested: boolean;
}

export type Rankings = IRankings & IRequestData;

const defaultState: Rankings = {
	items: [],
	next: false,
	is_data_requested: false,
};

export const rankings = createReducer<Rankings>({}, defaultState)
	.on(actions.getRankings, (state: Rankings) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.clearRankingsLadder, () => ({
		...defaultState,
		is_data_requested: false,
	}))
	.on(
		actions.getRankingsSuccess,
		(state: Rankings, payload: IRankItem[]) => ({
			...state,
			items: concat(state.items, payload),
			next: true,
			is_data_requested: false,
		})
	)
	.on(actions.getRankingsFailed, (state: Rankings) => ({
		...state,
		is_data_requested: false,
	}));
