import * as React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import theme from "../assets/css/theme";
import {SecondaryButton} from "../components";
import {withHeader} from "../components";

const NotFoundWrapper = styled.div`
	border-top: 8px solid #272935;
	border-bottom: 8px solid #272935;
	padding: 10px 0 20px;
	width: 210px;
	font-family: ${theme.base_bold_font};
	margin: 2em auto;
	text-align: center;
	span {
		font-size: 7em;
	}

	p {
		font-size: 1.5em;
	}
`;

const Title = styled.div`
	font-size: 16px;
	text-align: center;
	padding: 1em;
`;

const CustomLink = styled(NavLink)`
	display: block;
	max-width: 240px;
	margin: 1em auto;
	button {
		width: 100%;
	}
`;

export const NotFound = () => (
	<div>
		<NotFoundWrapper>
			<span>404</span>
			<p>{window.getTranslations("landing_form_page_not_found")}</p>
		</NotFoundWrapper>
		<Title>{window.getTranslations("modal_form_error_oops")}</Title>
		<CustomLink to="/">
			<SecondaryButton>
				{window.getTranslations("landing_form_take_me_home")}
			</SecondaryButton>
		</CustomLink>
	</div>
);

export default withHeader(NotFound);
