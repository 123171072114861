import * as React from "react";
import {IMyLeague, IStoreState} from "../../modules/types";
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import styled from "styled-components";
import {connect} from "react-redux";
import theme from "../../assets/css/theme";

import {below} from "../../assets/css/media";
import {isLoggedIn} from "../../modules/selectors";
import {IMenuItem, INavigation} from "../../modules/reducers/navigation";
import {getMyLeague} from "../../modules/selectors/leagues";
import {map} from "lodash";

const SubMenuWrapper = styled.div`
	display: flex;
	height: 48px;
	align-items: center;
	background: #ffffff;
	padding-left: 65px;

	${below.desktop`
		display: none;
	`};
`;

const SubMenuNavItem = styled(NavLink)`
	display: flex;
	align-items: center;
	height: 90%;
	font-family: ${theme.menu_font};
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	margin: 0 1.5em;
	color: ${theme.sub_menu_color};
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;

	${below.desktop`
		margin: 0 1em;
		padding: 6px;
	`} &.active, &:hover {
		color: ${theme.sponsor_league_secondary_color};
		border-bottom: 2px solid ${theme.sponsor_league_secondary_color};
	}
`;

interface IRouteParams {
	comp_id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
	readonly is_logged_in: boolean;
	readonly navigation: INavigation;
	readonly league: IMyLeague;
}

export class SubNavigationComponent extends React.Component<IProps> {
	/**
	 * @ignore
	 */
	public render() {
		const {
			match: {
				params: {comp_id},
			},
		} = this.props;

		if (comp_id) {
			return this.league_nav;
		}

		const menu = this.current_sub_menu;

		return menu && menu.sub_menu ? (
			<SubMenuWrapper>
				{map(menu.sub_menu, (item: IMenuItem, key: number) => (
					<SubMenuNavItem key={key} to={item.url}>
						{window.getTranslations(item.title)}
					</SubMenuNavItem>
				))}
			</SubMenuWrapper>
		) : null;
	}

	private get current_sub_menu() {
		const {
			match: {url},
			navigation,
		} = this.props;

		return navigation.main_menu.find((item) =>
			Boolean(
				(item.url === url && Boolean(item.sub_menu)) ||
					(item.sub_menu &&
						item.sub_menu.find((sub_item) => sub_item.url === url))
			)
		);
	}

	private get league_nav() {
		const {
			league,
			match: {
				params: {comp_id},
			},
		} = this.props;

		const showUsersTab =
			league.user_status === "commissioner" &&
			league.status === "scheduled";

		return (
			<SubMenuWrapper>
				<SubMenuNavItem to={`/league/${comp_id}/ladder`}>
					Ladder
				</SubMenuNavItem>
				{showUsersTab ? (
					<SubMenuNavItem to={`/league/${comp_id}/users`}>
						{window.getTranslations(
							"competitioninvite_subnav_tab_users"
						)}
					</SubMenuNavItem>
				) : null}
				<SubMenuNavItem to={`/league/${comp_id}/about`}>
					{window.getTranslations("aboutcompetition_title_th")}
				</SubMenuNavItem>
			</SubMenuWrapper>
		);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: isLoggedIn(state),
	navigation: state.navigation,
	league: getMyLeague(state) as IMyLeague,
});

export const SubNavigation = withRouter(
	connect(mapStateToProps)(SubNavigationComponent)
);

export default SubNavigation;
