import * as React from "react";
import styled from "styled-components";
import {bindAll, debounce} from "lodash";
import {isMobile} from "../../modules/utils";

const Header = styled.div`
	background: #1757df;
	.container {
		max-width: 1000px;
		margin: 0 auto;
		min-height: 66px;
		display: flex;
		align-items: center;
		position: relative;
		padding: 0 1em;
	}
	.furniture-logo a {
		width: 80px;
		height: 30px;
		display: block;
		background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/PjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI4MHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCA4MCAzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgODAgMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPiYjeEQ7IC5zdDB7ZmlsbDojRkZGRkZGO30mI3hEOzwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTYuNCwxMS4xYzEuMi0zLjksMy45LTguNywxMS4zLTguN2g1LjdsMS43LDUuNWgtNS42Yy0zLjcsMC01LjEsMS41LTYsNC40TDguOSwyNy42SDEuNUw2LjQsMTEuMXogTTU0LjQsNy43IGMwLjktMy4xLDIuNi01LjMsNy4yLTUuM0g3MWM1LDAsNy41LDIuNSw3LjUsNi4yYzAsMS4yLTAuMywyLjgtMC42LDMuOWwtNC41LDE1LjFoLTcuNGw0LjgtMTZjMC4yLTAuOCwwLjQtMS41LDAuNC0yLjEgYzAtMS4yLTAuOC0xLjctMi4yLTEuN2gtNS40Yy0xLjUsMC0yLjEsMC41LTIuNCwxLjdsLTUuMywxOGgtNy40TDU0LjQsNy43eiBNMjcuNywyLjRsMS42LDUuNWgxMC41YzEuNCwwLDIuMSwwLjUsMi4xLDEuNCBjMCwwLjQtMC4xLDAuOS0wLjMsMS4zbC0wLjQsMS4zSDI5LjdjLTQuNywwLTcuMSwyLjMtOC41LDYuNGMtMC42LDEuOC0wLjksMy41LTAuOSw1YzAsMi4yLDEuNCw0LjQsNS42LDQuNGgxMy4zIGMzLjksMCw1LjQtMS44LDYuNC01LjNsMi45LTkuOWMwLjUtMS44LDAuNy0zLjUsMC43LTQuNGMwLTMuMi0yLTUuOC03LTUuOEgyNy43eiBNMzguNywyMC42Yy0wLjMsMS0wLjgsMS44LTIuNSwxLjhoLTcuNiBjLTAuOCwwLTEuMy0wLjUtMS4zLTEuMWMwLTAuNiwwLjMtMS43LDAuNS0yLjJjMC41LTEuNCwxLjEtMi4xLDMtMi4xaDlMMzguNywyMC42eiIvPjwvc3ZnPg==");
	}
	.furniture-menu {
		margin: 0 auto;

		@media screen and (max-width: 840px) {
			margin: 0 0 0 auto;
		}
	}
	.furniture-menu li {
		display: inline-block;
	}
	.furniture-menu li a {
		text-transform: uppercase;
		color: #fff;
		font-family: Arial, Helvetica, sans-serif;
		text-decoration: none;
		padding: 1em;
		font-size: 13px;
		font-weight: 900;
	}
	.flexMenu-popup {
		position: absolute;
		background: #1657df;
		border-top: 1px solid #fff;
		width: 40%;
		z-index: 10;
		top: 100%;
		right: 0;
	}
	.flexMenu-popup li {
		display: block;
		padding: 0.5em 0;
	}
	.footer-furniture {
		background: #041c3a;
		font-size: 10px;
	}
	.footer-furniture a {
		text-transform: uppercase;
		color: #fff;
		font-family: Arial, Helvetica, sans-serif;
		text-decoration: none;
	}
	.footer-furniture .furniture-footer-top-menu {
		border-bottom: 1px solid #3174ff;
	}
	.footer-furniture .furniture-footer-top-menu li {
		display: inline-block;
		padding: 0.5em 1em;
	}
	.footer-furniture .furniture-footer-main-menus {
		display: flex;
		padding: 1em;
	}
	.footer-furniture .furniture-footer-main-menus .menu-item {
		flex: 1;
	}
	.footer-furniture .furniture-footer-main-menus li {
		padding: 0.3em;
	}
	.footer-furniture .furniture-footer-copyright {
		padding: 1.5em 1em;
	}
	.footer-furniture .furniture-footer-copyright a {
		color: #85878a;
	}
	.footer-furniture .furniture-footer-sponsor-text {
		padding: 1em;
	}
	@media screen and (max-width: 840px) {
		.js-furniture-menu-flex {
			display: flex;
			flex-direction: column;
			border-top: 1px solid #fff;
			width: 40%;
			z-index: 15;
			top: 100%;
			right: 0;
			position: absolute;
			background: #1657df;
			li {
				padding: 5px 0;
			}
		}
	}
`;

const ToggleButton = styled.button`
	color: #fff;
	cursor: pointer;
	background: none;
	border-radius: 0;
	border: 0;
	outline: 0;
	font-size: 16px;
`;

interface IState {
	open: boolean;
	is_mobile: boolean;
}

export class TopProsibenHeader extends React.Component<{}, IState> {
	public state = {
		open: false,
		is_mobile: isMobile(),
	};
	private onResizeDebounced?: () => void;

	constructor(props: {}, state: IState) {
		super(props, state);
		bindAll(this, ["toggle", "onResize"]);
	}

	public componentDidMount(): void {
		const debounce_time = 100;
		this.onResizeDebounced = debounce(this.onResize, debounce_time);
		window.addEventListener("resize", this.onResizeDebounced);
	}

	public render() {
		const {open, is_mobile} = this.state;
		return (
			<Header>
				<div className="container">
					<div className="furniture-logo">
						<a
							href="https://www.ran.de"
							title="Zur Startseite"
							target="_self"
							data-trackevent="Logo Click"
							data-track="Zur Startseite"
						/>
					</div>
					<div className="furniture-menu">
						<ToggleButton
							onClick={this.toggle}
							className="hidden-desktop"
						>
							MEHR
						</ToggleButton>
						{!is_mobile || open ? (
							<ul className="js-furniture-menu-flex">
								<li>
									<a href="https://www.ran.de/datenbank">
										Heute Live
									</a>
								</li>
								<li>
									<a href="https://www.ran.de/us-sport/nfl">
										NFL
									</a>
								</li>
								<li>
									<a href="https://www.ran.de/fussball">
										Fussball
									</a>
								</li>
								<li>
									<a href="https://www.ran.de/motorsport/dtm">
										DTM
									</a>
								</li>
								<li>
									<a href="https://www.ran.de/rugby">Rugby</a>
								</li>
								<li>
									<a href="https://www.ran.de/esports">
										eSports
									</a>
								</li>
							</ul>
						) : null}
					</div>
				</div>
			</Header>
		);
	}

	private toggle() {
		this.setState({
			open: !this.state.open,
		});
	}

	private onResize() {
		const is_mobile = isMobile();

		this.setState({
			is_mobile,
		});
	}
}

export default TopProsibenHeader;
