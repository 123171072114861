import styled from "styled-components";

const mapDirectionToDegrees = {
	top: 0,
	right: 90,
	bottom: 180,
	left: 270,
};

type Direction = "top" | "bottom" | "left" | "right";

interface IPropSide {
	to: Direction;
}

interface IProps {
	width: number;
	height: number;
	color: string;
	to: Direction;
}

const SvgContainer = styled.svg`
	transform: rotate(${({to}: IPropSide) => mapDirectionToDegrees[to]}deg);
`;

export const Chevron = ({width, height, to, color, ...rest}: IProps) => (
	<SvgContainer
		to={to}
		width={`${width}px`}
		height={`${height}px`}
		viewBox={`0 0 14 9`}
		{...rest}
	>
		<title>Chevron</title>
		<defs>
			<polygon
				id="path-1"
				points="12 8.879 18.707 15.586 17.293 17 12 11.707 6.707 17 5.293 15.586"
			/>
		</defs>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-375.000000, -1740.000000)">
				<g transform="translate(0.000000, 1704.000000)">
					<g transform="translate(370.000000, 28.000000)">
						<mask id="mask-2" fill="white">
							<use xlinkHref="#path-1" />
						</mask>
						<g
							id="Swatch"
							stroke-width="1"
							fill-rule="evenodd"
							mask="url(#mask-2)"
							fill={color}
						>
							<rect
								id="Rectangle"
								x="0"
								y="0"
								width="24"
								height="24"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgContainer>
);

Chevron.defaultProps = {
	width: 14,
	height: 9,
	color: "currentColor",
	to: "top",
};

export default Chevron;
