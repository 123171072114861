import * as React from "react";
import {Accordion} from "../../../components/Accordion";
import {MobileSectionHeader} from "../../../components/MobileSectionHeader";
import styled from "styled-components";
import {IComponentClass} from "pages/Help/help";

const ContentWrapper = styled.div`
	padding: 30px 20px;
	background-color: #fff;
`;

interface IProps {
	name: string;
	ComponentClass: React.ComponentType<IComponentClass>;
}

export const MobileContent = ({name, ComponentClass}: IProps) => (
	<Accordion>
		{(toggleState, state) => (
			<React.Fragment>
				<Accordion.ToggleIcon
					onClick={toggleState}
					state={state}
					scope_on_click={true}
				>
					<MobileSectionHeader>
						{window.getTranslations(name) || ""}
					</MobileSectionHeader>
				</Accordion.ToggleIcon>
				<Accordion.Content state={state}>
					<ContentWrapper>
						<ComponentClass header_hidden={true} />
					</ContentWrapper>
				</Accordion.Content>
			</React.Fragment>
		)}
	</Accordion>
);
