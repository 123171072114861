import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IRequestData} from "../../types";

export interface IContactUsReduser extends IRequestData {
	is_success?: boolean;
	name: string;
	email: string;
	category: string;
	question: string;
}

const defaultState: IContactUsReduser = {
	is_data_requested: false,
	is_success: false,
	name: "",
	email: "",
	category: "",
	question: "",
};

export const contact_us = createReducer<IContactUsReduser>({}, defaultState)
	.on(actions.saveContactUs, () => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(actions.saveContactUsSuccess, (state: IContactUsReduser) => ({
		...state,
		is_success: true,
	}))
	.on(actions.clearContactUsSuccess, (state) => ({
		...state,
		is_data_requested: false,
	}))
	.on(actions.saveContactUsFailed, () => ({
		...defaultState,
	}));
