import {IStoreState} from "../types";
import {get} from "lodash";

export const getPrizes = ({jsons}: IStoreState) => get(jsons, "prizes", []);
export const getFAQs = ({jsons}: IStoreState) => get(jsons, "faqs", []);
export const getRules = ({jsons}: IStoreState) => get(jsons, "rules", []);
export const getPrivacyPolicy = ({jsons}: IStoreState) =>
	get(jsons, "privacy_policy", []);
export const getGuidelines = ({jsons}: IStoreState) =>
	get(jsons, "guidelines", []);
