import styled from "styled-components";
import search from "../../assets/img/icons/search.png";
import theme from "../../assets/css/theme";

export const SearchField = styled.input`
	background-image: url(${search});
	background-repeat: no-repeat;
	background-position: center right 20px;
	box-sizing: border-box;
	height: 40px;
	max-width: 100%;
	width: 100%;
	border: 1px solid #cccccc;
	border-radius: 4px;
	margin-bottom: 20px;
	background-color: #ffffff;
	padding: 15px;
	&::placeholder {
		font-size: 14px;
		color: ${theme.placeholder};
	}
`;

export default SearchField;
