import {TooltipBubbleStyled} from "./../../components/TooltipBubble/TooltipBublleStyle";
import styled, {css} from "styled-components";
import {below} from "../../assets/css/media";
import theme from "../../assets/css/theme";

interface ITipWrapper {
	is_absolute: boolean;
}

export const TipWrapper = styled.div<ITipWrapper>`
	position: relative;
	background: #ffffff;
	width: 100%;
	margin-bottom: 30px;
	border-radius: 3px;
	box-shadow: 0 1px 2px 1px #ececec;

	${below.desktop`
		margin: 5px 0;
	`}
	${({is_absolute}) =>
		is_absolute
			? css`
					position: absolute;
					z-index: 2;
					box-shadow: 0 0 10px 0 rgba(224, 224, 224, 0.5);
			  `
			: ""};
`;

export const MatchBlock = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	border-bottom: 1px solid #eeeeee;
	font-size: 10px;
	font-family: ${theme.base_bold_font};
	color: #555555;
	letter-spacing: 0.1px;

	${TooltipBubbleStyled} {
		left: 46%;
		top: -45px;

		@media (max-width: 960px) {
			left: 41%;
		}

		@media (max-width: 700px) {
			left: 30%;
		}
	}
`;

export const OpponentWrapper = styled.div`
	display: flex;
	align-items: center;
	@media screen and (max-width: 840px) {
		position: relative;
	}
`;

const pickActionGradient = (position: string) => {
	if (position === "away") {
		return `linear-gradient(90deg, ${theme.pick_color} 30%, #fcfdff 96%)`;
	}

	return `linear-gradient(90deg, #fcfdff 30%, ${theme.pick_color}  96%);`;
};

interface IOpponent {
	readonly status: string;
	readonly is_wone: boolean;
	readonly position: string;
}

export const Opponent = styled.div`
	display: flex;
	height: 90px;
	align-items: center;
	flex: 50%;
	justify-content: space-between;

	&.checked {
		background: ${({status, position, is_wone}: IOpponent) => {
			if (status === "complete") {
				if (is_wone) {
					return position === "away"
						? "linear-gradient(90deg, #e7fddb 30%, #ffffff 96%);"
						: "linear-gradient(90deg, #ffffff  30%, #e7fddb 96%);";
				} else {
					return position === "away"
						? "linear-gradient(90deg, #ffd6d6 30%, #ffffff 96%);"
						: "linear-gradient(90deg, #ffffff 30%, #ffd6d6 96%);";
				}
			}
			return pickActionGradient(position);
		}};
	}
`;

export const HomeSquad = styled(Opponent)`
	padding-right: 20px;
	${below.desktop`
	padding-right: 10px;
	`};
	@media (max-width: 400px) {
		padding-right: 5px;
	}
`;

export const AwaySquad = styled(Opponent)`
	padding-left: 20px;
	${below.desktop`
	padding-left: 10px;
	`};
	@media (max-width: 400px) {
		padding-left: 5px;
	}
`;

export const SquadName = styled.div`
	font-size: 14px;
	letter-spacing: 0.1px;

	${below.desktop`
		font-size: 10px;
	`};
`;

export const SquadInfo = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	@media screen and (max-width: 840px) {
		position: static;
	}

	img {
		${below.desktop`
			width: 40px;
			margin: 0 10px;
		`};
	}
`;

export const SquadWrapper = styled.div`
	text-align: center;
`;

export const PriceBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	background: ${theme.odds_bg};
	border-radius: 0 0 3px 3px;
	position: relative;
	width: 100%;
	&.uk-brand {
		padding: 3px 0;
		height: 30px;
		box-sizing: border-box;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
		img {
			height: 24px;
		}
	}
`;

export const SkyBetBarImg = styled.img`
	height: 30px !important;
`;

interface ISquadPoints {
	readonly is_lose: boolean;
}

export const SquadPoints = styled.div`
	display: flex;
	font-size: 40px;
	color: ${({is_lose}: ISquadPoints) => (is_lose ? "#757575" : "#151515")};
	align-items: center;
	margin: 0 20px;
	font-family: ${theme.base_font_medium_display};

	${below.desktop`
		font-size: 24px;
		margin: 0 10px;
	`} span {
		position: relative;
	}

	@media (max-width: 400px) {
		font-size: 18px;
	}
`;

export const OdsItem = styled.div`
	color: #ffffff;
	margin: 0 20px;
	flex: 1;
	text-align: center;
	font-size: 14px;
`;

export const BettingPartner = styled.div`
	flex: 1;
	text-align: center;
	color: #ffffff;
	font-size: 14px;

	img {
		height: 20px;
	}
`;

export const MarginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const MarginInputWrapper = styled.div`
	position: relative;
	width: 300px;
	${below.desktop`
		width: 80%;
		position: static;
	`};
`;

export const MarginButton = styled.button`
	width: 145px;
	color: #1b48e0;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	font-family: ${theme.base_bold_font};
	display: flex;
	align-items: center;
	background: none;
	border: none;
	outline: none;
	justify-content: ${({to}: {to: string}) =>
		to === "left" ? "flex-start" : "flex-end"};

	svg {
		color: #1b48e0;
		padding: 0 20px;
	}

	&:disabled {
		opacity: 0.7;
		color: #757575;

		svg {
			color: #757575;
		}
	}

	span {
		text-transform: uppercase;
	}

	${below.desktop`
		span {
			display: none;
		}
		svg {
			padding: 0;
		}
	`};
`;

export const MarginDescription = styled.p`
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: center;
	font-family: ${theme.base_font};
	color: #757575;
	margin-bottom: 10px;
`;

export const MarginInput = styled.input`
	height: 40px;
	width: 100%;
	border: 1px solid #cccccc;
	border-radius: 4px;
	background-color: #ffffff;
	text-align: center;
	font-size: 14px;
	color: #757575;
	font-family: ${theme.base_font};
`;

export const MatchComplete = styled.div`
	font-size: 10px;
	color: #757575;
	text-transform: uppercase;
	font-family: ${theme.base_bold_font};
`;

export const MatchLive = styled.div`
	color: #3cb200;
	text-transform: uppercase;
	font-size: 10px;
	font-family: ${theme.base_bold_font};
`;

export const MarginInfo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #333333;
	font-size: 14px;
	margin-top: 20px;
	font-family: ${theme.base_bold_font};
`;

export const FinalMargin = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #1b48e0;
	font-family: ${theme.base_bold_font};
	font-size: 18px;
	margin-top: 5px;
	padding-bottom: 5px;
`;

export const BroadcastLogo = styled.a`
	position: absolute;
	right: 10px;
	top: 5px;
	z-index: 0;

	img {
		max-width: 90px;
		max-height: 22px;
		${below.desktop`
			max-width: 100%;
		`};
	}

	${below.desktop`
		max-width: 60px;
		top: 9px;
	`};
`;

export const SkyBetTipLink = styled.a`
	${below.desktop`
		font-size:12px
	`};
`;
