import styled from "styled-components";
import theme from "../../../assets/css/theme";
import * as React from "react";
import {below} from "../../../assets/css/media";

const LeagueNavWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 90px;
	border-radius: 4px;
	background-color: #f1f1f1;
	border: 2px solid #cccccc;
	padding: 17px 20px 17px 30px;
	margin-bottom: 30px;
	box-sizing: border-box;

	${below.desktop`
		flex-flow: column;
		height: auto;
		border: none;
		padding: 0 20px;
	`};
`;

const LeagueNavText = styled.div`
	max-width: 410px;
	padding-right: 15px;
	width: 100%;

	${below.desktop`
		max-width: 100%;
		width: 100%;
		padding:0;
	`};
`;

const LeagueNavTitle = styled.p`
	font-family: ${theme.base_bold_font};
	color: ${theme.titles};
	font-size: 12px;
	line-height: 14px;
`;

const LeagueNavDescription = styled.p`
	font-family: ${theme.base_font};
	color: ${theme.descriptions};
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
	margin-top: 10px;
`;

interface IProps {
	title: string | undefined;
	description: string | undefined;
	buttons: React.ReactElement;
}

export const LeagueNav = ({title, description, buttons}: IProps) => (
	<LeagueNavWrapper>
		<LeagueNavText>
			<LeagueNavTitle>{title}</LeagueNavTitle>
			<LeagueNavDescription>{description}</LeagueNavDescription>
		</LeagueNavText>

		{buttons}
	</LeagueNavWrapper>
);
