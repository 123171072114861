import * as React from "react";
import {PageAccountWrapper} from "./pageAccountWrapper";
import {withHeader} from "../../components";
import DeactivatePage from "./deactivateAccount/deactivatePage";

export const DeactivateAccount = () => (
	<PageAccountWrapper
		title={
			window.getTranslations("myaccount_button_deactivate_account") || ""
		}
	>
		<DeactivatePage />
	</PageAccountWrapper>
);

export default withHeader(DeactivateAccount);
