import styled from "styled-components";
import {ReactNode} from "react";
import * as React from "react";
import {Header} from "../../../components";
import theme from "../../../assets/css/theme";
import {below, above} from "../../../assets/css/media";
import {IFunc0} from "../../../modules/types";

interface IModelWrapper {
	readonly is_open?: boolean;
}

export const ModalWrapper = styled.div`
	position: fixed;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: ${({is_open}: IModelWrapper) => (is_open ? "flex" : "none")};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
	background: rgba(19, 18, 18, 0.62);
`;

const Content = styled.div`
	position: relative;
	background: #ffffff;
	width: 55%;
	font-family: ${theme.base_font};
	${below.desktop`
        width: 100%;
        height: 100%;
    `};
`;

export const Close = styled.div`
	position: absolute;
	right: -35px;
	cursor: pointer;
	width: 20px;
	height: 18px;
	top: 0;
	${below.desktop`
        right: 15px;
        top: 13px;
    `} &::before, &::after {
		content: "";
		position: absolute;
		height: 3px;
		width: 20px;
		border-radius: 1px;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #ffffff;
		${below.desktop`
            background: #616161;
            width: 16px;
        `};
	}

	&::before {
		transform: rotate(45deg);
	}
	&::after {
		transform: rotate(-45deg);
	}
`;

const HeaderWrapper = styled.div`
	${above.desktop`
        display: none;
    `};
`;

interface IModalSkeleton {
	readonly is_open?: boolean;
	readonly children: ReactNode;
	readonly show_close: boolean;
	onClose?: IFunc0;
}

export const ModalBlock = ({
	children,
	is_open,
	onClose,
	show_close,
}: IModalSkeleton) => (
	<ModalWrapper is_open={is_open}>
		<HeaderWrapper>
			<Header />
		</HeaderWrapper>
		<Content>
			{show_close ? <Close onClick={onClose} /> : null}
			{children}
		</Content>
	</ModalWrapper>
);

export default ModalBlock;
