import styled from "styled-components";
import theme from "../../assets/css/theme";
import {NavLink} from "react-router-dom";
import promo_leage_bg from "../../assets/img/header_bg_mobile.png";

/*
<PromoLeagueWrapDiv>
  <PromoLeagueLink to="/leagues/join/12345678">
    <PromoLeagueSponsorImg src={tab_logo} />
    <PromoLeagueSpan>
      Join TAB Official league
    </PromoLeagueSpan>
  </PromoLeagueLink>
</PromoLeagueWrapDiv>
 */

export const PromoLeagueWrapDiv = styled.div`
	background-image: url(${promo_leage_bg});
	margin: 20px 0 0;
`;

export const PromoLeagueLink = styled(NavLink)`
	background-color: rgba(0, 133, 66, 0.7);
	color: #fff;
	display: flex;
	height: 100%;
	width: 100%;
`;

export const PromoLeagueSponsorImg = styled.img`
	height: 50px;
	margin: 10px;
`;

export const PromoLeagueSpan = styled.div`
	display: flex;
	flex-direction: column;
	font-family: ${theme.menu_font};
	font-size: 18px;
	padding: 0 10px;
	justify-content: center;
	text-transform: uppercase;
`;

export const StandingsWrapper = styled.div`
	margin-top: 30px;
`;

export const Title = styled.h4`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
`;

export const SwitchStandings = styled.div`
	margin-top: 25px;
`;

export const DivisionalWrapper = styled.div`
	background: #ffffff;
	margin: 25px 0;
`;

interface ITableRow {
	readonly is_active?: boolean;
}

export const TableRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	height: 40px;
	border-bottom: 1px solid #eeeeee;
	font-size: 12px;
	font-family: ${theme.base_font_medium_display};
	background: ${({is_active}: ITableRow) =>
		is_active ? "#e4f4fd" : "transparent"};

	&:nth-child(2n + 1) {
		background: ${({is_active}: ITableRow) =>
			is_active ? "#e4f4fd" : "#fafafa"};
	}
`;

export const ItemValue = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ItemName = styled(ItemValue)`
	flex: 35%;
	justify-content: flex-start;
	padding-left: 5px;
`;
