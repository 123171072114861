import * as React from "react";
import {IStoreState} from "../../modules/types";
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import styled from "styled-components";
import {connect} from "react-redux";
import theme from "../../assets/css/theme";
import {bindAll, map} from "lodash";

import {below} from "../../assets/css/media";
import {isLoggedIn} from "../../modules/selectors";
import {IMainMenuItem, INavigation} from "../../modules/reducers/navigation";
import ExternalIcon from "../../assets/img/icons/external.png";
import {TooltipBubble} from "../../components";

const BaseMenuWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	height: 62px;
	font-family: ${theme.base_font};

	${below.desktop`
		display: none;
	`};
`;

interface INavItem {
	readonly hidden: boolean;
}

const NavItem = styled(NavLink)`
	display: ${({hidden}: INavItem) => (hidden ? "none" : "flex")};
	align-items: center;
	height: 96%;
	font-family: ${theme.menu_font};
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	margin: 0 1.5em;
	color: ${theme.menu_color};
	opacity: 1;

	${below.desktop`
		margin: 0 1em;
		padding: 6px;
	`} &.active, &:hover {
		color: #ffffff;
		opacity: 1;
	}
`;

const ExternalNavItem = styled.a`
	display: ${({hidden}: INavItem) => (hidden ? "none" : "flex")};
	align-items: center;
	height: 96%;
	font-family: ${theme.menu_font};
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	margin: 0 1.5em;
	color: ${theme.menu_color};
	opacity: 1;
	background: url(${ExternalIcon}) no-repeat right 44%;
	padding-right: 30px;

	${below.desktop`
		margin: 0 1em;
		padding: 6px;
	`} &:hover {
		color: #ffffff;
		opacity: 1;
	}
`;

const SECRET_KEY_ENABLED = JSON.parse(
	process.env.REACT_APP_SECRET_KEY || "false"
);

const OFF_SEASON_ENABLED = JSON.parse(
	process.env.REACT_APP_OFF_SEASON || "false"
);

interface IProps extends RouteComponentProps {
	readonly is_logged_in: boolean;
	readonly navigation: INavigation;
}

export const SECRET_COUNT = 10;

export class HeaderNavigationComponent extends React.Component<IProps> {
	public state = {
		showCrossPromotion: false,
	};

	constructor(props: IProps) {
		super(props);
		bindAll(this, ["navToolTip", "closeToolTip", "openToolTip"]);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {
			is_logged_in,
			navigation: {main_menu},
		} = this.props;

		if (this.secret_key_enabled || this.checkOnOffSeason) {
			return null;
		}

		return (
			<React.Fragment>
				<BaseMenuWrapper>
					{map(main_menu, (item: IMainMenuItem, key: number) =>
						!item.external ? (
							<NavItem
								key={key}
								exact={true}
								to={item.url}
								hidden={
									(item.only_is_login && !is_logged_in) ||
									Boolean(item.only_mobile)
								}
							>
								{window.getTranslations(item.title)}
							</NavItem>
						) : (
							<ExternalNavItem
								href={item.url}
								key={key}
								target="_blank"
								rel="noopener noreferrer"
								hidden={item.url === ""}
								onMouseEnter={this.openToolTip}
								onMouseLeave={this.closeToolTip}
							>
								{window.getTranslations(item.title)}
							</ExternalNavItem>
						)
					)}
				</BaseMenuWrapper>
				{this.state.showCrossPromotion && (
					<React.Fragment>
						<TooltipBubble
							bubble_name={"nav-propmo"}
							onClose={this.navToolTip}
							text={"navToolTip"}
							tipIndex={0}
							to={"top"}
						/>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}

	private get secret_key_enabled() {
		const {is_logged_in} = this.props;

		return (
			!!SECRET_KEY_ENABLED &&
			!is_logged_in &&
			this.secret_count < SECRET_COUNT
		);
	}

	public get secret_count(): number {
		return Number(localStorage.getItem("secret_count"));
	}

	private openToolTip() {
		this.setState({
			showCrossPromotion: false,
		});
	}
	private closeToolTip() {
		this.setState({
			showCrossPromotion: false,
		});
	}
	private navToolTip() {
		this.setState({
			showCrossPromotion: false,
		});
	}
	private get checkOnOffSeason(): boolean {
		return OFF_SEASON_ENABLED && this.secret_count < SECRET_COUNT;
	}
}

const mapStateToProps = (state: IStoreState) => ({
	is_logged_in: isLoggedIn(state),
	navigation: state.navigation,
});

export const HeaderNavigation = withRouter(
	connect(mapStateToProps)(HeaderNavigationComponent)
);

export default HeaderNavigation;
