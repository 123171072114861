import {createReducer} from "redux-act";
import * as actions from "../../actions";
import {IMyLeague} from "./my_leagues";

export interface ILeagueManageReduser {
	[x: string]: any;

	readonly league: IMyLeague;
	readonly is_error: boolean;
}

const defaultState: ILeagueManageReduser = {
	is_data_requested: false,
	league: {
		id: 0,
		commissioner: 0,
		name: "",
		status: "scheduled",
		class: "",
		class_id: 0,
		rank: 0,
		avg_score: 0,
		avg_margin: 0,
		score: 0,
		avg_last_round_score: 0,
		avg_last_round_margin: 0,
		last_round_score: 0,
		start_round: 0,
		privacy: "public",
		code: "",
		num_teams: 0,
		coaches_box: 0,
		avatar_version: 0,
		regenerated_from: 0,
		description: "",
		start_at: new Date(),
		user_status: "user",
		total: 0,
	},
	is_error: false,
};

export const league_manage = createReducer<ILeagueManageReduser>(
	{},
	defaultState
)
	.on(actions.showLeague, (state: ILeagueManageReduser) => ({
		...state,
		is_data_requested: true,
	}))
	.on(
		actions.showLeagueSuccess,
		(state: ILeagueManageReduser, payload: IMyLeague) => ({
			...state,
			league: payload,
			is_data_requested: false,
			is_error: false,
		})
	)
	.on(actions.showLeagueFailed, (state: ILeagueManageReduser) => ({
		...state,
		is_error: true,
		is_data_requested: false,
	}))
	.on(actions.updateLeague, (state: ILeagueManageReduser) => ({
		...state,
		is_data_requested: true,
	}))
	.on(
		actions.updateLeagueSuccess,
		(state: ILeagueManageReduser, payload: IMyLeague) => ({
			...state,
			league: payload,
			is_data_requested: false,
		})
	)
	.on(actions.updateLeagueFailed, (state: ILeagueManageReduser) => ({
		...state,
		is_data_requested: false,
	}))
	.on(actions.clearLeaguesState, (state: ILeagueManageReduser) => ({
		...defaultState,
	}));
