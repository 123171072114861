import {RegularButton} from "./";
import styled from "styled-components";
import theme from "../../assets/css/theme";
import {below} from "../../assets/css/media";

export const RegularButtonOutline = styled(RegularButton)`
	border: 1px solid ${theme.primary};
	background-color: #fff;
	color: ${theme.primary};
	max-width: 170px;

	${below.desktop`
		font-family: ${theme.base_font};
	`};
`;
