import * as React from "react";
import {bindAll, toInteger, toString, get, eq} from "lodash";
import {
	Content,
	ContentWithSidebar,
	withHeader,
	SearchField,
	Select,
	IconArrowSlim,
	PrizesLink,
	RulesLink,
	Widget,
	SponsorImage,
} from "../../components";
import {connect} from "react-redux";
import LeagueAbout from "./leagueAbout/leagueAbout";
import InvitesLeague from "./leagueAbout/invitesLeague";
import MobileFooter from "./Ladder/mobileFooter";

import {
	LadderWrapper,
	LadderTable,
	LeagueName,
	PageFooter,
	TableBody,
	TableBodyCell,
	TableBodyRow,
	TableHead,
	TableHeadCell,
	FiltersWrapper,
	FiltersRight,
	CustomLabel,
	MobilePageFooter,
	FlexCell,
} from "../../components/Ladder";
import {
	getLeagueLadder,
	getSponsoredLadder,
	getMyLeague,
	getLadderRounds,
	getLastGameWeek,
} from "../../modules/selectors";
import {
	IGameWeek,
	ILeague,
	ILeagueLadder,
	ISponsoredLeagueLadderResponse,
	IStoreState,
	ILadderPayload,
	IRules,
	IPrizes,
	IModelUser,
	IMyLeague,
} from "../../modules/types";
import {RouteComponentProps} from "react-router";
import {
	fetchLadder,
	showSponsorLadder,
	showLeague,
	fetchGameWeeksJSON,
	fetchMoreLadder,
	fetchPrizes,
	fetchRules,
} from "../../modules/actions";
import {LoadMoreButton} from "../../components/Buttons/LoadMoreButton";
import {getPrizes, getRules} from "../../modules/selectors/jsons";
import header_bg from "../../assets/img/header_bg.png";
import header_bg_mobile from "../../assets/img/header_bg_mobile.png";
import header_bg_uk from "../../assets/img/header_bg_league_uk.jpg";
import SpnsoredLadder from "components/SponsoredLadder";
import {createFanHubEvent} from "../../modules/utils/FanHubTagging";

const GAME_NAME = process.env.REACT_APP_GAME_NAME;

interface IRouteParams {
	comp_id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
	fetchLadder: typeof fetchLadder;
	showSponsorLadder: typeof showSponsorLadder;
	showLeague: typeof showLeague;
	fetchGameWeeksJSON: typeof fetchGameWeeksJSON;
	fetchMoreLadder: typeof fetchMoreLadder;
	ladder: ILeagueLadder[];
	sponsored_rank: ISponsoredLeagueLadderResponse;
	league: ILeague | IMyLeague;
	rounds: IGameWeek[];
	last_gw?: IGameWeek;
	fetchPrizes: typeof fetchPrizes;
	fetchRules: typeof fetchRules;
	rules: IRules[];
	prizes: IPrizes[];
	user: IModelUser;
}

interface IState {
	selected_gw: number;
	search: string;
	order_direction: boolean;
	order: string;
}

class LadderPage extends React.Component<IProps, IState> {
	public default_order = "ASC";
	public state = {
		selected_gw: 0,
		search: "",
		order_direction: true,
		order: "rank",
	};
	constructor(props: IProps, state: IState) {
		super(props, state);
		bindAll(this, [
			"ladderLine",
			"changeGW",
			"searchPlayer",
			"sortBy",
			"loadMore",
		]);
	}

	public componentDidMount() {
		createFanHubEvent("fe7cf721-b45d-4b92-a5bb-95575916ce08");
		const {
			fetchLadder: getLadder,
			showSponsorLadder: getSponsorLadder,
			showLeague: fetchLeague,
			fetchGameWeeksJSON: fetchGameWeeks,
			match: {
				params: {comp_id},
			},
			fetchPrizes: _fetchPrizes,
			fetchRules: _fetchRules,
			last_gw,
		} = this.props;
		const id = toInteger(comp_id);

		_fetchPrizes();
		_fetchRules();
		getSponsorLadder({round: last_gw?.id || 1});
		getLadder({
			id,
		});
		fetchLeague(id);
		fetchGameWeeks();
	}

	public render() {
		const {
			prizes,
			rules,
			sponsored_rank,
			match: {
				params: {comp_id},
			},
		} = this.props;
		const CELEBRITY_LEAGUE_ID =
			process.env.REACT_APP_UK_CELEBRITY_LEAGUE_ID || "0";
		return (
			<React.Fragment>
				<Content
					title="Ladder"
					header_background={
						GAME_NAME === "UK" ? header_bg_uk : header_bg
					}
					header_background_mobile={
						GAME_NAME === "UK" ? header_bg_uk : header_bg_mobile
					}
				>
					<ContentWithSidebar
						className="low-margin-bottom"
						sidebar_components={[
							<Widget
								key="Prizes"
								widget={{
									title: window.getTranslations(
										"pickem_nav_button_prizes"
									),
									content: prizes,
									type: "prizes",
									more: window.getTranslations(
										"global_term_load_more"
									),
									more_link: PrizesLink,
								}}
							/>,

							<Widget
								key="Scoring"
								widget={{
									title: window.getTranslations(
										"pickem_gamebar_text_scoring"
									),
									type: "scores",
									content: rules,
									more: window.getTranslations(
										"widget_leaguepage_text_find_more"
									),
									more_link: RulesLink,
								}}
							/>,
						]}
						hidden_sidebar_on_mobile={true}
					>
						{GAME_NAME === "UK" &&
							comp_id === CELEBRITY_LEAGUE_ID && (
								<SpnsoredLadder
									sponsored_rank={sponsored_rank}
								/>
							)}
						{this.filters}
						{this.ladder}
					</ContentWithSidebar>
				</Content>
				{this.footer}
			</React.Fragment>
		);
	}

	private get filters() {
		return (
			<FiltersWrapper>
				<FiltersRight>
					<div>
						<CustomLabel>
							{window.getTranslations(
								"league_standings_search_player"
							)}
						</CustomLabel>
						<SearchField
							type="text"
							placeholder={window.getTranslations(
								"help_faq_placeholder_search"
							)}
							onChange={this.searchPlayer}
						/>
					</div>
				</FiltersRight>

				{this.game_weeks}
			</FiltersWrapper>
		);
	}

	private get game_weeks() {
		const {rounds} = this.props;
		const {selected_gw} = this.state;
		const selected_gw_string = toString(selected_gw);
		return (
			<div>
				<CustomLabel>
					{window.getTranslations("league_standings_game_week")}
				</CustomLabel>
				<Select
					name="game_weeks"
					id="game_weeks"
					value={selected_gw_string}
					onChange={this.changeGW}
				>
					<option value="0">
						{window.getTranslations("league_standings_all_weeks")}
					</option>
					{rounds.map(({id}) => (
						<option key={id} value={id}>
							{window.getTranslations("pickem_gamebar_game_week")}{" "}
							{id}
						</option>
					))}
				</Select>
			</div>
		);
	}

	private get ladder() {
		const {league} = this.props;
		const is_sponsor_league = league?.class === "overall";
		return (
			<div>
				<LeagueName is_sponsor_league={is_sponsor_league}>
					<span>{league.name}</span>
					{is_sponsor_league && (
						<SponsorImage
							position="league"
							showPresentedBy={false}
							showWhiteImage={true}
						/>
					)}
				</LeagueName>
				<LadderWrapper>
					<LadderTable>
						{this.table_heading}
						{this.table_body}
					</LadderTable>
					{this.load_more}
				</LadderWrapper>
			</div>
		);
	}

	private get table_heading() {
		const {selected_gw, order, order_direction} = this.state;
		const {last_gw, league} = this.props;
		const id = get(last_gw, "id", 1);
		const arrow_direction = order_direction ? "bottom" : "top";
		const is_sponsor_league = league?.class === "overall";
		return (
			<TableHead>
				<TableHeadCell
					cell_width={"15"}
					onClick={this.sortBy}
					data-sort={"rank"}
					is_active={eq(order, "rank")}
					value_align="center"
					is_sponsor_league={is_sponsor_league}
					is_sticky={true}
					first_column={true}
				>
					<FlexCell>
						<div>
							{window.getTranslations("pickem_gamebar_text_rank")}
							<IconArrowSlim to={arrow_direction} />
						</div>
					</FlexCell>
				</TableHeadCell>
				<TableHeadCell
					cell_width={"25"}
					onClick={this.sortBy}
					data-sort={"team_name"}
					is_active={eq(order, "team_name")}
					is_sponsor_league={is_sponsor_league}
					is_sticky={true}
				>
					<FlexCell>
						<div>
							Player <IconArrowSlim to={arrow_direction} />
						</div>
					</FlexCell>
				</TableHeadCell>
				<TableHeadCell
					cell_width={"15"}
					onClick={this.sortBy}
					data-sort={"round_points"}
					value_align={"right"}
					is_active={eq(order, "round_points")}
					is_sponsor_league={is_sponsor_league}
				>
					<FlexCell>
						<div>
							<IconArrowSlim to={arrow_direction} />
							<span className={"hidden-mobile"}>
								{window.getTranslations(
									"competitionjoin_list_placeholder_week"
								)}{" "}
								{selected_gw || id} points
							</span>
							<span className={"hidden-desktop"}>
								{window.getTranslations(
									"league_standings_w_points",
									[
										{
											points: selected_gw
												? selected_gw.toString()
												: id.toString(),
										},
									]
								)}
							</span>
						</div>
					</FlexCell>
				</TableHeadCell>
				<TableHeadCell
					cell_width={"15"}
					onClick={this.sortBy}
					data-sort={"round_margin"}
					value_align={"right"}
					is_active={eq(order, "round_margin")}
					is_sponsor_league={is_sponsor_league}
				>
					<FlexCell>
						<div>
							<IconArrowSlim to={arrow_direction} />
							<span className={"hidden-mobile"}>
								{window.getTranslations(
									"league_standings_week_margin",
									[
										{
											points: selected_gw
												? selected_gw.toString()
												: id.toString(),
										},
									]
								)}
							</span>
							<span className={"hidden-desktop"}>
								{window.getTranslations(
									"league_standings_margin_points",
									[
										{
											points: selected_gw
												? selected_gw.toString()
												: id.toString(),
										},
									]
								)}
							</span>
						</div>
					</FlexCell>
				</TableHeadCell>
				<TableHeadCell
					cell_width={"15"}
					onClick={this.sortBy}
					data-sort={"overall_points"}
					value_align={"right"}
					is_active={eq(order, "overall_points")}
					is_sponsor_league={is_sponsor_league}
				>
					<FlexCell>
						<div>
							<IconArrowSlim to={arrow_direction} />{" "}
							{window.getTranslations(
								"pickem_matchup_subheading_pts"
							)}
						</div>
					</FlexCell>
				</TableHeadCell>
				<TableHeadCell
					cell_width={"15"}
					onClick={this.sortBy}
					data-sort={"overall_margin"}
					value_align={"right"}
					is_active={eq(order, "overall_margin")}
					is_sponsor_league={is_sponsor_league}
				>
					<FlexCell>
						<div>
							<IconArrowSlim to={arrow_direction} />{" "}
							{window.getTranslations(
								"league_standings_total_margin"
							)}
						</div>
					</FlexCell>
				</TableHeadCell>
			</TableHead>
		);
	}

	private get table_body() {
		const {ladder} = this.props;
		return <TableBody>{ladder.map(this.ladderLine)}</TableBody>;
	}

	private ladderLine(line: ILeagueLadder) {
		const {user, league} = this.props;
		const {
			id,
			rank,
			team_name,
			round_points,
			round_margin,
			sum_points,
			sum_margin,
			first_name,
			last_name,
		} = line;

		return (
			<TableBodyRow
				key={id}
				is_active={team_name === user.team_name}
				is_sponsor_league={league?.class === "overall"}
			>
				<TableBodyCell
					value_align="center"
					is_sticky={true}
					first_column={true}
				>
					{this.getCellValue(rank)}
				</TableBodyCell>
				<TableBodyCell is_sticky={true}>
					<FlexCell>
						<strong>{team_name}</strong>{" "}
						<span>
							{first_name} {last_name[0]}
						</span>
					</FlexCell>
				</TableBodyCell>
				<TableBodyCell value_align={"right"}>
					<FlexCell>{this.getCellValue(round_points)}</FlexCell>
				</TableBodyCell>
				<TableBodyCell value_align={"right"}>
					<FlexCell>{this.getCellValue(round_margin)}</FlexCell>
				</TableBodyCell>
				<TableBodyCell value_align={"right"}>
					<FlexCell>{this.getCellValue(sum_points)}</FlexCell>
				</TableBodyCell>
				<TableBodyCell value_align={"right"}>
					<FlexCell>{this.getCellValue(sum_margin)}</FlexCell>
				</TableBodyCell>
			</TableBodyRow>
		);
	}

	private get footer() {
		const {
			league: {class: league_class},
			match: {
				params: {comp_id},
			},
		} = this.props;

		return (
			<React.Fragment>
				<MobilePageFooter className="hidden-desktop">
					{league_class !== "overall" && league_class !== "club" ? (
						<MobileFooter
							name={"leaguecreate_form_text_invite"}
							ComponentClass={InvitesLeague}
						/>
					) : null}
					<MobileFooter
						name={"aboutcompetition_title_th"}
						ComponentClass={LeagueAbout}
					/>
				</MobilePageFooter>

				<PageFooter className="hidden-mobile">
					<InvitesLeague league_id={parseInt(comp_id, 0)} />
					<LeagueAbout />
				</PageFooter>
			</React.Fragment>
		);
	}

	private getCellValue(value: number | null) {
		return value === 0 ? 0 : value || "-";
	}

	private get load_more() {
		const {league, ladder} = this.props;
		const {num_teams = 0} = league;

		if (!num_teams || !ladder.length || num_teams <= ladder.length) {
			return null;
		}

		return (
			<LoadMoreButton onClick={this.loadMore}>
				{window.getTranslations("global_term_load_more")}
			</LoadMoreButton>
		);
	}

	private loadMore(event: React.SyntheticEvent<HTMLButtonElement>) {
		event.preventDefault();
		const {selected_gw, order_direction, order} = this.state;
		const order_by = !order_direction ? "DESC" : "ASC";

		const {
			fetchMoreLadder: getLadder,
			match: {
				params: {comp_id},
			},
			ladder,
		} = this.props;
		const id = toInteger(comp_id);
		let data = {
			id,
			order,
			order_direction: order_by,
			offset: ladder.length,
		} as ILadderPayload;

		if (selected_gw) {
			data = {
				...data,
				round: selected_gw,
			};
		}

		getLadder(data);
	}

	private searchPlayer(e: React.SyntheticEvent<HTMLInputElement>) {
		e.preventDefault();
		const {value} = e.currentTarget;
		const {
			fetchLadder: getLadder,
			match: {
				params: {comp_id},
			},
		} = this.props;
		const {selected_gw} = this.state;
		const id = toInteger(comp_id);
		let data = {
			id,
			q: value,
		} as ILadderPayload;
		if (selected_gw) {
			data = {
				...data,
				round: selected_gw,
			};
		}
		getLadder(data);
	}

	private changeGW(e: React.SyntheticEvent<HTMLSelectElement>) {
		e.preventDefault();
		const {value} = e.currentTarget;
		const round = toInteger(value);
		const {
			fetchLadder: getLadder,
			showSponsorLadder: getSponsorLadder,
			match: {
				params: {comp_id},
			},
			last_gw,
		} = this.props;
		const id = toInteger(comp_id);
		getLadder({
			id,
		});

		this.setState({
			selected_gw: round,
		});

		if (!round) {
			getLadder({
				id,
			});
			getSponsorLadder({round: last_gw?.id || 1});
		} else {
			getLadder({
				id,
				round,
			});
			getSponsorLadder({round});
		}
	}

	private sortBy({currentTarget}: React.SyntheticEvent<HTMLElement>) {
		const {sort} = currentTarget.dataset;
		const {
			fetchLadder: getLadder,
			match: {
				params: {comp_id},
			},
		} = this.props;
		const {selected_gw, order_direction, order} = this.state;
		const id = toInteger(comp_id);
		const order_by = !order_direction ? "DESC" : "ASC";
		let data = {
			id,
			order: sort,
			order_direction: sort === order ? order_by : this.default_order,
		} as ILadderPayload;

		if (selected_gw) {
			data = {
				...data,
				round: selected_gw,
			};
		}

		this.setState({
			order: sort || "",
			order_direction: sort === order ? !order_direction : false,
		});

		getLadder(data);
	}
}

const mapStateToProps = (state: IStoreState) => ({
	league: getMyLeague(state) as IMyLeague,
	ladder: getLeagueLadder(state),
	sponsored_rank: getSponsoredLadder(state),
	rounds: getLadderRounds(state),
	last_gw: getLastGameWeek(state),
	prizes: getPrizes(state),
	rules: getRules(state),
	user: get(state, "user"),
});

const mapDispatchToProps = {
	fetchLadder,
	showSponsorLadder,
	showLeague,
	fetchGameWeeksJSON,
	fetchMoreLadder,
	fetchPrizes,
	fetchRules,
};

export const Ladder = withHeader(
	connect(mapStateToProps, mapDispatchToProps)(LadderPage)
);

export default Ladder;
