import {createReducer} from "redux-act";
import * as actions from "../../actions";

export interface ILeagueLadder {
	id: number;
	name: string;
	team_name: string;
	round_points: number | null;
	round_margin: number | null;
	global_rank: number | null;
	sum_points: number | null;
	sum_margin: number | null;
	rank: number | null;
	avg_points: number | string | null;
	first_name: string;
	last_name: string;
}

export interface ISponsoredLeague extends ILeagueLadder {
	email: string;
	round_rank: number | null;
	sponsor: string;
}

export interface IDraftLeagueReducer {
	readonly league_created_id: number | null;
	readonly ladder: ILeagueLadder[];
	readonly sponsored_rank: ISponsoredLeagueLadderResponse;
	readonly joined_users: IJoinedUser[];
}

export interface ILadderPayloadOrder {
	order:
		| "team_name"
		| "round_points"
		| "round_margin"
		| "overall_points"
		| "overall_margin"
		| "avg_points"
		| "rank";
}

export interface ILadderPayload {
	id: number;
	round?: number;
	type?: string;
	order?: ILadderPayloadOrder["order"];
	order_direction?: "ASC" | "DESC";
	q?: string;
	offset?: number;
	limit?: number;
}

export interface ISponsorLadderPayload {
	round: number;
}

export interface ILeagueLadderResponse {
	ladder: ILeagueLadder[];
	user_rank: number;
	sponsored_rank?: ISponsoredLeagueLadderResponse;
}

export interface ISponsoredLeagueLadderResponse {
	ladder: ISponsoredLeague[];
}

export interface IJoinedUsersPayload {
	league_id: number;
	q?: string;
	offset?: number;
	limit?: number;
}

export interface IJoinedUser {
	id: number;
	first_name: string;
	last_name: string;
	team_name: string;
	status: string;
	paid: number;
}

export type IJoinedUsersResponse = IJoinedUser[];

export interface IRemoveFromLeague {
	league_id: number;
	user_id: number;
}

const initialState: IDraftLeagueReducer = {
	league_created_id: null,
	ladder: [],
	sponsored_rank: {ladder: []},
	joined_users: [],
};

export const league = createReducer({}, initialState)
	.on(actions.createLeagueSuccess, (state, league_id) => ({
		...state,
		league_created_id: league_id,
	}))
	.on(actions.clearLeagueFlags, (state) => ({
		...state,
		league_created_id: null,
	}))
	.on(
		actions.fetchLadderSuccess,
		(state, payload: ILeagueLadderResponse) => ({
			...state,
			ladder: payload.ladder,
		})
	)
	.on(
		actions.showSponsorLadderSuccess,
		(state, payload: ISponsoredLeagueLadderResponse) => ({
			...state,
			sponsored_rank: payload,
		})
	)
	.on(
		actions.showJoinedUsersSuccess,
		(state, payload: IJoinedUsersResponse) => ({
			...state,
			joined_users: payload,
		})
	)
	.on(
		actions.removeFromLeagueSuccess,
		(state, payload: IRemoveFromLeague) => ({
			...state,
			joined_users: state.joined_users.filter(
				(user) => user.id !== payload.user_id
			),
		})
	);
