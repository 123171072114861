import * as React from "react";
import {PageAccountWrapper} from "./pageAccountWrapper";
import NflAccountPage from "./nflAccount/nflAccountPage";
import {withHeader} from "../../components/Header";

export const NFLAccount = () => (
	<PageAccountWrapper title="NFL account">
		<NflAccountPage />
	</PageAccountWrapper>
);

export default withHeader(NFLAccount);
